!(function (e, t) {
    'object' == typeof exports && 'object' == typeof module
        ? (module.exports = t(require('tinymce')))
        : 'function' == typeof define && define.amd
        ? define(['tinymce'], t)
        : 'object' == typeof exports
        ? (exports['tinymce-paste-from-word-plugin'] = t(require('tinymce')))
        : (e['tinymce-paste-from-word-plugin'] = t(e.tinymce));
})(this, (e) =>
    (() => {
        'use strict';
        var t = {
                928: (t) => {
                    t.exports = e;
                },
            },
            r = {};
        function n(e) {
            var a = r[e];
            if (void 0 !== a) return a.exports;
            var i = (r[e] = { exports: {} });
            return t[e](i, i.exports, n), i.exports;
        }
        (n.n = (e) => {
            var t = e && e.__esModule ? () => e.default : () => e;
            return n.d(t, { a: t }), t;
        }),
            (n.d = (e, t) => {
                for (var r in t) n.o(t, r) && !n.o(e, r) && Object.defineProperty(e, r, { enumerable: !0, get: t[r] });
            }),
            (n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t));
        var a = n(928),
            i = n.n(a);
        function s(e, t) {
            return (
                i().util.Tools.each(t, function (t) {
                    e = t.constructor === RegExp ? e.replace(t, '') : e.replace(t[0], t[1]);
                }),
                e
            );
        }
        var o = function (e) {
                var t = !1;
                return (
                    (e = e.replace(/^[\u00a0 ]+/, '')),
                    i().util.Tools.each(
                        [
                            /^[IVXLMCD]+\.[ \u00a0]/,
                            /^[ivxlmcd]+\.[ \u00a0]/,
                            /^[a-z]{1,2}[\.\)][ \u00a0]/,
                            /^[A-Z]{1,2}[\.\)][ \u00a0]/,
                            /^[0-9]+\.[ \u00a0]/,
                            /^[\u3007\u4e00\u4e8c\u4e09\u56db\u4e94\u516d\u4e03\u516b\u4e5d]+\.[ \u00a0]/,
                            /^[\u58f1\u5f10\u53c2\u56db\u4f0d\u516d\u4e03\u516b\u4e5d\u62fe]+\.[ \u00a0]/,
                        ],
                        function (r) {
                            if (r.test(e)) return (t = !0), !1;
                        }
                    ),
                    t
                );
            },
            l = function (e, t, r) {
                var n = {},
                    a = i().DOM.parseStyle(r);
                return (
                    i().util.Tools.each(a, function (e, a) {
                        switch (a) {
                            case 'mso-list':
                                var i = /\w+ \w+([0-9]+)/i.exec(r);
                                i && (t._listLevel = parseInt(i[1], 10)),
                                    /Ignore/i.test(e) && t.firstChild && ((t._listIgnore = !0), (t.firstChild._listIgnore = !0));
                                break;
                            case 'horiz-align':
                                a = 'text-align';
                                break;
                            case 'vert-align':
                                a = 'vertical-align';
                                break;
                            case 'font-color':
                            case 'mso-foreground':
                                a = 'color';
                                break;
                            case 'mso-background':
                            case 'mso-highlight':
                                a = 'background';
                                break;
                            case 'font-weight':
                            case 'font-style':
                                return void ('normal' !== e && (n[a] = e));
                            case 'text-indent':
                            case 'margin':
                            case 'margin-top':
                            case 'margin-bottom':
                            case 'margin-left':
                            case 'margin-right':
                                if ('li' === t.name) return;
                                break;
                            case 'mso-element':
                                if (/^(comment|comment-list)$/i.test(e)) return void t.remove();
                        }
                        0 !== a.indexOf('mso-comment') ? 0 !== a.indexOf('mso-') && (n[a] = e) : t.remove();
                    }),
                    /(bold)/i.test(n['font-weight']) && (delete n['font-weight'], t.wrap(new (i().html.Node)('b', 1))),
                    /(italic)/i.test(n['font-style']) && (delete n['font-style'], t.wrap(new (i().html.Node)('i', 1))),
                    i().DOM.serializeStyle(n, t.name) || null
                );
            };
        return (
            i().PluginManager.add('paste_from_word', function (e) {
                return (
                    e.on('PastePreProcess', function (t) {
                        var r;
                        (r = t.content),
                            (/<font face="Times New Roman"|class="?Mso|style="[^"]*\bmso-|style='[^']*\bmso-|w:WordDocument/i.test(r) ||
                                /class="OutlineElement/.test(r) ||
                                /id="?docs\-internal\-guid\-/.test(r)) &&
                                (t.content = (function (e, t) {
                                    t = s(t, [
                                        /<br class="?Apple-interchange-newline"?>/gi,
                                        /<b[^>]+id="?docs-internal-[^>]*>/gi,
                                        /<!--[\s\S]+?-->/gi,
                                        /<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s\/>]))[^>]*>/gi,
                                        [/<(\/?)s>/gi, '<$1strike>'],
                                        [/&nbsp;/gi, ' '],
                                        [
                                            /<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi,
                                            function (e, t) {
                                                return t.length > 0
                                                    ? t
                                                          .replace(/./, ' ')
                                                          .slice(Math.floor(t.length / 2))
                                                          .split('')
                                                          .join(' ')
                                                    : '';
                                            },
                                        ],
                                    ]);
                                    var r = e.getParam(
                                            'pastefromword_valid_elements',
                                            '-strong/b,-em/i,-u,-span,-p,-ol,-ul,-li,-h1,-h2,-h3,-h4,-h5,-h6,-p/div,-a[href|name],sub,sup,strike,br,del,table[width],tr,td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody'
                                        ),
                                        n = i().html.Schema({ valid_elements: r, valid_children: '-li[p]' });
                                    i().util.Tools.each(n.elements, function (e) {
                                        e.attributes.class || ((e.attributes.class = {}), e.attributesOrder.push('class')),
                                            e.attributes.style || ((e.attributes.style = {}), e.attributesOrder.push('style'));
                                    });
                                    var a = i().html.DomParser({}, n);
                                    a.addAttributeFilter('style', function (e) {
                                        for (var t, r = e.length; r--; )
                                            (t = e[r]).attr('style', l(0, t, t.attr('style'))),
                                                'span' === t.name && t.parent && !t.attributes.length && t.unwrap();
                                    }),
                                        a.addAttributeFilter('class', function (e) {
                                            for (var t, r, n = e.length; n--; )
                                                (r = (t = e[n]).attr('class')),
                                                    /^(MsoCommentReference|MsoCommentText|msoDel)$/i.test(r) && t.remove(),
                                                    t.attr('class', null);
                                        }),
                                        a.addNodeFilter('del', function (e) {
                                            for (var t = e.length; t--; ) e[t].remove();
                                        }),
                                        a.addNodeFilter('a', function (e) {
                                            for (var t, r, n, a = e.length; a--; )
                                                if (((r = (t = e[a]).attr('href')), (n = t.attr('name')), r && -1 !== r.indexOf('#_msocom_'))) t.remove();
                                                else if ((r && 0 === r.indexOf('file://') && (r = r.split('#')[1]) && (r = '#' + r), r || n)) {
                                                    if (n && !/^_?(?:toc|edn|ftn)/i.test(n)) {
                                                        t.unwrap();
                                                        continue;
                                                    }
                                                    t.attr({ href: r, name: n });
                                                } else t.unwrap();
                                        });
                                    var u = a.parse(t);
                                    return (
                                        e.getParam('pastefromword_convert_fake_lists', !0) &&
                                            (function (e) {
                                                for (
                                                    var t,
                                                        r,
                                                        n = 1,
                                                        a = function (e) {
                                                            var t = '';
                                                            if (3 === e.type) return e.value;
                                                            if ((e = e.firstChild))
                                                                do {
                                                                    t += a(e);
                                                                } while ((e = e.next));
                                                            return t;
                                                        },
                                                        s = function (e, t) {
                                                            if (3 === e.type && t.test(e.value)) return (e.value = e.value.replace(t, '')), !1;
                                                            if ((e = e.firstChild))
                                                                do {
                                                                    if (!s(e, t)) return !1;
                                                                } while ((e = e.next));
                                                            return !0;
                                                        },
                                                        u = function (e) {
                                                            if (e._listIgnore) e.remove();
                                                            else if ((e = e.firstChild))
                                                                do {
                                                                    u(e);
                                                                } while ((e = e.next));
                                                        },
                                                        c = function (e, a, o) {
                                                            var c = e._listLevel || n;
                                                            c !== n && (c < n ? t && (t = t.parent.parent) : ((r = t), (t = null))),
                                                                t && t.name === a
                                                                    ? t.append(e)
                                                                    : ((r = r || t),
                                                                      (t = new (i().html.Node)(a, 1)),
                                                                      o > 1 && t.attr('start', '' + o),
                                                                      e.wrap(t)),
                                                                (e.name = 'li'),
                                                                e.attr('style', l(0, e, e.attr('style'))),
                                                                c > n && r && r.lastChild.append(t),
                                                                (n = c),
                                                                u(e),
                                                                s(e, /^\u00a0+/),
                                                                s(e, /^\s*([\u2022\u00b7\u00a7\u25CF]|\w+\.)/),
                                                                s(e, /^\u00a0+/);
                                                        },
                                                        f = [],
                                                        d = e.firstChild;
                                                    null != d;

                                                )
                                                    if ((f.push(d), null !== (d = d.walk()))) for (; void 0 !== d && d.parent !== e; ) d = d.walk();
                                                for (var m = 0; m < f.length; m++)
                                                    if ('p' === (e = f[m]).name && e.firstChild) {
                                                        var p = a(e);
                                                        if (/^[\s\u00a0]*[\u2022\u00b7\u00a7\u25CF]\s*/.test(p)) {
                                                            c(e, 'ul');
                                                            continue;
                                                        }
                                                        if (o(p)) {
                                                            var h = /([0-9]+)\./.exec(p),
                                                                g = 1;
                                                            h && (g = parseInt(h[1], 10)), c(e, 'ol', g);
                                                            continue;
                                                        }
                                                        if (e._listLevel) {
                                                            c(e, 'ul', 1);
                                                            continue;
                                                        }
                                                        t = null;
                                                    } else (r = t), (t = null);
                                            })(u),
                                        s((t = i().html.Serializer({}, n).serialize(u)), [/<span[^>]*>\s*<\/span>/gi])
                                    );
                                })(e, t.content));
                    }),
                    {
                        getMetadata: function () {
                            return { name: 'Paste from Word', url: 'https://github.com/pangaeatech/tinymce-paste-from-word-plugin' };
                        },
                    }
                );
            }),
            {}
        );
    })()
);
