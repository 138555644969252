x
<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div v-if="auth.user().roleId == 2 || isProfile" class="links">
            <ul>
                <li class="active">
                    <a class="nav-link" data-toggle="tab" href="#student" aria-label="Student Details"> STUDENT DETAILS </a>
                </li>
                <li v-if="auth.user().roleId == 2">
                    <a class="nav-link" data-toggle="tab" href="#courses" aria-label="Courses"> COURSES </a>
                </li>
                <!--<li>
					<a class="nav-link" data-toggle="tab" href="#log">ACTIVITY LOG</a>
				</li>-->
                <li v-if="isProfile && APP_ENV !== 'production'">
                    <a class="nav-link" data-toggle="tab" href="#receipt">RECEIPTS</a>
                </li>
            </ul>
        </div>
        <div v-if="auth.user().roleId <= 2 || auth.user().uuid == student.uuid" class="row tab-content">
            <div id="student" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">
                            {{ student.displayName }}
                        </div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs hidden-sm">
                                <template v-if="!student.isSuspended">
                                    <router-link
                                        v-if="isProfile"
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'profile.edit',
                                            params: $route.params.id,
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else-if="!isFromCourse && auth.user().roleId == 2"
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'students.edit',
                                            params: { id: $route.params.id },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else-if="auth.user().roleId == 2"
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: $route.params.course_id,
                                                id: $route.params.id,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                        </button>
                                    </router-link>
                                </template>
                                <button
                                    v-if="!student.isSuspended && !isProfile && student.email"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key marginRight8" aria-hidden="true"></i>Reset Password
                                </button>
                                <!--<router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact>
					            	<i class="fa fa-caret-left"></i>&nbsp;Back
					            </router-link>
					            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
					             	<i class="fa fa-caret-left"></i>&nbsp;Back
					            </router-link>-->
                            </div>
                            <div class="hidden-md hidden-lg">
                                <template v-if="!student.isSuspended">
                                    <router-link
                                        v-if="isProfile"
                                        v-slot="{ navigate }"
                                        v-tooltip="'Edit'"
                                        :to="{
                                            name: 'profile.edit',
                                            params: $route.params.id,
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else-if="!isFromCourse && auth.user().roleId == 2"
                                        v-slot="{ navigate }"
                                        v-tooltip="'Edit'"
                                        :to="{
                                            name: 'students.edit',
                                            params: { id: $route.params.id },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else-if="auth.user().roleId == 2"
                                        v-slot="{ navigate }"
                                        v-tooltip="'Edit'"
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: $route.params.course_id,
                                                id: $route.params.id,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                </template>
                                <button
                                    v-if="!student.isSuspended && !isProfile && student.email"
                                    v-tooltip="{ content: 'Reset Password' }"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                </button>
                                <!-- <router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
					            	<i class="fa fa-caret-left"></i>
					            </router-link>
					            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn"  v-tooltip="{content: 'Back'}">
					            	<i class="fa fa-caret-left"></i>
					            </router-link>
					       	 	-->
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="col-xs-12 col-md-6 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">FULL NAME</label>
                                        <p class="form-control-static">
                                            {{ student.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">EMAIL</label>
                                        <p class="form-control-static">
                                            {{ student.email ? student.email : '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">STATUS</label>
                                        <p class="form-control-static">
                                            <i
                                                class="fa fa-circle marginRight8"
                                                :class="{
                                                    suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(student.status),
                                                    readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(student.status),
                                                    activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(student.status),
                                                }"
                                                aria-hidden="true"
                                            >
                                            </i>
                                            {{ student.status }}
                                            <!--
				                        	<template v-if="!student.isSuspended">
					                            <i class="fa fa-circle activeCircle"></i>&nbsp;Active
					                        </template>
					                        <template v-else>
					                            <i class="fa fa-circle suspendCircle"></i>&nbsp;Suspended
					                        </template>
											-->
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACTIVATED ON</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateActivated">
                                                {{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">CREATED BY</label>
                                        <p class="form-control-static">
                                            {{ student.createBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">CREATED ON</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateCreated">
                                                {{ convertToReadableDate(student.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACCOUNT LAST LOGIN</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateLastLogin">
                                                {{ convertToReadableDate(student.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACCOUNT LAST MODIFIED</label>
                                        <p class="form-control-static">
                                            <template v-if="student.lastModified">
                                                {{ convertToReadableDate(student.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPayPlan()" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">PAYMENT STATUS</label>
                                        <p class="form-control-static">
                                            <template v-if="student.status != 'Active (Paid)'">
                                                <span style="color: #dc3545"> Not yet Paid </span>
                                                <router-link
                                                    v-if="auth.user().roleId == 2"
                                                    class="link"
                                                    :to="{
                                                        name: 'payments.gift',
                                                        params: {
                                                            id: student.uuid,
                                                        },
                                                    }"
                                                    >Gift Subscription</router-link
                                                >
                                            </template>
                                            <template v-else>
                                                <span style="color: #28a745">
                                                    Paid on
                                                    {{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </span>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">PAYMENT METHOD</label>
                                        <p class="form-control-static">
                                            <template v-if="student.paymentMode">
                                                <template v-if="student.paymentMode == 'paypal' && student.amount != 0"> Credit Card </template>
                                                <template v-else-if="student.paymentMode == 'paypal' && student.amount == 0"> Activated by Superuser </template>
                                                <template v-else-if="student.paymentMode == 'access'">
                                                    Access Code
                                                    {{ student.accessCode }}
                                                </template>
                                                <template v-else>
                                                    {{ student.paymentMode }}
                                                </template>
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="student.isSuspended" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">SUSPENDED ON</label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(student.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(student.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ORIGIN</label>
                                        <p class="form-control-static">
                                            {{ student.origin }}
                                        </p>
                                    </div>
                                </div>
                                <div v-if="isPayPlan()" class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACCOUNT EXPIRY DATE</label>
                                        <p class="form-control-static">
                                            <template v-if="student.endDate">
                                                {{ convertToReadableDate(student.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <div
                    v-if="auth.user().roleId == 2 || (auth.user().roleId == 1 && isPayPlan() && student.status != 'Active (Paid)')"
                    class="marginBottom30 flexRight"
                >
                    <div class="dropdown col-xs-2">
                        <button
                            id="viewDropdownMenuButton"
                            class="mainBtn width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-label="More Actions"
                        >
                            More Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                            <ul>
                                <li v-if="!student.isSuspended && auth.user().roleId == 2">
                                    <a
                                        v-tooltip="'Suspend Account'"
                                        class="danger-state"
                                        :class="{
                                            disabled: student.hasTeacherAccount,
                                        }"
                                        href="#"
                                        aria-label="Suspend Account"
                                        @click.prevent="openSuspendModal()"
                                    >
                                        <span> <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend Account </span>
                                    </a>
                                </li>

                                <li v-else-if="student.isSuspended && auth.user().roleId == 2">
                                    <a v-tooltip="'Reinstate'" class="primary-state" href="#" aria-label="Reinstate" @click.prevent="openSuspendModal()">
                                        <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>
                                    </a>
                                </li>

                                <li v-else-if="auth.user().roleId == 1">
                                    <a
                                        v-tooltip="'Set Student As Paid'"
                                        class="primary-state"
                                        href="#"
                                        aria-label="Set Student As Paid"
                                        @click.prevent="openSetStudentAsPaidModal()"
                                    >
                                        <span> <i class="fa fa-check-square-o marginRight8" aria-hidden="true"></i>Set Student As Paid </span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- <div
              class="dropdown-menu"
              aria-labelledby="viewDropdownMenuButton"
              style="top:inherit;margin-top:0;"
            >
              <button
                class="btn btn-danger"
                v-if="!student.isSuspended && auth.user().roleId == 2"
                :disabled="(student.hasTeacherAccount) ? true:undefined"
                @click.prevent="openSuspendModal()"
                aria-label="Suspend Account"
              >
                <i class="fa fa-archive"></i>&nbsp;Suspend Account
              </button>
              <button
                class="btn btn-success"
                v-else-if="student.isSuspended && auth.user().roleId == 2"
                @click.prevent="openSuspendModal()"
                aria-label="Reinstate"
              >
                <i class="fa fa-refresh"></i>&nbsp;Reinstate
              </button>
              <button
                class="btn btn-success"
                v-else-if="auth.user().roleId == 1"
                @click.prevent="openSetStudentAsPaidModal()"
                aria-label="Set Student As Paid"
              >
                Set Student As Paid
              </button>
            </div> -->
                    </div>
                </div>

                <kr-panel v-if="auth.user().roleId <= 2" :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">Logs</div>
                    </template>
                    <template #content>
                        <ul class="timeline">
                            <template v-for="(log, idx) in logs" :key="idx">
                                <li
                                    class="event completed"
                                    :data-date="
                                        convertToReadableDate(log.created_at, 'DD MMM YYYY, hh:mm a').date +
                                        ' ' +
                                        convertToReadableDate(log.created_at, 'DD MMM YYYY, hh:mm a').current_timezone
                                    "
                                >
                                    <div>
                                        <p>{{ log.description }}</p>
                                    </div>
                                </li>
                            </template>
                            <!--<li class="event" data-date="08 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Lynx Santiago entered access code 20210408UP3ryert678. Status: Successful</p>
						    	</div>
						    </li>
						    <li class="event" data-date="08 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Account is lapsed due to incomplete payment</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="05 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Lynx Santiago submitted IRAT TBL Online Introduction.</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="05 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Lynx Santiago started IRAT TBL Online Introduction.</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="05 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Lynx Santiago performed a password reset.</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="04 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Lynx Santiago activated the account and set a password.</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="04 Apr 2021 10am (UTC+8)">
						    	<div>
						      		<p>Alex Santiago invited Lynx Santiago to the course TBL Online.</p>
						    	</div>
						    </li>
						    <li class="event completed" data-date="01 Apr 2021 8am (UTC+8)">
						    	<div>
						      		<p>Alex Santiago added Lynx Santiago to the course TBL Online.</p>
						    	</div>
						    </li>-->
                        </ul>
                    </template>
                </kr-panel>
            </div>
            <div v-if="auth.user().roleId == 2" id="courses" class="tab-pane fade in">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title>
                        <div class="col-xs-6">COURSES</div>
                        <!--<div class="col-xs-6 flexRight">
							<div class="col-xs-2 buttonGroup">
								<div class="hidden-xs hidden-sm">
						            <router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>&nbsp;Back
						            </router-link>
						            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>&nbsp;Back
						            </router-link>
						        </div>
						        <div class="hidden-md hidden-lg">
						            <router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>
						            </router-link>
						            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>
						            </router-link>
						        </div>
					        </div>
					    </div>-->
                    </template>
                    <template #content>
                        <div class="row col-xs-12">
                            <div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th colspan="12">All Courses ({{ student.courses.length }})</th>
                                            </tr>
                                            <tr>
                                                <th>COURSE</th>
                                                <th style="width: 132px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(course, c_idx) in student.courses">
                                                <td>
                                                    <router-link
                                                        v-tooltip="{
                                                            content: 'View Course',
                                                        }"
                                                        tag="a"
                                                        :to="{
                                                            name: 'courses.view',
                                                            params: {
                                                                id: course.uuid,
                                                            },
                                                        }"
                                                        class="link"
                                                        :aria-label="course.code ? course.code + ' - ' + course.name : course.name"
                                                    >
                                                        {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button
                                                            id="viewDropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            aria-label="Actions"
                                                        >
                                                            Actions
                                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                        </button>

                                                        <div
                                                            class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                            aria-labelledby="viewDropdownMenuButton"
                                                        >
                                                            <ul>
                                                                <li>
                                                                    <router-link
                                                                        v-tooltip="'View Course'"
                                                                        class="primary-state"
                                                                        :to="{
                                                                            name: 'courses.view',
                                                                            params: {
                                                                                id: course.uuid,
                                                                            },
                                                                        }"
                                                                        aria-label="View Course"
                                                                    >
                                                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true"></i>View Course </span>
                                                                    </router-link>
                                                                </li>

                                                                <li>
                                                                    <a
                                                                        v-tooltip="'Disenroll Student From Course'"
                                                                        class="danger-state"
                                                                        href="#"
                                                                        aria-label="Disenroll Student From Course"
                                                                        @click.prevent="removeStudentFromCourse(course, c_idx)"
                                                                    >
                                                                        <span>
                                                                            <i class="fa fa-minus-circle marginRight8" aria-hidden="true"></i>Disenroll Student
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <!-- <router-link
                            tag="button"
                            :to="{
                              name: 'courses.view',
                              params: { id: course.uuid },
                            }"
                            class="btn btn-primary subBtn hidden-xs hidden-sm"
                            v-tooltip="{ content: 'View Course' }"
                            aria-label="View Course"
                            ><i class="fa fa-eye"></i>&nbsp;View
                            Course</router-link
                          >
                          <button
                            class="btn btn-danger subBtn"
                            @click.prevent="
                              removeStudentFromCourse(course, c_idx)
                            "
                            aria-label="Disenroll Student From Course"
                          >
                            Disenroll Student from Course
                          </button>
                          <router-link
                            tag="button"
                            v-tooltip="{ content: 'View Course' }"
                            :to="{
                              name: 'courses.view',
                              params: { id: course.uuid },
                            }"
                            class="btn btn-primary mainBtn hidden-md hidden-lg"
                            aria-label="View"
                          >
                            <i class="fa fa-eye"></i>
                          </router-link> -->
                                                </td>
                                            </tr>
                                            <tr v-if="student.courses.length == 0">
                                                <td colspan="3" style="text-align: center">NO DATA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--<div class="displayNo">
		                        Displaying 5 of 5 courses
		                    </div>-->
                        </div>
                    </template>
                </kr-panel>
            </div>
            <!--<div id="log" class="tab-pane fade in">
				<kr-panel :withFooter="false">
					<template #title>
						<div class="col-xs-6">
							ACTIVITY LOG
						</div>	
						<div class="col-xs-6 flexRight">
							<div class="col-xs-2 buttonGroup">
								<div class="hidden-xs hidden-sm">
						            <router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>&nbsp;Back
						            </router-link>
						            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>&nbsp;Back
						            </router-link>
						        </div>
						        <div class="hidden-md hidden-lg">
						            <router-link v-if="!isFromCourse" :to="{name:'students.index'}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>
						            </router-link>
						            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
						            	<i class="fa fa-caret-left"></i>
						            </router-link>
						        </div>
					        </div>
					    </div>
					</template>
				    <template #content>
				    	<kr-search name="log_listing" :url="{url:'/users/activity-log/index'}" 
			                :columns="{
			                    user:'display:NAME|sortable:false|width:35%|mobile:hide',
			                    event:'display:ACTION|sortable:false|width:30%',
			                    dateCreated :'display:TIMESTAMP|sortable:false|width:45%',
			                }"
			                :options="{trash_field:false,suspended_field:false,action_column:false,search_field:false,query_fields:query_fields}"
			                @mounted="initStartDatepicker();initEndDatepicker()"
			                >
			               <template #lower-left>
			                	<div class="form-group periodBars">
								    <label class="col-form-label">VIEW PERIOD</label>
								    <div class="form-date">
						                <input type="text" class="form-control" name="start_log_daterange" placeholder="Select start period">
						            </div>
						            <div class="form-date">
						                <input type="text" class="form-control" name="end_log_daterange" placeholder="Select end period">
						            </div>
								</div>	
					        </template>	
			                <template slot="top-display-from-to" slot-scope="props">
			                    <div class="displayNo">
			                        {{props.model.from}}-{{props.model.to}} of {{props.model.meta.paginator.total}}
			                    </div>
			                </template>
			                <template #table-title="props">
			                    <th colspan="6">All Logs ({{props.model.paginator.total}})</th>
			                </template>
			                 <template #bottom-display-from-to="props">
			                    <div class="displayNo">
			                        {{props.model.from}}-{{props.model.to}} of {{props.model.meta.paginator.total}}
			                    </div>
			                </template>
			                <template #cdata="props">
			                    <td class="hidden-xs hidden-sm">
			                        {{props.model.user}}
			                    </td>
			                    <td>
			                        {{props.model.event}}
			                    </td>
			                    <td>
			                       <template v-if="props.model.dateCreated"> {{convertToReadableDate(props.model.dateCreated,'LLLL').date}}</template> <template v-else>N/A</template>
			                    </td>
			               </template>                     
						</kr-search>
				    </template>
			    </kr-panel>
			</div>-->
            <!--v-if="isProfile&&APP_ENV!=='production'"-->
            <div v-if="isProfile && APP_ENV !== 'production'" id="receipt" class="tab-pane fade in">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">RECEIPTS</div>
                    </template>
                    <template #content>
                        <kr-search
                            name="receipt_listing"
                            :url="{ url: '/profile/payment-plans' }"
                            :columns="{
                                user: 'display:NAME|sortable:false|width:35%|mobile:hide',
                                startDate: 'display:START DATE|sortable:false|width:30%',
                                endDate: 'display:END DATE|sortable:false|width:30%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: true,
                                search_field: false,
                                query_fields: query_fields,
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>
                            <!-- <template slot="top-display-from-to" slot-scope="props">
			                    <div class="displayNo">
			                        {{props.model.from}}-{{props.model.to}} of {{props.model.meta.paginator.total}}
			                    </div>
			                </template> -->
                            <template #table-title="props">
                                <th colspan="6">All Receipts ( {{ props.model.from }}-{{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }}-{{ props.model.to }}
                                    of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>
                                <td>
                                    <template v-if="props.model.startDate"> {{ convertToReadableDate(props.model.startDate, 'LLLL').date }}</template>
                                    <template v-else>N/A</template>
                                </td>
                                <td>
                                    <template v-if="props.model.endDate"> {{ convertToReadableDate(props.model.endDate, 'LLLL').date }}</template>
                                    <template v-else>N/A</template>
                                </td>
                                <td>
                                    <div class="buttonGroupInline hidden-xs hidden-sm">
                                        <button
                                            v-tooltip="{
                                                content: 'Download Receipt',
                                            }"
                                            class="btn btn-primary subBtn"
                                            aria-label="Download"
                                            @click="downloadReceipt(props.model.uuid)"
                                        >
                                            <i class="fa fa-dowload marginRight8" aria-hidden="true"></i>Download
                                        </button>
                                    </div>
                                    <div class="buttonGroupInline hidden-md hidden-lg">
                                        <button
                                            v-tooltip="{
                                                content: 'Download Receipt',
                                            }"
                                            class="btn btn-primary subBtn"
                                            aria-label="Download"
                                            @click="downloadReceipt(props.model.uuid)"
                                        >
                                            <i class="fa fa-dowload" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>
        <div v-if="auth.user().roleId == 3" class="row">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">
                        {{ student.displayName }}
                        <p>{{ student.email ? student.email : '-' }}</p>
                    </div>
                    <template v-if="auth.user().uuid == student.uuid">
                        <div class="col-xs-6 buttonGroup flexRight">
                            <div class="hidden-xs hidden-sm">
                                <template v-if="auth.user().uuid == student.uuid">
                                    <router-link
                                        v-if="!isFromCourse"
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'students.edit',
                                            params: { id: $route.params.id },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: $route.params.course_id,
                                                id: $route.params.id,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                        </button>
                                    </router-link>
                                </template>

                                <router-link v-if="!isFromCourse" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                    </button>
                                </router-link>
                                <router-link
                                    v-else
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: $route.params.course_id },
                                        query: { tab: 'student' },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <template v-if="auth.user().uuid == student.uuid">
                                    <router-link
                                        v-if="!isFromCourse"
                                        v-slot="{ navigate }"
                                        v-tooltip="'Edit'"
                                        :to="{
                                            name: 'students.edit',
                                            params: { id: $route.params.id },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        v-tooltip="'Edit'"
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: $route.params.course_id,
                                                id: $route.params.id,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                </template>

                                <router-link v-if="!isFromCourse" v-slot="{ navigate }" v-tooltip="'Back'" :to="{ name: 'students.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left" aria-hidden="true"></i>
                                    </button>
                                </router-link>
                                <router-link
                                    v-else
                                    v-slot="{ navigate }"
                                    v-tooltip="'Back'"
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: $route.params.course_id },
                                        query: { tab: 'student' },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left" aria-hidden="true"></i>
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-xs-6 flexRight">
                            <div class="col-xs-2 buttonGroup">
                                <div class="hidden-xs hidden-sm">
                                    <router-link v-if="!isFromCourse" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'courses.view',
                                            params: {
                                                id: $route.params.course_id,
                                            },
                                            query: { tab: 'student' },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link v-if="!isFromCourse" v-slot="{ navigate }" v-tooltip="'Back'" :to="{ name: 'students.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        v-tooltip="'Back'"
                                        :to="{
                                            name: 'courses.view',
                                            params: {
                                                id: $route.params.course_id,
                                            },
                                            query: { tab: 'student' },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <template #content>
                    <div class="row col-xs-12">
                        <div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="6">All Courses ({{ student.courses.length }})</th>
                                        </tr>
                                        <tr>
                                            <th>COURSE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="course in student.courses">
                                            <td>
                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                            </td>
                                        </tr>
                                        <tr v-if="student.courses.length == 0">
                                            <td colspan="2" style="text-align: center">NO DATA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--<div class="displayNo">
	                        Displaying 5 of 5 courses
	                    </div>-->
                    </div>
                </template>
            </kr-panel>
        </div>
        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!student.isSuspended">Suspend</template>
                            <template v-else> Reinstate </template>
                            {{ student.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!student.isSuspended" class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to suspend
                                    {{ student.displayName }}
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to reinstate
                                    {{ student.displayName }}
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="!student.isSuspended" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Suspend" @click.prevent="suspend()">
                                        <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Reinstate" @click.prevent="unsuspend()">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                        <div class="col-xs-12" style="text-align:center">
                           <template v-if="!superuser.isSuspended">
                           		Suspending..
                           </template>
                           <template v-else>
                           		Reinstating
                           </template>
                        </div>
                    </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div id="studentAsPaidModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="studentAsPaidModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="studentAsPaidModal-title" class="modal-title">You are about to set this student as paid</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="optionDiv marginBottom30">
                                <kr-radio-multiple
                                    name="type"
                                    display="Paid Duration"
                                    :form="form"
                                    :items="paymentPlans"
                                    item_value_idx="value"
                                    item_desc_idx="description"
                                >
                                </kr-radio-multiple>
                            </div>
                        </div>
                        <div class="row marginTop10">
                            <div class="flex">
                                <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm" @click="setAsPaid">Confirm</button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            student: null,
            isFromCourse: false,
            query_fields: { dateFrom: '', dateTo: '' },
            course: {},
            APP_ENV: process.env.VUE_APP_ENV,
            form: new KrForm({
                fields: {
                    type: '',
                },
                props: {
                    successMessage: false,
                },
            }),
            paymentPlans: [],
            logs: [],
        };
    },
    computed: {
        isProfile() {
            return this.$route.params.id == this.auth.user().uuid;
        },
    },
    created() {
        if (this.$route.name == 'courses.manage.students.view') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            });
            this.isFromCourse = true;
        } else {
            if (this.isProfile) {
                Events.fire('topbar_update', {
                    breadcrumb: [{ name: 'Home' }, { name: 'My Profile' }],
                });
            } else {
                Events.fire('topbar_update', {
                    breadcrumb: [{ name: 'Home' }, { name: 'Students', link: 'students.index' }, { name: 'View Student' }],
                });
            }
        }
        this.fetch();

        document.title = `${this.isProfile ? 'My Profile | InteDashboard | TBL Makes Teams Work' : 'Student Profile | InteDashboard | TBL Makes Teams Work'}`;
    },
    methods: {
        removeStudentFromCourse(course, course_idx) {
            var that = this;
            axios
                .post('students/disenroll', {
                    userUuid: this.student.uuid,
                    courseUuid: course.uuid,
                })
                .then(function (response) {
                    that.student.courses.splice(course_idx, 1);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Student has been disenrolled',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        isPayPlan() {
            if (this.student.account.paymentMethod == 'Student-Paid' && !this.student.isGeneric && this.student.email != null) {
                return true;
            }
            return false;
        },
        downloadReceipt(id) {
            var that = this;
            axios({
                url: '/profile/payment-plans/' + id + '/receipt',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(receipt)' + moment().format('YYYY_MM_DD') + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openSetStudentAsPaidModal() {
            $('#studentAsPaidModal').modal('show');
        },
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        setAsPaid() {
            var success = function (response) {
                this.student.status = 'Active (Paid)';
                this.student.paidDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been set as paid',
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            this.form.put(`users/${this.student.uuid}/paid`).then(function (response) {
                if (!response.krFormError) {
                    success(response);
                } else {
                    del();
                }
            });
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.student = response.data.data;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.student.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.student.uuid + '/suspend', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                var that = this;
                this.student = response.data.data;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.student.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.student.uuid + '/reinstate', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal() {
            Events.fire('open-reset-password-modal', {
                user: this.student,
                organisation: this.student.account.organisationName,
            });
        },
        fetch() {
            let that = this;
            let calls = [axios.get('/users/' + that.$route.params.id)];
            if (!this.isFromCourse) {
                if (this.auth.user().roleId <= 2) {
                    calls.push(axios.get(`/students/${that.$route.params.id}/logs`));
                }
                axios.all(calls).then(
                    axios.spread(function (...response) {
                        that.student = response[0].data.data;
                        if (that.auth.user().roleId <= 2) {
                            that.logs = response[1].data.data;
                        }
                        if (that.$route.name == 'accounts.students.view') {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    {
                                        name: 'Accounts',
                                        link: 'accounts.index',
                                    },
                                    {
                                        name: that.student.account.organisationName,
                                        link: 'accounts.view',
                                        params: {
                                            id: that.student.account.uuid,
                                        },
                                        query: { tab: 'student' },
                                    },
                                    { name: 'View Student' },
                                ],
                            });
                        } else if (!that.isProfile) {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    { name: 'Home' },
                                    {
                                        name: 'Students',
                                        link: 'students.index',
                                    },
                                    { name: that.student.displayName },
                                ],
                            });
                        }
                        if (that.isPayPlan()) {
                            that.form.model.type = that.student.account.paymentPlans[0].type;
                            for (var i = 0; i < that.student.account.paymentPlans.length; i++) {
                                that.paymentPlans.push({
                                    value: that.student.account.paymentPlans[i].type,
                                    description: that.student.account.paymentPlans[i].plan,
                                });
                            }
                        }
                        that.component_done_loading = true;
                    })
                );
            } else {
                calls.push(axios.get('/courses/' + this.$route.params.course_id));
                if (this.auth.user().roleId <= 2) {
                    calls.push(axios.get(`/students/${that.$route.params.id}/logs`));
                }
                axios.all(calls).then(
                    axios.spread(function (...response) {
                        that.student = response[0].data.data;
                        that.course = response[1].data.data;
                        if (that.auth.user().roleId <= 2) {
                            that.logs = response[2].data.data;
                        }
                        if (!that.isProfile) {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    { name: 'Home' },
                                    { name: 'Courses', link: 'courses.index' },
                                    {
                                        name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                                        link: 'courses.view',
                                        params: { id: that.course.uuid },
                                        query: { tab: 'student' },
                                    },
                                    { name: that.student.displayName },
                                ],
                            });
                        }
                        that.component_done_loading = true;
                        // Both requests are now complete
                    })
                );
            }
        },
    },
};
</script>
<style scoped>
/* .drop-down {
  width: 250px;
  height: 36px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
  border: 1px solid #d8d8d8;
} */

/* .dropdown .dropdown-menu {
  top: 120%;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 250px;
} */

/* #viewDropdownMenuButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .viewDropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 10px;
} */

@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}

.timeline {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: rgba(255, 255, 255, 0.03);
    position: relative;
    left: 170px;
    list-style: none;
    text-align: left;
    max-width: calc(100% - 300px);
}

.timeline .event {
    border-left: 25px solid #bdc3c7;
    padding: 0 25px;
    border-bottom: 0;
    padding-bottom: 50px;
    position: relative;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -240px;
    content: attr(data-date);
    text-align: right;
    min-width: 200px;
    width: 200px;
    letter-spacing: 0.5px;
    color: #bdc3c7;
}

.timeline .event:after {
    left: -35px;
    background: #bdc3c7;
    height: 2px;
    width: 45px;
    content: '';
}

.timeline .event div {
    border: 1px solid #d8d8d8;
    padding: 5px 10px;
    border-radius: 3px;
    display: inline-block;
}

.timeline .event.completed {
    border-left: 25px solid #1abb9c;
}

.timeline .event.completed:after {
    background: #1abb9c;
}
</style>
