<template>
    <div v-if="component_done_loading">
        <test-topbar :test-obj="test" :options="{ forceHideTimer: true }"></test-topbar>
        <div class="col-xs-12 right-container">
            <div class="instruction_panel">
                <div class="x_header">Instructions</div>
                <div class="x_content">
                    <div class="col-xs-12">
                        <label class="control-label">Course</label>
                        <p class="form-control-static">
                            {{ test.course.name }}
                        </p>
                    </div>
                    <div class="col-xs-12">
                        <label class="control-label">Module</label>
                        <p class="form-control-static">
                            {{ test.module.name }}
                        </p>
                    </div>
                    <div class="col-xs-12">
                        <label class="control-label">Activity Type</label>
                        <p class="form-control-static">
                            <template v-if="test.type == 'prework'"> Prework </template>
                            <template v-if="test.type == 'irat'"> IRAT </template>
                            <template v-if="test.type == 'trat'"> TRAT </template>
                            <template v-if="test.type == 'iratntrat'"> IRAT & TRAT </template>
                            <template v-if="test.type == 'application'"> Application Case </template>
                            <template v-if="test.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-if="test.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                        </p>
                    </div>
                    <div v-show="test.settings.type == 'asynchronous'" class="col-xs-12">
                        <label class="control-label">Open Period</label>
                        <p class="form-control-static">
                            {{ convertToReadableDate(test.settings.startDate, 'DD MMM YYYY, h:mm a').date }}
                            ({{ convertToReadableDate(test.settings.startDate, 'D MMM YYYY, h:mm a').current_timezone }}) -
                            {{ convertToReadableDate(test.settings.endDate, 'DD MMM YYYY, h:mm a').date }}
                            ({{ convertToReadableDate(test.settings.endDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                        </p>
                    </div>
                    <div class="col-xs-12">
                        <label class="control-label"> Instruction </label>
                        <template v-if="test.settings.instruction">
                            <!-- <p v-if="test.settings.instructionIsHTML" class="form-control-static" v-html="test.settings.instruction"></p>
                            <p v-else class="form-control-static">
                                {{ test.settings.instruction }}
                            </p> -->
                            <div v-html="test.settings.instruction"></div>
                        </template>
                        <template v-else>
                            <p class="form-control-static marginBottom0">-</p>
                        </template>
                    </div>

                    <template v-if="test.type != 'peerEvaluationV2'">
                        <div v-if="test.settings.attachments.length > 0" class="col-xs-12 col-sm-6 col-md-4">
                            <div class="flex align-items attachmentFile">
                                <div class="col-xs-12">
                                    <template v-for="(file, idx) in test.settings.attachments">
                                        <div class="flexSpaceBetween">
                                            <div>
                                                <span>{{ idx + 1 }}.</span>
                                                <span>
                                                    <i class="far fa-file-pdf" aria-hidden="true"></i>
                                                </span>
                                                <template v-if="isPdf(file)">
                                                    <template v-if="test.settings.allowPdfDownload">
                                                        <a class="attachmentLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                                            {{ file.filename }}
                                                        </a>
                                                    </template>
                                                    <template v-else>
                                                        <a class="attachmentLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{
                                                            file.filename
                                                        }}</a>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <a class="attachmentLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                        file.filename
                                                    }}</a>
                                                </template>
                                            </div>
                                            <!--<div>
                                                <i class="fa fa-eye"></i>
                                            </div>-->
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: null,
        };
    },
    created() {
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            axios
                .get('student/tests/' + this.$route.params.id + '/instructions')
                .then(function (response) {
                    that.test = response.data.data;
                    that.test.settings.attachments = _.get(that.test, 'settings.attachments', []);
                    that.component_done_loading = true;
                })
                .catch(function (errors) {});
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.test.settings.allowPdfDownload,
            });
        },
    },
    components: {
        'test-topbar': require(`./rat/partials/topbar.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        //from mixin.
        Events.off('current-question-changed');
    },
};
</script>

<style scoped>
.instruction_panel {
    position: relative;
    width: 100%;
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    opacity: 1;
    transition: all 0.2s ease;
}

.instruction_panel .x_header {
    padding: 15px 20px;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    color: #222222;
    font-size: 16px;
    font-weight: bold;
}

.instruction_panel .x_content {
    padding: 20px;
    margin-top: 0;
}
</style>

<style scoped>
.attachmentLink {
    color: #222;
}

.far {
    color: #717171;
}

.attachmentFile > div {
    margin-top: 10px;
}

.attachmentFile > div:first-child {
    margin-top: 0;
}
</style>
