<template>
    <div class="inlineFlex align-items">
        <div v-if="testObj.startedEarly && testObj.expectedEndingTime" class="flex align-items marginRight8 fs-14px">
            <i class="fa fa-clock marginRight5" aria-hidden="true" />
            <p>Early Start</p>
        </div>
        <div class="hidden-xs hidden-sm">
            <template v-if="!shouldStartTimer">
                <div class="inlineFlex align-items">
                    <h2
                        class="bigAlert margin0"
                        :class="{
                            darkGreen: ['not started', 'scheduled'].includes(testObj.status),
                        }"
                    >
                        <template v-if="['ended', 'completed'].includes(testObj.status)"> ACTIVITY ENDED </template>
                        <template v-if="['not started', 'scheduled'].includes(testObj.status)">
                            <template v-if="testObj.status == 'not started'"> NOT STARTED </template>
                            <template v-else> SCHEDULED </template>
                        </template>
                    </h2>
                    <router-link
                        v-if="showWindowLink"
                        :to="{
                            name: 'activities.tests.timer',
                            params: { id: testObj.uuid },
                        }"
                        class="btn btn-default"
                        target="_blank"
                        aria-label="maximize"
                    >
                        <i class="fas fa-external-link-alt" aria-hidden="true" />
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div class="inlineFlex align-items">
                    <div class="timer_Div darkGreen flex align-items justifyCenter" :class="{ timerPaused: testObj.status == 'paused' }">
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.days.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.days.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">DAYS</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.hours.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.hours.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">HOURS</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.minutes.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.minutes.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">MINUTES</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.seconds.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.seconds.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">SECONDS</div>
                        </div>
                    </div>
                    <router-link
                        v-if="showWindowLink"
                        :to="{
                            name: 'activities.tests.timer',
                            params: { id: testObj.uuid },
                        }"
                        class="btn btn-default"
                        target="_blank"
                        aria-label="maximize"
                    >
                        <i class="fas fa-external-link-alt" aria-hidden="true" />
                    </router-link>
                </div>
            </template>
            <!--<div class="flex">
				<div class="control-label">EXTEND TIME</div>
				<div class="col-xs-4 col-md-3">
                   <kr-field :form="timer_form" name="durationDays" :options="{type:'number',min:0}">
                   <template #addon>Days</template></kr-field>
                </div>
                <div class="col-xs-4 col-md-3">
                   <kr-field :form="timer_form" name="durationHours" :options="{type:'number',min:0,max:23}">
                   <template #addon>Hours</template></kr-field>
                </div>
                <div class="col-xs-4 col-md-3">
                   <kr-field :form="timer_form" name="durationMinutes" :options="{type:'number',min:0,max:59}">
                   <template #addon>Mins</template></kr-field>
                </div>
				<button class="btn btn-primary mainBtn">
					Extend&nbsp;<i class="fas fa-angle-right"></i>
				</button>
			</div-->
        </div>
        <div class="hidden-md hidden-lg">
            <div class="col-xs-12 textAlignCenter">
                <template v-if="['not started', 'scheduled', 'ended', 'completed'].includes(testObj.status) && !testObj.expectedEndingTime">
                    <div v-if="['ended', 'completed'].includes(testObj.status)" class="bigAlert">ACTIVITY ENDED</div>
                    <div v-if="['not started', 'scheduled'].includes(testObj.status)" class="bigAlert darkGreen">
                        <template v-if="testObj.status == 'not started'"> NOT STARTED </template>
                        <template v-else> SCHEDULED </template>
                    </div>
                </template>
                <template v-else>
                    <div class="timer_Div darkGreen flex align-items justifyCenter" :class="{ timerPaused: testObj.status == 'paused' }">
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.days.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.days.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">DAYS</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.hours.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.hours.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">HOURS</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.minutes.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.minutes.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">MINUTES</div>
                        </div>
                        <div class="colon">:</div>
                        <div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.seconds.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.seconds.charAt(1) }}
                                </div>
                            </div>
                            <div class="timeText">SECONDS</div>
                        </div>
                    </div>
                </template>
            </div>
            <!--<div class="col-xs-12">
			<div class="control-label">EXTEND TIME</div>
			<div class="col-xs-4 col-md-3">
               <kr-field :form="timer_form" name="durationDays" :options="{type:'number',min:0}">
               <template #addon>Days</template></kr-field>
            </div>
            <div class="col-xs-4 col-md-3">
               <kr-field :form="timer_form" name="durationHours" :options="{type:'number',min:0,max:23}">
               <template #addon>Hours</template></kr-field>
            </div>
            <div class="col-xs-4 col-md-3">
               <kr-field :form="timer_form" name="durationMinutes" :options="{type:'number',min:0,max:59}">
               <template #addon>Mins</template></kr-field>
            </div>
			<button class="btn btn-primary mainBtn">
				<i class="fas fa-angle-right"></i>
			</button>
		</div>-->
        </div>
    </div>
</template>
<script>
export default {
    props: ['testObj', 'options'],
    data() {
        return {
            timer: null,
            time: { days: '00', hours: '00', minutes: '00', seconds: '00' },
            showWindowLink: false,
            runAutoEnd: false,
        };
    },
    computed: {
        shouldStartTimer() {
            return this.testObj.status == 'ongoing' || this.testObj.expectedEndingTime || this.testObj.status == 'paused';
        },
    },
    watch: {
        'testObj.expectedEndingTime': {
            handler: function () {
                if (this.shouldStartTimer) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
        'testObj.status': {
            handler: function () {
                if (this.testObj.status == 'paused') {
                    this.processPausedTimerOnInit();
                } else if (this.shouldStartTimer) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
        'testObj.startTime': {
            handler: function () {
                if (this.shouldStartTimer) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
        'testObj.endTime': {
            handler: function () {
                if (this.shouldStartTimer) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
    },
    created() {
        this.showWindowLink = _.get(this.options, 'showWindowLink', false);
        this.runAutoEnd = _.get(this.options, 'runAutoEnd', false);
    },
    mounted() {
        if (this.testObj.status == 'paused') {
            this.processPausedTimerOnInit();
        } else if (this.shouldStartTimer) {
            this.initTimer();
        } else {
        }
    },
    beforeUnmount() {
        this.stopTimer();
    },
    methods: {
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        processPausedTimerOnInit() {
            if (this.testObj.status == 'paused') {
                this.stopTimer();
                var startTime = this.convertToReadableDate(_.get(this.testObj, 'startTime', '')).dateObj;
                var endTime = this.convertToReadableDate(_.get(this.testObj, 'endTime', '')).dateObj;
                var totalDistance = moment.duration(endTime.diff(startTime));
                var timePaused = this.convertToReadableDate(_.get(this.testObj, 'timePaused', '')).dateObj;
                var distance = moment.duration(endTime.diff(timePaused));
                if (distance > 0) {
                    this.time.days = ('0' + distance.days()).slice(-2);
                    this.time.hours = ('0' + distance.hours()).slice(-2);
                    this.time.minutes = ('0' + distance.minutes()).slice(-2);
                    this.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    this.time.days = '00';
                    this.time.hours = '00';
                    this.time.minutes = '00';
                    this.time.seconds = '00';
                }
                return;
            }
        },
        initTimer() {
            var that = this;
            this.isShown = true;
            if (!(_.get(this.testObj, 'startTime') && _.get(this.testObj, 'endTime')) && !_.get(this.testObj, 'expectedEndingTime')) {
                return;
            }
            //sync or async uses same time.
            var startTime = this.convertToReadableDate(_.get(this.testObj, 'startTime', '')).dateObj;
            var endTime = this.convertToReadableDate(_.get(this.testObj, 'endTime') || _.get(this.testObj, 'expectedEndingTime')).dateObj;
            var totalDistance = moment.duration(endTime.diff(startTime));
            this.stopTimer();
            this.timer = setInterval(function () {
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.days = ('0' + distance.days()).slice(-2);
                    that.time.hours = ('0' + distance.hours()).slice(-2);
                    that.time.minutes = ('0' + distance.minutes()).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    that.time.days = '00';
                    that.time.hours = '00';
                    that.time.minutes = '00';
                    that.time.seconds = '00';
                    that.stopTimer();
                    if (that.testObj.settings.type == 'synchronous' && that.runAutoEnd) {
                        axios
                            .post('tests/' + that.testObj.uuid + '/auto-end')
                            .then(function (response) {
                                //console.log(that)
                            })
                            .catch(function (errors) {});
                    }
                }
            }, 1000);
        },
    },
};
</script>
<style scoped>
.bigAlert {
    color: #ed1c24;
    /* font-size:48px; */
    font-size: 2.667rem;
    font-weight: bold;
    line-height: 1;
}

.fa-external-link-alt {
    /* font-size:14px; */
    font-size: 1rem;
}

.time .number-div {
    background: #fff;
    padding: 5px 10px 0px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}

.time .number-div:last-child {
    margin-left: 5px;
}

.colon {
    color: #717171;
}

@media (min-width: 992px) {
    .timer_Div {
        /* font-size:20px; */
        font-size: 1.111rem;
        font-weight: bold;
        line-height: 1;
    }

    .colon {
        padding: 0 10px;
    }

    .timer-wrapper .timer_Div {
        /* font-size:100px; */
        font-size: 5.556rem;
    }

    .timer-wrapper .time .number-div {
        padding: 10px 10px 0;
    }
}

@media (max-width: 991px) {
    .timer_Div {
        /* font-size:18px; */
        font-size: 1rem;
        font-weight: bold;
        line-height: 1;
    }

    .colon {
        padding: 0 5px;
    }

    .timer-wrapper .timer_Div {
        /* font-size:30px; */
        font-size: 1.667rem;
    }
}

.timeText {
    color: #222;
    /* font-size:10px; */
    font-size: 0.88rem;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
}
.timerPaused {
    opacity: 0.2;
}
</style>
