<template>
    <div>
        <kr-panel v-if="component_done_loading" :with-footer="false">
            <template #title>
                <div class="col-xs-12 flexSpaceBetween align-items">
                    <div class="col-xs-6">
                        <h2>COLLABORATORS</h2>
                    </div>
                    <div class="col-xs-6">
                        <div v-if="hasWritePrivilege(course)" class="textAlignRight">
                            <div
                                v-tooltip="{
                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                }"
                                class="hidden-xs hidden-sm"
                            >
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'courses.manage.teachers.create',
                                        params: { id: course.uuid },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-success" @click="navigate">
                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />Add Teacher
                                    </button>
                                </router-link>
                                <button class="btn btn-primary mainBtn" aria-label="Export" @click="downloadTeachers">
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />Export
                                </button>
                            </div>
                            <div
                                v-tooltip="{
                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                }"
                                class="hidden-md hidden-lg"
                            >
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="'Add Teacher'"
                                    :to="{
                                        name: 'courses.manage.teachers.create',
                                        params: { id: course.uuid },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-success" @click="navigate">
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </button>
                                </router-link>
                                <button class="btn btn-primary mainBtn" aria-label="Export" @click="downloadTeachers">
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <kr-search
                    name="teachers_listing"
                    :url="{ url: 'courses/' + course.uuid + '/users' }"
                    :columns="columns"
                    :options="{
                        archived_field: false,
                        permission_field: false,
                        search_field: false,
                        trash_field: false,
                        drop_page_number: true,
                        params: { role: '2,3' },
                        action_column: false,
                        top_bar: false,
                        table_caption: true,
                    }"
                    @retrieve-data="getSearchData"
                >
                    <template #upper-right />
                    <!-- <template slot="top-display-from-to" slot-scope="props">
                    <div class="displayNo">
                        {{props.model.to}} of {{props.model.meta.paginator.total}}
                    </div>
                </template> -->
                    <template #table-caption="props">
                        Teachers in this Course
                        <template v-if="props.model.meta.paginator.total > 0">
                            ( {{ props.model.from }} - {{ props.model.to }} of
                            {{ props.model.meta.paginator.total }}
                            )
                        </template>
                    </template>
                    <template #bottom-display-from-to="props">
                        <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                            {{ props.model.from }} - {{ props.model.to }} of
                            {{ props.model.meta.paginator.total }}
                        </div>
                    </template>
                    <template #cdata="props">
                        <td>
                            <router-link
                                v-tooltip="{ content: 'View Teacher' }"
                                tag="a"
                                :to="{
                                    name: 'courses.manage.teachers.view',
                                    params: {
                                        course_id: course.uuid,
                                        id: props.model.uuid,
                                    },
                                }"
                                class="link"
                                :aria-label="props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')"
                            >
                                {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                            </router-link>
                            <!-- <template v-if="props.model.role=='Super Admin'"><span class="label label-primary">Admin Teacher</span></template> -->
                        </td>
                        <td class="hidden-sm hidden-xs">
                            <template v-if="props.model.role == 'Super Admin'"> Super Admin </template>
                            <template v-else-if="props.model.canAddTeacher"> Admin Teacher </template>
                            <template v-else> Teacher </template>
                        </td>
                        <!-- <td class="hidden-sm hidden-xs">
                        {{props.model.email}}
                    </td> -->
                        <td v-if="hasWritePrivilege(course)" class="hidden-xs hidden-sm">
                            <template v-if="props.model.type == 'Owner'">
                                <div class="flexOnly">
                                    <i class="fa fa-circle ownerCircle marginTop5 marginRight8" aria-hidden="true" />Course Owner
                                    <template v-if="props.model.uuid == auth.user().uuid"> (Me) </template>
                                </div>
                            </template>
                            <template v-if="props.model.type == 'Full Access'">
                                <div class="flexOnly"><i class="fa fa-circle fullAccessCircle marginTop5 marginRight8" aria-hidden="true" />Full Access</div>
                            </template>
                            <template v-if="props.model.type == 'Read Only'">
                                <div class="flexOnly"><i class="fa fa-circle readOnlyCircle marginTop5 marginRight8" aria-hidden="true" />Read Only</div>
                            </template>
                        </td>
                        <td>
                            <div class="dropdown">
                                <button id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Actions">
                                    Actions
                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                    <ul>
                                        <li v-if="!props.model.isSuspended">
                                            <router-link
                                                v-tooltip="'View Teacher'"
                                                class="primary-state"
                                                :to="{
                                                    name: 'courses.manage.teachers.view',
                                                    params: {
                                                        course_id: course.uuid,
                                                        id: props.model.uuid,
                                                    },
                                                }"
                                                aria-label="View Teacher"
                                            >
                                                <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Teacher </span>
                                            </router-link>
                                        </li>

                                        <hr
                                            v-if="
                                                hasWritePrivilege(course) === 1 &&
                                                props.model.uuid != auth.user().uuid &&
                                                auth.user().roleId < 3 &&
                                                !props.model.isSuspended
                                            "
                                        />

                                        <li v-if="hasWritePrivilege(course) === 1 && props.model.uuid != auth.user().uuid && auth.user().roleId < 3">
                                            <a
                                                v-tooltip="'Suspend Teacher'"
                                                class="danger-state"
                                                href="#"
                                                aria-label="Suspend Teacher"
                                                @click.prevent="openSuspendModal(props.model)"
                                            >
                                                <span> <i class="fas fa-times marginRight8" aria-hidden="true" />Suspend Teacher </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- <div class="buttonGroupInline hidden-xs hidden-sm">
                <router-link
                  tag="button"
                  v-tooltip="{ content: 'View Teacher' }"
                  v-if="!props.model.isSuspended"
                  :to="{
                    name: 'courses.manage.teachers.view',
                    params: { course_id: course.uuid, id: props.model.uuid },
                  }"
                  class="btn btn-primary subBtn"
                  aria-label="View"
                >
                  <i class="fa fa-eye"></i>&nbsp;View
                  <template v-if="hasWritePrivilege(course)&&(props.model.type!='Owner'||auth.user().uuid==props.model.uuid)">
                                    View/Edit&nbsp;
                                </template>
                                <template v-else>
                                    View
                                </template>
                                <i class="fa fa-caret-right"></i>
                </router-link>
                <button v-if="hasWritePrivilege(course)>0&&props.model.uuid!=auth.user().uuid&&auth.user().roleId<3" class="btn btn-default subBtn" @click.prevent="openResetPasswordModal(props.model)">
                                <i class="fa fa-key"></i>&nbsp;Reset Password
                            </button>
                <button
                  v-if="
                    hasWritePrivilege(course) === 1 &&
                      props.model.uuid != auth.user().uuid &&
                      auth.user().roleId < 3
                  "
                  class="btn btn-danger"
                  @click.prevent="openSuspendModal(props.model)"
                  aria-label="Suspend"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="hidden-md hidden-lg">
                <router-link
                  tag="button"
                  v-tooltip="{ content: 'View Teacher' }"
                  v-if="!props.model.isSuspended"
                  :to="{
                    name: 'courses.manage.teachers.view',
                    params: { course_id: course.uuid, id: props.model.uuid },
                  }"
                  class="btn btn-primary mainBtn"
                  aria-label="View"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                <button  v-tooltip="{content: 'Reset Password'}" v-if="hasWritePrivilege(course)>0&&props.model.uuid!=auth.user().uuid&&auth.user().roleId<3" class="btn btn-default mainBtn" @click.prevent="openResetPasswordModal(props.model)">
                                <i class="fa fa-key"></i>
                            </button>
                <button
                  v-if="
                    hasWritePrivilege(course) === 1 &&
                      props.model.uuid != auth.user().uuid &&
                      auth.user().roleId < 3
                  "
                  class="btn btn-danger"
                  @click.prevent="openSuspendModal(props.model)"
                  aria-label="Suspend"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div> -->
                        </td>
                    </template>
                </kr-search>
            </template>
        </kr-panel>
        <kr-reset-password :options="{ id: 'courses-teachers-manage' }" />
        <div
            id="courses-teacher-manage-suspend-modal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="courseTeacherSuspendModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="courseTeacherSuspendModal-title" class="modal-title">
                            <template v-if="!teacher.isTrashed"> Delete </template>
                            <template v-else> Reinstate </template>
                            {{ teacher.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!teacher.isTrashed" class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to remove
                                    <b>{{ teacher.displayName }}</b> from your course
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to reinstate
                                    <b>{{ teacher.displayName }}</b>
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="!teacher.isTrashed" class="col-xs-12 col-md-6">
                                    <button
                                        class="btn btn-danger mainBtn"
                                        style="width: 100%"
                                        data-dismiss="modal"
                                        aria-label="Remove From Course"
                                        @click.prevent="trash(teacher)"
                                    >
                                        <i class="fa fa-trash marginRight8" aria-hidden="true" />Remove From Course
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Reinstate">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                        <div class="col-xs-12" style="text-align:center">
                           <template v-if="!superuser.isSuspended">
                                Suspending..
                           </template>
                           <template v-else>
                                Reinstating
                           </template>
                        </div>
                    </div>-->
                    </div>
                </div>
            </div>
        </div>

        <div id="transferModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="transferModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="transferModal-title" class="modal-title">Transfer Ownership</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="textAlignCenter">
                                <div>Are you sure you want to transfer to</div>
                                <div>
                                    <span style="font-size: 16px">
                                        <b>{{ teacher.displayName }}</b> </span
                                    >?
                                </div>
                            </div>
                        </div>
                        <div class="marginTop30">
                            <div class="flex">
                                <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm New Owner" @click="transfer">
                                    Confirm New Owner
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {
        //'teams-students-form': require(`./form-partials/partial.teams-students.vue`),
    },
    props: ['courseObj'],
    data() {
        var auth = new KrAuth();
        return {
            course: null,
            component_done_loading: false,
            auth: auth,
            passwordReset: { user: null, status: 'none', pin: '', type: '' },
            trash_field: true,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isTrashed: '0' },
            },
            teacher: {},
            readOnlyLabel: 'You have read-only access',
        };
    },
    created() {
        var that = this;

        if (!this.courseObj) {
            axios.get('/courses/' + this.$route.params.id).then(function (response) {
                that.course = response.data.data;
                that.initColumnsDependingOnWritePrivilege();
                that.component_done_loading = true;
            });
        } else {
            this.course = this.courseObj;
            this.initColumnsDependingOnWritePrivilege();
            this.component_done_loading = true;
        }
    },
    methods: {
        getSearchData(data) {
            this.searchData = data;
        },
        openResetPasswordModal(user) {
            Events.fire('open-reset-password-modalcourses-teachers-manage', {
                user: user,
                organisation: user.account.organisationName,
            });
        },
        openSuspendModal(teacher) {
            this.teacher = teacher;
            $('#courses-teacher-manage-suspend-modal').modal('show');
        },
        trash(teacher) {
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed teacher from course',
                });
                Events.fire('teachers_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/courses/' + this.course.uuid + '/users/remove', {
                    userUuid: this.teacher.uuid,
                })
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        initColumnsDependingOnWritePrivilege() {
            if (this.hasWritePrivilege(this.course)) {
                this.columns = {
                    name: 'display:TEACHER|sortable:false|width:30%',
                    role: 'display:ROLE|sortable:false|width:25%|mobile:hide',
                    // email:'display:EMAIL|sortable:false|width:25%|mobile:hide',
                    myRole: 'display:COURSE ACCESS LEVEL|sortable:false|width:30%|mobile:hide',
                    action: 'display:|sortable:false',
                };
            } else {
                this.columns = {
                    name: 'display:TEACHER|sortable:false|width:50%',
                    role: 'display:ROLE|sortable:false|width:50%|mobile:hide',
                    // email:'display:EMAIL|sortable:false|width:35%|mobile:hide',
                    action: 'display:|sortable:false',
                };
                this.trash_field = false;
            }
        },
        openTransferModal(teacher) {
            this.teacher = teacher;
            $('#transferModal').modal('show');
        },
        transfer() {
            $('#transferModal').modal('hide');
            console.log(this.teacher.uuid);
            var success = function () {
                var that = this;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have successfully transfered ownership of this course',
                });

                Events.fire('teachers_listing_refresh');
            }.bind(this);
            var del = function (message = false) {
                if (!message) {
                    message = 'Something went wrong, please try again later';
                }
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: message,
                });
            }.bind(this);
            axios.post('/courses/' + this.course.uuid + '/' + 'users/' + 'transfer-ownership', { userUuid: this.teacher.uuid }).then(function (response) {
                if (!response.krFormError) {
                    success();
                } else {
                    if (response.data.message) {
                        del(response.data.message);
                    } else {
                        del();
                    }
                }
            });
        },
        dropdownToggle() {
            // get the scollTop (distance scrolled from top)
            var scrollTop = $(window).scrollTop();
            // get the top offset of the dropdown (distance from top of the page)
            var topOffset = $('.dropdown').offset().top;
            // calculate the dropdown offset relative to window position
            var relativeOffset = topOffset - scrollTop;
            // get the window height
            var windowHeight = $(window).height();

            // if the relative offset is greater than half the window height,
            // reverse the dropdown.
            if (relativeOffset > windowHeight / 2) {
                $('.dropdown-menu').addClass('reverse');
            } else {
                $('.dropdown-menu').removeClass('reverse');
            }
        },
        downloadTeachers() {
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: `teachers/courses/${this.course.uuid}/export`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Teachers_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
    },
};
</script>
<style scoped>
#courses-teacher-manage-suspend-modal .modal-body .row:last-child {
    margin-top: 20px;
}

#courses-teacher-manage-suspend-modal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#courses-teacher-manage-suspend-modal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.setting .dropdown-menu {
    top: inherit;
}
</style>
