<template>
    <div v-if="component_done_loading" class="test bg-white">
        <test-topbar :test-obj="test" :test-settings="testSettings" :options="{ forceHideTimer: false }" @timer-shown="processTimerShown" />
        <div class="evaluationV2-test" :class="{ timer: is_timer_shown }">
            <stopping-div :test-obj="test" @display="processDisplayTestProper" />

            <div v-if="isMobile" id="mySidenav" class="mobileSideBar">
                <evaluation-sidebar
                    ref="sideBarRefEvaluation"
                    :test-obj="test"
                    :test-settings="testSettings"
                    :side-bar-closed-value="sideBarClosed"
                    @evaluation-sidebar-clicked="handleButtonClicked"
                />
            </div>

            <div v-if="display_test_proper" class="sideBarStatusV2">
                <evaluation-sidebar
                    v-if="!isMobile"
                    ref="sideBarRefEvaluation"
                    :test-obj="test"
                    :test-settings="testSettings"
                    :side-bar-closed-value="sideBarClosed"
                    @evaluation-sidebar-clicked="handleButtonClicked"
                />

                <main
                    class="main"
                    :style="{
                        marginLeft: sideBarElement.clientWidth,
                        width: 'calc(100% - ' + sideBarElement.clientWidth + ')',
                    }"
                >
                    <test-details-topbar :test-obj="test" :test-settings="testSettings" />

                    <div class="padding20 paddingBottom66">
                        <question-groups-v2 :test-obj="test" :test-settings="testSettings" @submit-test="submitTest" />
                    </div>
                </main>
            </div>
        </div>

        <div id="test_confirm_modal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/submit_alert.png" alt="" class="marginBottom20" width="30%" />

                        <p class="marginBottom5">You will not be able to change your answers after submitting. This is <b>final.</b></p>

                        <p class="marginBottom30">Do you want to submit?</p>

                        <div class="flex marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button class="btn btn-success mainBtn" aria-label="Submit Test" data-dismiss="modal" @click.prevent="submitTest()">
                                Submit Test
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="redirectingModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/redirecting.png" alt="" class="marginBottom20" width="30%" />
                        <h3 class="text-success">Submission Successful</h3>
                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                        <p>
                            <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                <button class="btn btn-primary" @click="navigate">Return to Dashboard</button>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="teacherEndedModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/teacher_ended.png" alt="" class="marginBottom20" width="30%" />

                        <p class="marginBottom5">
                            <b>Your instructor has ended the activity.</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="timeUpModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/time_up.png" alt="" class="marginBottom20" width="25%" />

                        <p class="marginBottom5">
                            <b>Time’s Up!</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
import useTest from '../../../../../src/composables/studentTest/test.ts';
export default {
    data() {
        var test = {};
        const { canSubmitTest, isTestCompleted } = useTest(test);
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            display_test_proper: false,
            is_timer_shown: false,
            sideBarClosed: false,
            sideBarElement: '',
            test: test,
            testSettings: {},
            canSubmitTest: canSubmitTest,
            isTestCompleted: isTestCompleted,
            isMobile: false,
        };
    },
    watch: {
        testSettings: {
            handler(newValue, oldValue) {
                this.scrollActiveNaviIntoView();
            },
            deep: true,
        },
        'test.status': {
            handler() {
                this.updateTopNavHeight();
            },
            deep: true,
        },
    },
    mounted() {
        if (['tests.evaluation-v2'].includes(this.$route.name)) {
            this.init();
        } else if (this.$route.name == 'teachers.tests.preview.evaluation-v2') {
            var that = this;
            this.fetchActivity().then(function (response) {
                let team = [
                    {
                        id: 1,
                        displayName: 'Student-1',
                    },
                    {
                        id: 2,
                        displayName: 'Student-2',
                    },
                    {
                        id: 3,
                        displayName: 'Student-3',
                    },
                ];
                that.test = response.data.data;
                that.test.team = team;
                let questions = {};
                for (var i = 0; i < that.test.questions.length; i++) {
                    questions[i + 1] = [
                        {
                            ...that.test.questions[i][0],
                            question: { name: that.test.questions[i][0].name, description: that.test.questions[i][0].description },
                        },
                    ];
                }
                that.test.questions = questions;
                that.initSettings();

                that.testSettings.isPreview = true;
                that.test.status = 'ongoing';
                that.test._isPreview = true;
                that.test._isTeacherPreview = true;
                that.test.myProgression = { userTestStatus: 'started' };
                that.test.settings = that.test.settings.peerEvaluationV2;
                that.component_done_loading = true;
            });
        }

        this.isMobile = window.innerWidth < 992;
        this.scrollActiveNaviIntoView();
    },
    updated() {
        var that = this;
        this.$nextTick(() => {
            if (that.$refs.sideBarRefEvaluation && !this.isMobile) {
                that.sideBarElement = this.$refs.sideBarRefEvaluation.$el;
                that.handleResize();
            }
        });

        window.addEventListener('resize', this.handleResize);
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');

        Events.listen('submit-test', function (data) {
            $('#test_confirm_modal').modal('show');
        });
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                if (that.test.settings.type == 'synchronous') {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime = e.test.settings.endTime;
                } else {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime =
                        moment
                            .utc(that.test.myProgression.endTime, 'YYYY-MM-DDThh:mm:ssZ')
                            .add(e.test.settings.diffInSeconds, 'seconds')
                            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                }
                let old_status = that.test.status;
                that.test.status = e.test.status;

                if (!['not started', 'scheduled'].includes(that.test.status)) {
                    if (that.test._isPreview) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'This test has started. Redirecting you back to test listings',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }
                }
                if (!that.test._isSubmitting) {
                    if (that.test.status == 'completed') {
                        $('#timeUpModal').modal('show');

                        window.setTimeout(function () {
                            $('#timeUpModal').modal('hide');

                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: "Time's up! Pass your papers!",
                            });
                            that.$router.push({ name: 'tests.index' });
                        }, 3000);
                    }
                }
                if (that.test.status == 'ended') {
                    $('#teacherEndedModal').modal('show');

                    window.setTimeout(function () {
                        $('#teacherEndedModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Teacher has ended the test',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has unpublished this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c2 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityUnpublished', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'ActivityUnpublished',
                handler: h2,
            });

            let h3 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has deleted this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c3 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityDeleted', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityDeleted',
                handler: h3,
            });

            let h4 = (e) => {
                that.init();
            };
            let c4 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentResetAnswers', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'StudentResetAnswers',
                handler: h4,
            });

            let h5 = (e) => {
                let old_status = that.test.myProgression.userTestStatus;
                let students = _.get(that.test, 'myProgression.team.students', []);
                that.test.myProgression = e.userPlacementTests.myProgression;
                if (that.test.myProgression.team) {
                    that.test.myProgression.team.students = students;
                }
                if (old_status != that.test.myProgression.userTestStatus && !that.test._isSubmitting) {
                    if (that.test.myProgression.userTestStatus == 'finished') {
                        $('#timeUpModal').modal('show');
                        window.setTimeout(function () {
                            $('#timeUpModal').modal('hide');
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: "Time's up! Pass your papers!",
                            });
                            that.$router.push({ name: 'tests.index' });
                        }, 3000);
                    }
                    if (that.test.myProgression.userTestStatus == 'incomplete') {
                        $('#teacherEndedModal').modal('show');

                        window.setTimeout(function () {
                            $('#teacherEndedModal').modal('hide');
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: 'Teacher has ended the test',
                            });
                            that.$router.push({ name: 'tests.index' });
                        }, 3000);
                    }
                }
            };
            let c5 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTestStatusUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'StudentTestStatusUpdated',
                handler: h5,
            });
            let h6 = (e) => {
                this.test.extraTimeValues = e.extraTimeValues;
                this.test.extraTimeValues.extraTimeValues = e.extraTimeValues;
                this.test.expectedEndingTime = e.expectedEndingTime;
                this.test.myProgression.userTestStatus = e.userPlacementTestStatus;
            };
            let c6 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTimeExtended', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'StudentTimeExtended',
                handler: h6,
            });
        },
        init() {
            var that = this;
            this.fetchTest().then(function (response) {
                that.test = response.data.data;
                that.processEchoListener();
                that.initSettings();
                that.component_done_loading = true;
            });
        },
        initQuestionList() {
            let flagged = false;
            var firstList = [];
            var secondList = [];
            var thirdList = [];
            this.testSettings.questionList = [];
            for (const [key, value] of Object.entries(this.test.questions)) {
                let qGroup = value[0];
                if (!flagged) {
                    if (!qGroup.settings.answerQuestionsForEachTeammate || qGroup.type == 'pointDistribution') {
                        firstList.push({ key: key, type: 'single', studentIdx: null });
                    } else {
                        secondList.push({ key: key, type: 'peer' });
                        flagged = true;
                    }
                } else {
                    if (!qGroup.settings.answerQuestionsForEachTeammate || qGroup.type == 'pointDistribution') {
                        thirdList.push({ key: key, type: 'single', studentIdx: null });
                    } else {
                        secondList.push({ key: key, type: 'peer' });
                    }
                }
            }
            let bList = [];
            for (var i = 0; i < this.test.team.length; i++) {
                for (var j = 0; j < secondList.length; j++) {
                    bList.push({ key: secondList[j].key, type: 'peer', studentIdx: i });
                }
            }

            this.testSettings.questionList = firstList.concat(bList, thirdList);
        },
        initSettings() {
            this.testSettings.currentQuestionGroupIdx = 0;
            this.testSettings.currentStudentIdx = null;
            this.testSettings.currentQuestionStemIdx = 0;
            this.testSettings.currentQuestionGroupUuid = '';
            if (Object.keys(this.test.questions).length > 0) {
                this.testSettings.currentQuestionGroupUuid = this.test.questions[1][0].uuid;
                if (this.test.questions[1][0].type != 'pointDistribution' && this.test.questions[1][0].settings.answerQuestionsForEachTeammate) {
                    this.testSettings.currentStudentIdx = 0;
                }
                this.initQuestionList();
            }
        },
        submitTest() {
            var that = this;
            axios
                .put('student/tests/' + that.test.uuid + '/submit')
                .then((response) => {
                    $('#redirectingModal').modal('show');
                    that.test._isSubmitting = true;
                    window.setTimeout(function () {
                        $('#redirectingModal').modal('hide');
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Please complete the evaluation before submitting',
                    });
                });
        },
        fetchTest() {
            return axios.get('student-tests/' + this.$route.params.id);
        },
        fetchActivity() {
            return axios.get('activities/' + this.$route.params.id);
        },
        processTimerShown(isShown) {
            this.is_timer_shown = isShown;
        },
        processDisplayTestProper(stopDisplay) {
            if (!this.test.isPublished) {
                this.display_test_proper = true;
            } else {
                this.display_test_proper = !stopDisplay;
            }
        },
        handleButtonClicked() {
            var that = this;

            $('.statusBar').toggleClass('sidebar--Collapse');
            $('.main').toggleClass('main--slide');
            $('#toggleIcon').toggleClass('rotate');
            this.sideBarClosed = !this.sideBarClosed;
            window.setTimeout(function () {
                $('.main').css('margin-left', that.sideBarElement.clientWidth + 'px');
                $('.main').css('width', 'calc(100% - ' + that.sideBarElement.clientWidth + 'px)');
            }, 500);
        },
        handleResize() {
            $('.main').css('margin-left', this.sideBarElement.clientWidth + 'px');
            $('.main').css('width', 'calc(100% - ' + this.sideBarElement.clientWidth + 'px)');
            $('.top-bar-activity-details-container').css('left', this.sideBarElement.clientWidth + 'px');
            $('.top-bar-activity-details-container').css('width', 'calc(100% - ' + this.sideBarElement.clientWidth + 'px)');

            this.isMobile = window.innerWidth < 992;
        },
        scrollActiveNaviIntoView() {
            this.$nextTick(() => {
                const element = document.getElementById('activated');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
        updateTopNavHeight() {
            $('.test_topBar_evaluation_placeholder').height($('.test_topBar_v2').height() + 'px');
        },
    },
    components: {
        'test-topbar': require(`./partials/topbar.vue`).default,
        'stopping-div': require(`./partials/stopping-div.vue`).default,
        'question-groups-v2': require(`./partials/questions/question-groups-V2.vue`).default,
        'evaluation-sidebar': require(`./partials/questions/sidebar.vue`).default,
        'test-details-topbar': require(`./partials/test-details-topbar.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        Events.off('submit-test');

        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
