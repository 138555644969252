<template>
    <div>
        <div class="flexRight marginBottom20">
            <div class="hidden-xs hidden-sm listingSwitch" role="menu">
                <button
                    v-tooltip="'Stacked view'"
                    type="button"
                    role="menuitem"
                    class="alignBaseLine"
                    :class="{ active: !question.enableStackingColumns }"
                    @click.prevent="setColumnToggle(false)"
                >
                    <i class="fa-solid fa-layer-group" aria-hidden="true"></i>
                </button>
                <button
                    v-tooltip="'Two columns view'"
                    type="button"
                    role="menuitem"
                    class="alignBaseLine"
                    :class="{ active: question.enableStackingColumns }"
                    @click.prevent="setColumnToggle(true)"
                >
                    <i class="fa-solid fa-table-columns" aria-hidden="true"></i>
                </button>
            </div>
        </div>

        <div :class="{ 'flex maxWidth2000px': question.enableStackingColumns }">
            <div
                class="question presentationMode"
                :class="{
                    'col-xs-12 col-md-6 paddingRight10 marginBottom20': question.enableStackingColumns,
                }"
            >
                <kr-math :input="question.question" :safe="!question.questionIsHTML" />
                <!-- llist with no answer = array in question.presentStudentsWithNoAnswer or presentTeamsWithNoAnswer-->
                <template v-if="question.attachments && question.attachments.length != 0">
                    <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
                    <div class="textLink">
                        <div v-for="(file, idx) in question.attachments" class="flex">
                            <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                        </div>
                    </div>
                </template>
            </div>

            <div
                :class="{
                    'col-xs-12 col-md-6': question.enableStackingColumns,
                    marginTop20: !question.enableStackingColumns,
                }"
            >
                <!-- <template v-for="(option,option_idx) in question.options">
				<div class="discussionOption" :class="{'correct':option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers),'padding10 paddingLeft32':!option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers)}">
					<div class="flexOnly">
						<i class="fa fa-check-circle" v-if="option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers)"></i>
						<i class="fas fa-times-circle" v-if="!option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers)"></i>
						<div class="fontBold marginRight5">{{option.key}}</div>
						<div v-if="option.contentIsHTML" v-html="option.content"></div>
						<div v-else>{{option.content}}</div>
					</div>
				</div>
			</template> -->

                <template v-for="(option, option_idx) in question.optionKeys">
                    <div
                        class="discussionOption"
                        :class="{
                            correct: option.isCorrect && question.displayAnswer,
                            'padding10 paddingLeft32': !option.isCorrect && question.displayAnswer,
                        }"
                    >
                        <div class="flexOnly">
                            <i v-if="option.isCorrect && question.displayAnswer" class="fa fa-check-circle" />
                            <i v-if="!option.isCorrect && question.displayAnswer" class="fas fa-times-circle" />
                            <div class="fontBold marginRight5">
                                {{ option.key }}
                            </div>
                            <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                        </div>

                        <div v-if="question.displayAnswerStatistics" class="marginTop10">
                            <div class="discussion-bar">
                                <template v-if="option.percent.toFixed(0) >= 10">
                                    <div
                                        class="barProgress"
                                        :style="'width:' + option.percent.toFixed(0) + '%'"
                                        :class="{
                                            'colorGreenBg whiteText': option.isCorrect && question.displayAnswer,
                                            'colorRedBg whiteText': !option.isCorrect && question.displayAnswer,
                                        }"
                                    >
                                        {{ option.percent.toFixed(0) }}%
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-xs-12">{{ option.percent.toFixed(0) }}%</div>
                                </template>
                            </div>
                            <div class="marginTop10 flexOnly hidden-xs hidden-sm">
                                <div class="responseFont col-xs-2 maxWidth100px">
                                    {{ option.answersPerOption.length }}
                                    <template v-if="option.answersPerOption.length <= 1"> Response </template>
                                    <template v-else> Responses </template>
                                </div>
                                <div class="discussion-buttons">
                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                        <template v-if="model.student">
                                            <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown">
                                                <button
                                                    id="popOver"
                                                    class="btn subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.student.fullname"
                                                >
                                                    {{ truncate(model.student.fullname) }}&nbsp;<i class="far fa-comment-dots" />
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight5 greyText" />
                                                            <span class="lineHeight08 fontBold">Elaboration</span>
                                                        </div>
                                                        <div v-if="model.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                            <template v-if="model.comment.length > 400">
                                                                <div class="marginTop10 blue pointer" @click="openModelInfoModal(question, model, 'student')">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight5 greyText" />
                                                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                        </div>
                                                        <div>
                                                            <template v-if="model.attachments && model.attachments.length != 0">
                                                                <div v-for="(file, idx) in model.attachments" class="flex maxHeight100px">
                                                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                                <template v-if="model.attachments.length > 4">
                                                                    <div
                                                                        class="marginTop10 blue pointer"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(question, model, 'student')"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                    <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Team Members</span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <div
                                                                class="blue pointer"
                                                                aria-label="View More"
                                                                @click="openModelInfoModal(question, model, 'student')"
                                                            >
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-else>
                                                <button
                                                    class="btn subBtn marginBottom10 cursor-norm"
                                                    :aria-label="model.student.fullname"
                                                    @click.prevent="openModelInfoModal(question, model, 'student')"
                                                >
                                                    {{ truncate(model.student.fullname) }}
                                                </button>
                                            </template>
                                        </template>

                                        <template v-else-if="model.team">
                                            <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown">
                                                <button
                                                    id="popOver"
                                                    class="btn subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.team.name"
                                                >
                                                    {{ truncate(model.team.name) }}&nbsp;<i class="far fa-comment-dots" />
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight5 greyText" />
                                                            <span class="lineHeight08 fontBold"> Elaboration </span>
                                                        </div>
                                                        <div v-if="model.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                            <template v-if="model.comment.length > 400">
                                                                <div
                                                                    class="marginTop10 blue pointer"
                                                                    aria-label="View More"
                                                                    @click="openModelInfoModal(question, model)"
                                                                >
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight5 greyText" />
                                                            <span class="lineHeight08 fontBold"> Attachment(s) </span>
                                                        </div>
                                                        <div>
                                                            <template v-if="model.attachments && model.attachments.length != 0">
                                                                <div
                                                                    v-for="(file, idx) in model.attachments"
                                                                    :key="'attachment_file_' + idx"
                                                                    class="flex maxHeight100px"
                                                                >
                                                                    <span class="marginRight5"> {{ idx + 1 }}. </span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                                <template v-if="model.attachments.length > 4">
                                                                    <div
                                                                        class="marginTop10 blue pointer"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(question, model)"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                    <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Team Members</span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members" :key="'team_members_' + idx">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(question, model)">
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-else>
                                                <div class="dropdown">
                                                    <button
                                                        id="popOver"
                                                        class="btn subBtn marginBottom10"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        :aria-label="model.team.name"
                                                    >
                                                        {{ truncate(model.team.name) }}
                                                    </button>
                                                    <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                        <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-users marginRight8 greyText" />
                                                                <span class="lineHeight08 fontBold"> Team Members </span>
                                                            </div>
                                                            <div class="maxHeight100px">
                                                                <ul v-for="(student, idx) in model.team.members">
                                                                    <li>
                                                                        {{ student.displayName }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <template v-if="model.team.members.length > 4">
                                                                <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(question, model)">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <div class="responseFont marginTop10">
                                    {{ option.answersPerOption.length }}
                                    <template v-if="option.answersPerOption.length <= 1"> RESPONSE </template>
                                    <template v-else> RESPONSES </template>
                                </div>
                                <div class="discussion-buttons marginTop10">
                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                        <template v-if="model.student">
                                            <template v-if="(model.attachments && model.attachments.length != 0) || model.comment">
                                                <button
                                                    class="btn subBtn marginBottom10"
                                                    :aria-label="model.student.fullname"
                                                    @click="openModelInfoModal(question, model, 'student')"
                                                >
                                                    {{ truncate(model.student.fullname) }}&nbsp;<i class="far fa-comment-dots" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="btn subBtn marginBottom10 cursor-norm" :aria-label="model.student.fullname">
                                                    {{ truncate(model.student.fullname) }}
                                                </button>
                                            </template>
                                        </template>

                                        <template v-if="model.team">
                                            <template v-if="(model.attachments && model.attachments.length != 0) || model.comment">
                                                <button
                                                    class="btn subBtn marginBottom10"
                                                    :aria-label="model.team.name"
                                                    @click="openModelInfoModal(question, model)"
                                                >
                                                    {{ truncate(model.team.name) }}&nbsp;<i class="far fa-comment-dots" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    class="btn subBtn marginBottom10"
                                                    :aria-label="model.team.name"
                                                    @click="openModelInfoModal(question, model)"
                                                >
                                                    {{ truncate(model.team.name) }}
                                                </button>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <!-- <div class="hidden-xs hidden-sm marginTop20"  v-if="question.displayAnswerStatistics||options.showCorrectAnswers">
		<div class="flex">
			<div class="col-xs-12 col-md-9 paddingRight10">
				<div class="mini-panel panel">
					<div class="panel-heading flexSpaceBetween">
						<div>ANSWER CHOICES</div>
					</div>
					<div class="panel-body flex">
						<table class="barChart">
							<tbody class="body">
								<template v-for="(option,option_idx) in question.optionKeys">
									<tr>
										<div class="percentage" :style="'bottom:'+((option.percent.toFixed(0)/100)*80)+'%'">{{round(option.percent)}}%</div>
										<td class="bars" :class="{'correct':option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers),'wrong':!option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers),'default':!(question.displayAnswer||options.showCorrectAnswers)}" :style="'height:'+((option.percent.toFixed(0)/100)*80)+'%;'"></td>
										<td class="record">
											<div>Choice {{option.key}}</div>
											<template v-for="(model,model_idx) in option.answersPerOption">
												<template v-if="model.student">
													<div class="btnDiscussionTeams marginTop10">
														<button class="btn btn-discussionTeams subBtn" @click="openModelInfoModal(model,'student')">
															{{model.student.fullname}}&nbsp;<i v-if="(model.attachments&&model.attachments.length!=0)||model.comment" class="fa fa-comments"></i>
														</button>
													</div>
												</template>
												<template v-if="model.team">
													<div class="btnDiscussionTeams marginTop10">
														<button class="btn btn-discussionTeams subBtn" @click="openModelInfoModal(model)">
															{{model.team.name}}&nbsp;<i v-if="(model.attachments&&model.attachments.length!=0)||model.comment" class="fa fa-comments"></i>
														</button>
													</div>
												</template>
											</template>
											<button class="btn subBtn marginLeft0 fontSize12">
												<span>More</span>&nbsp;<i class="fa fa-sort-down"></i>
											</button>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-3 paddingLeft10">
				<div class="mini-panel panel inner-panel">
					<div class="panel-heading flexSpaceBetween">
						<div>STATISTICS</div>
					</div>
					<div class="panel-body height100">
						<div class="staticDetails">
							<div>
								<i class="far fa-clock greyText">&nbsp;</i><span class="fontBold">Average Time</span>
							</div>
							<div class="fontNormal">						
								<span>{{question.statistics.averageTime}}</span>
							</div>
						</div>
						<div class="staticDetails marginTop20" v-if="question.type!='mcqm'">
							<div>
								<i class="fas fa-chart-pie-alt greyText"></i>&nbsp;</i><span class="fontBold">% Correct</span>
							</div>
							<div class="fontNormal">						
								<span>{{round(question.statistics.percentCorrect)}}%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="hidden-md hidden-lg">
		<div class="mini-panel panel inner-panel marginTop20" v-if="question.displayAnswerStatistics">
			<div class="panel-heading flexSpaceBetween">
				<div>STATISTICS</div>
			</div>
			<div class="panel-body height100">
				<div class="staticDetails">
					<div>
						<i class="far fa-clock greyText">&nbsp;</i><span class="fontBold">Average Time</span>
					</div>
					<div class="fontNormal">						
						<span>{{question.statistics.averageTime}}</span>
					</div>
				</div>
				<div class="staticDetails marginTop20" v-if="question.type!='mcqm'">
					<div>
						<i class="fas fa-chart-pie-alt greyText"></i>&nbsp;</i><span class="fontBold">% Correct</span>
					</div>
					<div class="fontNormal">						
						<span>{{round(question.statistics.percentCorrect)}}%</span>
					</div>
				</div>
			</div>
		</div>

		<div class="mini-panel panel">
			<div class="panel-heading flexSpaceBetween">
				<div>ANSWER CHOICES</div>
			</div>
			<div class="panel-body flex">
				<table class="barChart">
					<tbody class="body">
						<template v-for="(option,option_idx) in question.optionKeys">
							<tr>
								<div class="percentage" :style="'bottom:'+((option.percent.toFixed(0)/100)*80)+'%'">{{round(option.percent)}}%</div>
								<td class="bars" :class="{'correct':option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers),'wrong':!option.isCorrect&&(question.displayAnswer||options.showCorrectAnswers),'default':!(question.displayAnswer||options.showCorrectAnswers)}" :style="'height:'+((option.percent.toFixed(0)/100)*80)+'%;'"></td>
								<td class="record">
									<div>Choice {{option.key}}</div>
									<template  v-for="(model,model_idx) in option.answersPerOption">
										<template v-if="model.student">
											<div class="btnDiscussionTeams marginTop10">
												<button class="col-xs-12 btn btn-discussionTeams subBtn" @click="openModelInfoModal(model,'student')">
													{{model.student.fullname}}&nbsp;<i v-if="(model.attachments&&model.attachments.length!=0)||model.comment" class="fa fa-comments-o"></i>
												</button>
											</div>
										</template>
										<template v-if="model.team">
											<div class="btnDiscussionTeams marginTop10">
												<button class="col-xs-12 btn btn-discussionTeams subBtn" @click="openModelInfoModal(model)">
													{{model.team.name}}&nbsp;<i v-if="(model.attachments&&model.attachments.length!=0)||model.comment" class="fa fa-comments-o"></i>
												</button>
											</div>
										</template>
									</template>
									<button class="btn subBtn marginLeft0 fontSize12">
										<span>More</span>&nbsp;<i class="fa fa-sort-down"></i>
									</button>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div> -->
        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>
                        <h2 :id="'eGalleryModelModal' + previewModel.question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20 collapsingQuestion">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)"
                            >
                                Question
                                {{ getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1) }}
                            </button>
                            <div
                                :id="'viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)"
                                class="collapse padding10"
                            >
                                <div class="question">
                                    <kr-math :input="previewModel.question.question" :safe="!previewModel.question.questionIsHTML" />
                                    <template v-if="previewModel.question.attachments && previewModel.question.attachments.length != 0">
                                        <div class="marginTop20 fontBold">
                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)
                                        </div>
                                        <div class="textLink">
                                            <div v-for="(file, idx) in previewModel.question.attachments" class="flex">
                                                <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                    file.filename
                                                }}</a>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <div class="marginTop20">
                                    <template v-for="(option, option_idx) in previewModel.question.optionKeys">
                                        <div
                                            class="discussionOption"
                                            :class="{
                                                correct: option.isCorrect && previewModel.question.displayAnswer,
                                                'padding10 paddingLeft32': !option.isCorrect && previewModel.question.displayAnswer,
                                            }"
                                        >
                                            <div class="flexOnly">
                                                <i v-if="option.isCorrect && previewModel.question.displayAnswer" class="fa fa-check-circle" />
                                                <i v-if="!option.isCorrect && previewModel.question.displayAnswer" class="fas fa-times-circle" />
                                                <div class="fontBold marginRight5">
                                                    {{ option.key }}
                                                </div>
                                                <kr-math
                                                    :input="previewModel.question.options[option_idx].content"
                                                    :safe="!previewModel.question.options[option_idx].contentIsHTML"
                                                />
                                            </div>
                                            <template v-if="previewModel.question.displayAnswerStatistics">
                                                <div class="discussion-buttons">
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <button
                                                            v-if="previewModel.type == 'team' && model.team.uuid == previewModel.team.uuid"
                                                            class="btn marginTop10"
                                                        >
                                                            {{ previewModel.team.name }}
                                                        </button>
                                                        <button
                                                            v-else-if="
                                                                previewModel.type == 'student' &&
                                                                model.student.userPlacementTestUuid == previewModel.student.userPlacementTestUuid
                                                            "
                                                            class="btn marginTop10"
                                                        >
                                                            {{ previewModel.student.fullname }}
                                                        </button>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="marginTop20 buttonGroup">
                            <button class="btn btn-default" @click.prevent="closeAllModal()">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { testMixins } from '../../../../../../../../../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'options', 'showRealtimeToggle'],
    data() {
        return {
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
                question: {},
            },
            model: [],
            previewQuestion: {},
        };
    },
    created() {
        if (!this.options) {
            this.options = {};
        }

        var that = this;
        Vue.nextTick(function () {
            $('#eGalleryModelModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
        });
    },
    methods: {
        openModelInfoModal(question, model, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = model.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = model.student;
            }

            this.previewModel.question = question;
            this.previewModel.comment = model.comment;
            this.previewModel.attachments = model.attachments ? model.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                    question: {},
                };
            });
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        setColumnToggle(value) {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/enable-stacking-columns', { activityQuestionUuid: this.question.uuid })
                .then(function (response) {
                    that.question.enableStackingColumns = value;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now' + (that.question.enableStackingColumns ? ' 2 Columns.' : ' Stacked.'),
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question answers at this moment, please try again later',
                    });
                });
        },
    },
};
</script>

<style scoped lang="scss">
.discussionOption {
    margin-top: 20px;
    line-height: 1.2;
    padding: 10px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;

    &:first-child {
        margin-top: 0;
    }
}

.discussionOption.correct {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #29cb97;
    border-radius: 3px;
    padding: 10px;
}

.discussionOption .fa-check-circle {
    color: #1b8360;
    margin-right: 10px;
}

.discussionOption .fa-times-circle {
    color: #717171;
    margin-right: 10px;
}

.mini-panel .panel-heading {
    font-size: 1rem;
}

.mini-panel .panel-heading .link {
    /* color: #0071be; */
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.commentTeams div {
    margin-top: 15px;
}

.commentTeams div:first-child {
    margin-top: 0;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
table.barChart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 330px;
    max-height: 330px;
    position: relative;
    overflow: auto;
    border: none;
    box-shadow: none;
}

.barChart .bars {
    display: flex;
    flex-direction: column;
}

.barChart .bars tr {
    flex-grow: 1;
}

.barChart .bars td {
    text-align: center;
    display: block;
    margin-right: 0.5em;
    line-height: 0em;
}

.barChart .bars td::after {
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    position: absolute;
}

.barChart .body {
    display: flex;
    flex-grow: 1;
    max-height: 200px;
    border-bottom: 2px solid #d8d8d8;
}

.barChart .body tr {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 2% 0 0;
    position: relative;
    width: 150px;
    max-height: 200px;
}

.barChart .body tr:first-child {
    margin-left: 2%;
}

.barChart .record {
    text-align: center;
    position: absolute;
    top: 200px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0071be;
    font-weight: bold;
    line-height: 1;
}

.barChart .bars {
    background: #989b9c;
    display: block;
    width: 30px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0;
    max-height: 184px;
}

.barChart .percentage {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
}

.barChart tr:nth-child(6n + 1) .btn-discussionTeams {
    background: #e48b3a;
}
.barChart tr:nth-child(6n + 2) .btn-discussionTeams {
    background: #4e669b;
}
.barChart tr:nth-child(6n + 3) .btn-discussionTeams {
    background: #81359f;
}
.barChart tr:nth-child(6n + 4) .btn-discussionTeams {
    background: #428459;
}
.barChart tr:nth-child(6n + 5) .btn-discussionTeams {
    background: #bfa677;
}
.barChart tr:nth-child(6n + 6) .btn-discussionTeams {
    background: #ba4747;
}

.barChart .bars.correct {
    background: #3e7c5b;
}
.barChart .bars.wrong {
    background: #ca3333;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
}

.discussion-buttons .btn {
    margin-left: 0;
    margin-right: 20px;
}

.discussionOption:nth-child(6n + 1) .discussion-buttons button {
    background: #e48b3a;
    color: #fff;
}
.discussionOption:nth-child(6n + 2) .discussion-buttons button {
    background: #4e669b;
    color: #fff;
}
.discussionOption:nth-child(6n + 3) .discussion-buttons button {
    background: #81359f;
    color: #fff;
}
.discussionOption:nth-child(6n + 4) .discussion-buttons button {
    background: #428459;
    color: #fff;
}
.discussionOption:nth-child(6n + 5) .discussion-buttons button {
    background: #bfa677;
    color: #fff;
}
.discussionOption:nth-child(6n + 6) .discussion-buttons button {
    background: #ba4747;
    color: #fff;
}
</style>
