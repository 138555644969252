<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">NEW MODULE</div>
                    <div class="col-xs-6 buttonGroupInline flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit(true)">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <button
                                class="btn btn-default mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save and New"
                                @click.prevent="submit(false)"
                            >
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save and New
                            </button>
                            <!--<router-link v-if="isSetCourse" :to="{name:'courses.view',params:{id:$route.params.course_id},query:{tab:'module'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit(true)">
                                <i class="far fa-save" aria-hidden="true"></i>
                            </button>
                            <button
                                class="btn btn-default mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save and New"
                                @click.prevent="submit(false)"
                            >
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save and New
                            </button>
                            <!--<router-link v-if="isSetCourse" :to="{name:'courses.view',params:{id:$route.params.course_id},query:{tab:'module'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <module-form :form="form" :course="course"></module-form>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="buttonGroup">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit(true)">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <button
                                class="btn btn-default mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save and New"
                                @click.prevent="submit(false)"
                            >
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save and New
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.course_id },
                                    query: { tab: 'module' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            course: {},
            form: new KrForm({
                fields: {
                    courseUuid: '',
                    name: '',
                    //code:'',
                    description: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    descriptionIsHTML: '',
                },
                props: {},
                urls: {
                    post: 'modules',
                },
            }),
        };
    },
    components: {
        'module-form': require(`./form-partials/partial.module.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        this.form.model.descriptionIsHTML = false;
        //this.form.model.startDate = moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')+'Z';
        //this.form.model.endDate = moment().endOf('day').utc().add(7,'days').format('YYYY-MM-DDTHH:mm:ss')+'Z';
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
        axios.get('/courses/' + this.$route.params.course_id).then(function (response) {
            that.course = response.data.data;
            that.form.model.courseUuid = that.course.uuid;
            that.form.model.startDate = that.course.startDate;
            that.form.model.endDate = that.course.endDate;
            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    {
                        name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                        link: 'courses.view',
                        params: { id: that.course.uuid },
                        query: { tab: 'module' },
                    },
                    { name: 'New Module' },
                ],
            });
            that.component_done_loading = true;
        });
    },
    methods: {
        submit(backToView = false) {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    if (backToView) {
                        that.$router.push({
                            name: 'courses.view',
                            params: { id: that.$route.params.course_id },
                            query: {
                                tab: 'module',
                                moduleUuid: response.data.uuid,
                            },
                        });
                    } else {
                        that.form.model.courseUuid = that.course.uuid;
                        that.form.model.startDate = that.course.startDate;
                        that.form.model.endDate = that.course.endDate;
                    }
                }
            });
        },
    },
};
</script>
