<template>
    <div v-if="component_done_loading">
        <dark-topbar :test-obj="test"></dark-topbar>
        <div class="padding70px25px">
            <div class="questions-wrapper">
                <activity-application-e-gallery
                    v-if="test.type == 'application'"
                    :test-obj="test"
                    :options="{ showWindowLink: false }"
                ></activity-application-e-gallery>
                <activity-questions-div v-else :test-obj="test" :options="{ showWindowLink: false }"></activity-questions-div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            test: {},
            component_done_loading: false,
            debouncedUpdate: null,
            isDiscussionScreen: false,
        };
    },
    created() {
        $('body').addClass('login');
        var that = this;
        this.debouncedUpdate = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    that.test = response.data.data;
                });
            },
            500,
            { maxWait: 800 }
        );
        Events.fire('topbar_update', {
            title: '<i class="fas fa-pencil-ruler"></i>&nbsp;Activity',
        });
        this.fetchTest().then(function (response) {
            that.test = response.data.data;
            if (that.test.type == 'application' && that.$route.name == 'activities.tests.questions') {
                that.isDiscussionScreen = true;
                axios
                    .post('tests/' + that.$route.params.id + '/presentation-mode', { presentationMode: true })
                    .then(function (response) {
                        that.component_done_loading = true;
                    })
                    .catch(function (errors) {});
            } else {
                that.component_done_loading = true;
            }
            that.processEchoListeners();
        });
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.testUuid != that.test.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('TeamAnswerSaved', h1);

            this.echoChannels.push({
                channel: c1,
                event: 'TeamAnswerSaved',
                handler: h1,
            });
            let h2 = (e) => {
                if (e.testUuid != that.test.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('StudentAnswerSaved', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentAnswerSaved',
                handler: h2,
            });

            let h3 = (e) => {
                for (var i = 0; i < this.test.questions.length; i++) {
                    for (var j = 0; j < this.test.questions[i].length; j++) {
                        if (this.test.questions[i][j].uuid == e.activityQuestion.uuid) {
                            this.test.questions[i][j].displayAnswer = e.activityQuestion.displayAnswer ? true : false;
                            this.test.questions[i][j].displayAnswerStatistics = e.activityQuestion.displayAnswerStatistics ? true : false;
                            this.test.questions[i][j].enableStackingColumns = e.activityQuestion.enableStackingColumns ? true : false;
                        }
                    }
                }
            };
            let c3 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('DiscussionQuestionStatusUpdated', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'DiscussionQuestionStatusUpdated',
                handler: h3,
            });
        },
        fetchTest() {
            return axios.get('/tests/' + this.$route.params.id + '/presentation/questions');
        },
    },
    components: {
        'activity-application-e-gallery': require(`./partials/application/e-gallery-walk/index.vue`).default,
        'activity-questions-div': require(`./partials/partials/questions-div.vue`).default,
        'dark-topbar': require(`./partials/dark-topbar.vue`).default,
    },
    beforeUnmount() {
        if (this.isDiscussionScreen) {
            axios
                .post('tests/' + this.$route.params.id + '/presentation-mode', {
                    presentationMode: false,
                })
                .then(function (response) {})
                .catch(function (errors) {});
        }
    },
};
</script>
<style scoped>
.questions-wrapper {
    display: flex;
    /*height:100%;*/
    align-items: center;
    justify-content: center;
}
</style>
