<template>
    <div v-if="component_done_loading">
        <div class="test_topBar_placeholder" @load="updateTopNavHeight"></div>
        <div ref="testTopBar" class="test_topBar_v2 no-print" :class="{ timer: isTimerShown, 'd-block': isEvaluationScore }">
            <template v-if="!isEvaluationScore">
                <div>
                    <img src="/img/icons/_newicons_intedashboardlogo.png" width="25px" alt="Intedashboard Logo" />
                </div>

                <div class="test_topBarContent flexOnly align-items">
                    <div class="col-xs-12">
                        <template v-if="!forceHideTimer && testObj.settings.hasTimeLimit">
                            <topbar-timer v-show="isTimerShown" :test-obj="testObj" @is-shown="processTimerShown" />
                        </template>
                    </div>
                    <template v-if="testObj._isTeacherPreview">
                        <div class="hidden-xs hidden-sm no-print">
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.modules.activities.continue',
                                    params: { id: testObj.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate"><i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                            </router-link>
                        </div>
                        <div class="hidden-md hidden-lg no-print">
                            <router-link
                                v-slot="{ navigate }"
                                v-tooltip="'Copy'"
                                :to="{
                                    name: 'courses.modules.activities.continue',
                                    params: { id: testObj.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">
                                    <i class="fas fa-caret-left" aria-hidden="true" />
                                </button>
                            </router-link>
                        </div>
                    </template>
                    <div>
                        <div class="buttonGroup flexRight">
                            <div class="hidden-xs hidden-sm">
                                <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link v-slot="{ navigate }" v-tooltip="'Back To Index Page'" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <router-link v-slot="{ navigate }" v-tooltip="'Back To Index Page'" :to="{ name: 'tests.index' }" custom>
                    <button class="btn btn-default" @click="navigate">
                        <i class="fas fa-arrow-left-long" aria-hidden="true" />
                    </button>
                </router-link>
                <h1 class="capitalize marginBottom0">{{ testObj.type }} Results</h1>
                <p class="capitalize">
                    {{ testObj.name }}
                </p>
            </template>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['testObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            isTimerShown: true,
            auth: new KrAuth(),
            forceHideTimer: false,
            isEvaluationScore: false,
        };
    },
    created() {
        this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);
        this.isEvaluationScore = _.get(this.options, 'isEvaluationScore', false);
        window.addEventListener('load', () => {
            this.updateTopNavHeight();
        });
    },
    mounted() {
        var that = this;

        this.component_done_loading = true;

        this.$nextTick(() => {
            this.updateTopNavHeight();
        });

        window.addEventListener('resize', this.updateTopNavHeight);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateTopNavHeight);
    },
    methods: {
        downloadReport() {
            var that = this;
            axios({
                url: '/student/tests/' + this.testObj.uuid + '/report/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.testObj.type + ')' + that.testObj.name + '_' + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        processTimerShown(isShown) {
            this.isTimerShown = isShown;
            this.$emit('timer-shown', this.isTimerShown);
        },
        openInstructions() {
            Events.fire('show-stopping-div');
        },
        getDisplayName() {
            return (this.auth.user().displayName && this.auth.user().displayName.trim()) != ''
                ? this.auth.user().displayName
                : this.getStudentName(this.auth.user(), false);
        },
        getStudentName(student, limit = true) {
            var name = student.firstname + (student.lastname ? ' ' + student.lastname : '');
            if (name.length > 20 && limit) {
                return name.slice(0, 17) + '...';
            }
            return name;
        },
        init() {
            var ret = [];
            for (var i = 0; i < this.testObj.questions.length; i++) {
                ret.push(this.testObj.questions[i][0]);
            }

            var j = ret.findIndex((z) => z.uuid === this.testObj.eGalleryProgression.currentQuestionUuid);

            return (this.current_question_data = this.testObj.questions[j][0]);
        },
        updateTopNavHeight() {
            if (!this.$refs.testTopBar) {
                return;
            }
            $('.test_topBar_placeholder').height(this.$refs.testTopBar.offsetHeight + 'px');
        },
        truncate(str, characters = 20) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
    },
    components: {
        'topbar-timer': require(`./topbar-timer-V2.vue`).default,
    },
    watch: {
        options: {
            handler: function () {
                this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);
                this.isEvaluationScore = _.get(this.options, 'isEvaluationScore', false);
            },
            deep: true,
        },
    },
};
</script>
