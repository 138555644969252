<template>
    <div class="padding0">
        <div class="evaluation-sub-header">
            <div class="tableStatusTag">Requirements</div>
            <div>
                <div v-if="props.question.settings.shouldDistributePointsWithoutRepeatingValues" class="flexOnly alignFlexStart">
                    <span
                        class="marginRight8"
                        :class="{
                            'material-icons success': isDifferentPoints,
                            'material-symbols-outlined requirements-notComplete': !isDifferentPoints,
                        }"
                    >
                        <template v-if="isDifferentPoints"> check_circle </template>
                        <template v-else> cancel </template>
                    </span>
                    <p
                        :class="{
                            success: isDifferentPoints,
                            'requirements-notComplete': !isDifferentPoints,
                        }"
                    >
                        Must distribute points without repeating values
                    </p>
                </div>
                <div v-if="props.question.settings.shouldDistributeAllPoints" class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8"
                        :class="{
                            'material-icons success': availablePoints == 0,
                            'material-symbols-outlined requirements-notComplete': availablePoints != 0,
                        }"
                    >
                        <template v-if="availablePoints == 0"> check_circle </template>
                        <template v-else> cancel </template>
                    </span>
                    <p
                        :class="{
                            success: availablePoints == 0,
                            'requirements-notComplete': availablePoints != 0,
                        }"
                    >
                        Must distribute <b>ALL</b> points
                    </p>
                </div>
                <div v-if="props.question.settings.shouldGiveOneTeammateAboveTenPoints" class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8"
                        :class="{
                            'material-icons success': isAboveTen,
                            'material-symbols-outlined requirements-notComplete': !isAboveTen,
                        }"
                    >
                        <template v-if="isAboveTen"> check_circle </template>
                        <template v-else> cancel </template>
                    </span>
                    <p
                        :class="{
                            success: isAboveTen,
                            'requirements-notComplete': !isAboveTen,
                        }"
                    >
                        Must give at least one teammate a point above {{ props.question.settings.pointsAllocatedPerMember }}
                    </p>
                </div>
                <div v-if="props.question.settings.shouldGiveOneTeammateBelowTenPoints" class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8"
                        :class="{
                            'material-icons success': isBelowTen,
                            'material-symbols-outlined requirements-notComplete': !isBelowTen,
                        }"
                    >
                        <template v-if="isBelowTen"> check_circle </template>
                        <template v-else> cancel </template>
                    </span>
                    <p
                        :class="{
                            success: isBelowTen,
                            'requirements-notComplete': !isBelowTen,
                        }"
                    >
                        Must give at least one teammate a point below {{ props.question.settings.pointsAllocatedPerMember }}&nbsp;<b>(0 is allowed)</b>
                    </p>
                </div>
                <div v-if="props.question.settings.allowToGiveThemselvesPoints" class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8"
                        :class="{
                            'material-icons success': props.question.settings.allowToGiveThemselvesPoints,
                            'material-symbols-outlined requirements-notComplete': !props.question.settings.allowToGiveThemselvesPoints,
                        }"
                    >
                        <template v-if="props.question.settings.allowToGiveThemselvesPoints"> check_circle </template>
                        <template v-else> cancel </template>
                    </span>
                    <p
                        :class="{
                            success: props.question.settings.allowToGiveThemselvesPoints,
                            'requirements-notComplete': !props.question.settings.allowToGiveThemselvesPoints,
                        }"
                    >
                        Give points to self&nbsp;<b>(0 is allowed)</b>
                    </p>
                </div>
            </div>
        </div>
        <div class="padding16">
            <div class="flexSpaceBetween marginBottom20">
                <h4 class="margin0 paddingTop5">
                    <b> Point Distribution </b>
                </h4>

                <div class="flex align-items important">
                    <div class="circular-points marginRight8">
                        <span>{{ availablePoints }}</span>
                    </div>
                    <p class="paddingTop3">Points Left</p>
                </div>
            </div>
            <div v-for="(student, studentIdx) in value" class="col-xs-12 col-lg-5" :class="{ marginTop20: studentIdx + 1 > 1 }">
                <div class="flexSpaceBetween">
                    <div class="flexOnly align-items">
                        <div class="profile_image">
                            <div class="badge_profile img-circle">
                                <span> {{ globalInitials(getStudentName(student.receiverId)) }} </span>
                            </div>
                        </div>
                        <p class="marginLeft10">{{ getStudentName(student.receiverId) }}</p>
                    </div>

                    <b class="color-purple fs-20px">
                        <template v-if="student.point == null"> 0 </template>
                        <template v-else>
                            {{ student.point }}
                        </template>
                    </b>
                </div>

                <cl-slider
                    v-model.number="student.point"
                    :show-number-input="false"
                    :is-range-only="false"
                    :include-buttons="true"
                    :set-max="totalPoints"
                    :left-points="availablePoints"
                    :is-disabled="disabled ? true : undefined"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { ref, computed, onBeforeUpdate } from 'vue';
const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    testObj: {
        type: Object,
        default: () => ({}),
    },
    question: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelModifiers: { default: () => ({}) },
});
const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
const isAboveTen = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            if (value.value[i].point > 10) {
                return true;
            }
        }
        return false;
    },
});
const isBelowTen = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            if (value.value[i].point < 10) {
                return true;
            }
        }
        return false;
    },
});
const isDifferentPoints = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            for (var j = 0; j < value.value.length; j++) {
                if (i != j && value.value[i].point == value.value[j].point) {
                    return false;
                }
            }
        }
        return true;
    },
});
const totalPoints = computed({
    get() {
        if (props.question.settings.pointDistributionStyle == 'fink') {
            return _.get(props.question, 'settings.pointsAllocatedPerMember', 100);
        } else {
            return value.value.length * _.get(props.question, 'settings.pointsAllocatedPerMember', 10);
        }
    },
});

const availablePoints = computed({
    get() {
        let usedPoints = 0;
        for (var i = 0; i < value.value.length; i++) {
            usedPoints += value.value[i].point ? value.value[i].point : 0;
        }
        return totalPoints.value - usedPoints;
    },
});

const totalPointsUsed = computed(() => {
    let newPoints = 0;
    for (var i = 0; i < value.value.length; i++) {
        newPoints += value.value[i].point ? parseInt(value.value[i].point, 10) : 0;
    }
    return newPoints;
});
const getAnswer = () => {
    let studentAnswer = props.question.pointDistributions;
    studentAnswer = studentAnswer ? studentAnswer : [];
    if (studentAnswer.length > 0) {
        return studentAnswer;
    } else {
        let distributions = [];
        for (var i = 0; i < props.testObj.team.length; i++) {
            let student = props.testObj.team[i];
            distributions.push({ receiverId: student.id, point: null });
        }
        return distributions;
    }
};
const canSubmitAnswer = computed(() => {
    if (
        (props.question.settings.shouldDistributePointsWithoutRepeatingValues && !isDifferentPoints.value) ||
        (props.question.settings.shouldDistributeAllPoints && availablePoints.value != 0) ||
        (props.question.settings.shouldGiveOneTeammateAboveTenPoints && !isAboveTen.value) ||
        (props.question.settings.shouldGiveOneTeammateBelowTenPoints && !isBelowTen.value)
    ) {
        return false;
    }
    let savedAnswer = _.map(getAnswer(), 'point');
    let currentAnswer = _.map(value.value, 'point');
    if (_.isEqual(savedAnswer, currentAnswer)) {
        return false;
    }
    return true;
});

const maxReached = (e, index) => {
    if (totalPointsUsed.value >= totalPoints.value) {
        value.value[index].point -= totalPointsUsed.value - totalPoints.value;
        e.preventDefault();
    }
};
const getStudentName = (id) => {
    let idx = _.findIndex(props.testObj.team, function (o) {
        return o.id == id;
    });
    if (idx != -1) {
        return props.testObj.team[idx].displayName;
    } else {
        return '';
    }
};
const setupEventListeners = () => {
    const inputs = document.querySelectorAll("input[type='range']");

    inputs.forEach((input, index) => {
        input.addEventListener('input', (e) => maxReached(e, index));
    });
};

onBeforeUpdate(() => {
    setupEventListeners();
});
// expose data and method thingy
defineExpose({
    canSubmitAnswer,
});
</script>
