<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">EDIT STUDENT</div>
                    <!-- <div class="col-xs-6 textAlignRight">
						<div class="hidden-xs hidden-sm">
				            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                <i class="fa fa-check"></i>&nbsp;Update
				            </button>
				            <router-link v-if="!isFromCourse":to="{name:'students.view',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" exact>
					            Back
				            </router-link>
				            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                <i class="fa fa-check"></i>
				            </button>
				            <router-link v-if="!isFromCourse" :to="{name:'students.view',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" exact>
					            <i class="fa fa-caret-left"></i>
				            </router-link>
				            <router-link v-else :to="{name:'courses.view',params:{id:''}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>
				        </div>
			        </div> -->
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <student-form :form="form" :from-course="isFromCourse"></student-form>
                        <div class="profileBtns buttonGroup">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-if="!isFromCourse"
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'students.view',
                                    params: $route.params.id,
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                            <router-link
                                v-else
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.course_id },
                                    query: { tab: 'student' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import { useAuthUserStore } from '../../../stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            isFromCourse: false,
            course: [],
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    displayName: '',
                    email: '',
                    roleId: 'default!:4',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    accountUuid: '',
                    organisationID: '',
                    remarks: '',
                },
                props: {},
            }),
            store: useAuthUserStore,
        };
    },
    components: {
        'student-form': require(`./form-partials/partial.student.vue`).default,
    },
    computed: {
        canSend() {
            if (
                (!this.form.errors.any() &&
                    this.form.model.email &&
                    this.form.model.firstname &&
                    this.form.model.lastname &&
                    this.form.model.accountUuid &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        if (this.$route.name == 'courses.manage.students.edit') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            });
            this.isFromCourse = true;
        } else {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-users"></i>&nbsp;Students',
                breadcrumb: [
                    { name: 'Home' },
                    {
                        name: 'Student',
                        link: 'students.view',
                        params: { id: this.$route.params.id },
                    },
                    { name: 'Edit Student' },
                ],
            });
        }
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            if (!this.isFromCourse) {
                axios.get('/users/' + that.$route.params.id).then(function (user) {
                    that.form.setModel(user.data.data);
                    if (!that.form.model.email) {
                        that.form.setDisabled('organisationID', true);
                    }
                    that.component_done_loading = true;
                });
            } else {
                axios
                    .all([
                        axios.get(`/courses/${this.$route.params.course_id}/students/${that.$route.params.id}`),
                        axios.get('/courses/' + this.$route.params.course_id),
                    ])
                    .then(
                        axios.spread(function (user, course) {
                            that.form.setModel(user.data.data);
                            if (!that.form.model.email) {
                                that.form.setDisabled('organisationID', true);
                            }
                            that.course = course.data.data;
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    { name: 'Home' },
                                    { name: 'Courses', link: 'courses.index' },
                                    {
                                        name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                                        link: 'courses.view',
                                        params: { id: that.course.uuid },
                                        query: { tab: 'student' },
                                    },
                                ],
                            });
                            that.component_done_loading = true;
                            // Both requests are now complete
                        })
                    );
            }
        },
        submit() {
            var that = this;
            let url = '/users/' + this.$route.params.id;
            if (this.isFromCourse) {
                url = `/courses/${this.$route.params.course_id}/students/${that.$route.params.id}`;
            }
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put(url).then(function (response) {
                if (!response.krFormError) {
                    if (that.auth.user().uuid == _.get(response, 'data.data.uuid', that.$route.params.id)) {
                        that.store.updateProfile({
                            profile: response.data.data,
                        });
                    }
                    if (!that.isFromCourse) {
                        that.$router.push({
                            name: 'students.view',
                            params: { id: response.data.data.uuid },
                        });
                    } else {
                        //that.$router.push({name:'courses.manage.students.view',params:{course_id:that.course.uuid,id:that.$route.params.id}});
                        that.$router.push({
                            name: 'courses.view',
                            params: { id: that.$route.params.course_id },
                            query: { tab: 'student' },
                        });
                    }
                }
            });
        },
    },
};
</script>
