<template>
    <div class="circle-progress">
        <svg
            class="circle-progress__svg"
            :viewBox="'-' + svgXandY + ' -' + svgXandY + ' ' + svgWidthHeight + ' ' + svgWidthHeight"
            :width="svgWidthHeight"
            :height="svgWidthHeight"
        >
            <circle class="circle-progress__circle" :r="radius" cx="0" cy="0" />
            <circle class="circle-progress__progression" :style="fillingCircleStyles" :r="radius" cx="0" cy="0" transform="rotate(-70)" />
        </svg>

        <div v-if="showContent" class="circle-progress__content">
            {{ percentageForDisplay }}%
            <slot />
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    max: {
        type: Number,
        required: true,
    },

    value: {
        type: Number,
        required: true,
    },
    showContent: {
        type: Boolean,
    },
});

const fillingCircleStyles = computed(() => {
    return {
        strokeDashoffset: filledCircle.value,
        strokeDasharray: circleSize.value,
    };
});

const svgWidthHeight = ref(36);
const svgXandY = ref(svgWidthHeight.value / 2);
const radius = ref(svgWidthHeight.value / 3);

const circleSize = computed(() => radius.value * Math.PI * 2);
const percentage = computed(() => Math.floor((props.value / props.max) * 100));
const percentageForDisplay = ref(percentage);
const filledCircle = computed(() => {
    return circleSize.value - circleSize.value * (percentage.value / 100);
});
</script>

<style lang="scss">
@keyframes filling {
    from {
        stroke-dashoffset: v-bind('circleSize');
    }
    to {
        stroke-dashoffset: v-bind('filledCircle');
    }
}
.circle-progress {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    &__circle {
        fill: transparent;
        stroke-width: 4px;
        stroke: #fff;
        stroke-dashoffset: 0;
    }

    &__progression {
        fill: transparent;
        stroke-width: 4px;
        stroke: #f59e0b;
        stroke-linecap: round;
        animation-name: filling;
        animation-duration: 0.5s;
        animation-timing-function: ease-in;
        transition: 0.5s all;
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
</style>
