<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT ONBOARDING</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <!--<button id="top-preview-email-btn" class="btn btn-default mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                Preview Email
				            </button>
				            <router-link id="top-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-send-invitation-btn"
                                v-tooltip="{ content: 'Create' }"
                                class="btn btn-primary mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Create"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                            <!--<button id="top-mobile-preview-email-btn" class="btn btn-default mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                <i class="fa fa-eye"></i>
				            </button>
				             <router-link id="top-mobile-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <onboard-form :form="form" :account="account" :superusers="superusers"></onboard-form>
                        <div class="buttonGroup">
                            <button
                                id="bottom-send-invitation-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: $route.params.account_id },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    onboarding: 'default!:N/A',
                    customerAdvisorUuid: '',
                    lms: '',
                    schoolYear: '',
                    date: '',
                },
                props: {},
                urls: {
                    put: 'accounts/' + this.$route.params.id + '/onboarding',
                },
            }),
            account: {},
            superusers: [],
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetch();
    },
    components: {
        'onboard-form': require(`./form-partials/partial.onboard.vue`).default,
    },
    methods: {
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.id },
                    });
                }
            });
        },
        fetch() {
            var that = this;
            axios.all([axios.get('/accounts/' + this.$route.params.id), axios.get('users?role=1&per_page=100')]).then(
                axios.spread(function (account, superusers) {
                    that.account = account.data.data;
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Accounts', link: 'accounts.index' },
                            {
                                name: that.account.organisationName,
                                link: 'accounts.view',
                                params: { id: that.$route.params.id },
                            },
                            { name: 'Edit Onboarding' },
                        ],
                    });
                    that.superusers = superusers.data.data;
                    that.form.setModel(that.account.onboarding);
                    that.component_done_loading = true;
                })
            );
        },
    },
    computed: {
        canSend() {
            /*if(!this.form.model.quantity||!this.form.model.bookstoreName||!this.form.model.prefix||!this.form.model.validityPeriod||!this.form.model.validForRefundDate){
	    			return false;
	    		}*/
            return true;
        },
    },
};
</script>
