<template>
    <div>
        <div>
            <caption class="visible-hidden">
                Question Stem
            </caption>
            <kr-math :input="question.question" :safe="!question.question.questionIsHTML" class="question" :can-highlight="true" />
        </div>

        <div v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <template v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename">
                    <div class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <template
                            v-if="
                                (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                                testObj.type != 'application'
                            "
                        >
                            <template v-if="isPdf(file)">
                                <template v-if="testObj.settings.allowPdfDownload">
                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                </template>
                            </template>
                            <template v-else>
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="testObj._isPreview && !testObj._isTeacherPreview" class="marginTop20">
            <template v-for="option in question.options">
                <div class="radioField flex">
                    <label>
                        <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey"> {{ option.key }}. </span>
                        <span v-html="option.content" />
                    </label>
                </div>
            </template>
        </div>

        <div v-else-if="testObj.settings.confidenceBasedTesting && question.type == 'mcqs'" class="marginTop20">
            <template v-for="option in question.options" :key="'mcqs-' + question.uuid + '-' + option.key">
                <div :id="question.uuid + '-' + option.key + '-div'" class="radioField flex">
                    <div class="numberInput">
                        <input
                            type="number"
                            class="form-control"
                            :value="model[option.key]"
                            :disabled="disabled || !canChangePointSpreading(option.key, model[option.key] + 1) ? true : undefined"
                            :aria-label="option.key"
                            @keypress="isNumber($event, option.key)"
                            @keyup="changePointSpreading(option.key, $event.target.value)"
                        />
                        <span class="quantity-nav">
                            <div
                                class="quantity-button quantity-up"
                                :class="{
                                    disabled: !canChangePointSpreading(option.key, model[option.key] + 1),
                                }"
                                @click.prevent="changePointSpreading(option.key, model[option.key] + 1)"
                            >
                                <i class="fas fa-plus" aria-hidden="true" />
                            </div>
                            <div
                                class="quantity-button quantity-down"
                                :class="{
                                    disabled: !canChangePointSpreading(option.key, model[option.key] - 1),
                                }"
                                @click.prevent="changePointSpreading(option.key, model[option.key] - 1)"
                            >
                                <i class="fas fa-minus" aria-hidden="true" />
                            </div>
                        </span>
                    </div>
                    <label>
                        <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey"> {{ option.key }}. </span>
                        <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                    </label>
                </div>
            </template>
        </div>
        <div v-else-if="question.type == 'mcqs'" class="marginTop20">
            <template v-for="(option, op_idx) in question.options" :key="'mcqs-' + question.uuid + '-' + op_idx">
                <div :id="question.uuid + '-' + option.key + '-div'" class="radioField flex" :class="{ highlight: option.highlight }">
                    <div v-if="isCorrect(option.key) === true || isWrong(option.key) === true" class="correctOrWrong flexOnly width100">
                        <div class="marginRight10">
                            <template v-if="isCorrect(option.key) === true">
                                <i class="correct fa fa-check-circle" />
                            </template>
                            <template v-else-if="isWrong(option.key) === true">
                                <i class="wrong fa fa-times-circle" />
                            </template>
                        </div>
                        <label :for="question.uuid + '-' + option.key" class="paddingTop2">
                            <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey">
                                <template v-if="testObj.settings.randomizeAnswerOptionOrder && testObj.type == 'trat'">
                                    {{ String.fromCharCode(65 + op_idx) }}.
                                </template>
                                <template v-else> {{ option.key }}. </template>
                            </span>
                            <span v-html="option.content" />
                        </label>
                    </div>
                    <template v-else>
                        <input
                            :id="question.uuid + '-' + option.key"
                            v-model="model"
                            :name="question.uuid"
                            type="radio"
                            :disabled="disabled ? true : undefined"
                            :value="option.key"
                            @change="$emit('changed', model)"
                        />
                        <!-- <input type="radio" :disabled="(disabled) ? true:undefined" v-model="model" :value="option.key" @change="$emit('changed',model)" :class="{'correct':isCorrect(option.key)===true,'wrong':isWrong(option.key)===true}" :id="question.uuid+'-'+option.key"> -->
                        <label :for="question.uuid + '-' + option.key" class="paddingTop2">
                            <span v-if="!testObj.settings.hideQuestionOptionLabels" class="withOptionKey">
                                <template v-if="testObj.settings.randomizeAnswerOptionOrder && testObj.type == 'trat'">
                                    {{ String.fromCharCode(65 + op_idx) }}.
                                </template>
                                <template v-else> {{ option.key }}. </template>
                            </span>
                            <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                        </label>
                    </template>
                </div>
                <div class="savedTime" v-html="displayLastAttemptDate(option)" />
            </template>
        </div>

        <div v-else-if="question.type == 'mcqm'" class="marginTop20">
            <p v-if="question.countForRequiredAnswersForMcqm > 0" class="fontSize16">
                <b>Please pick exactly {{ question.countForRequiredAnswersForMcqm }} options</b>
            </p>

            <div
                v-for="option in question.options"
                :id="question.uuid + '-' + option.key + '-div'"
                :key="'mcqm-' + question.uuid + '-' + option.key"
                class="checkboxField flex"
            >
                <div v-if="isCorrect(option.key) === true || isWrong(option.key) === true" class="correctOrWrong flexOnly width100">
                    <div class="marginRight10">
                        <template v-if="isCorrect(option.key) === true">
                            <i class="correct fa fa-check-circle" />
                        </template>
                        <template v-else-if="isWrong(option.key) === true">
                            <i class="wrong fa fa-times-circle" />
                        </template>
                    </div>
                    <label :for="question.uuid + '-' + option.key" class="paddingTop3">
                        <span class="withOptionKey">{{ option.key }}.</span>
                        <span class="withAnswerKey">
                            <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                        </span>
                    </label>
                </div>
                <input
                    :id="question.uuid + '-' + option.key"
                    v-model="model"
                    :name="question.uuid"
                    type="checkbox"
                    :disabled="
                        disabled
                            ? true
                            : question.countForRequiredAnswersForMcqm > 0 &&
                              model.length == question.countForRequiredAnswersForMcqm &&
                              !model.includes(option.key)
                            ? true
                            : undefined
                    "
                    :value="option.key"
                    @change="$emit('changed', model)"
                />
                <label :for="question.uuid + '-' + option.key" class="paddingTop3">
                    <span class="withOptionKey"> {{ option.key }}. </span>
                    <span><kr-math :input="option.content" :safe="!option.contentIsHTML" /></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question', 'value', 'disabled', 'isTestCompleted'],

    data() {
        return {
            model: null,
        };
    },
    computed: {
        pointSpreadingPointsLeft() {
            if (!this.testObj.settings.confidenceBasedTesting) {
                return null;
            }
            let total = this.question.options.length;
            //return total;
            var that = this;
            Object.keys(this.model).forEach(function (key, index) {
                total -= parseInt(that.model[key]);
            });
            return total;
        },
    },
    watch: {
        value: {
            handler: function () {
                this.model = this.value;
            },
        },
        question: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
    },
    created() {
        this.model = this.value;
    },
    methods: {
        displayLastAttemptDate(option) {
            if (!this.question.studentAnswer.attempts2 || this.testObj.type != 'irat') {
                return '';
            }
            for (var i = this.question.studentAnswer.attempts2.length - 1; i >= 0; i--) {
                let key = this.testObj.settings.randomizeAnswerOptionOrder ? option.originalKey : option.key;
                if (key == this.question.studentAnswer.attempts2[i].attempt) {
                    //console.log(this.question.studentAnswer.attempts2[i])
                    return `<span class="${i != this.question.studentAnswer.attempts2.length - 1 ? 'greyText' : ''}">Selected this answer at ${
                        this.convertToReadableDate(this.question.studentAnswer.attempts2[i].timestamp, 'YYYY-MM-DD h:mm A').date
                    } (${this.convertToReadableDate(this.question.studentAnswer.attempts2[i].timestamp, 'YYYY-MM-DD h:mm A').current_timezone})</span>`;
                }
            }
            return '';
        },
        isNumber(event, key) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = event.key;
            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            } else {
                event.target.value = event.target.value.replace(/^0+/, '');
            }
        },
        canChangePointSpreading(key, value) {
            if (this.disabled) {
                return false;
            }
            if (value == '') {
                value = '0';
            }
            let oldPoint = parseInt(this.model[key]);
            let newPoint = parseInt(value);
            let pointsLeft = this.pointSpreadingPointsLeft;
            if (newPoint < 0) {
                return false;
            }
            pointsLeft = pointsLeft + oldPoint;
            if (pointsLeft - newPoint >= 0 || (newPoint > oldPoint && this.pointSpreadingPointsLeft > 0)) {
                return true;
            } else {
                return false;
            }
        },
        changePointSpreading(key, value) {
            if (value == '') {
                this.model[key] = 0;
                value = 0;
            }
            if (this.canChangePointSpreading(key, value)) {
                if (parseInt(value) > parseInt(this.model[key]) + this.pointSpreadingPointsLeft) {
                    value = parseInt(this.model[key]) + this.pointSpreadingPointsLeft;
                }
                this.model[key] = value;
                //Vue.set(this.model, key, value);
                this.$emit('changed', this.model);
            }
        },
        isCorrect(key) {
            if (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.randomizeAnswerOptionOrder', false)) {
                key = this.question.options[key.charCodeAt(0) - 65].originalKey;
            }
            if (!(this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback', false)))) {
                return null;
            }
            let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
            let attempts = _.clone(_.get(this.question, 'studentAnswer.attempts', []));
            if (attempts.length == 0) {
                return false;
            }

            if (isCorrect) {
                if (key == attempts[attempts.length - 1]) {
                    return true;
                }
            } else {
                return false;
            }
        },
        isWrong(key) {
            if (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.randomizeAnswerOptionOrder', false)) {
                key = this.question.options[key.charCodeAt(0) - 65].originalKey;
            }
            if (!(this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback', false)))) {
                return null;
            }
            let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
            let attempts = _.clone(_.get(this.question, 'studentAnswer.attempts', []));
            if (attempts.length == 0) {
                return false;
            }
            if (isCorrect) {
                attempts.pop();
                if (attempts.includes(key)) {
                    return true;
                }
            } else {
                if (attempts.includes(key)) {
                    return true;
                }
            }
            return false;
        },
        init() {
            this.model = this.value;
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.testObj.settings.allowPdfDownload,
            });
        },
    },
};
</script>

<!-- <style scoped lang="scss">
.radioField {
  margin-top: 25px;

  &.highlight {
    border: 1px solid blue;
  }

  &:first-child {
    margin-top: 0;
  }

  input[type="radio"] {
    border: solid 2px #222;

    &:hover {
      border-color: #0071be;
    }

    &:checked {
      border-color: #3e7c5b;
      box-shadow: inset 0 0 0 3px #3e7c5b;

      &:before {
        content: inherit;
      }

      &:disabled {
        box-shadow: inset 0 0 0 3px #d8d8d8;
      }
    }

    &:disabled {
      border-color: #d8d8d8;
    }
  }

  label {
    display: inline-flex;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    flex: 1;
    margin-left: 5px;

    span:first-child {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}

.checkboxField {
  margin-top: 25px;

  &:first-child {
    margin-top: 0;
  }

  input[type="checkbox"] {
    border-radius: 20%;

    &:hover {
      border-color: #0071be;
    }

    &:checked {
      border: 2px solid #3e7c5b;
      background: #3e7c5b;
      box-shadow: inset 0 0 0 2px #3e7c5b;

      &:before {
        content: "\f00c";
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        letter-spacing: inherit;
        background: inherit;
        width: auto;
        color: #fff;
      }

      &:disabled {
        background: #d8d8d8;
        border: 2px solid #d8d8d8;
        box-shadow: inset 0 0 0 2px #d8d8d8;
      }
    }

    &:disabled {
      border: 2px solid #d8d8d8;
    }
  }

  label {
    display: inline-flex;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    flex: 1;
    margin-left: 5px;

    span:first-child {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}

.numberInput {
  position: relative;
  width: 60px;
}

.quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  color: #222;
  z-index: 4;
}

.quantity-nav .disabled {
  color: #dfe3e9;
  cursor: not-allowed;
}

.quantity-button {
  height: 18px;
  display: flex;
  align-items: center;
  width: 22px;
  justify-content: center;
  background-color: white !important;
  border: 1px solid #dfe3e9 !important;
}

.quantity-button:last-child {
  border: 1px solid #dfe3e9 !important;
}

i.correct {
  color: #3e7c5b;
  font-size: 18px;
}

i.wrong {
  color: #ed1c24;
  font-size: 18px;
}

.boxSize15 {
  width: 15px;
  height: 15px;
}
</style> -->
