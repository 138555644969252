<template>
    <div class="col-xs-12 right-container">
        <kr-panel v-if="component_done_loading" :with-footer="false">
            <template #title>
                <div class="col-xs-6">
                    {{ teacher.displayName }}
                </div>
                <div
                    v-if="hasWritePrivilege(course) && (getPrivilege(teacher.uuid, course) != 'Owner' || auth.user().uuid == teacher.uuid)"
                    class="col-xs-6 buttonGroupInline flexRight"
                >
                    <div class="hidden-xs hidden-sm">
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'courses.manage.teachers.edit',
                                params: {
                                    id: $route.params.id,
                                    course_id: $route.params.course_id,
                                },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit</button>
                        </router-link>
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'courses.view',
                                params: { id: $route.params.course_id },
                                query: { tab: 'permission' },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                        </router-link>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <router-link
                            v-slot="{ navigate }"
                            v-tooltip="'Edit'"
                            :to="{
                                name: 'courses.manage.teachers.edit',
                                params: {
                                    id: $route.params.id,
                                    course_id: $route.params.course_id,
                                },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate">
                                <i class="fa fa-pencil-square-o" aria-hidden="true" />
                            </button>
                        </router-link>
                        <router-link
                            v-slot="{ navigate }"
                            v-tooltip="'Back'"
                            :to="{
                                name: 'courses.view',
                                params: { id: $route.params.course_id },
                                query: { tab: 'permission' },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate">
                                <i class="fa fa-caret-left" aria-hidden="true" />
                            </button>
                        </router-link>
                    </div>
                </div>
                <div v-else class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'courses.view',
                                params: { id: $route.params.course_id },
                                query: { tab: 'permission' },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                        </router-link>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <router-link
                            v-slot="{ navigate }"
                            v-tooltip="'Back'"
                            :to="{
                                name: 'courses.view',
                                params: { id: $route.params.course_id },
                                query: { tab: 'permission' },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate">
                                <i class="fa fa-caret-left" aria-hidden="true" />
                            </button>
                        </router-link>
                    </div>
                </div>
            </template>
            <template #content>
                <div class="col-xs-12 col-md-6">
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">FULL NAME</label>
                                <p class="form-control-static">
                                    {{ teacher.displayName }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">EMAIL</label>
                                <p class="form-control-static">
                                    {{ teacher.email }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">STATUS</label>
                                <p class="form-control-static align-items">
                                    <template v-if="teacher.dateActivated">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                    </template>
                                    <template v-else-if="teacher.isSuspended">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended
                                    </template>
                                    <template v-else> Invite Sent </template>
                                </p>
                            </div>
                        </div>
                        <div v-if="teacher.isSuspended" class="col-xs-12 col-md-6">
                            <div class="col-xs-12 col-md-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">SUSPENDED ON</label>
                                    <p class="form-control-static">
                                        {{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">JOB TITLE</label>
                                <p class="form-control-static">
                                    {{ teacher.jobTitle || 'N/A' }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">ACADEMIC DISCIPLINE</label>
                                <p class="form-control-static">
                                    {{ teacher.academicDiscipline || 'N/A' }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">CREATED BY</label>
                                <p class="form-control-static">
                                    {{ teacher.createBy }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">CREATED ON</label>
                                <p class="form-control-static">
                                    <template v-if="teacher.dateCreated">
                                        {{ convertToReadableDate(teacher.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(teacher.dateCreated, 'd MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">ACTIVATED ON</label>
                                <p class="form-control-static">
                                    <template v-if="teacher.dateActivated">
                                        {{ convertToReadableDate(teacher.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(teacher.dateActivated, 'd MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">ACCOUNT LAST LOGIN</label>
                                <p class="form-control-static">
                                    <template v-if="teacher.dateLastLogin">
                                        {{ convertToReadableDate(teacher.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(teacher.dateLastLogin, 'd MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label text-uppercase">ACCOUNT LAST MODIFIED</label>
                                <p class="form-control-static">
                                    <template v-if="teacher.lastModified">
                                        {{ convertToReadableDate(teacher.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(teacher.lastModified, 'd MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <div
            v-if="
                component_done_loading &&
                (auth.user().role == 'Super Admin' ||
                    (getPrivilege(teacher.uuid, course) != 'Owner' &&
                        getPrivilege(auth.user().uuid, course) == 'Owner' &&
                        $route.params.id != auth.user().uuid))
            "
            class="viewDropdown flexRight"
        >
            <div class="dropdown col-xs-2">
                <button
                    id="viewDropdownMenuButton"
                    class="mainBtn width100 flexSpaceBetween align-items"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    More Actions
                    <i class="fa fa-caret-down marginRight8" aria-hidden="true" />
                </button>

                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                    <ul>
                        <template v-if="!teacherIsTrashed()">
                            <li v-if="$route.params.id != auth.user().uuid">
                                <a class="danger-state" href="#" @click.prevent="openSuspendModal()">
                                    <span> <i class="fa fa-trash marginRight8" aria-hidden="true" />Remove Teacher From Course </span>
                                </a>
                            </li>

                            <hr v-if="$route.params.id != auth.user().uuid" />

                            <li>
                                <a class="primary-state" href="#" @click.prevent="openTransferModal()">
                                    <span> <i class="fa fa-exchange marginRight8" aria-hidden="true" />Transfer Course Ownership </span>
                                </a>
                            </li>
                        </template>

                        <li v-else>
                            <a class="primary-state" href="#" @click.prevent="openSuspendModal()">
                                <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate Teacher </span>
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- <div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
          <button
            class="btn btn-danger"
            v-if="!teacherIsTrashed()"
            @click.prevent="openSuspendModal()"
            aria-label="Remove Teacher From Course"
          >
            <i class="fa fa-trash"></i>&nbsp;Remove Teacher From Course
          </button>
          <button
            class="btn btn-default"
            v-if="!teacherIsTrashed()"
            @click.prevent="openTransferModal()"
          >
            Transfer Course Ownership
          </button>
          <button
            class="btn btn-success"
            v-else
            @click.prevent="openSuspendModal()"
            aria-label="Reinstate Teacher"
          >
            <i class="fa fa-refresh"></i>&nbsp;Reinstate Teacher
          </button>
        </div> -->
            </div>
        </div>
        <div
            v-if="component_done_loading"
            id="suspendModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 idy="suspendModal-title" class="modal-title">
                            <template v-if="!teacherIsTrashed()"> Delete </template>
                            <template v-else> Reinstate </template>
                            {{ teacher.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!teacherIsTrashed()" class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to remove
                                    {{ teacher.displayName }} from your course
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to reinstate
                                    {{ teacher.displayName }}
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="!teacherIsTrashed()" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Remove From Course" @click.prevent="trash()">
                                        <i class="fa fa-trash marginRight8" aria-hidden="true" />Remove From Course
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Reinstate" @click.prevent="reinstate()">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                        <div class="col-xs-12" style="text-align:center">
                           <template v-if="!superuser.isSuspended">
                           		Suspending..
                           </template>
                           <template v-else>
                           		Reinstating
                           </template>
                        </div>
                    </div>-->
                    </div>
                </div>
                <div class="modal-footer" />
            </div>
        </div>
        <div id="transferModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="transferModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="transferModal-title" class="modal-title">Transfer Ownership</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="textAlignCenter">
                                <p>Are you sure you want to transfer to</p>
                                <p>
                                    <b>
                                        {{ teacher.displayName }}
                                        <span v-if="$route.params.id == auth.user().uuid"> (Me) </span>
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div class="marginTop30">
                            <div class="flex">
                                <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Confirm New Owner" @click="transfer">
                                    Confirm New Owner
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" />
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            teacher: {},
            course: {},
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
        });
        this.fetch();
    },
    methods: {
        transfer() {
            $('#transferModal').modal('hide');
            var success = function () {
                var that = this;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have successfully transfered ownership of this course',
                });
                this.$router.push({
                    name: 'courses.view',
                    params: { id: this.$route.params.course_id },
                    query: { tab: 'permission' },
                });
            }.bind(this);
            var del = function (message = false) {
                if (!message) {
                    message = 'Something went wrong, please try again later';
                }
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: message,
                });
            }.bind(this);
            axios.post('/courses/' + this.course.uuid + '/' + 'users/' + 'transfer-ownership', { userUuid: this.teacher.uuid }).then(function (response) {
                if (!response.krFormError) {
                    success();
                } else {
                    if (response.data.message) {
                        del(response.data.message);
                    } else {
                        del();
                    }
                }
            });
        },
        openTransferModal() {
            $('#transferModal').modal('show');
        },
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        trash() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed teacher from course',
                });
                /*let course_idx = _.findIndex(this.teacher.courses,function(o){
						return o.uuid ==that.$route.params.uuid;
					})*/

                //this.teacher.courses[course_idx].isTrashed = true;

                this.$router.push({
                    name: 'courses.view',
                    params: { id: this.$route.params.course_id },
                    query: { tab: 'permission' },
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/courses/' + this.course.uuid + '/users/remove', {
                    userUuid: this.teacher.uuid,
                })
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        teacherIsTrashed() {
            var that = this;
            let course = _.find(this.teacher.courses, function (o) {
                return o.uuid == that.$route.params.course_id;
            });
            if (course) {
                return course.isTrashed;
            }
        },
        fetch() {
            let that = this;
            axios.all([axios.get('/users/' + that.$route.params.id), axios.get('/courses/' + this.$route.params.course_id)]).then(
                axios.spread((teacher, course) => {
                    that.teacher = teacher.data.data;
                    let inCourse = _.find(that.teacher.courses, function (o) {
                        return o.uuid == that.$route.params.course_id;
                    });
                    if (!inCourse) {
                        //teacher is not part of course anymore
                        that.$router.push({
                            name: 'courses.view',
                            params: { id: that.$route.params.course_id },
                            query: { tab: 'permission' },
                        });
                    }
                    that.course = course.data.data;
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Courses', link: 'courses.index' },
                            {
                                name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                            },
                            {
                                name: 'Permissions',
                                link: 'courses.view',
                                params: { id: that.course.uuid },
                                query: { tab: 'permission' },
                            },
                            { name: that.teacher.displayName },
                        ],
                    });
                    that.component_done_loading = true;
                })
            );
        },
    },
};
</script>
<style scoped>
/* .drop-down {
  width: 250px;
  height: 36px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
  border: 1px solid #d8d8d8;
}
.dropdown .dropdown-menu {
  top: 120%;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 250px;
}

#viewDropdownMenuButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewDropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 10px;
} */
</style>
