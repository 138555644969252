<template>
    <div class="panel-body-content">
        <div class="question">
            <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>
            <!-- list with no answer = array in question.amswers and check if answer.uuid==null-->
            <template v-if="question.attachments && question.attachments.length != 0">
                <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>Question Attachment(s)</div>
                <div class="textLink">
                    <div v-for="(file, idx) in question.attachments" :key="idx" class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                    </div>
                </div>
            </template>
        </div>

        <div class="hidden-xs">
            <div v-if="question.displayKeywords" class="marginTop20 marginBottom20">
                <div class="mini-panel panel">
                    <div v-if="false" class="panel-heading flexSpaceBetween">
                        <div>
                            <span>AVG. MM:SS</span>
                            <span class="marginLeft5 fontNormal">{{ question.statistics.averageTime }}</span>
                        </div>
                    </div>
                    <div class="panel-body height100 flex">
                        <div class="width274px">
                            <div class="fontSize14 fontBold">Keyword(s):</div>
                            <template v-for="(tag, idx) in question.tags" :key="idx">
                                <div class="marginTop10">
                                    <span class="highlight">
                                        {{ tag }}
                                    </span>
                                </div>
                            </template>
                            <template v-if="question.tags && question.tags.length == 0"> - </template>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="showRealtimeToggle">
                <template v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)">
                    <template v-if="question.displayAnswer && question.answers.length != 0">
                        <div class="flexRight width100">
                            <div class="checkboxField align-items">
                                <label class="marginRight10" style="margin-left: 0; line-height: 0.6"> Select all for e-Gallery Walk </label>
                                <input type="checkbox" :checked="isAllVotingToggled" @click="toggleAllVoting($event)" />
                            </div>
                        </div>
                    </template>
                </template>
            </template>
            <div
                class="cr-display-columns"
                :class="{
                    row: oneColumn || twoColumns || threeColumns || fourColumns,
                }"
            >
                <template v-if="showRealtimeToggle">
                    <template v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)">
                        <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                            <template v-if="question.displayAnswer">
                                <div
                                    class="new-discussion"
                                    :class="{
                                        padding0: !question.displayAnswer,
                                        'border-black': answer.answer != null,
                                        'add-blueBg': question.answers[answer_idx].isSelected == true,
                                        oneColumn: oneColumn,
                                        twoColumns: twoColumns,
                                        threeColumns: threeColumns,
                                        fourColumns: fourColumns,
                                    }"
                                >
                                    <template v-if="!question.displayAnswer">
                                        <div class="minusWidth179px new-discussion-dotted flex align-items padding10">
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                v-tooltip="{
                                                                    content: answer.team.name,
                                                                }"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.team.name"
                                                            >
                                                                <template v-if="answer.team.name.length > 10">
                                                                    {{ truncate(answer.team.name, 10) }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ answer.team.name }}
                                                                </template>
                                                                &nbsp;
                                                                <i
                                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                                    class="far fa-comment-dots"
                                                                ></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer)"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer)"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                                <div class="marginTop20 previewModelTeam">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                                    </div>
                                                                    <div class="maxHeight100px">
                                                                        <ul class="marginBottom0">
                                                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                                {{ student.displayName }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template v-if="answer.team.members.length > 4">
                                                                        <div
                                                                            class="blue pointer marginTop10"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.team.name">
                                                            <template v-if="answer.team.name.length > 10">
                                                                {{ truncate(answer.team.name, 10) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ answer.team.name }}
                                                            </template>
                                                        </button>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.student.fullname"
                                                            >
                                                                {{ answer.student.fullname }}&nbsp;<i class="far fa-comment-dots"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer, 'student')"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer, 'student')"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.student.fullname">
                                                            {{ answer.student.fullname }}
                                                        </button>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-if="answer.uuid">
                                                <label class="thumb-up-toggle">
                                                    <input
                                                        v-model="question.answers[answer_idx].isGoodAnswer"
                                                        type="checkbox"
                                                        :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                        aria-label="Toggle Good Answer"
                                                        @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="question.displayAnswer">
                                        <div>
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                v-tooltip="{
                                                                    content: answer.team.name,
                                                                }"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.team.name"
                                                            >
                                                                <template v-if="answer.team.name.length > 10">
                                                                    {{ truncate(answer.team.name, 10) }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ answer.team.name }}
                                                                </template>
                                                                &nbsp;
                                                                <i
                                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                                    class="far fa-comment-dots"
                                                                ></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer)"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer)"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                                <div class="marginTop20 previewModelTeam">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                                    </div>
                                                                    <div class="maxHeight100px">
                                                                        <ul class="marginBottom0">
                                                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                                {{ student.displayName }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template v-if="answer.team.members.length > 4">
                                                                        <div
                                                                            class="blue pointer marginTop10"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.team.name">
                                                            <template v-if="answer.team.name.length > 10">
                                                                {{ truncate(answer.team.name, 10) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ answer.team.name }}
                                                            </template>
                                                        </button>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.student.fullname"
                                                            >
                                                                {{ answer.student.fullname }}&nbsp;<i class="far fa-comment-dots"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Elaboration</span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer, 'student')"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer, 'student')"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.student.fullname">
                                                            {{ answer.student.fullname }}
                                                        </button>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-if="answer.uuid" class="textAlignCenter flex align-items marginLeft20">
                                                <div class="checkboxField align-items">
                                                    <label v-if="oneColumn || twoColumn" class="marginRight10" style="margin-left: 0; line-height: 0.6">
                                                        Select for e-Gallery Walk
                                                    </label>
                                                    <input
                                                        v-model="question.answers[answer_idx].isSelected"
                                                        v-tooltip="{
                                                            content: generateToolTip(question.answers[answer_idx].isSelected),
                                                        }"
                                                        type="checkbox"
                                                        :disabled="
                                                            hasWritePrivilege(testObj.course) == -1 ||
                                                            question.answers[answer_idx].isGoodAnswer == 1 ||
                                                            question.answers[answer_idx].isGoodAnswer == true
                                                                ? true
                                                                : undefined
                                                        "
                                                        aria-label="Toggle Select Answer"
                                                    />
                                                </div>

                                                <label class="thumb-up-toggle marginLeft10">
                                                    <input
                                                        v-model="question.answers[answer_idx].isGoodAnswer"
                                                        v-tooltip="{
                                                            content: answer.isGoodAnswer ? 'Remove from good answers' : 'Select as good answer',
                                                        }"
                                                        type="checkbox"
                                                        :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                        aria-label="Toggle Good Answer"
                                                        @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="question.displayAnswer" class="new-discussion-ans width100 new-discussion-ans-content">
                                            <div class="new-discussion-answer">
                                                <template v-if="answer.answer != null">
                                                    <div :id="answer.uuid + '-answer-wrapper'" class="answerWrapperDiv">
                                                        <div
                                                            :id="answer.uuid + '-answer-content'"
                                                            class="wordBreakWord whiteSpacePreWrap"
                                                            v-html="highlightAnswerKeywords(answer.answer)"
                                                        ></div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            class="marginTop10 blue padding0"
                                                            @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                                        >
                                                            View Answer
                                                        </button>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="fontItalic greyText">
                                                        <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                                        <template v-else> Student </template>
                                                        Has Not Answered Yet.
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </template>
                    <template
                        v-if="
                            ['paused', 'ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                            ['ongoing', 'completed', 'ended', 'paused'].includes(testObj.status)
                        "
                    >
                        <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                            <template v-if="answer.isSelected">
                                <div
                                    v-if="answer.answer != null"
                                    class="flex new-discussion fontBlack"
                                    :class="{
                                        padding0: !question.displayAnswer,
                                        oneColumn: oneColumn,
                                        twoColumns: twoColumns,
                                        threeColumns: threeColumns,
                                        fourColumns: fourColumns,
                                    }"
                                >
                                    <template>
                                        <div>
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template>
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                v-tooltip="{
                                                                    content: answer.team.name,
                                                                }"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.team.name"
                                                            >
                                                                <template v-if="answer.team.name.length > 10">
                                                                    {{ truncate(answer.team.name, 10) }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ answer.team.name }}
                                                                </template>
                                                                &nbsp;<i
                                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                                    class="far fa-comment-dots"
                                                                ></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer)"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer)"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                                <div class="marginTop20 previewModelTeam">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                                    </div>
                                                                    <div class="maxHeight100px">
                                                                        <ul class="marginBottom0">
                                                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                                {{ student.displayName }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template v-if="answer.team.members.length > 4">
                                                                        <div
                                                                            class="blue pointer marginTop10"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                    <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="answer.student.fullname"
                                                            >
                                                                {{ answer.student.fullname }}&nbsp;<i class="far fa-comment-dots"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div>
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                                    </div>
                                                                    <div v-if="answer.comment">
                                                                        <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                        <template v-if="answer.comment.length > 400">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer, 'student')"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>-</div>
                                                                </div>
                                                                <div class="marginTop20">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                    </div>
                                                                    <div>
                                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                            <div
                                                                                v-for="(file, idx) in answer.attachments"
                                                                                :key="idx"
                                                                                class="flex maxHeight100px"
                                                                            >
                                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                                <a
                                                                                    :href="file.url"
                                                                                    target="_blank"
                                                                                    class="textLink"
                                                                                    download
                                                                                    :aria-label="file.filename"
                                                                                    >{{ file.filename }}</a
                                                                                >
                                                                            </div>
                                                                            <template v-if="answer.attachments.length > 4">
                                                                                <div
                                                                                    class="marginTop10 blue pointer"
                                                                                    aria-label="View More"
                                                                                    @click="openModelInfoModal(answer, 'student')"
                                                                                >
                                                                                    View More
                                                                                </div>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else> - </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.student.fullname">
                                                            {{ answer.student.fullname }}
                                                        </button>
                                                    </template>
                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-if="question.displayVotes" class="top-right-votes">
                                                {{ answer.votes.length }}
                                                <template v-if="answer.votes.length < 2"> Vote </template>
                                                <template v-else> Votes </template>
                                            </div>
                                        </div>
                                        <div
                                            class="new-discussion-ans width100 new-discussion-ans-content"
                                            :class="{
                                                height100px: answer.answer == null,
                                            }"
                                        >
                                            <div v-if="question.displayAnswer" class="new-discussion-answer">
                                                <template v-if="answer.answer != null">
                                                    <div :id="answer.uuid + '-answer-wrapper'" class="answerWrapperDiv">
                                                        <div
                                                            :id="answer.uuid + '-answer-content'"
                                                            class="wordBreakWord whiteSpacePreWrap"
                                                            v-html="highlightAnswerKeywords(answer.answer)"
                                                        ></div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            class="marginTop10 blue pointer"
                                                            @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                                        >
                                                            View Answer
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="fontItalic greyText">
                                                        <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                                        <template v-else> Student </template>
                                                        Has Not Answered Yet.
                                                    </div>
                                                </template>
                                            </div>

                                            <div v-if="question.displayComments" class="borderNone paddingTop0">
                                                <div
                                                    class="marginTop10 pointer flexRight"
                                                    style="color: #717171"
                                                    :aria-label="`${answer.comments.length} ${answer.comments.length < 2 ? 'Comment' : 'Comments'}`"
                                                    @click="openCommentsModal(answer)"
                                                >
                                                    {{ answer.comments.length }}
                                                    <template v-if="answer.comments.length < 2"> Comment </template>
                                                    <template v-else> Comments </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="question.displayVotes" class="flexOnly width100 align-items votes-percentages">
                                            <div>{{ percentVoted(answer_idx) }}%</div>
                                            <div class="discussion-bar">
                                                <div class="barProgress" :style="'width:' + percentVoted(answer_idx) + '%'"></div>
                                            </div>
                                        </div>
                                        <div v-if="answer.votes.length != 0" class="marginTop10 flexOnly width100 paddingLeft10">
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div class="responseFont">
                                                    {{ answer.votes.length }}
                                                    <template v-if="answer.votes.length <= 1"> Vote </template>
                                                    <template v-else> Votes </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template v-for="(model, model_idx) in answer.votes" :key="model_idx">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default marginBottom10"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="model.name"
                                                            >
                                                                {{ truncate(model.name) }}
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                                    <div class="flexOnly marginBottom5 align-items">
                                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                                    </div>
                                                                    <div class="maxHeight100px">
                                                                        <ul class="marginBottom0">
                                                                            <li v-for="(student, idx) in model.members" :key="idx">
                                                                                {{ student.displayName }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template v-if="model.members.length > 4">
                                                                        <div
                                                                            class="blue pointer marginTop10"
                                                                            aria-label="View More"
                                                                            @click="openTeamInfoModal(model)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="responseFont">
                                                    {{ answer.votes.length }}
                                                    <template v-if="answer.votes.length <= 1"> Vote </template>
                                                    <template v-else> Votes </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template v-for="(model, model_idx) in answer.votes" :key="model_idx">
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default marginBottom10"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="model.fullname"
                                                            >
                                                                {{ truncate(model.fullname) }}
                                                            </button>
                                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                                {{ model.fullname }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                        <div
                            class="new-discussion"
                            :class="{
                                'border-black': answer.answer != null,
                                oneColumn: oneColumn,
                                twoColumns: twoColumns,
                                threeColumns: threeColumns,
                                fourColumns: fourColumns,
                            }"
                        >
                            <div>
                                <template v-if="testObj.others.applicationType == 'team'">
                                    <div
                                        class="goodAnswerDiv"
                                        :class="{
                                            inlineFlex: answer.isGoodAnswer,
                                        }"
                                    >
                                        <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                            <div class="dropdown">
                                                <button
                                                    id="popOver"
                                                    v-tooltip="{
                                                        content: answer.team.name,
                                                    }"
                                                    class="btn gradient btn-default"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="answer.team.name"
                                                >
                                                    <template v-if="answer.team.name.length > 10">
                                                        {{ truncate(answer.team.name, 10) }}
                                                    </template>
                                                    <template v-else>
                                                        {{ answer.team.name }}
                                                    </template>
                                                    &nbsp;<i
                                                        v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                        class="far fa-comment-dots"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight5 greyText"></i>
                                                            <span class="lineHeight08 fontBold"> Elaboration </span>
                                                        </div>
                                                        <div v-if="answer.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                            <template v-if="answer.comment.length > 400">
                                                                <div
                                                                    class="marginTop10 blue pointer"
                                                                    aria-label="View More"
                                                                    @click="openModelInfoModal(answer)"
                                                                >
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                        </div>
                                                        <div>
                                                            <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                                <template v-if="answer.attachments.length > 4">
                                                                    <div
                                                                        class="marginTop10 blue pointer"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(answer)"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                    <div class="marginTop20 previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                                            <span class="lineHeight08 fontBold">Team Members</span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul class="marginBottom0">
                                                                <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="answer.team.members.length > 4">
                                                            <div class="blue pointer marginTop10" aria-label="View More" @click="openModelInfoModal(answer)">
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <button class="btn gradient btn-default cursor-norm" :aria-label="answer.team.name">
                                                <template v-if="answer.team.name.length > 10">
                                                    {{ truncate(answer.team.name, 10) }}
                                                </template>
                                                <template v-else>
                                                    {{ answer.team.name }}
                                                </template>
                                            </button>
                                        </template>
                                        <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                            Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        class="goodAnswerDiv"
                                        :class="{
                                            inlineFlex: answer.isGoodAnswer,
                                        }"
                                    >
                                        <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                            <div class="dropdown">
                                                <button
                                                    id="popOver"
                                                    class="btn gradient btn-default"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="answer.student.fullname"
                                                >
                                                    {{ answer.student.fullname }}&nbsp;<i class="far fa-comment-dots"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight5 greyText"></i>
                                                            <span class="lineHeight08 fontBold"> Elaboration </span>
                                                        </div>
                                                        <div v-if="answer.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                            <template v-if="answer.comment.length > 400">
                                                                <div
                                                                    class="marginTop10 blue pointer"
                                                                    aria-label="View More"
                                                                    @click="openModelInfoModal(answer, 'student')"
                                                                >
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                        </div>
                                                        <div>
                                                            <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                                <template v-if="answer.attachments.length > 4">
                                                                    <div
                                                                        class="marginTop10 blue pointer"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(answer, 'student')"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <button class="btn gradient btn-default cursor-norm" :aria-label="answer.student.fullname">
                                                {{ answer.student.fullname }}
                                            </button>
                                        </template>
                                        <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess goodAnswers whiteSpaceNowrap">
                                            Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="answer.uuid" class="textAlignCenter flex align-items marginLeft20">
                                    <label class="thumb-up-toggle marginLeft10">
                                        <input
                                            v-model="question.answers[answer_idx].isGoodAnswer"
                                            v-tooltip="{
                                                content: answer.isGoodAnswer ? 'Remove from good answers' : 'Select as good answer',
                                            }"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Toggle Good Answer"
                                            @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div
                                class="new-discussion-ans width100 flexOnly new-discussion-ans-content"
                                :class="{
                                    gridDiscussion: answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded,
                                }"
                            >
                                <div class="minusWidth179px new-discussion-answer">
                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap">
                                            <span v-html="truncate(highlightAnswerKeywords(answer.answer), 400)"></span>
                                            <template v-if="highlightAnswerKeywords(answer.answer).length > 400">
                                                <div
                                                    class="marginTop10 blue pointer"
                                                    aria-label="View More"
                                                    @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                                >
                                                    View More
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="fontItalic greyText">
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </div>
                                    </template>
                                </div>
                                <div v-if="answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded">
                                    <div>
                                        <div class="fontBold marginBottom10">
                                            Grade
                                            <i v-if="answer._gradeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                                        </div>
                                        <div class="input-group width130px">
                                            <div class="numberInput">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Enter Points"
                                                    min="0"
                                                    :max="question.score"
                                                    step=".25"
                                                    :value="answer.applicationGrade"
                                                    aria-label="Grade"
                                                    @keyup="changePoints($event, answer_idx)"
                                                    @keydown="answer._gradeSaved = false"
                                                    @blur="saveGrade(answer_idx)"
                                                />
                                            </div>
                                            <div class="input-group-addon">/{{ question.score }}</div>
                                        </div>
                                    </div>
                                    <div class="marginTop20">
                                        <div class="fontBold marginBottom10">
                                            Feedback
                                            <i v-if="answer._narrativeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                                        </div>
                                        <div class="form-group">
                                            <textarea
                                                ref="textarea"
                                                class="form-control"
                                                placeholder="Enter Feedback here..."
                                                :value="answer.narrativeFeedback"
                                                aria-label="Feedback"
                                                rows="5"
                                                @keyup="changeNarrativeFeedback($event, answer_idx)"
                                                @keydown="answer._narrativeSaved = false"
                                                @blur="saveNarrativeFeedback(answer_idx)"
                                                @input="textareaResize"
                                            >
                                            </textarea>
                                            <span class="limitText"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template> </template
                >-
            </div>
        </div>
        <div class="hidden-sm hidden-md hidden-lg" style="color: black">
            <template v-if="showRealtimeToggle">
                <template v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)">
                    <template v-if="question.displayAnswer && question.answers.length != 0">
                        <div class="flexRight width100">
                            <div class="checkboxField align-items">
                                <label class="marginRight10" style="margin-left: 0; line-height: 0.6"> Select all for e-Gallery Walk </label>
                                <input type="checkbox" :checked="isAllVotingToggled" @click="toggleAllVoting($event)" />
                            </div>
                        </div>
                    </template>
                </template>
            </template>

            <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                <div class="new-discussion" :class="{ 'border-black': answer.answer != null }">
                    <div class="flexSpaceBetween align-items padding10">
                        <div class="flex">
                            <template v-if="testObj.others.applicationType == 'team'">
                                <div>
                                    <template v-if="answer.attachments && answer.attachments.length != 0">
                                        <button class="btn gradient btn-default" :aria-label="answer.team.name" @click="openModelInfoModal(answer)">
                                            {{ answer.team.name }}<i class="far fa-comment-dots marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button class="btn gradient btn-default" :aria-label="answer.team.name">
                                            {{ answer.team.name }}
                                        </button>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <template v-if="answer.attachments && answer.attachments.length != 0">
                                        <button
                                            class="btn gradient btn-default"
                                            :aria-label="answer.student.fullname"
                                            @click="openModelInfoModal(answer, 'student')"
                                        >
                                            {{ answer.student.fullname }}&nbsp;<i class="far fa-comment-dots"></i>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button class="btn gradient btn-default cursor-norm" :aria-label="answer.student.fullname">
                                            {{ answer.student.fullname }}
                                        </button>
                                    </template>
                                </div>
                            </template>
                            <template v-if="answer.isGoodAnswer">
                                <button class="statusTag statusTagSuccess goodAnswers whiteSpaceNowrap marginLeft10" aria-label="Godd Answer">
                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                </button>
                            </template>
                        </div>
                        <div v-if="answer.uuid" class="textAlignCenter flex align-items marginLeft20">
                            <div class="checkboxField align-items">
                                <input
                                    v-model="question.answers[answer_idx].isSelected"
                                    v-tooltip="{
                                        content: generateToolTip(question.answers[answer_idx].isSelected),
                                    }"
                                    type="checkbox"
                                    :disabled="
                                        hasWritePrivilege(testObj.course) == -1 ||
                                        question.answers[answer_idx].isGoodAnswer == 1 ||
                                        question.answers[answer_idx].isGoodAnswer == true
                                            ? true
                                            : undefined
                                    "
                                    aria-label="Toggle Select Answer"
                                />
                            </div>

                            <label class="thumb-up-toggle marginLeft10">
                                <input
                                    v-model="question.answers[answer_idx].isGoodAnswer"
                                    v-tooltip="{
                                        content: answer.isGoodAnswer ? 'Remove from good answers' : 'Select as good answer',
                                    }"
                                    type="checkbox"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Toggle Good Answer"
                                    @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                />
                            </label>
                        </div>
                    </div>

                    <div v-if="question.displayAnswer" class="new-discussion-ans width100">
                        <div>
                            <template v-if="answer.answer != null">
                                <div class="wordBreakWord whiteSpacePreWrap">
                                    <span v-html="truncate(highlightAnswerKeywords(answer.answer), 400)"></span>
                                    <template v-if="highlightAnswerKeywords(answer.answer).length > 400">
                                        <div
                                            class="marginTop10 blue pointer"
                                            aria-label="View More"
                                            @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                        >
                                            View More
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="fontItalic greyText">
                                    <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                    <template v-else> Student </template>
                                    Has Not Answered Yet.
                                </div>
                            </template>
                        </div>
                    </div>

                    <div v-if="answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded" class="padding10">
                        <div class="fontBold marginBottom10">
                            Grade
                            <i v-if="answer._gradeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                        </div>
                        <div class="input-group width130px">
                            <div class="numberInput">
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Enter Points"
                                    min="0"
                                    :max="question.score"
                                    step=".5"
                                    :value="answer.applicationGrade"
                                    aria-label="Grade"
                                    @keyup="changePoints($event, answer_idx)"
                                    @keydown="answer._gradeSaved = false"
                                    @blur="saveGrade(answer_idx)"
                                />
                            </div>
                            <div class="input-group-addon">/{{ question.score }}</div>
                        </div>
                    </div>
                    <div v-if="answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded" class="padding10">
                        <div class="fontBold marginBottom10">
                            Feedback
                            <i v-if="answer._narrativeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                        </div>
                        <div class="form-group">
                            <textarea
                                ref="textarea"
                                class="form-control"
                                placeholder="Enter Feedback here..."
                                :value="answer.narrativeFeedback"
                                aria-label="Feedback"
                                rows="5"
                                @keyup="changeNarrativeFeedback($event, answer_idx)"
                                @keydown="answer._narrativeSaved = false"
                                @blur="saveNarrativeFeedback(answer_idx)"
                                @input="textareaResize"
                            >
                            </textarea>
                            <span class="limitText"></span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div id="eGalleryTeamInfoModal" class="modal" style="z-index: 50001 !important" tabindex="-1" role="dialog" aria-labelledby="eGalleryTeamInfoModal">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 id="eGalleryTeamInfoModal" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <ul v-for="(student, idx) in previewTeam.members" :key="idx">
                                <li>{{ student }}</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryAttachmentsModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryAttachmentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryAttachmentsModal' + question.uuid + '-title'" class="modal-title">Attachments</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-6">
                            <div v-if="previewAttachments && previewAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in previewAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                        <div class="col-xs-12 buttonGroup marginTop20">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryTeamInfoModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryTeamInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryTeamInfoModal' + question.uuid + '-title'" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <div v-if="previewModel.type == 'team'" class="previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <template v-for="(student, idx) in previewTeam.members" :key="idx">
                                    <ul>
                                        <li>{{ student.displayName }}</li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryVotersInfoModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryVotersInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryVotersInfoModal' + question.uuid + '-title'" class="modal-title">Voted By</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <template v-if="testObj.others.applicationType == 'individual'">
                                <ul v-for="(student, idx) in previewVoters" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </template>
                            <template v-if="testObj.others.applicationType == 'team'">
                                <ul v-for="(team, idx) in previewVoters" :key="idx">
                                    <li>{{ team.name }}</li>
                                </ul>
                            </template>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryQnsModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryQnsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryQnsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Answer
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 wordBreakWord whiteSpacePreWrap imgMax100" v-html="questionModal"></div>
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryModelModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" :key="idx" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCommentModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryCommentModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Comment</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryCommentsModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>
                        <h2 :id="'eGalleryCommentsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Comments
                        </h2>
                    </div>
                    <div class="modal-body">
                        <template v-for="(comment, comment_idx) in previewComments">
                            <div v-if="comment.team" :key="'team_' + comment_idx" class="modal_comments">
                                <div class="marginBottom10">
                                    <!--openCommentInfoModal(comment)-->
                                    <button class="btn gradient btn-default">
                                        {{ comment.team.name }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <span class="whiteSpacePreWrap" v-html="filterProfanity(comment.comment)"></span>
                            </div>

                            <div v-if="comment.student" :key="'student_' + comment_idx" class="modal_comments">
                                <div class="marginBottom10">
                                    <!--@click="openCommentInfoModal(comment,'student')"-->
                                    <button class="btn gradient btn-default subBtn" :aria-label="'Student ' + comment.student.fullname">
                                        Student
                                        {{ comment.student.fullname }}&nbsp;<i class="fa fa-info-circle"></i>
                                    </button>
                                </div>
                                <span class="whiteSpacePreWrap" v-html="filterProfanity(comment.comment)"> </span>
                            </div>
                        </template>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../../../../../../../../../../components/auth/auth';
export default {
    props: ['testObj', 'question', 'showRealtimeToggle', 'oneColumn', 'twoColumns', 'threeColumns', 'fourColumns'],
    data() {
        let auth = new KrAuth();
        return {
            previewTeam: {},
            previewVoters: {},
            previewAttachments: [],
            questionModal: '',
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
            previewComments: [],
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            wordLength: 0,
        };
    },
    computed: {
        isAllVotingToggled() {
            for (var i = 0; i < this.question.answers.length; i++) {
                if (!this.question.answers[i].isSelected) {
                    return false;
                }
            }
            return true;
        },
        isAllGoodAnswerToggled() {
            for (var i = 0; i < this.question.answers.length; i++) {
                if (!this.question.answers[i].isGoodAnswer) {
                    return false;
                }
            }
            return true;
        },
        canShowGrades() {
            if (this.$route.name == 'activities.tests.questions') {
                return false;
            }
            return true;
        },
    },
    watch: {
        oneColumn: {
            handler: function () {
                var that = this;
                this.$nextTick(function () {
                    that.myEventHandler();
                });
            },
        },
        twoColumns: {
            handler: function () {
                var that = this;
                this.$nextTick(function () {
                    that.myEventHandler();
                });
            },
        },
        threeColumns: {
            handler: function () {
                var that = this;
                this.$nextTick(function () {
                    that.myEventHandler();
                });
            },
        },
        fourColumns: {
            handler: function () {
                var that = this;
                this.$nextTick(function () {
                    that.myEventHandler();
                });
            },
        },
    },
    created() {
        var that = this;

        Vue.nextTick(function () {
            $('#eGalleryTeamInfoModal .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryAttachmentsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryTeamInfoModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryVotersInfoModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryQnsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryModelModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryCommentModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryCommentsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
        });
    },
    mounted() {
        this.$forceUpdate();
        window.addEventListener('resize', this.myEventHandler);
        if (!this.$refs.textarea) {
            return;
        }
        for (var i = 0; i < this.$refs.textarea.length; i++) {
            if (this.$refs.textarea) {
                var text = this.$refs.textarea[i].value;
                this.wordLength = this.computeNoOfWords(text);

                $(this.$refs.textarea[i])
                    .siblings('.limitText')
                    .text(this.wordLength + ' Words  / Max 300 Words');

                if (this.wordLength == 300) {
                    $(this.$refs.textarea[i]).siblings('.limitText').addClass('fontRed');
                } else {
                    $(this.$refs.textarea[i]).siblings('.limitText').removeClass('fontRed');
                }

                this.$refs.textarea[i].style.minHeight = this.$refs.textarea[i].scrollHeight + 'px';
            }
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.myEventHandler);
    },
    methods: {
        myEventHandler(e) {
            this.$forceUpdate();
        },
        toggleIsGoodAnswer(answer) {
            axios
                .post(`tests/${this.testObj.uuid}/discussions/is-good-answer`, {
                    answerUuid: answer.uuid,
                })
                .then(function (response) {});

            if (answer.isGoodAnswer) {
                answer.isSelected = true;
                //Vue.set(answer, 'isSelected', true);
            }
        },
        saveGrade(answer_idx) {
            var that = this;
            axios
                .post(
                    'tests/' + this.testObj.uuid + '/discussions/save-grade',
                    {
                        answerUuid: this.question.answers[answer_idx].uuid,
                        grade: this.question.answers[answer_idx]._applicationGrade,
                    },
                    { _internal: { hasLoadingBar: false } }
                )
                .then(function (response) {
                    that.question.answers[answer_idx].applicationGrade = that.question.answers[answer_idx]._applicationGrade;
                    that.question.answers[answer_idx]._gradeSaved = true;
                })
                .catch(function (errors) {});
        },
        saveNarrativeFeedback(answer_idx) {
            var that = this;
            axios
                .post(
                    'tests/' + this.testObj.uuid + '/discussions/save-narrative-feedback',
                    {
                        answerUuid: this.question.answers[answer_idx].uuid,
                        narrativeFeedback: this.question.answers[answer_idx]._narrativeFeedback,
                    },
                    { _internal: { hasLoadingBar: false } }
                )
                .then(function (response) {
                    that.question.answers[answer_idx].narrativeFeedback = that.question.answers[answer_idx]._narrativeFeedback;
                    that.$set(that.question.answers[answer_idx], '_narrativeSaved', true);
                })
                .catch(function (errors) {});
        },
        changePoints(event, answer_idx) {
            let value = parseFloat(event.target.value);
            if (isNaN(value)) {
                return;
            }
            let rem = value % 1;
            if (rem != 0) {
                value = Math.floor(value);
                if (rem > 0.5) {
                    value += 0.75;
                } else if (rem > 0.25) {
                    value += 0.5;
                } else {
                    value += 0.25;
                }
            }
            if (value <= this.question.score && value >= 0) {
                this.question.answers[answer_idx]._applicationGrade = value;
            } else {
                $(event.target).val(this.question.answers[answer_idx].applicationGrade);
            }
        },
        computeNoOfWords(str) {
            return str ? str.split(/[^\s]+/).length - 1 : 0;
        },
        changeNarrativeFeedback(event, answer_idx) {
            let text = event.target.value;
            this.wordLength = this.computeNoOfWords(text);

            if (this.wordLength > 300) {
                $(event.target).val(this.question.answers[answer_idx].narrativeFeedback);
            } else {
                this.question.answers[answer_idx]._narrativeFeedback = text;
            }

            $(event.target)
                .siblings('.limitText')
                .text(this.wordLength + ' Words / Max 300 Words');

            if (this.wordLength == 300) {
                $(event.target).siblings('.limitText').addClass('fontRed');
            } else {
                $(event.target).siblings('.limitText').removeClass('fontRed');
            }
        },
        quantityUp() {
            if (parseInt(this.question.eGallery.rankingValue) == this.question.answers.length) {
                return false;
            }
            this.question.eGallery.rankingValue += 1;
        },
        quantityDown() {
            if (parseInt(this.question.eGallery.rankingValue) == 1) {
                return false;
            }
            this.question.eGallery.rankingValue -= 1;
        },
        toggleAllVoting(event) {
            if (this.isAllVotingToggled) {
                for (var i = 0; i < this.question.answers.length; i++) {
                    if (this.question.answers[i].isGoodAnswer) {
                        this.question.answers[i].isSelected = true;
                        //Vue.set(this.question.answers[i], 'isSelected', true);
                    } else {
                        this.question.answers[i].isSelected = false;
                        //Vue.set(this.question.answers[i], 'isSelected', false);
                    }
                }
            } else {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isSelected = true;
                    //Vue.set(this.question.answers[i], 'isSelected', true);
                }
            }
        },
        toggleAllGoodAnswer(event) {
            if (this.isAllGoodAnswerToggled) {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isGoodAnswer = false;
                    //Vue.set(this.question.answers[i], 'isGoodAnswer', false);
                }
            } else {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isGoodAnswer = true;
                    //Vue.set(this.question.answers[i], 'isGoodAnswer', true);
                }
            }
        },
        /*Whole comments*/
        openCommentsModal(answer) {
            var that = this;
            this.previewComments = answer.comments;
            if (this.previewComments.length == 0) {
                return;
            }
            $('#eGalleryCommentsModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#eGalleryTeamInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryTeamInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openAttachmentsModal(attachments) {
            var that = this;
            this.previewAttachments = attachments;
            $('#eGalleryAttachmentsModal' + this.question.uuid).modal('show');
            $('#eGalleryAttachmentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewAttachments = [];
            });
        },
        openVotersInfoModal(voters) {
            var that = this;
            this.previewVoters = voters;
            $('#eGalleryVotersInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryVotersInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewVoters = {};
            });
        },
        openModelInfoModal(answer, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = answer.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = answer.student;
            }
            this.previewModel.comment = answer.comment;
            this.previewModel.attachments = answer.attachments ? answer.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        openCommentInfoModal(comment, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = comment.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = comment.student;
            }
            this.previewModel.comment = comment.comment;
            $('#eGalleryCommentModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        highlightAnswerKeywords(answer = '') {
            answer = answer ? answer : '';
            var res = answer;
            var tags = this.question.tags ? this.question.tags : [];
            for (var i = 0; i < tags.length; i++) {
                res = res.replace(new RegExp(this.escapeRegExp(tags[i]), 'gi'), `<span class="highlight marginBottom10">${tags[i]}</span>`);
            }
            return this.filterProfanity(res);
            //return res;
        },
        openQuestionModal(str) {
            this.questionModal = str;
            $('#eGalleryQnsModal' + this.question.uuid).modal('show');
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        percentVoted(answer_idx) {
            let present = this.question.answers.length;
            if (present == 0) {
                return null;
            }
            return Math.round((this.question.answers[answer_idx].votes.length / present) * 100);
        },
        generateToolTip(isSelected) {
            if (this.threeColumns || this.fourColumns) {
                if (isSelected) {
                    return 'Remove from eGallery Walk';
                } else {
                    return 'Select for eGallery Walk';
                }
            }
        },
        textareaResize($event) {
            if (!this.$refs.textarea) {
                return;
            }

            $event.target.style.height = 'auto';
            $event.target.style.height = $event.target.scrollHeight + 'px';
        },
    },
};
</script>

<style lang="scss" scoped>
.answerWrapperDiv {
    height: 75%;
    overflow: hidden;

    & > div {
        height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        line-height: 1.25;
    }
}
.discussionOption div:first-child {
    font-weight: bold;
}

.discussionOption div:last-child {
    margin-left: 10px;
}

.discussionOption {
    margin-top: 20px;
}

.discussionOption:first-child {
    margin-top: 0;
}

.mini-panel .panel-heading {
    font-size: 14px;
}

.mini-panel .panel-heading .link {
    // color: #0071be;
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams div {
    margin-top: 15px;
}

.commentTeams div.marginTop0 {
    margin-top: 0;
}

.commentTeams div:first-child {
    margin-top: 0;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 20px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.mini-table .numberInput input {
    width: 100px;
}

.eGalleryVoteMethod:first-child {
    margin-left: 0;
}

.numberInput {
    position: relative;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav input.disabled {
    color: #dfe3e9;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    // border-left: 1px solid #dfe3e9;
    border-left: 1px solid #717171;
    background-color: white;
}
.quantity-button.disabled {
    background-color: #dfe3e9;
    opacity: 0.3;
}

.quantity-button:last-child {
    // border-top: 1px solid #dfe3e9;
    border-top: 1px solid #717171;
}

.staticDetails {
    font-size: 14px;
}

.panel-content {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.panel-content:last-child {
    border-bottom: 0;
}

.width230px {
    width: 230px;
}

@media (min-width: 992px) {
    .eGalleryVoteMethod {
        margin-left: 20px;
    }
}

@media (max-width: 991px) {
    .eGalleryVoteMethod:last-child {
        margin-top: 10px;
    }
}

.rankDiv {
    margin-left: 20px;
}

.rankDiv:first-child {
    margin-left: 0;
}

.width44 {
    width: 44%;
}

.width88px {
    width: 88px;
}

.width156px {
    width: 156px;
}

.width179px {
    width: 179px;
}

.width180px {
    width: 180px;
}

.width274px {
    width: 274px;
}

.minusWidth179px {
    width: calc(100% - 179px);
}

.new-discussion {
    margin-top: 20px;
    line-height: 1.2;
    background: #fff;
    border: 1px dotted #d8d8d8;
    border-radius: 5px;
}

.new-discussion-dotted {
    border: 1px dotted #d8d8d8;
}

.new-discussion-ans > div {
    border-top: 1px solid #d8d8d8;
    padding: 10px;
}

.new-discussion-ans > div.borderNone {
    border: none;
}

.border-black {
    border: 1px solid #222;
}

.discussion-buttons .btn {
    margin-left: 0;
    margin-right: 20px;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
    min-width: 55px;
    max-width: 100px;
}

.big-bold-text {
    font-size: 28px;
    font-weight: bold;
}

.comment-comment div:not(:first-child) {
    margin-top: 10px;
}

.cr-display-columns.row {
    margin-left: 0;
    margin-right: 0;
}

.add-blueBg {
    background: #c1cff2;

    .thumb-up-toggle input[type='checkbox']:before {
        color: #fff;
    }
}

.thumb-up-toggle input[type='checkbox']:checked:before {
    content: '\F164';
    color: #14b14f;
}

.comment-section {
    background: #f4f1e4;
    border-bottom: 1px solid #d8d8d8;
}

.votes-percentages {
    padding: 10px;
}

.votes-percentages div:first-child {
    width: 50px;
    font-weight: bold;
    font-size: 18px;
    margin-right: 20px;
    text-align: center;
}

.votes-percentages div:last-child {
    width: calc(100% - 50px);
}

.panel-body-content {
    border: none;
}

.night-mode .panel-body-content > .question {
    color: #fff;
}

.fontBlack {
    color: #222;
}

.night-mode .mini-panel .panel-body {
    color: #222;
}

.modal_comments {
    margin-bottom: 20px;

    &:last-child,
    &:only-child {
        margin-bottom: 0;
    }
}

.gridDiscussion {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
}

.cr-display-columns {
    max-width: 1500px;
}

.limitText {
    font-size: 14px;
    margin-top: 10px;
    float: right;
    color: #717171;

    &.fontRed {
        color: #ca3333;
    }
}
</style>
