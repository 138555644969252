<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">Student Analysis</div>
            </template>
            <template #content>
                <div v-if="component_done_loading">
                    <div class="flexSpaceBetween marginBottom20 hidden-xs hidden-sm">
                        <!--<div class="col-xs-6 flex">
							<div class="col-xs-5 flexSpaceBetween">
								<label class="control-label">
									VIEW EACH QUESTION SCORE
								</label>
								<label class="switch">
									<input type="checkbox">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="col-xs-5 flexSpaceBetween marginLeft20">
								<label class="control-label">
									VIEW EACH QUESTION SCORE
								</label>
								<label class="switch">
									<input type="checkbox">
							s		<span class="slider round"></span>
								</label>
							</div>
						</div>-->
                        <div class="col-xs-6 col-xs-offset-6 legend">
                            <div class="marginBottom15">Legend</div>
                            <div class="flex">
                                <div><i class="fas fa-minus successColor marginRight8" aria-hidden="true" />100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />> 50% and 100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />≤ 50% and > 0%</div>
                                <div class="marginLeft20"><i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />0%</div>
                            </div>
                        </div>
                    </div>
                    <div class="marginBottom20 hidden-md hidden-lg">
                        <!--<div class="marginBottom20">
							<div class="col-xs-12 flexSpaceBetween marginBottom10">
								<label class="control-label">
									VIEW EACH QUESTION SCORE
								</label>
								<label class="switch">
									<input type="checkbox">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="col-xs-12 flexSpaceBetween">
								<label class="control-label">
									VIEW EACH QUESTION SCORE
								</label>
								<label class="switch">
									<input type="checkbox">
									<span class="slider round"></span>
								</label>
							</div>
						</div>-->
                        <div class="legend">
                            <div class="marginBottom15">Legend</div>
                            <div class="hidden-sm">
                                <div class="flex">
                                    <div><i class="fas fa-minus successColor marginRight8" aria-hidden="true" />100%</div>
                                    <div class="marginLeft20"><i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />> 50% and 100%</div>
                                </div>
                                <div class="flex">
                                    <div><i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />≤ 50% and > 0%</div>
                                    <div class="marginLeft20"><i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />0%</div>
                                </div>
                            </div>
                            <div class="hidden-xs">
                                <div class="flex">
                                    <div><i class="fas fa-minus successColor marginRight8" aria-hidden="true" />100%</div>
                                    <div class="marginLeft20"><i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />> 50% and 100%</div>
                                    <div class="marginLeft20"><i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />≤ 50% and > 0%</div>
                                    <div class="marginLeft20"><i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />0%</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hidden-xs hidden-sm table-responsive new-table-container-analysis">
                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar-IRAT-student-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-IRAT-student-analysis">
                                <div style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="scrollable-table-wrapper flex" tabindex="0">
                            <div class="new-left-table-IRAT-student-analysis" @scroll="handleLeftScroll">
                                <caption class="visible-hidden">
                                    Student Analysis table
                                </caption>

                                <div class="sticky-top-header zIndex1001">Details</div>
                                <table class="table">
                                    <thead class="zIndex1001">
                                        <tr>
                                            <!-- <th>
                        #
                        <template v-if="sort === 'rank'">
                          <a
                            @click.prevent="toggle('rank')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('rank')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('rank')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th> -->
                                            <th v-if="canClearAnswers" />
                                            <th>
                                                Student
                                                <template v-if="sort === 'fullname'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('fullname')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('fullname')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('fullname')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Team
                                                <template v-if="sort === 'team'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('team')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('team')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Status
                                                <template v-if="sort === 'status'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('status')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('status')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('status')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                % Correct
                                                <template v-if="sort === 'finishedOnPercentCorrect'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('finishedOnPercentCorrect')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('finishedOnPercentCorrect')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('finishedOnPercentCorrect')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                            <th>
                                                Score
                                                <template v-if="sort === 'totalPoint'">
                                                    <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('totalPoint')">
                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                    </a>
                                                    <a v-else aria-label="desc" @click.prevent="toggle('totalPoint')">
                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                    </a>
                                                </template>
                                                <a v-else aria-label="sort" @click.prevent="toggle('totalPoint')">
                                                    <i class="fa fa-sort" aria-hidden="true" />
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(student, idx) in students" :key="idx">
                                            <!-- <td>
                        {{ student.rank }}
                      </td> -->
                                            <td v-if="canClearAnswers">
                                                <div class="dropdown">
                                                    <i
                                                        class="fas fa-grip-vertical pointer"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        aria-hidden="true"
                                                    />

                                                    <div class="dropdown-menu dropdown-menu-new capitalize" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            <li>
                                                                <a
                                                                    v-tooltip="'Clear ' + student.fullname + ' Answers'"
                                                                    class="danger-state"
                                                                    href="#"
                                                                    :aria-label="'Clear ' + student.fullname + ' Answers'"
                                                                    @click.prevent="clearAnswers(student)"
                                                                >
                                                                    <span> <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Clear Answers </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="nameColumn" :aria-label="'row ' + idx + 'Student name ' + student.fullname" tabindex="0">
                                                {{ student.fullname }}
                                            </td>
                                            <td :aria-label="'row ' + idx + 'Team ' + student.team" tabindex="0">
                                                {{ student.team }}
                                            </td>
                                            <td :aria-label="'row ' + idx + 'Status ' + student.status" tabindex="0">
                                                {{ student.status }}
                                            </td>
                                            <td :aria-label="'row ' + idx + ' ' + student.finishedOnPercentCorrect + '% Correct'" tabindex="0">
                                                {{ student.finishedOnPercentCorrect ? round(student.finishedOnPercentCorrect) : '0.0' }}%
                                            </td>
                                            <td :aria-label="'row ' + idx + ' score ' + student.totalPoint" tabindex="0">
                                                {{ student.totalPoint }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <scroll-box-shadow
                                class="new-right-table-IRAT-student-analysis"
                                :options="{
                                    setScrollBar: true,
                                }"
                            >
                                <div class="sticky-top-header">Questions</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th v-for="(question, idx) in questions" :key="'question' + '-' + question.uuid" tabindex="0">
                                                <span class="buttonGroupInline">
                                                    <button
                                                        class="btn gradient btn-default"
                                                        :aria-label="'Question number ' + (idx + 1) + ' click to view the question'"
                                                        @click.prevent="openQuestionInfoModal(question)"
                                                    >
                                                        {{ idx + 1 }}.<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                                    </button>
                                                    <template
                                                        v-for="(option, option_idx) in question.options"
                                                        :key="'question' + '-' + question.uuid + '-option-' + option.key"
                                                    >
                                                        <div
                                                            v-if="option.isCorrect"
                                                            class="statusTag tagCorrectOutline marginLeft10 whiteSpaceNowrap"
                                                            :aria-label="'correct option ' + option.key"
                                                        >
                                                            {{ option.key }}<i class="fa fa-check-circle marginLeft8" aria-hidden="true" />
                                                        </div>
                                                    </template>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(student, idx) in students" :key="'student-' + idx">
                                            <template v-for="(question, question_idx) in student.questions" :key="'question' + '-' + question.uuid">
                                                <template v-if="!testObj.settings.confidenceBasedTesting">
                                                    <template v-if="question.studentAnswer">
                                                        <td tabindex="0">
                                                            <template v-if="question.isCorrect">
                                                                <div
                                                                    v-tooltip="{
                                                                        content: formatTooltipForAnswerHistory(question.attempts2),
                                                                    }"
                                                                    class="statusTag tagCorrect whiteSpaceNowrap"
                                                                    :aria-label="
                                                                        question.studentAnswer
                                                                            ? 'row ' + idx + ' student answer ' + question.studentAnswer
                                                                            : 'row ' + idx + ' student has no answer'
                                                                    "
                                                                >
                                                                    {{ question.studentAnswer ? question.studentAnswer : '-' }}
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <div
                                                                    v-tooltip="{
                                                                        content: formatTooltipForAnswerHistory(question.attempts2),
                                                                    }"
                                                                    class="statusTag tagIncorrect whiteSpaceNowrap"
                                                                    :aria-label="
                                                                        question.studentAnswer
                                                                            ? 'row ' + idx + ' student answer ' + question.studentAnswer
                                                                            : 'row ' + idx + ' student has no answer'
                                                                    "
                                                                >
                                                                    {{ question.studentAnswer ? question.studentAnswer : '-' }}
                                                                </div>
                                                            </template>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td tabindex="0" />
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="getPointSpreadingAttempts(question) != '-'">
                                                        <td tabindex="0">
                                                            <div
                                                                class="statusTag whiteSpaceNowrap"
                                                                :class="{
                                                                    tagCorrect: question.percent == 100,
                                                                    tagPrimary: question.percent >= 50 && question.percent < 100,
                                                                    tagWarning: question.percent < 50 && question.percent > 0,
                                                                    tagIncorrect: question.percent == 0,
                                                                }"
                                                                :aria-label="'row ' + idx + ' ' + getPointSpreadingAttempts(question)"
                                                            >
                                                                {{ getPointSpreadingAttempts(question) }}
                                                            </div>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td tabindex="0" />
                                                    </template>
                                                </template>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </scroll-box-shadow>
                        </div>

                        <div class="scrollable-table-wrapper flex">
                            <div class="new-left-table-scrollbar-IRAT-student-analysis" @scroll="handleLeftScroll">
                                <div style="height: 0.1px" />
                            </div>
                            <div class="new-right-table-scrollbar-IRAT-student-analysis">
                                <div style="height: 0.1px" />
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <!-- <div
              class="hidden-xs hidden-sm flexOnly tablewDiv studentAnalysisIRAT scrollable-table-wrapper"
              style="overflow: auto hidden;"
            >
              <div class="tableDiv-app1"></div>
              <div class="tableDiv-app" id="studentAnalysisIRAT-tableDiv">
                <div
                  class="studentAnalysisIRAT-tableDiv-copy"
                  style="height:0.1px"
                ></div>
              </div>
            </div>

            <div
              class="hidden-xs hidden-sm flexOnly tablewDiv studentAnalysisIRAT scrollable-table-wrapper"
              id="studentAnalysisIRATOverall"
            >
              <div class="tableDiv-app1 studentAnalysisIRAT">
                <table id="studentAnalysisIRAT-clone-left-fixed" class="table">
                  <thead>
                    <tr class="sticky">
                      <th colspan="12">DETAILS</th>
                    </tr>
                    <tr class="sticky">
                      <th class="width50px">
                        #
                        <template v-if="sort === 'rank'">
                          <a
                            @click.prevent="toggle('rank')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('rank')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('rank')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                      <th class="width112px">
                        STUDENT
                        <template v-if="sort === 'fullname'">
                          <a
                            @click.prevent="toggle('fullname')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('fullname')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('fullname')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                      <th>
                        TEAM
                        <template v-if="sort === 'team'">
                          <a
                            @click.prevent="toggle('team')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('team')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('team')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                      <th>
                        STATUS
                        <template v-if="sort === 'status'">
                          <a
                            @click.prevent="toggle('status')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('status')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('status')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                      <th>
                        % CORRECT
                        <template v-if="sort === 'finishedOnPercentCorrect'">
                          <a
                            @click.prevent="toggle('finishedOnPercentCorrect')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('finishedOnPercentCorrect')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('finishedOnPercentCorrect')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                      <th>
                        SCORE
                        <template v-if="sort === 'totalPoint'">
                          <a
                            @click.prevent="toggle('totalPoint')"
                            v-if="order === 'asc'"
                            aria-label="asc"
                          >
                            <i class="fa fa-sort-amount-asc"></i>
                          </a>
                          <a
                            @click.prevent="toggle('totalPoint')"
                            v-else
                            aria-label="desc"
                          >
                            <i class="fa fa-sort-amount-desc"></i>
                          </a>
                        </template>
                        <a
                          @click.prevent="toggle('totalPoint')"
                          v-else
                          aria-label="sort"
                        >
                          <i class="fa fa-sort"></i>
                        </a>
                      </th>
                    </tr>
                  </thead>
                </table>
                <table class="table" id="studentAnalysisIRAT-clone-left">
                  <thead>
                    <tr class="sticky">
                      <th colspan="12">DETAILS</th>
                    </tr>
                    <tr class="sticky">
                      <th class="width50px"></th>
                      <th class="width112px"></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(student, idx) in students">
                      <td>
                        {{ student.rank }}
                      </td>
                      <td class="nameColumn">{{ student.fullname }}</td>
                      <td>{{ student.team }}</td>
                      <td>{{ student.status }}</td>
                      <td>
                        {{
                          student.finishedOnPercentCorrect
                            ? round(student.finishedOnPercentCorrect)
                            : "0.0"
                        }}%
                      </td>
                      <td>{{ student.totalPoint }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="tableDiv-app studentAnalysisIRAT"
                id="studentAnalysisIRAT-tableOG"
              >
                <table id="header-fixed" class="table">
                  <thead>
                    <tr class="sticky">
                      <th :colspan="questions.length">QUESTIONS</th>
                    </tr>
                    <tr class="sticky">
                      <template v-for="(question, idx) in questions">
                        <th>
                          <span class="buttonGroupInline">
                            <button
                              class="btn gradient btn-default subBtn"
                              @click.prevent="openQuestionInfoModal(question)"
                              :aria-label="idx + 1"
                            >
                              {{ idx + 1 }}.&nbsp;<i
                                class="fas fa-info-circle"
                              ></i>
                            </button>
                            <template
                              v-for="(option, option_idx) in question.options"
                            >
                              <div
                                v-if="option.isCorrect"
                                class="statusTag tagCorrectOutline subBtn marginLeft10"
                              >
                                {{ option.key }}&nbsp;<i
                                  class="fa fa-check-circle"
                                ></i>
                              </div>
                            </template>
                          </span>
                        </th>
                      </template>
                    </tr>
                  </thead>
                </table>
                <table id="studentAnalysisIRAT-clone-right" class="table">
                  <tbody>
                    <tr v-for="(student, idx) in students">
                      <template
                        v-for="(question, question_idx) in student.questions"
                      >
                        <template
                          v-if="!testObj.settings.confidenceBasedTesting"
                        >
                          <template v-if="question.studentAnswer">
                            <td>
                              <template v-if="question.isCorrect">
                                <div class="statusTag tagCorrect subBtn">
                                  {{
                                    question.studentAnswer
                                      ? question.studentAnswer
                                      : "-"
                                  }}
                                </div>
                              </template>
                              <template v-else>
                                <div class="buttonGroup align-items">
                                  <div class="statusTag tagIncorrect subBtn">
                                    {{
                                      question.studentAnswer
                                        ? question.studentAnswer
                                        : "-"
                                    }}
                                  </div>
                                </div>
                              </template>
                            </td>
                          </template>
                          <template v-else>
                            <td></td>
                          </template>
                        </template>
                        <template v-else>
                          <template
                            v-if="getPointSpreadingAttempts(question) != '-'"
                          >
                            <td>
                              <div class="buttonGroup align-items">
                                <div
                                  class="statusTag subBtn"
                                  :class="{
                                    tagCorrect: question.percent == 100,
                                    tagPrimary:
                                      question.percent >= 50 &&
                                      question.percent < 100,
                                    tagWarning:
                                      question.percent < 50 &&
                                      question.percent > 0,
                                    tagIncorrect: question.percent == 0,
                                  }"
                                >
                                  {{ getPointSpreadingAttempts(question) }}
                                </div>
                              </div>
                            </td>
                          </template>
                          <template v-else>
                            <td></td>
                          </template>
                        </template>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->

                        <div class="hidden-xs hidden-sm flexOnly tablewDiv studentAnalysisIRAT scrollable-table-wrapper" style="overflow: auto hidden">
                            <div class="tableDiv-app1" />
                            <div id="studentAnalysisIRAT-tableDiv-1" class="tableDiv-app">
                                <div class="studentAnalysisIRAT-tableDiv-copy" style="height: 0.1px" />
                            </div>
                        </div>

                        <div class="hidden-md hidden-lg">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <!-- <th>
                      #
                      <template v-if="sort === 'rank'">
                        <a
                          @click.prevent="toggle('rank')"
                          v-if="order === 'asc'"
                          aria-label="asc"
                        >
                          <i class="fa fa-sort-amount-asc"></i>
                        </a>
                        <a
                          @click.prevent="toggle('rank')"
                          v-else
                          aria-label="desc"
                        >
                          <i class="fa fa-sort-amount-desc"></i>
                        </a>
                      </template>
                      <a
                        @click.prevent="toggle('rank')"
                        v-else
                        aria-label="sort"
                      >
                        <i class="fa fa-sort"></i>
                      </a>
                    </th> -->
                                        <th>
                                            Student
                                            <template v-if="sort === 'fullname'">
                                                <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('fullname')">
                                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                </a>
                                                <a v-else aria-label="desc" @click.prevent="toggle('fullname')">
                                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                </a>
                                            </template>
                                            <a v-else aria-label="sort" @click.prevent="toggle('fullname')">
                                                <i class="fa fa-sort" aria-hidden="true" />
                                            </a>
                                        </th>
                                        <th>
                                            Team
                                            <template v-if="sort === 'team'">
                                                <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('team')">
                                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                </a>
                                                <a v-else aria-label="desc" @click.prevent="toggle('team')">
                                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                </a>
                                            </template>
                                            <a v-else aria-label="sort" @click.prevent="toggle('team')">
                                                <i class="fa fa-sort" aria-hidden="true" />
                                            </a>
                                        </th>
                                        <th>
                                            Status
                                            <template v-if="sort === 'status'">
                                                <a v-if="order === 'asc'" aria-label="asc" @click.prevent="toggle('status')">
                                                    <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                </a>
                                                <a v-else aria-label="desc" @click.prevent="toggle('status')">
                                                    <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                </a>
                                            </template>
                                            <a v-else aria-label="sort" @click.prevent="toggle('status')">
                                                <i class="fa fa-sort" aria-hidden="true" />
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!--<tr :class="{'first':student.rank==1,'second':student.rank==2,'third':student.rank==3}">
											<td><template v-if="idx<=3"><i class="fas fa-trophy"></i></template><template v-else>{{student.rank}}</template></td>
											<td>{{student.fullname}}</td>
											<td>{{student.team}}</td>
											<td>{{student.status}}</td>
										</tr>-->
                                    <!-- <tr :class="{'first':student.rank==1,'second':student.rank==2,'third':student.rank==3}"> -->
                                    <tr v-for="(student, idx) in students" :key="idx">
                                        <!-- <td>
                        <template v-if="student.rank<=3">
													<i class="fas fa-trophy"></i>
												</template>
												<template v-else>
													{{student.rank}}
												</template>
                        {{ student.rank }}
                      </td> -->
                                        <td>{{ student.fullname }}</td>
                                        <td>{{ student.team }}</td>
                                        <td>{{ student.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- <div class="studentAnalysis table-responsive">
						<div class="hidden-xs hidden-sm flexOnly tableDiv">
							<div class="scrollable-table-wrapper" id="studentAnalysisTable-copy-backup">
								<div class="studentAnalysisTable-copy">&nbsp;</div>
							</div>
						</div>
						<div class="hidden-xs hidden-sm flexOnly tablewDiv">
							<div class="scrollable-table-wrapper" id="studentAnalysisTableOG">
								<table class="table paddingBtm29">
									<thead>
										<tr>
											<th colspan="6">DETAILS</th>
											<th colspan="100" style="line-height:1;">QUESTIONS</th>
										</tr>
										<tr class="sticky">
											<th class="width50px">
												#
												<template v-if="sort==='rank'">
								       				<a @click.prevent="toggle('rank')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('rank')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('rank')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>
									       	</th>
									       	<th class="width100px">
												STUDENT
												<template v-if="sort==='fullname'">
								       				<a @click.prevent="toggle('fullname')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('fullname')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('fullname')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<th>
												TEAM
												<template v-if="sort==='team'">
								       				<a @click.prevent="toggle('team')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('team')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('team')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<th>
												STATUS
												<template v-if="sort==='status'">
								       				<a @click.prevent="toggle('status')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('status')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('status')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<th>
												% CORRECT
												<template v-if="sort==='finishedOnPercentCorrect'">
								       				<a @click.prevent="toggle('finishedOnPercentCorrect')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('finishedOnPercentCorrect')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('finishedOnPercentCorrect')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<th>
												SCORE
												<template v-if="sort==='totalPoint'">
								       				<a @click.prevent="toggle('totalPoint')" v-if="order ==='asc'" aria-label="asc">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('totalPoint')"  v-else aria-label="desc">
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('totalPoint')" v-else aria-label="sort">
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<th>
												TOTAL TIME TAKEN
												<template v-if="sort==='totalTimeTaken'">
								       				<a @click.prevent="toggle('totalTimeTaken')" v-if="order ==='asc'">
								       					<i class="fa fa-sort-amount-asc"></i>
								       				</a>
								       				<a @click.prevent="toggle('totalTimeTaken')"  v-else>
								       					<i class="fa fa-sort-amount-desc"></i>
								       				</a>
								       			</template>
									       		<a @click.prevent="toggle('totalTimeTaken')" v-else>
									       			<i class="fa fa-sort"></i>
									       		</a>	
									       	</th>
									       	<template v-for="(question,idx) in questions">
												<th style="line-height:1;">
													<span class="buttonGroupInline">
														<button class="btn gradient btn-default subBtn"  @click.prevent="openQuestionInfoModal(question)" :aria-label="idx+1">
															{{idx+1}}.&nbsp;<i class="fas fa-info-circle"></i>
														</button>
														<template v-for="(option,option_idx) in question.options">
															<div v-if="option.isCorrect" class="statusTag tagCorrectOutline subBtn marginLeft10">
																{{option.key}}&nbsp;<i class="fa fa-check-circle"></i>
															</div>
														</template>
													</span>
												</th>
											</template>
										</tr>
									</thead>
									<tbody>
										<template v-for="(student,idx) in students">
											<tr :class="{'first':student.rank==1,'second':student.rank==2,'third':student.rank==3}">
											<tr>
												<td class="fixedHeight72px">
													<template v-if="student.rank<=3">
														<i class="fas fa-trophy"></i>
													</template>
													<template v-else>
														{{student.rank}}
													</template>
													{{student.rank}}
												</td>
												<td class="fixedHeight72px">{{student.fullname}}</td>
												<td class="fixedHeight72px">{{student.team}}</td>
												<td class="fixedHeight72px">{{student.status}}</td>
												<td class="fixedHeight72px">{{student.finishedOnPercentCorrect?round(student.finishedOnPercentCorrect):'0.0'}}%</td>
												<td class="fixedHeight72px">{{student.totalPoint}}</td>
												<td class="fixedHeight72px">{{student.totalTimeTaken}}</td>
												<template v-for="(question,question_idx) in student.questions">
													<template v-if="!testObj.settings.confidenceBasedTesting">
														<template v-if="question.studentAnswer">
															<td>
																<template v-if="question.isCorrect">
																	<div class="statusTag tagCorrect subBtn">
																		{{question.studentAnswer?question.studentAnswer:'-'}}
																	</div>
																</template>
																<template v-else>
																	<div class='buttonGroup align-items'>
																		<div class="statusTag tagIncorrect subBtn">
																			{{question.studentAnswer?question.studentAnswer:'-'}}
																		</div>
																	</div>
																</template>
															</td>
														</template>
														<template v-else>
															<td class="fixedHeight72px"></td>
														</template>
													</template>
													<template v-else>
														<template v-if="getPointSpreadingAttempts(question)!='-'">
															<td>
																<div class='buttonGroup align-items'>
																	<div class="statusTag subBtn" :class="{'tagCorrect':question.percent==100,'tagPrimary':question.percent>=50&&question.percent<100,'tagWarning':question.percent<50&&question.percent>0,'tagIncorrect':question.percent==0}">
																		{{getPointSpreadingAttempts(question)}}
																	</div>
																</div>
															</td>
														</template>
														<template v-else>
															<td class="fixedHeight72px"></td>
														</template>
													</template>
												</template>
											</tr>	
										</template>
									</tbody>
								</table>
							</div>
						</div>
						<div class="hidden-md hidden-lg">
					  		<table class="table">
								<thead>
									<tr>
										<th>
											#
											<template v-if="sort==='rank'">
							       				<a @click.prevent="toggle('rank')" v-if="order ==='asc'" aria-label="asc">
							       					<i class="fa fa-sort-amount-asc"></i>
							       				</a>
							       				<a @click.prevent="toggle('rank')"  v-else aria-label="desc">
							       					<i class="fa fa-sort-amount-desc"></i>
							       				</a>
							       			</template>
								       		<a @click.prevent="toggle('rank')" v-else aria-label="sort">
								       			<i class="fa fa-sort"></i>
								       		</a>
								       	</th>
								       	<th>
											STUDENT
											<template v-if="sort==='fullname'">
							       				<a @click.prevent="toggle('fullname')" v-if="order ==='asc'" aria-label="asc">
							       					<i class="fa fa-sort-amount-asc"></i>
							       				</a>
							       				<a @click.prevent="toggle('fullname')"  v-else aria-label="desc">
							       					<i class="fa fa-sort-amount-desc"></i>
							       				</a>
							       			</template>
								       		<a @click.prevent="toggle('fullname')" v-else aria-label="sort">
								       			<i class="fa fa-sort"></i>
								       		</a>	
								       	</th>
								       	<th>
											TEAM
											<template v-if="sort==='team'">
							       				<a @click.prevent="toggle('team')" v-if="order ==='asc'" aria-label="asc">
							       					<i class="fa fa-sort-amount-asc"></i>
							       				</a>
							       				<a @click.prevent="toggle('team')"  v-else aria-label="desc">
							       					<i class="fa fa-sort-amount-desc"></i>
							       				</a>
							       			</template>
								       		<a @click.prevent="toggle('team')" v-else aria-label="sort">
								       			<i class="fa fa-sort"></i>
								       		</a>	
								       	</th>
								       	<th>
											STATUS
											<template v-if="sort==='status'">
							       				<a @click.prevent="toggle('status')" v-if="order ==='asc'" aria-label="asc">
							       					<i class="fa fa-sort-amount-asc"></i>
							       				</a>
							       				<a @click.prevent="toggle('status')"  v-else aria-label="desc">
							       					<i class="fa fa-sort-amount-desc"></i>
							       				</a>
							       			</template>
								       		<a @click.prevent="toggle('status')" v-else aria-label="sort">
								       			<i class="fa fa-sort"></i>
								       		</a>	
								       	</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(student,idx) in students">
										<tr :class="{'first':student.rank==1,'second':student.rank==2,'third':student.rank==3}">
											<td><template v-if="idx<=3"><i class="fas fa-trophy"></i></template><template v-else>{{student.rank}}</template></td>
											<td>{{student.fullname}}</td>
											<td>{{student.team}}</td>
											<td>{{student.status}}</td>
										</tr>
										<tr :class="{'first':student.rank==1,'second':student.rank==2,'third':student.rank==3}">
										<tr>
											<td>
												<template v-if="student.rank<=3">
													<i class="fas fa-trophy"></i>
												</template>
												<template v-else>
													{{student.rank}}
												</template>
												{{student.rank}}
											</td>
											<td>{{student.fullname}}</td>
											<td>{{student.team}}</td>
											<td>{{student.status}}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div> -->
                </div>
                <vcl-table v-else />
            </template>
        </kr-panel>
        <div
            id="studentAnalysisQuestionInfoModal"
            data-backdrop="static"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="studentAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog" :class="{ 'widthAuto maxWidth1000px': ['mcqs', 'mcqm'].includes(previewQuestion.type) }">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="studentAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />View Question
                        </h2>
                    </div>
                    <div class="modal-body">
                        <label class="control-label"> Question {{ getQuestionNo(previewQuestion.group - 1, previewQuestion.order - 1) }} </label>

                        <question-display :question="previewQuestion" />

                        <div v-if="['mcqs', 'mcqm'].includes(previewQuestion.type)" class="mcqStatisticsBarChart marginTop20">
                            <div class="panel-header">
                                <div>Answer Choices</div>
                            </div>
                            <div>
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in previewQuestion.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect,
                                                        wrong: !option.isCorrect,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.student">
                                                            <div
                                                                v-tooltip="model.student.fullname"
                                                                class="btn btnDiscussionTeams marginTop10 cursor-norm"
                                                                tabindex="0"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label>{{ model.student.fullname }}</label>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <div v-if="previewQuestion.remarks" class="gradient panel marginTop10">
                            <div class="panel-heading padding10">
                                <label>Question Notes</label>
                            </div>
                            <div class="panel-body padding10">
                                <p class="whiteSpaceBreakSpacesText">
                                    {{ previewQuestion.remarks }}
                                </p>
                            </div>
                        </div> -->

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div
            id="studentAnalysisClearAnswersModal"
            class="modal danger-modal clearAnswersModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clearAnswersModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clearAnswersModal-title" class="modal-title">Clear Answers</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">Clear Answers For</h2>
                        <h2 class="fw-normal">
                            {{ studentToClear.fullname }}
                        </h2>
                        <br />
                        <p>You are about to clear all</p>
                        <p>answers that were submitted</p>
                        <p>
                            by <b>{{ studentToClear.fullname }}</b> for this activity.
                        </p>

                        <br />

                        <p>Do you want to proceed?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="clear()">Yes, Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { VclTable } from 'vue-content-loading';
import { testMixins } from '../../../../../../../../mixins/test';

export default {
    mixins: [testMixins],
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            questions: [],
            previewQuestion: {},
            studentAnalysis: null,
            students: [],
            order: 'asc',
            sort: 'rank',
            debouncedUpdate: null,
            lastUpdateTimestamp: '',
            initialized: false,
            studentToClear: {},
        };
    },
    created() {
        var that = this;
        this.debouncedUpdate = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    if (!that.lastUpdateTimestamp) {
                        for (var i = 0; i < response.data.data.studentAnalysis.students.length; i++) {
                            response.data.data.studentAnalysis.students[i].rank = i + 1;
                        }
                    }
                    that.lastUpdateTimestamp = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).format('YYYY-MM-DD HH:mm:ss');
                    if (that.studentAnalysis == null) {
                        that.studentAnalysis = response.data.data.studentAnalysis;
                    } else {
                        that.studentAnalysis.students = that.mergeArrayOfObjectsWithKey(
                            that.studentAnalysis.students,
                            response.data.data.studentAnalysis.students,
                            'id'
                        );
                        /*Vue.set(
                            that.studentAnalysis,
                            'students',
                            that.mergeArrayOfObjectsWithKey(
                                that.studentAnalysis.students,
                                response.data.data.studentAnalysis.students,
                                'id'
                            )
                        );*/
                        that.initStudentsFinishedOnPercentCorrect(response.data.data.studentAnalysis);
                    }
                    //that.recomputeRank();
                    that.testObj.studentAnalysis = that.studentAnalysis;
                    /*Vue.set(
                        that.testObj,
                        'studentAnalysis',
                        that.studentAnalysis
                    );*/
                    that.init();
                    if (!that.component_done_loading) {
                        that.processEchoListeners();
                        that.component_done_loading = true;
                        Vue.nextTick(function () {
                            $('#studentAnalysisQuestionInfoModal .modal-dialog').draggable({
                                handle: '.modal-header',
                            });
                        });
                    }
                });
            },
            1000,
            { maxWait: 3000 }
        );
        this.debouncedUpdate();
    },
    updated() {
        this.alignTable();
    },
    mounted() {
        var that = this;

        this.$root.$on('fontResized', () => {
            that.alignTable();
        });
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('StudentTestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'StudentTestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('StudentAnswerSaved', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentAnswerSaved',
                handler: h2,
            });
        },
        formatTooltipForAnswerHistory(attempts) {
            if (!attempts || !_.get(this.testObj, 'settings.allowChangeAnswersBeforeFinalSubmission', false)) {
                attempts = [];
            }
            var ret = ``;
            for (var i = attempts.length - 1; i >= 0; i--) {
                let attempt = attempts[i];

                ret += `<div class="${i != attempts.length - 1 ? 'greyText' : ''}"><span style="${i == attempts.length - 1 ? 'font-weight:700;' : ''}">${
                    attempt.attempt
                }</span> ${this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').date}
						(${this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').current_timezone})</div>`;
            }
            return ret;
        },
        fetchTest() {
            return axios.get(
                '/tests/' + this.$route.params.id + '/dashboard/students' + (this.lastUpdateTimestamp != '' ? `?timestamp=${this.lastUpdateTimestamp}` : ''),
                { _internal: { hasLoadingBar: false } }
            );
        },
        recomputeRank() {
            if (this.initialized) {
                this.studentAnalysis.students = _.sortBy(this.studentAnalysis.students, function (o) {
                    let parsed = parseFloat(o.finishedOnPercentCorrect);
                    if (Number.isNaN(parsed)) {
                        parsed = 0;
                    }
                    return parsed;
                }).reverse();
            }
            for (var i = 0; i < this.studentAnalysis.students.length; i++) {
                this.studentAnalysis.students[i].rank = i + 1;
                //Vue.set(this.studentAnalysis.students[i], 'rank', i + 1);
            }
            this.initialized = true;
        },
        initStudentsFinishedOnPercentCorrect(newStudentAnalysis) {
            for (var i = 0; i < newStudentAnalysis.students.length; i++) {
                let idx = _.findIndex(this.studentAnalysis.students, function (o) {
                    return o.fullname == newStudentAnalysis.students[i].fullname;
                });
                if (idx != -1) {
                    this.studentAnalysis.studentsFinishedOnPercentCorrect[idx] = newStudentAnalysis.studentsFinishedOnPercentCorrect[i];
                    //Vue.set(this.studentAnalysis.studentsFinishedOnPercentCorrect, idx, newStudentAnalysis.studentsFinishedOnPercentCorrect[i]);
                }
            }
        },
        init() {
            if (_.isEmpty(this.studentAnalysis)) {
                this.studentAnalysis = { students: [] };
            }
            if (_.isEmpty(this.questions)) {
                this.questions = _.flatten(this.testObj.questions);
            }
            this.students = _.clone(this.studentAnalysis.students);
            for (var i = 0; i < this.students.length; i++) {
                if (this.students[i].finishedOnPercentCorrect == null) {
                    this.students[i].finishedOnPercentCorrect = 0;
                }
            }
            this.sortList();
            var that = this;
            Vue.nextTick(function () {
                that.alignTable();

                $('.new-right-table-IRAT-student-analysis div').on('scroll', function () {
                    $('.new-right-table-scrollbar-IRAT-student-analysis').scrollLeft($(this).scrollLeft());
                });

                $('.new-right-table-scrollbar-IRAT-student-analysis').on('scroll', function () {
                    $('.new-right-table-IRAT-student-analysis div').scrollLeft($(this).scrollLeft());
                });
            });
        },
        sortList() {
            let sort = this.sort;
            if (sort != 'team') {
                this.students = _.orderBy(this.students, [sort], [this.order]);
            } else {
                this.natSort(this.students, 'team');
                if (this.order == 'desc') {
                    _.reverse(this.students);
                }
            }

            var that = this;
            Vue.nextTick(function () {
                that.alignTable();
            });
        },
        toggle(column = '') {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }

            this.sortList();
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#studentAnalysisQuestionInfoModal').modal('show');
        },
        getPointSpreadingAttempts(question) {
            let attempts = _.get(question, 'attempts', []);
            if (attempts == null) {
                attempts = [];
            }
            if (attempts.length > 0) {
                let ret = '';
                let attempt = attempts[question.attempts.length - 1];
                for (var k in attempt) {
                    if (attempt.hasOwnProperty(k)) {
                        ret += k + ':' + attempt[k] + ' ';
                    }
                }
                return ret;
            } else {
                return '-';
            }
        },
        alignTable() {
            // new table

            $('.new-table-container-analysis .new-left-table-IRAT-student-analysis thead tr').each(function (index) {
                var theadOneHeight = $(this).height();
                var theadTwo = $('.new-table-container-analysis .new-right-table-IRAT-student-analysis thead tr:eq(' + index + ')');

                if (!theadTwo.length) return false;

                var theadTwoHeight = theadTwo.height();

                if (theadOneHeight > theadTwoHeight) {
                    theadTwo.height(theadOneHeight);
                } else {
                    $(this).height(theadTwoHeight);
                }
            });

            $('.new-table-container-analysis .new-left-table-IRAT-student-analysis tbody tr').each(function (index) {
                var rowOneHeight = $(this).height();
                var rowTwo = $('.new-table-container-analysis .new-right-table-IRAT-student-analysis tbody tr:eq(' + index + ')');

                if (!rowTwo.length) return false;

                var rowTwoHeight = rowTwo.height();

                if (rowOneHeight > rowTwoHeight) {
                    rowTwo.height(rowOneHeight);
                } else {
                    $(this).height(rowTwoHeight);
                }
            });

            $('.new-table-container-analysis .new-right-table-IRAT-student-analysis div').scroll(function () {
                $('.new-table-container-analysis .new-left-table-IRAT-student-analysis').scrollTop($(this).scrollTop());
            });

            $('.new-table-container-analysis .new-left-table-IRAT-student-analysis').scroll(function () {
                $('.new-table-container-analysis .new-right-table-IRAT-student-analysis div').scrollTop($(this).scrollTop());
            });

            var widthOfTheLeftTable = $('.new-table-container-analysis .new-left-table-IRAT-student-analysis table').width();
            $('.new-table-container-analysis .new-left-table-scrollbar-IRAT-student-analysis div').width(widthOfTheLeftTable + 'px');

            var widthOfTheRightTable = $('.new-table-container-analysis .new-right-table-IRAT-student-analysis table').width();
            $('.new-table-container-analysis .new-right-table-scrollbar-IRAT-student-analysis div').width(widthOfTheRightTable + 'px');
        },
        clearAnswers(student) {
            this.studentToClear = student;
            $('#studentAnalysisClearAnswersModal').modal('show');
        },
        clear() {
            var that = this;
            let data = {};
            data.userPlacementTestUuid = this.studentToClear.uuid;
            axios
                .post(`tests/${this.testObj.uuid}/reset-answers`, data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Answers have been cleared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        handleLeftScroll(event) {
            $('.new-left-table-IRAT-student-analysis').scrollLeft(event.target.scrollLeft);
            $('.new-left-table-scrollbar-IRAT-student-analysis').scrollLeft(event.target.scrollLeft);
        },
        handleRightScroll(event) {
            $('.new-right-table-IRAT-student-analysis div').scrollLeft(event.target.scrollLeft);
            $('.new-right-table-scrollbar-IRAT-student-analysis').scrollLeft(event.target.scrollLeft);
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
        VclTable,
    },
    computed: {
        canClearAnswers() {
            if (['scheduled', 'not started'].includes(this.testObj.status)) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tagCorrect {
    background-color: #3e7c5b;
    border-radius: 3px;
    color: #fff;
}

.tagIncorrect {
    background-color: #ca3333;
    border-radius: 3px;
    color: #fff;
}
.tagPrimary {
    background-color: #546ea9;
    border-radius: 3px;
    color: #fff;
}
.tagWarning {
    background-color: #e69b23;
    border-radius: 3px;
    color: #fff;
}

.statusTag.tagCorrect i {
    font-size: 12px;
}

.tagCorrectOutline {
    border: 1px solid #3e7c5b;
    color: #3e7c5b;
}

i.fa-trophy {
    display: inline-block;
    border-radius: 60px;
    padding: 0.5em;
    color: #fff;
    font-size: 8px;
}

.studentAnalysis .first {
    background-color: #fdfbf4;
}

.first i.fa-trophy {
    box-shadow: 0px 0px 2px #dab025;
    background: #facd36;
    border: 1px solid #dab025;
}

.studentAnalysis .second {
    background-color: #fbfbfb;
}

.second i.fa-trophy {
    box-shadow: 0px 0px 2px #a3a3a3;
    background: #dadada;
    border: 1px solid #a3a3a3;
}

.studentAnalysis .third {
    background-color: #fdf8f3;
}

.third i.fa-trophy {
    box-shadow: 0px 0px 2px #9c4317;
    background: #c6937a;
    border: 1px solid #9c4317;
}

.legend {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.legend div:first-child {
    text-transform: uppercase;
    font-weight: bold;
}

.legend div:last-child div {
    display: flex;
    align-items: center;
    line-height: 0.8;
}

.legend .fas {
    font-size: 15px;
}

.fixedHeight69px {
    height: 69px;
}

.openended-space {
    width: 250px;
    height: 50px;
    overflow: hidden;
}

.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv-app {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv-app .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv-app1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tagCorrect {
    background-color: #3e7c5b;
    border-radius: 3px;
    color: #fff;
}

.statusTag.tagCorrect i {
    font-size: 12px;
}

.tableDiv-app1 {
    min-width: 618px;
    width: 618px;
}

.tableDiv-app {
    width: calc(100% - 618px);
}

.tableDiv-app1 .table > thead > tr > th,
.tableDiv-app1 .table > thead > tr > td,
.tableDiv-app1 .table > tbody > tr > th,
.tableDiv-app1 .table > tbody > tr > td,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app .table > thead > tr > th,
.tableDiv-app .table > thead > tr > td,
.tableDiv-app .table > tbody > tr > th,
.tableDiv-app .table > tbody > tr > td,
.tableDiv-app .table > tfoot > tr > th,
.tableDiv-app .table > tfoot > tr > th {
    padding: 15px;
}

thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

table {
    border-top: 0;
}

#header-fixed,
#studentAnalysisIRAT-clone-left-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#studentAnalysisIRAT-clone-left-fixed {
    border-right: 1px solid #ddd;
}

#studentAnalysisIRAT-clone-left-fixed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
}

#studentAnalysisIRAT-clone-left {
    border-top-left-radius: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;
}

#studentAnalysisIRAT-clone-right {
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
}

#studentAnalysisIRATOverall {
    border: 1px solid #ddd;
    border-radius: 3px;
}

#studentAnalysisIRAT-tableOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.width112px {
    min-width: 112px;
}

.width50px {
    max-width: 50px;
}

.nameColumn {
    word-wrap: break-word;
    max-width: 130px;
}

/* new table */

.new-table-container-analysis {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 52.84px;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .sticky-top-header {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        text-transform: uppercase;
    }

    .new-left-table-IRAT-student-analysis {
        min-width: 600px;
        width: 600px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table-IRAT-student-analysis {
        width: calc(100% - 600px);
        max-height: 500px;
        scrollbar-width: none;

        .sticky-top-header {
            left: 0;
        }

        // &::-webkit-scrollbar {
        //     height: 0px;
        //     width: 16px;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-track {
        //     border-radius: 0;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background-color: #babac0;
        //     border-radius: 16px;
        //     border: 4px solid #fff;
        // }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }

    .new-left-table-scrollbar-IRAT-student-analysis {
        width: 600px;
    }

    .new-right-table-scrollbar-IRAT-student-analysis {
        width: calc(100% - 600px);
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar-IRAT-student-analysis,
            .new-right-table-scrollbar-IRAT-student-analysis {
                // border-bottom: 0;
                border: 0;
            }
        }

        // &:last-child {
        //   .new-left-table-scrollbar-IRAT-student-analysis,
        //   .new-right-table-scrollbar-IRAT-student-analysis {
        //     border-top: 0;
        //   }
        // }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}
</style>
