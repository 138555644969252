/*
Kelvin Roger Ang Yap
krayap@gmail.com
*/
import * as filestack from 'filestack-js';

Vue.component('PinCode', require('./components/pin-code.vue').default);

Vue.component('KrField', require('./components/kr-field.vue').default);

Vue.component('KrSelect', require('./components/kr-select.vue').default);

Vue.component('KrFilePicture', require('./components/kr-file-picture.vue').default);

Vue.component('KrFileMultiple', require('./components/kr-file-multiple.vue').default);

Vue.component('KrDate', require('./components/kr-date.vue').default);

Vue.component('KrDateRange', require('./components/kr-date-range.vue').default);

Vue.component('KrRadioMultiple', require('./components/kr-radio-multiple.vue').default);

Vue.component('KrToggle', require('./components/kr-toggle.vue').default);

Vue.component('KrChangePassword', require('./components/kr-change-password.vue').default);

Vue.component('KrEditor', require('./components/kr-editor.vue').default);

Vue.component('KrFile', {
    props: ['display', 'form', 'name', 'options'],
    data() {
        //image:form.model[this.name]
        return {
            client: null,
            url: null,
            label: 'No file chosen',
            is_uploading: false,
        };
    },
    created() {
        this.url = _.get(this.form.model, this.name + '.url', null);
        this.label = _.get(this.form.model, this.name + '.filename', 'No file chosen');
    },
    methods: {
        openFileStack() {
            this.client = filestack.init(process.env.MIX_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    maxFiles: 1,
                    minFiles: 1,
                    onUploadStarted: function (file) {
                        that.is_uploading = true;
                    },
                    onFileUploadFinished: function (file) {
                        that.is_uploading = false;
                        that.label = file.filename;
                        that.url = file.url;
                        _.set(that.form.model, that.name, file);
                    },
                    onOpen() {},
                })
                .open();
        },
    },
    template: `<div v-if="!form.isHidden(name)" class="form-group" :class="{'has-error': form.errors.has(name)}">
	    <label class="control-label">{{ display }}</label>
	    <template v-if="url!=null">
			<a :href="url" class="btn btn-block" download>Click to download file</a>
		    <div style="clear:both"></div>
	    </template>
	    <div class="">
	    	<div class="input-group">
	    		<input disabled type="text" class="form-control" :value="label">
	        	<span class="input-group-btn">
		        	<a class="btn btn-primary" @click.prevent="openFileStack"><i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i> <template v-else>Upload</template></a>
		      	</span>
	        </div>
	        <span class="help-block" v-show="form.errors.has(name)">
	            <strong>{{ form.errors.get(name) }}</strong>
	        </span>
	    </div>
	 </div>`,
});
Vue.component('KrCheckbox', {
    props: {
        // basic type check (`null` means accept any type)
        display: String,
        form: '',
        name: String,
        trueval: {
            default: '1',
        },
        falseval: {
            default: '0',
        },
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                this.value = _.get(this.form.model, this.name, '');
            },
        },
    },
    created() {
        this.value = _.get(this.form.model, this.name);
    },
    methods: {
        emit(emit) {
            _.set(this.form.model, this.name, this.value);
            this.$emit('changed');
        },
    },
    template: `
	<fieldset v-if="!form.isHidden(name)" class="form-group">
	      <label class="form-check-label" style="font-weight:normal">
	        <input @change="emit" :name="name" type="checkbox" :disabled="(form.isDisabled(name)) ? true:undefined" class="form-check-input" v-model="value" 
	        v-bind:true-value="trueval"
  			v-bind:false-value="falseval"
	        @keydown="form.errors.clear($event.target.name);">
	        {{display}}
	      </label>
  	</fieldset>`,
});

Vue.component('ClSlider', require('./components/cl-slider.vue').default);
Vue.component('ClCircleProgressBar', require('./components/cl-circle-progress-bar.vue').default);
Vue.component('ClMagicStar', require('./components/cl-magic-star.vue').default);
