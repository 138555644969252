<template>
    <div v-if="component_done_loading">
        <div v-if="pageType == 'form'" class="marginBottom30">
            <!--<button class="btn btn-default mainBtn">
                <i class="fas fa-caret-left"></i>&nbsp;Back
            </button>-->
            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings()">
                Review your Activity<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default mainBtn" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
            </button>
        </div>
        <kr-panel v-if="activityObj.type == 'peerEvaluationV2'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">Peer Evaluation Settings</div>
                <div v-if="pageType == 'confirmation' && peerEvaluationV2Mode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('peerEvaluationV2')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="peerEvaluationV2Mode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('peerEvaluationV2')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="peerEvaluationV2Mode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && peerEvaluationV2Mode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                peerEvaluationV2Mode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                peerEvaluationV2Mode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && peerEvaluationV2Mode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && peerEvaluationV2Mode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <div>
                                    <h2 class="panel-content-subHeader">Behavior Settings</h2>

                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="allowChangeAnswersBeforeFinalSubmission"
                                                v-model="form.model.allowChangeAnswersBeforeFinalSubmission"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="allowChangeAnswersBeforeFinalSubmission" class="control-label marginBottom0 paddingTop2">
                                            Students can change their answers
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="allowViewQuestionsAhead"
                                                v-model="form.model.allowViewQuestionsAhead"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :true-value="false"
                                                :false-value="true"
                                                @input="waitToSave()"
                                                @change="form.model.allowViewQuestionsAhead ? (form.model.allowViewPreviousQuestions = true) : ''"
                                            />
                                        </div>
                                        <label for="allowViewQuestionsAhead" class="control-label marginBottom0 paddingTop2">
                                            Students must answer questions in sequence
                                        </label>
                                    </div>

                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="allowViewPreviousQuestions"
                                                v-model="form.model.allowViewPreviousQuestions"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.allowViewQuestionsAhead ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="allowViewPreviousQuestions" class="control-label marginBottom0 paddingTop2">
                                            Students can navigate to previously answered questions
                                        </label>
                                    </div>

                                    <div class="marginTop30">
                                        <h3 class="panel-content-subHeader">Instructions</h3>
                                        <div>
                                            <kr-field
                                                :form="form"
                                                name="peerEvaluationV2Instruction"
                                                :options="{
                                                    htmlEditor: true,
                                                    placeholder: 'Enter instructions for this activity here',
                                                    hasLabel: false,
                                                    showError: false,
                                                }"
                                            />
                                        </div>
                                        <div class="marginTop15">
                                            <kr-file-multiple
                                                :options="{ reverse: true }"
                                                :form="form"
                                                name="peerEvaluationV2Attachments"
                                                @preview-attachment="showAttachment"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <h2 class="panel-content-subHeader">Student App Preview</h2>

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="peerEvaluationV2_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-evaluation-v2`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            <h2 class="panel-content-subHeader">Behavior Settings</h2>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.allowChangeAnswersBeforeFinalSubmission"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students can change their answers </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.allowViewQuestionsAhead"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer questions in sequence </label>
                            </div>

                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.allowViewPreviousQuestions"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students can navigate to previously answered questions </label>
                            </div>
                        </div>
                        <div class="marginTop30">
                            <div>
                                <label class="control-label">Instructions</label>
                                <template v-if="form.model.peerEvaluationV2Instruction">
                                    <div v-html="form.model.peerEvaluationV2Instruction" />
                                </template>
                                <template v-else>
                                    <p>-</p>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <div class="control-label">Attachments</div>
                                <div>
                                    <div v-if="form.model.iratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in form.model.iratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'irat' || activityObj.type == 'iratntrat'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">IRAT Settings</div>
                <div v-if="pageType == 'confirmation' && iratMode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('irat')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="iratMode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('irat')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="iratMode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && iratMode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                iratMode = 'edit';
                                tratMode = 'view';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                iratMode = 'edit';
                                tratMode = 'view';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && iratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && iratMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <div>
                                    <h2 class="panel-content-subHeader">Behavior Settings</h2>
                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  v-if="!form.model.iratImmediateFeedback"
                  name="iratAllowChangeAnswersBeforeFinalSubmission"
                  display="Allow students to change answers before final submission"
                  :form="form"
                  class="marginBottom15"
                >
                </kr-toggle> -->

                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratAllowChangeAnswersBeforeFinalSubmission"
                                                v-model="form.model.iratAllowChangeAnswersBeforeFinalSubmission"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.iratImmediateFeedback ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <!-- <label style="height:20px;line-height:20px;margin-right:10px;">
                    Yes
                  </label> -->
                                        <label for="iratAllowChangeAnswersBeforeFinalSubmission" class="control-label marginBottom0 paddingTop2">
                                            Students can change their answers
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                  name="iratAllowViewQuestionsAhead"
                  display="Students must answer questions in sequence"
                  :form="form"
                  class="marginBottom15"
                  :options="{ trueval: false, falseval: true, reverse: true }"
                >
                </kr-toggle> -->
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratAllowViewQuestionsAhead"
                                                v-model="form.model.iratAllowViewQuestionsAhead"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :true-value="false"
                                                :false-value="true"
                                                @input="waitToSave()"
                                                @change="form.model.iratAllowViewQuestionsAhead ? (form.model.iratAllowViewPreviousQuestions = true) : ''"
                                            />
                                        </div>
                                        <label for="iratAllowViewQuestionsAhead" class="control-label marginBottom0 paddingTop2">
                                            Students must answer questions in sequence
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  v-if="
                    !form.model.iratAllowChangeAnswersBeforeFinalSubmission &&
                      !form.model.iratAllowViewQuestionsAhead
                  "
                  name="iratAllowViewPreviousQuestions"
                  display="Allow students to view previous questions"
                  :form="form"
                  class="marginBottom15"
                >
                </kr-toggle> -->

                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratAllowViewPreviousQuestions"
                                                v-model="form.model.iratAllowViewPreviousQuestions"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.iratAllowViewQuestionsAhead ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratAllowViewPreviousQuestions" class="control-label marginBottom0 paddingTop2">
                                            Students can navigate to previously answered questions
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  name="iratRandomizeAnswerOptionOrder"
                  display="Randomize answer options order"
                  :form="form"
                  class="marginBottom15"
                  @changed="
                    !form.model.iratRandomizeAnswerOptionOrder
                      ? (form.model.iratHideQuestionOptionLabels = false)
                      : ''
                  "
                >
                </kr-toggle> -->

                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratRandomizeAnswerOptionOrder"
                                                v-model="form.model.iratRandomizeAnswerOptionOrder"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @change="
                                                    form.model.iratRandomizeAnswerOptionOrder
                                                        ? (form.model.iratHideQuestionOptionLabels = true)
                                                        : (form.model.iratHideQuestionOptionLabels = false)
                                                "
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratRandomizeAnswerOptionOrder" class="control-label marginBottom0 paddingTop2"> Randomise choices </label>
                                    </div>

                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  v-if="form.model.iratRandomizeAnswerOptionOrder"
                  name="iratHideQuestionOptionLabels"
                  display="Hide answer choice key"
                  :form="form"
                >
                </kr-toggle> -->

                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratHideQuestionOptionLabels"
                                                v-model="form.model.iratHideQuestionOptionLabels"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="!form.model.iratRandomizeAnswerOptionOrder ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratHideQuestionOptionLabels" class="control-label marginBottom0 paddingTop2">
                                            Hide choice key (e.g. A, B, C ...)
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  v-if="!form.model.iratConfidenceBasedTesting"
                  name="iratImmediateFeedback"
                  display="Enable Immediate Feedback"
                  :form="form"
                  class="marginBottom15"
                  @changed="
                    form.model.iratImmediateFeedback
                      ? (form.model.iratAllowChangeAnswersBeforeFinalSubmission = true)
                      : ''
                  "
                >
                </kr-toggle> -->
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratImmediateFeedback"
                                                v-model="form.model.iratImmediateFeedback"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.iratConfidenceBasedTesting ? true : undefined"
                                                @change="
                                                    form.model.iratImmediateFeedback
                                                        ? (form.model.iratAllowChangeAnswersBeforeFinalSubmission = false) &&
                                                          (form.model.iratConfidenceBasedTesting = false)
                                                        : (form.model.iratEnableHintAndExplanation = false)
                                                "
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <!-- <label style="height:20px;line-height:20px;margin-right:10px;">
                    No
                  </label> -->
                                        <label for="iratImmediateFeedback" class="control-label marginBottom0 paddingTop2"> Enable Immediate feedback </label>
                                    </div>

                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratEnableHintAndExplanation"
                                                v-model="form.model.iratEnableHintAndExplanation"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="!form.model.iratImmediateFeedback ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratEnableHintAndExplanation" class="control-label marginBottom0 paddingTop2">
                                            Shows hints and explanations for questions that have them
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                  :options="{ reverse: true }"
                  v-if="!form.model.iratImmediateFeedback"
                  name="iratConfidenceBasedTesting"
                  display="Confidence-Based Testing"
                  :form="form"
                  class="marginBottom15"
                >
                </kr-toggle> -->

                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratConfidenceBasedTesting"
                                                v-model="form.model.iratConfidenceBasedTesting"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.iratImmediateFeedback ? true : undefined"
                                                @change="form.model.iratConfidenceBasedTesting ? (form.model.iratImmediateFeedback = false) : ''"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <!-- <label style="height:20px;line-height:20px;margin-right:10px;">
                    No
                  </label> -->
                                        <label for="iratConfidenceBasedTesting" class="control-label marginBottom0 paddingTop2">
                                            Use Confidence-based Testing
                                        </label>
                                    </div>

                                    <div v-if="!hideSafeExamBrowserField" class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratAllowSafeExamBrowserOnly"
                                                v-model="form.model.iratAllowSafeExamBrowserOnly"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratAllowSafeExamBrowserOnly" class="control-label marginBottom0 paddingTop2">
                                            Students must answer using Safe Exam Browser
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratAllowPdfDownload"
                                                v-model="form.model.iratAllowPdfDownload"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratAllowPdfDownload" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print PDF documents
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="iratEnableImagePointerEvents"
                                                v-model="form.model.iratEnableImagePointerEvents"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="iratEnableImagePointerEvents" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print images from questions
                                        </label>
                                    </div>

                                    <!--<kr-toggle name="iratAllowStudentsToUploadFiles" display="Allow students upload files" :form="form" class="marginBottom15">
                </kr-toggle>	

                <kr-toggle name="iratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
                </kr-toggle>-->
                                </div>

                                <div class="marginTop30">
                                    <h3 class="panel-content-subHeader">Instructions</h3>

                                    <div>
                                        <kr-field
                                            :form="form"
                                            name="iratInstruction"
                                            :options="{
                                                htmlEditor: true,
                                                placeholder: 'Enter instructions for this activity here',
                                                hasLabel: false,
                                                showError: false,
                                            }"
                                        />
                                    </div>
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="iratAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h2 class="panel-content-subHeader">Student App Preview</h2>

                                <!-- <div id="message-area" aria-live="assertive">
                                    test irat
                                </div> -->

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="irat_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat?type=irat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            <h2 class="panel-content-subHeader">Behavior Settings</h2>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students can change their answers </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.iratAllowViewQuestionsAhead"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer questions in sequence </label>
                            </div>

                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowViewPreviousQuestions"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students can navigate to previously answered questions </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratRandomizeAnswerOptionOrder"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Randomise choices </label>
                            </div>

                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratHideQuestionOptionLabels"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Hide choice key (e.g. A, B, C ...) </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratImmediateFeedback"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Enable Immediate feedback </label>
                            </div>
                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratEnableHintAndExplanation"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Shows hints and explanations for questions that have them </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratConfidenceBasedTesting"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Use Confidence-based Testing </label>
                            </div>
                            <div v-if="!hideSafeExamBrowserField">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowSafeExamBrowserOnly"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer using Safe Exam Browser </label>
                            </div>
                            <div>
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowPdfDownload"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                            </div>
                            <div>
                                <label class="marginRight10">
                                    <template v-if="form.model.iratEnableImagePointerEvents"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                            </div>
                            <!-- <div class="marginBottom15">
              <label class="marginRight10">
                <template
                  v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"
                >
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Students can change their answers
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="!form.model.iratAllowViewQuestionsAhead">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Students must answer questions in sequence
              </label>
            </div>

            <div
              class="marginBottom15"
              v-if="
                !form.model.iratAllowChangeAnswersBeforeFinalSubmission &&
                  !form.model.iratAllowViewQuestionsAhead
              "
            >
              <label class="marginRight10">
                <template v-if="form.model.iratAllowViewPreviousQuestions">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Students can navigate to previously answered questions
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="form.model.iratRandomizeAnswerOptionOrder">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Randomise choices
              </label>
            </div>

            <div
              v-if="form.model.iratRandomizeAnswerOptionOrder"
            >
              <label class="marginRight10">
                <template v-if="form.model.iratHideQuestionOptionLabels">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Hide choice key (e.g. A, B, C ...)
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="form.model.iratImmediateFeedback">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Enable Immediate feedback
              </label>
            </div>

            <div class="marginBottom15 marginTop15">
              <label class="marginRight10">
                <template v-if="form.model.iratConfidenceBasedTesting">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Use Confidence-based Testing
              </label>
            </div> -->

                            <!--<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>-->
                        </div>

                        <div class="marginTop30">
                            <div>
                                <label class="control-label">INSTRUCTION</label>
                                <template v-if="form.model.iratInstruction">
                                    <div v-html="form.model.iratInstruction" />
                                </template>
                                <template v-else>
                                    <div>-</div>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <div class="control-label">Attachments</div>
                                <div>
                                    <div v-if="form.model.iratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in form.model.iratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </kr-panel>

        <!-- <kr-panel
      :withFooter="false"
      :options="{ canToggle: false }"
      v-if="activityObj.type == 'irat' || activityObj.type == 'iratntrat'"
      :class="{ marginTop20: pageType != 'form' }"
    >
      <template #title>
        <div class="col-xs-6">
          <template v-if="activityObj.type == 'iratntrat'">
            IRAT INSTRUCTIONS TO STUDENTS
          </template>
          <template v-else>
            INSTRUCTIONS TO STUDENTS
          </template>
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && iratMode == 'edit'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('irat')"
              aria-label="Update"
            >
              Update
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="iratMode = 'view'"
              aria-label="Cancel"
            >
              Cancel
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('irat')"
              aria-label="Update"
            >
              <i class="fa fa-floppy-o"></i>
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="iratMode = 'view'"
              aria-label="Cancel"
            >
              <i class="fa fa-caret-left"></i>
            </button>
          </div>
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && iratMode == 'view'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                iratMode = 'edit';
                tratMode = 'view';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                iratMode = 'edit';
                tratMode = 'view';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>
            </button>
          </div>
        </div>
      </template>
      <template #content>
        <template
          v-if="!isSet && iratMode == 'view' && pageType == 'confirmation'"
        >
          NOT SET
        </template>
        <template v-else>
          <div
            v-if="pageType == 'form' || iratMode == 'edit'"
          >
            <kr-field
              :form="form"
              name="iratInstruction"
              :options="{
                htmlEditor: true,
                placeholder: 'Enter instructions for this activity here',
                hasLabel: false,
                showError: false,
              }"
            ></kr-field>
          </div>
          <div v-else>
            <label class="control-label">INSTRUCTION</label>
            <template v-if="form.model.iratInstruction">
              <div v-html="form.model.iratInstruction"></div>
            </template>
            <template v-else>
              <div>-</div>
            </template>
          </div>

          <div
            class="marginTop15"
            v-if="pageType == 'form' || iratMode == 'edit'"
          >
            <kr-file-multiple
              :options="{ reverse: true }"
              :form="form"
              name="iratAttachments"
              @preview-attachment="showAttachment"
            ></kr-file-multiple>
          </div>
          <div class="marginTop15" v-else>
            <div class="control-label">
              ATTACHMENTS
            </div>
            <div>
              <div
                class="flex align-items"
                v-if="form.model.iratAttachments.length != 0"
              >
                <div class="col-xs-12">
                  <div
                    v-for="(file, idx) in form.model.iratAttachments"
                    class="flexSpaceBetween"
                    :key="idx"
                  >
                    <div>
                      <span>{{ idx + 1 }}.&nbsp;</span>
                      <span class="marginRight5">
                        <i class="fa fa-file-image-o"></i>
                      </span>
                      <a :href="file.url" target="_blank" download>{{
                        file.filename
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <div>-</div>
              </template>
            </div>
          </div>
        </template>
      </template>
    </kr-panel> -->

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='irat'||(activityObj.type=='iratntrat'&&pageType=='form')">
			<template #title>
				<div class="col-xs-6">
					IRAT SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&iratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('irat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('irat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratMode='view'" aria-label="Cancel">
				            <i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div>   
			    <div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&iratMode=='view'">
			        <div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratMode='edit';tratMode='view';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratMode='edit';tratMode='view';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div>          
			</template>
			<template #content>
				<template v-if="!isSet&&iratMode=='view'&&pageType=='confirmation'">
					NOT SET
				</template>
				<template v-else>
					<div class="control-label">
						WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="pageType=='form'||(pageType=='confirmation'&&iratMode=='edit')">
						<div class="col-xs-12 col-md-6 marginBottom30" :class="{'marginBottom30':pageType=='form'}">
							<kr-toggle v-if="!form.model.iratConfidenceBasedTesting" name="iratImmediateFeedback" display="Enable Immediate Feedback" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween"
								@changed="form.model.iratImmediateFeedback?form.model.iratAllowChangeAnswersBeforeFinalSubmission=true:''"
							>
							</kr-toggle>
							<div v-else class="flexSpaceBetween marginBottom15 marginTop15">
								<label class="control-label marginBottom0" style="marginTop:5px">
									Enable Immediate Feedback
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									No
								</label>
							</div>
							<kr-toggle v-if="!form.model.iratImmediateFeedback" name="iratConfidenceBasedTesting" display="Confidence-Based Testing" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<div v-else class="flexSpaceBetween marginBottom15">
								<label class="control-label marginBottom0" style="marginTop:5px">
									Confidence-Based Testing
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									No
								</label>
							</div>

							<kr-toggle name="iratAllowStudentsToUploadFiles" display="Allow students upload files" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle name="iratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle v-if="!form.model.iratImmediateFeedback" name="iratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>

							<div v-else class="flexSpaceBetween marginBottom15">
								<label class="control-label  marginBottom0" style="marginTop:5px">
									Allow students to change answers before final submission
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									Yes
								</label>
							</div>	

							<kr-toggle name="iratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15 flexSpaceBetween" :options="{trueval:false,falseval:true}">
							</kr-toggle>

							<kr-toggle v-if="!form.model.iratAllowViewQuestionsAhead" name="iratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle :options="{reverse:true}" name="iratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form" class="marginBottom15" @changed="!form.model.iratRandomizeAnswerOptionOrder?form.model.iratHideQuestionOptionLabels=false:form.model.iratHideQuestionOptionLabels=true">
							</kr-toggle>
							<kr-toggle v-if="form.model.iratRandomizeAnswerOptionOrder" name="iratHideQuestionOptionLabels" display="Hide answer choice key" :form="form" class="flexSpaceBetween">
							</kr-toggle>
						</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Enable Immediate Feedback
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratImmediateFeedback"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Confidence-Based Testing
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratConfidenceBasedTesting"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Allow students to change answers before final submission
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="!form.model.iratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15" v-if="!form.model.iratAllowViewQuestionsAhead">
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div v-if="form.model.iratRandomizeAnswerOptionOrder" class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Hide answer choice key
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratHideQuestionOptionLabels"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
					</div>
					<div class="marginBottom10" v-if="pageType=='form'||iratMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="iratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items" v-if="form.model.iratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.iratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="pageType=='form'||iratMode=='edit'">
						<kr-field :form="form" name="iratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.iratInstruction">
							<div v-html="form.model.iratInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>
				</template>
			</template>
		</kr-panel> -->

        <kr-panel
            v-if="activityObj.type == 'trat' || activityObj.type == 'iratntrat'"
            :with-footer="false"
            :options="{ canToggle: false }"
            :class="{ marginTop20: pageType != 'form' }"
        >
            <template #title>
                <div class="col-xs-6">TRAT Settings</div>
                <div v-if="pageType == 'confirmation' && tratMode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('trat')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="tratMode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('trat')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="tratMode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && tratMode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                iratMode = 'view';
                                tratMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                iratMode = 'view';
                                tratMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && tratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && tratMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <div>
                                    <h2 class="panel-content-subHeader">Behavior Settings</h2>
                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                name="tratAllowStudentsToUploadFiles"
                display="Allow students to upload files"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratAllowStudentsToUploadFiles"
                                                v-model="form.model.tratAllowStudentsToUploadFiles"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratAllowStudentsToUploadFiles" class="control-label marginBottom0 paddingTop2">
                                            Teams can upload files
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                name="tratAllowTeamClarifications"
                display="Allow team clarifications"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratAllowTeamClarifications"
                                                v-model="form.model.tratAllowTeamClarifications"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratAllowTeamClarifications" class="control-label marginBottom0 paddingTop2">
                                            Teams can submit clarifications for questions
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                name="tratIsSyncToTeamQuestion"
                display="During Team-based activities, team members will see what the team leader sees"
                :form="form"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratIsSyncToTeamQuestion"
                                                v-model="form.model.tratIsSyncToTeamQuestion"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratIsSyncToTeamQuestion" class="control-label marginBottom0 paddingTop2">
                                            Team members will see what the team reporter sees
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                name="tratAllowViewQuestionsAhead"
                display="Students must answer questions in sequence"
                :form="form"
                class="marginBottom15"
                :options="{ trueval: false, falseval: true, reverse: true }"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratAllowViewQuestionsAhead"
                                                v-model="form.model.tratAllowViewQuestionsAhead"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :true-value="false"
                                                :false-value="true"
                                                @input="waitToSave()"
                                                @change="form.model.tratAllowViewQuestionsAhead ? (form.model.tratAllowViewPreviousQuestions = true) : ''"
                                            />
                                        </div>
                                        <label for="tratAllowViewQuestionsAhead" class="control-label marginBottom0 paddingTop2">
                                            Teams must answer questions in sequence
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                v-if="!form.model.tratAllowViewQuestionsAhead"
                name="tratAllowViewPreviousQuestions"
                display="Allow students to view previous questions"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratAllowViewPreviousQuestions"
                                                v-model="form.model.tratAllowViewPreviousQuestions"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.tratAllowViewQuestionsAhead ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratAllowViewPreviousQuestions" class="control-label marginBottom0 paddingTop2">
                                            Teams can navigate to previously answered questions
                                        </label>
                                    </div>

                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratEnableHintAndExplanation"
                                                v-model="form.model.tratEnableHintAndExplanation"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratEnableHintAndExplanation" class="control-label marginBottom0 paddingTop2">
                                            Shows hints and explanations for questions that have them
                                        </label>
                                    </div>

                                    <div v-if="!hideSafeExamBrowserField" class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratAllowSafeExamBrowserOnly"
                                                v-model="form.model.tratAllowSafeExamBrowserOnly"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratAllowSafeExamBrowserOnly" class="control-label marginBottom0 paddingTop2">
                                            Teams must answer using Safe Exam Browser
                                        </label>
                                    </div>
                                    <!-- <div
                    class="marginBottom15 marginTop15 flex"
                  >
                    <div class="checkboxField marginRight10">
                      <input
                        type="checkbox"
                        class="checkbox-field"
                        aria-label="Allow students to download and/or print PDF documents"
                        v-model="form.model.tratAllowPdfDownload"
                        @input="waitToSave()"
                      />
                    </div>
                    <label
                      for="iratAllowPdfDownload"
                      class="control-label marginBottom0 paddingTop2"
                    >
                    Allow students to download and/or print PDF documents
                    </label>
                  </div> -->
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratllowPdfDownload"
                                                v-model="form.model.tratAllowPdfDownload"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratAllowPdfDownload" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print PDF documents
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratRandomizeAnswerOptionOrder"
                                                v-model="form.model.tratRandomizeAnswerOptionOrder"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @change="
                                                    form.model.tratRandomizeAnswerOptionOrder
                                                        ? (form.model.tratHideQuestionOptionLabels = true)
                                                        : (form.model.tratHideQuestionOptionLabels = false)
                                                "
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratRandomizeAnswerOptionOrder" class="control-label marginBottom0 paddingTop2"> Randomise choices </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratHideQuestionOptionLabels"
                                                v-model="form.model.tratHideQuestionOptionLabels"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="!form.model.tratRandomizeAnswerOptionOrder ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratHideQuestionOptionLabels" class="control-label marginBottom0 paddingTop2">
                                            Hide choice key (e.g. A, B, C ...)
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="tratEnableImagePointerEvents"
                                                v-model="form.model.tratEnableImagePointerEvents"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="tratEnableImagePointerEvents" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print images from questions
                                        </label>
                                    </div>
                                    <!--<kr-toggle name="tratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>-->

                                    <!--<kr-toggle name="tratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15">
							</kr-toggle>-->
                                </div>

                                <div class="marginTop30">
                                    <h3 class="panel-content-subHeader">Instructions</h3>
                                    <kr-field
                                        :form="form"
                                        name="tratInstruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="tratAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h2 class="panel-content-subHeader">Student App Preview</h2>

                                <!-- <div id="message-area" aria-live="assertive">
                                    test trat
                                </div> -->

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="trat_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat?type=trat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowStudentsToUploadFiles"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can upload files </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowTeamClarifications"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can submit clarifications for questions </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratIsSyncToTeamQuestion"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Team members will see what the team reporter sees </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.tratAllowViewQuestionsAhead"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Teams must answer questions in sequence </label>
                            </div>

                            <div class="marginLeft20 marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowViewPreviousQuestions"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can navigate to previously answered questions </label>
                            </div>
                        </div>

                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratEnableHintAndExplanation"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Shows hints and explanations for questions that have them </label>
                        </div>
                        <div v-if="!hideSafeExamBrowserField" class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratAllowSafeExamBrowserOnly"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Teams must answer using Safe Exam Browser </label>
                        </div>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratAllowPdfDownload"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                        </div>
                        <div>
                            <label class="marginRight10">
                                <template v-if="form.model.tratEnableImagePointerEvents"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                        </div>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratRandomizeAnswerOptionOrder"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Randomise choices </label>
                        </div>

                        <div class="marginBottom15 marginLeft20">
                            <label class="marginRight10">
                                <template v-if="form.model.tratHideQuestionOptionLabels"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Hide choice key (e.g. A, B, C ...) </label>
                        </div>
                        <div class="marginTop30">
                            <label class="control-label">INSTRUCTION</label>
                            <template v-if="form.model.tratInstruction">
                                <div v-html="form.model.tratInstruction" />
                            </template>
                            <template v-else>
                                <div>-</div>
                            </template>

                            <div class="marginTop15">
                                <div class="control-label">ATTACHMENTS</div>
                                <div>
                                    <div v-if="form.model.tratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in form.model.tratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="form.model.tratAllowStudentsToUploadFiles">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow students to upload files
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="form.model.tratAllowTeamClarifications">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow team clarifications
              </label>
            </div>

            <div>
              <label class="marginRight10">
                <template v-if="form.model.tratIsSyncToTeamQuestion">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                During Team-based activities, team members will see what the
                team leader sees
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="!form.model.tratAllowViewQuestionsAhead">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Students must answer questions in sequence
              </label>
            </div>

            <div
              class="marginBottom15"
              v-if="!form.model.tratAllowViewQuestionsAhead"
            >
              <label class="marginRight10">
                <template v-if="form.model.tratAllowViewPreviousQuestions">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow students to view previous questions
              </label>
            </div> -->

                        <!--<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>-->
                        <!--<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students to change answers before final submission
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>-->
                        <!--<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>-->
                    </div>
                </template>
            </template>
        </kr-panel>

        <!-- <kr-panel
      :withFooter="false"
      :options="{ canToggle: false }"
      v-if="activityObj.type == 'trat' || activityObj.type == 'iratntrat'"
      :class="{ marginTop20: pageType != 'form' }"
    >
      <template #title>
        <div class="col-xs-6">
          <template v-if="activityObj.type == 'iratntrat'">
            TRAT INSTRUCTIONS TO STUDENTS
          </template>
          <template v-else>
            INSTRUCTIONS TO STUDENTS
          </template>
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && tratMode == 'edit'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('trat')"
              aria-label="Update"
            >
              Update
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="tratMode = 'view'"
              aria-label="Cancel"
            >
              Cancel
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('trat')"
              aria-label="Update"
            >
              <i class="fa fa-floppy-o"></i>
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="tratMode = 'view'"
              aria-label="Cancel"
            >
              <i class="fa fa-caret-left"></i>
            </button>
          </div>
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && tratMode == 'view'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                iratMode = 'view';
                tratMode = 'edit';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                iratMode = 'view';
                tratMode = 'edit';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>
            </button>
          </div>
        </div>
      </template>
      <template #content>
        <template
          v-if="!isSet && tratMode == 'view' && pageType == 'confirmation'"
        >
          NOT SET
        </template>
        <template v-else>
          <div
            v-if="pageType == 'form' || tratMode == 'edit'"
          >
            <kr-field
              :form="form"
              name="tratInstruction"
              :options="{
                htmlEditor: true,
                placeholder: 'Enter instructions for this activity here',
                hasLabel: false,
                showError: false,
              }"
            ></kr-field>
          </div>
          <div v-else>
            <label class="control-label">INSTRUCTION</label>
            <template v-if="form.model.tratInstruction">
              <div v-html="form.model.tratInstruction"></div>
            </template>
            <template v-else>
              <div>-</div>
            </template>
          </div>

          <div
            class="marginTop15"
            v-if="pageType == 'form' || tratMode == 'edit'"
          >
            <kr-file-multiple
              :options="{ reverse: true }"
              :form="form"
              name="tratAttachments"
              @preview-attachment="showAttachment"
            ></kr-file-multiple>
          </div>
          <div class="marginTop15" v-else>
            <div class="control-label">
              ATTACHMENTS
            </div>
            <div>
              <div
                class="flex align-items"
                v-if="form.model.tratAttachments.length != 0"
              >
                <div class="col-xs-12">
                  <div
                    v-for="(file, idx) in form.model.tratAttachments"
                    class="flexSpaceBetween"
                    :key="idx"
                  >
                    <div>
                      <span>{{ idx + 1 }}.&nbsp;</span>
                      <span class="marginRight5">
                        <i class="fa fa-file-image-o"></i>
                      </span>
                      <a :href="file.url" target="_blank" download>{{
                        file.filename
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <div>-</div>
              </template>
            </div>
          </div>
        </template>
      </template>
    </kr-panel> -->

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='trat'||(activityObj.type=='iratntrat'&&pageType=='form')">
			<template #title>
				<div class="col-xs-6">
					TRAT SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&tratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('trat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="tratMode='view'" aria-label="Cancel">
				        	Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('trat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="tratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&tratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratMode='view';tratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratMode='view';tratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&tratMode=='view'&&pageType=='confirmation'">
					NOT SET
				</template>
				<template v-else>
					<div class="control-label">
						WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="pageType=='form'||(pageType=='confirmation'&&tratMode=='edit')">
						<div class="col-xs-12 col-md-6 marginBottom30">
							<kr-toggle name="tratAllowStudentsToUploadFiles" display="Allow students to upload files" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween">
							</kr-toggle>	

							<kr-toggle name="tratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15 flexSpaceBetween" :options="{trueval:false,falseval:true}">
							</kr-toggle>

							<kr-toggle v-if="!form.model.tratAllowViewQuestionsAhead" name="tratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle name="tratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle name="tratAllowTeamClarifications" display="Allow team clarifications" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>

							<kr-toggle name="tratIsSyncToTeamQuestion" display="During Team-based activities, team members will see what the team leader sees" :form="form" class="flexSpaceBetween">
							</kr-toggle>
						</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Allow students to upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students to change answers before final submission
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>


						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="!form.model.tratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15" v-if="!form.model.tratAllowViewQuestionsAhead">
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Allow team clarifications
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowTeamClarifications"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	During Team-based activities, team members will see what the team leader sees
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratIsSyncToTeamQuestion"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						
					</div>
					<div class="marginBottom10" v-if="pageType=='form'||iratMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="tratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.tratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.tratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="pageType=='form'||tratMode=='edit'">
						<kr-field :form="form" name="tratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.tratInstruction">
							<div v-html="form.model.tratInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>
				</template>
			</template>
		</kr-panel> -->

        <kr-panel v-if="activityObj.type == 'prework'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <template v-if="!isSet && preworkMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div class="col-xs-6">Prework Settings</div>
                    <div v-if="pageType == 'confirmation' && preworkMode == 'edit'" class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('prework')">Update</button>
                            <button class="btn btn-default mainBtn" exact @click.prevent="preworkMode = 'view'">Cancel</button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('prework')">
                                <i class="fa fa-floppy-o" aria-hidden="true" />
                            </button>
                            <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="preworkMode = 'view'">
                                <i class="fa fa-caret-left" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div v-if="pageType == 'confirmation' && preworkMode == 'view'" class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                class="btn btn-default mainBtn"
                                @click.prevent="
                                    preworkMode = 'edit';
                                    init();
                                "
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                class="btn btn-default mainBtn"
                                aria-label="Edit"
                                @click.prevent="
                                    preworkMode = 'edit';
                                    init();
                                "
                            >
                                <i class="fa fa-pencil-square-o" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </template>
            </template>
            <template #content>
                <template v-if="!isSet && preworkMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || preworkMode == 'edit'">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <div>
                                    <h2 class="panel-content-subHeader marginBottom15">Behavior Settings</h2>
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="preworkAllowPdfDownload"
                                                v-model="form.model.preworkAllowPdfDownload"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="preworkAllowPdfDownload" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print PDF documents
                                        </label>
                                    </div>
                                    <h2 class="panel-content-subHeader">INSTRUCTIONS</h2>
                                    <kr-field
                                        :form="form"
                                        name="preworkInstruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h2 class="panel-content-subHeader marginBottom15">Behavior Settings</h2>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.preworkAllowPdfDownload"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                        </div>
                        <label class="control-label">INSTRUCTION</label>
                        <template v-if="form.model.preworkInstruction">
                            <div v-html="form.model.preworkInstruction" />
                        </template>
                        <template v-else>
                            <div>-</div>
                        </template>
                    </div>

                    <div v-if="pageType == 'form' || preworkMode == 'edit'" class="marginTop15">
                        <kr-file-multiple :options="{ reverse: true }" :form="form" name="preworkAttachments" @preview-attachment="showAttachment" />
                    </div>
                    <div v-else class="marginTop15">
                        <div class="control-label">ATTACHMENTS</div>
                        <div>
                            <div v-if="form.model.preworkAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in form.model.preworkAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.&nbsp;</span>
                                            <span class="marginRight10">
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='prework'">
			<template v-if="!isSet&&preworkMode=='view'&&pageType=='confirmation'">
				NOT SET
			</template>
			<template v-else>
				<template #title>
					<div class="col-xs-6">
						PREWORK SETTINGS
					</div>
					<div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&preworkMode=='edit'">
						<div class="hidden-xs hidden-sm">
				            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('prework')" aria-label="Update">
				            	Update
				            </button>
				            <button class="btn btn-default mainBtn" exact @click.prevent="preworkMode='view'" aria-label="Cancel">
					            Cancel
					        </button>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('prework')" aria-label="Update">
				            	<i class="fa fa-floppy-o"></i>
				            </button>
				            <button class="btn btn-default mainBtn" exact @click.prevent="preworkMode='view'" aria-label="Update">
					            	<i class="fa fa-caret-left"></i>
					        </button>
				        </div>
				    </div> 
				    <div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&preworkMode=='view'">
						<div class="hidden-xs hidden-sm">
				            <button class="btn btn-default mainBtn" @click.prevent="preworkMode='edit';init()" aria-label="Edit">
								<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
							</button>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button class="btn btn-default mainBtn" @click.prevent="preworkMode='edit';init()" aria-label="Edit">
								<i class="fa fa-pencil-square-o"></i>
							</button>
				        </div>
				    </div> 
				</template>
				<template #content>
					<div class="marginBottom10" v-if="pageType=='form'||preworkMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="preworkAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.preworkAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.preworkAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="pageType=='form'||preworkMode=='edit'">
						<kr-field :form="form" name="preworkInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.preworkInstruction">
							<div v-html="form.model.preworkInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>
				</template>
			</template>
		</kr-panel> -->

        <!--<kr-panel :withFooter="false" :options="{canToggle:false}" v-if="false&&activityObj.type=='iratntrat'&&pageType=='confirmation'">
			<template #title>
				<div class="col-xs-6">
					IRAT INTERACTION SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="iratandtratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="iratandtratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&iratandtratMode=='view'">
					<div>
						NOT SET
					</div>
				</template>
				<template v-else>
					<template v-if="iratandtratMode=='edit'">
						<div>
							<kr-toggle :options="{reverse:true}" v-if="!form.model.iratConfidenceBasedTesting" name="iratImmediateFeedback" display="Enable Immediate Feedback" :form="form" class="marginBottom15"
								@changed="form.model.iratImmediateFeedback?form.model.iratAllowChangeAnswersBeforeFinalSubmission=true:''"
							>
							</kr-toggle>
							<div v-else class="marginBottom15">
								<label style="height:20px;line-height:20px;margin-right:10px;">
									No
								</label>
								<label class="control-label marginBottom0" style="marginTop:5px">
									Enable Immediate Feedback
								</label>
							</div>
							<kr-toggle :options="{reverse:true}" v-if="!form.model.iratImmediateFeedback" name="iratConfidenceBasedTesting" display="Confidence-Based Testing" :form="form" class="marginBottom15">
							</kr-toggle>
							<div v-else class="flexSpaceBetween marginBottom15">
								<label style="height:20px;line-height:20px;margin-right:10px;">
									No
								</label>
								<label class="control-label marginBottom0" style="marginTop:5px">
									Confidence-Based Testing
								</label>
							</div>

							<kr-toggle name="iratAllowStudentsToUploadFiles" display="Allow students upload files" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle name="iratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle :options="{reverse:true}" v-if="!form.model.iratImmediateFeedback" name="iratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15">
							</kr-toggle>

							<div v-else class="flexSpaceBetween marginBottom15">
								<label style="height:20px;line-height:20px;margin-right:10px;">
									Yes
								</label>
								<label class="control-label  marginBottom0" style="marginTop:5px">
									Allow students to change answers before final submission
								</label>
							</div>	

							<kr-toggle name="iratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15" :options="{trueval:false,falseval:true,reverse:true}">
							</kr-toggle>

							<kr-toggle :options="{reverse:true}" v-if="!form.model.iratAllowViewQuestionsAhead" name="iratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle :options="{reverse:true}"  name="iratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form">
							</kr-toggle>
						</div>
					</template>
					<div v-else>
						<div class="marginBottom15">
							<label class="marginRight10">
								<template v-if="form.model.iratImmediateFeedback"> YES </template>
								<template v-else>NO</template>
							</label>
							<label class="control-label fontBlack">
								Enable Immediate Feedback
							</label>
						</div>
						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="form.model.iratConfidenceBasedTesting"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Confidence-Based Testing
						   	</label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Allow students to change answers before final submission
						   	</label>
						</div>

						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="!form.model.iratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						</div>

						<div class="marginBottom15" v-if="!form.model.iratAllowViewQuestionsAhead">
						    <label class="marginRight10">
						    	<template v-if="form.model.iratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						</div>
						<div>
						    <label class="marginRight10">
						    	<template v-if="form.model.iratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						</div>
					</div>

				</template>

			</template>
		</kr-panel>

		<kr-panel :withFooter="false" :options="{canToggle:false}" v-if="false&&activityObj.type=='iratntrat'&&pageType=='confirmation'" :class="{'marginTop20':pageType!='form'}">
			<template #title>
				<div class="col-xs-6">
					IRAT INSTRUCTIONS TO STUDENTS
				</div>
				<div class="col-xs-6 flexRight" v-if="iratandtratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="iratandtratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&iratandtratMode=='view'">
					<div>
						NOT SET
					</div>
				</template>
				<template v-else>

					<div v-if="iratandtratMode=='edit'">
						<kr-field :form="form" name="iratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else>
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.iratInstruction">
							<div v-html="form.model.iratInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>

					<div class="marginTop15" v-if="iratandtratMode=='edit'">
						<kr-file-multiple :options="{reverse:true}" :form="form" name="iratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div class="marginTop15" v-else>
						<div class="control-label">
							ATTACHMENTS
						</div>
						<div>
							<div class="flex align-items" v-if="form.model.iratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.iratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.&nbsp;</span>
									    	<span class="marginRight5">
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a class="fontBlack" :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>

				</template>

			</template>
		</kr-panel>

		<kr-panel :withFooter="false" :options="{canToggle:false}" v-if="false&&activityObj.type=='iratntrat'&&pageType=='confirmation'" :class="{'marginTop20':pageType!='form'}">
			<template #title>
				<div class="col-xs-6">
					TRAT INTERACTION SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="iratandtratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="iratandtratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&iratandtratMode=='view'">
					<div>
						NOT SET
					</div>
				</template>
				<template v-else>
					<template v-if="iratandtratMode=='edit'">
						<div>
							<kr-toggle :options="{reverse:true}" name="tratAllowStudentsToUploadFiles" display="Allow students to upload files" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle name="tratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15" :options="{trueval:false,falseval:true,reverse:true}">
							</kr-toggle>

							<kr-toggle :options="{reverse:true}" v-if="!form.model.tratAllowViewQuestionsAhead" name="tratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle name="tratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle :options="{reverse:true}" name="tratAllowTeamClarifications" display="Allow team clarifications" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle :options="{reverse:true}" name="tratIsSyncToTeamQuestion" display="During Team-based activities, team members will see what the team leader sees" :form="form">
							</kr-toggle>
							
						</div>
					</template>
					<div v-else>
						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="form.model.tratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Allow students to upload files
						   	</label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students to change answers before final submission
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="!form.model.tratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						</div>

						<div class="marginBottom15" v-if="!form.model.tratAllowViewQuestionsAhead">
						    <label class="marginRight10">
						    	<template v-if="form.model.tratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="marginBottom15">
						    <label class="marginRight10">
						    	<template v-if="form.model.tratAllowTeamClarifications"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	Allow team clarifications
						    </label>
						</div>
						<div>
						    <label class="marginRight10">
						    	<template v-if="form.model.tratIsSyncToTeamQuestion"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						    <label class="control-label fontBlack">
						    	During Team-based activities, team members will see what the team leader sees
						    </label>
						</div>
					</div>

				</template>

			</template>
		</kr-panel>

		<kr-panel :withFooter="false" :options="{canToggle:false}" v-if="false&&activityObj.type=='iratntrat'&&pageType=='confirmation'" :class="{'marginTop20':pageType!='form'}">
			<template #title>
				<div class="col-xs-6">
					TRAT INSTRUCTIONS TO STUDENTS
				</div>
				<div class="col-xs-6 flexRight" v-if="iratandtratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="iratandtratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&iratandtratMode=='view'">
					<div>
						NOT SET
					</div>
				</template>
				<template v-else>
					
					<div v-if="iratandtratMode=='edit'">
						<kr-field :form="form" name="tratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else>
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.tratInstruction">
							<div v-html="form.model.tratInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>

					<div class="marginTop15" v-if="iratandtratMode=='edit'">
						<kr-file-multiple :options="{reverse:true}" :form="form" name="tratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div class="marginTop15" v-else>
						<div class="control-label">
							ATTACHMENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.tratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.tratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.&nbsp;</span>
									    	<span class="marginRight5">
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					
				</template>

			</template>
		</kr-panel>!-->

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='iratntrat'&&pageType=='confirmation'">
			<template #title>
				<div class="col-xs-6">
					IRAT AND TRAT SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="iratandtratMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('iratntrat')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="iratandtratMode='view'" aria-label="Cancel">
				            	<i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="iratandtratMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="iratandtratMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&iratandtratMode=='view'">
					<div>
						NOT SET
					</div>
				</template>
				<template v-else>
					<div class="marginBottom10">
						<h3 class="marginTop0">IRAT SETTINGS</h3>
					</div>
					<div class="control-label">
						WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="iratandtratMode=='edit'">
						<div class="col-xs-12 col-md-6 marginBottom30">
							<kr-toggle v-if="!form.model.iratConfidenceBasedTesting" name="iratImmediateFeedback" display="Enable Immediate Feedback" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween"
								@changed="form.model.iratImmediateFeedback?form.model.iratAllowChangeAnswersBeforeFinalSubmission=true:''"
							>
							</kr-toggle>
							<div v-else class="flexSpaceBetween marginBottom15 marginTop15">
								<label class="control-label marginBottom0" style="marginTop:5px">
									Enable Immediate Feedback
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									No
								</label>
							</div>
							<kr-toggle v-if="!form.model.iratImmediateFeedback" name="iratConfidenceBasedTesting" display="Confidence-Based Testing" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<div v-else class="flexSpaceBetween marginBottom15">
								<label class="control-label marginBottom0" style="marginTop:5px">
									Confidence-Based Testing
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									No
								</label>
							</div>

							<kr-toggle name="iratAllowStudentsToUploadFiles" display="Allow students upload files" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle name="iratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>	

							<kr-toggle v-if="!form.model.iratImmediateFeedback" name="iratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>

							<div v-else class="flexSpaceBetween marginBottom15">
								<label class="control-label  marginBottom0" style="marginTop:5px">
									Allow students to change answers before final submission
								</label>
								<label class="static-label-toggle" style="height:20px;line-height:20px">
									Yes
								</label>
							</div>	

							<kr-toggle name="iratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15 flexSpaceBetween" :options="{trueval:false,falseval:true}">
							</kr-toggle>

							<kr-toggle v-if="!form.model.iratAllowViewQuestionsAhead" name="iratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle name="iratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form" class="marginBottom15flexSpaceBetween" @changed="!form.model.iratRandomizeAnswerOptionOrder?form.model.iratHideQuestionOptionLabels=false:''">
							</kr-toggle>
							<kr-toggle v-if="form.model.iratRandomizeAnswerOptionOrder" name="iratHideQuestionOptionLabels" display="Hide answer choice key" :form="form" class="flexSpaceBetween">
							</kr-toggle>
						</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
							<label class="control-label fontBlack">
								Enable Immediate Feedback
							</label>
							<label class="static-label-toggle">
								<template v-if="form.model.iratImmediateFeedback"> YES </template>
								<template v-else>NO</template>
							</label>
						</div>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Confidence-Based Testing
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratConfidenceBasedTesting"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Allow students to change answers before final submission
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="!form.model.iratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15" v-if="!form.model.iratAllowViewQuestionsAhead">
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div v-if="form.model.iratRandomizeAnswerOptionOrder" class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Hide answer choice key
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.iratHideQuestionOptionLabels"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
					</div>
					<div class="marginBottom10" v-if="iratandtratMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="iratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items" v-if="form.model.iratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.iratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a class="fontBlack" :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="iratandtratMode=='edit'">
						<kr-field :form="form" name="iratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.iratInstruction">
							<div v-html="form.model.iratInstruction"></div>
						</template>
						<template v-else>
							-
						</template>
					</div>

					<div class="marginTop30 marginBottom10">
						<h3 class="marginTop0">TRAT SETTINGS</h3>
					</div>
					<div class="control-label">
						WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="iratandtratMode=='edit'">
						<div class="col-xs-12 col-md-6 marginBottom30">
							<kr-toggle name="tratAllowStudentsToUploadFiles" display="Allow students to upload files" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>	

							<kr-toggle name="tratLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="tratAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15 flexSpaceBetween" :options="{trueval:false,falseval:true}">
							</kr-toggle>

							<kr-toggle v-if="!form.model.tratAllowViewQuestionsAhead" name="tratAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle name="tratRandomizeAnswerOptionOrder" display="Randomize answer options order" :form="form" class="marginBottom15">
							</kr-toggle>
							<kr-toggle name="tratAllowTeamClarifications" display="Allow team clarifications" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle name="tratIsSyncToTeamQuestion" display="During Team-based activities, team members will see what the team leader sees" :form="form" class="flexSpaceBetween">
							</kr-toggle>
							
						</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Allow students to upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Allow students to change answers before final submission
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="!form.model.tratAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15" v-if="!form.model.tratAllowViewQuestionsAhead">
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Randomize answer option order
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratRandomizeAnswerOptionOrder"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Allow team clarifications
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratAllowTeamClarifications"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	During Team-based activities, team members will see what the team leader sees
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.tratIsSyncToTeamQuestion"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
					</div>
					<div class="marginBottom10" v-if="iratandtratMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="tratAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.tratAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.tratAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="iratandtratMode=='edit'">
						<kr-field :form="form" name="tratInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.tratInstruction">
							<div v-html="form.model.tratInstruction"></div>
						</template>
						<template v-else>
							-
						</template>
					</div>
				</template>

			</template>
		</kr-panel> -->

        <kr-panel v-if="activityObj.type == 'application'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">Application Case Settings</div>
                <div v-if="pageType == 'confirmation' && applicationMode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('application')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="applicationMode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('application')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="applicationMode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && applicationMode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                applicationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                applicationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && applicationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && applicationMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <div>
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationType"
                                                v-model="form.model.others.applicationType"
                                                type="checkbox"
                                                class="checkbox-field"
                                                true-value="team"
                                                false-value="individual"
                                                @change="changedApplicationType()"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationType" class="control-label marginBottom0 paddingTop2"> This is a team-based activity </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                v-if="activityObj.others.applicationType == 'team'"
                name="applicationIsSyncToTeamQuestion"
                display="During Team-based activities, team members will see what the team leader sees"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 marginTop15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationIsSyncToTeamQuestion"
                                                v-model="form.model.applicationIsSyncToTeamQuestion"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.others.applicationType != 'team' ? true : undefined"
                                            />
                                        </div>
                                        <label for="applicationIsSyncToTeamQuestion" class="control-label marginBottom0 paddingTop2">
                                            Team members will see what the team reporter sees
                                        </label>
                                    </div>

                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="isApplicationGraded"
                                                v-model="form.model.others.isApplicationGraded"
                                                type="checkbox"
                                                class="checkbox-field"
                                            />
                                        </div>
                                        <label for="isApplicationGraded" class="control-label marginBottom0 paddingTop2"> This activity is graded </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                name="applicationAllowStudentsToUploadFiles"
                display="Allow students to upload files"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationAllowStudentsToUploadFiles"
                                                v-model="form.model.applicationAllowStudentsToUploadFiles"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationAllowStudentsToUploadFiles" class="control-label marginBottom0 paddingTop2">
                                            <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                            <template v-else> Students </template>
                                            can upload files
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                name="applicationAllowChangeAnswersBeforeFinalSubmission"
                display="Allow students to change answers before final submission"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationAllowChangeAnswersBeforeFinalSubmission"
                                                v-model="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationAllowChangeAnswersBeforeFinalSubmission" class="control-label marginBottom0 paddingTop2">
                                            <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                            <template v-else> Students </template>
                                            can change their answers
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                name="applicationAllowViewQuestionsAhead"
                display="Students must answer questions in sequence"
                :form="form"
                class="marginBottom15"
                :options="{ trueval: false, falseval: true, reverse: true }"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationAllowViewQuestionsAhead"
                                                v-model="form.model.applicationAllowViewQuestionsAhead"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :true-value="false"
                                                :false-value="true"
                                                @input="waitToSave()"
                                                @change="
                                                    form.model.applicationAllowViewQuestionsAhead
                                                        ? (form.model.applicationAllowViewPreviousQuestions = true)
                                                        : ''
                                                "
                                            />
                                        </div>
                                        <label for="applicationAllowViewQuestionsAhead" class="control-label marginBottom0 paddingTop2">
                                            <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                            <template v-else> Students </template>
                                            must answer questions in sequence
                                        </label>
                                    </div>

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                v-if="
                  !form.model
                    .applicationAllowChangeAnswersBeforeFinalSubmission &&
                    !form.model.applicationAllowViewQuestionsAhead
                "
                name="applicationAllowViewPreviousQuestions"
                display="Allow students to view previous questions"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle>  -->
                                    <div class="marginBottom15 flex marginLeft20">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationAllowViewPreviousQuestions"
                                                v-model="form.model.applicationAllowViewPreviousQuestions"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.applicationAllowViewQuestionsAhead ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationAllowViewPreviousQuestions" class="control-label marginBottom0 paddingTop2">
                                            <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                            <template v-else> Students </template>
                                            can navigate to previously answered questions
                                        </label>
                                    </div>

                                    <!--<div class="marginBottom15 flex">
                    <div class="checkboxField marginRight10">
                      <input
                        id="applicationRandomizeAnswerOptionOrder"
                        type="checkbox"
                        class="checkbox-field"
                        aria-label="Randomise choices for MCQ"
                        v-model="
                          form.model.applicationRandomizeAnswerOptionOrder
                        "
                        @change="
                          form.model.applicationRandomizeAnswerOptionOrder
                            ? (form.model.applicationHideQuestionOptionLabels = true)
                            : (form.model.applicationHideQuestionOptionLabels = false)
                        "
                        @input="waitToSave()"
                      />
                    </div>
                    <label
                      for="applicationRandomizeAnswerOptionOrder"
                      class="control-label marginBottom0 paddingTop2"
                    >
                      Randomise choices for MCQ
                    </label>
                  </div>-->

                                    <!--<div class="marginBottom15 flex">
                    <div class="checkboxField marginRight10">
                      <input
                        id="applicationHideQuestionOptionLabels"
                        type="checkbox"
                        class="checkbox-field"
                        aria-label="Hide choice key (e.g. A, B, C ...)"
                        v-model="form.model.applicationHideQuestionOptionLabels"
                        :disabled="
                          !form.model.applicationRandomizeAnswerOptionOrder
                        "
                        @input="waitToSave()"
                      />
                    </div>
                    <label
                      for="applicationHideQuestionOptionLabels"
                      class="control-label marginBottom0 paddingTop2"
                    >
                      Hide choice key (e.g. A, B, C ...)
                    </label>
                  </div>-->

                                    <!-- <kr-toggle
                :options="{ reverse: true }"
                v-if="activityObj.others.applicationType != 'team'"
                name="applicationConfidenceBasedTesting"
                display="Confidence-Based Testing"
                :form="form"
                class="marginBottom15"
              >
              </kr-toggle> -->
                                    <div class="marginBottom15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationConfidenceBasedTesting"
                                                v-model="form.model.applicationConfidenceBasedTesting"
                                                type="checkbox"
                                                class="checkbox-field"
                                                :disabled="form.model.others.applicationType == 'team' ? true : undefined"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationConfidenceBasedTesting" class="control-label marginBottom0 paddingTop2">
                                            Use Confidence-based Testing for MCQ
                                        </label>
                                    </div>
                                    <div v-if="!hideSafeExamBrowserField" class="flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationAllowSafeExamBrowserOnly"
                                                v-model="form.model.applicationAllowSafeExamBrowserOnly"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationAllowSafeExamBrowserOnly" class="control-label marginBottom0 paddingTop2">
                                            <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                            <template v-else> Students </template>
                                            must answer using Safe Exam Browser
                                        </label>
                                    </div>
                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                v-model="form.model.applicationAllowPdfDownload"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationAllowPdfDownload" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print PDF documents
                                        </label>
                                    </div>

                                    <div class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationEnableImagePointerEvents"
                                                v-model="form.model.applicationEnableImagePointerEvents"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationEnableImagePointerEvents" class="control-label marginBottom0 paddingTop2">
                                            Allow students to download and/or print images from questions
                                        </label>
                                    </div>
                                    <div v-if="hasMcqmQuestions" class="marginBottom15 marginTop15 flex">
                                        <div class="checkboxField marginRight10">
                                            <input
                                                id="applicationShowRequiredOptionsForMcqm"
                                                v-model="form.model.applicationShowRequiredOptionsForMcqm"
                                                type="checkbox"
                                                class="checkbox-field"
                                                @input="waitToSave()"
                                            />
                                        </div>
                                        <label for="applicationShowRequiredOptionsForMcqm" class="control-label marginBottom0 paddingTop2">
                                            For MCQM, require to select exact number of correct answers
                                        </label>
                                    </div>

                                    <!--<kr-toggle name="applicationLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>-->
                                </div>

                                <div class="marginTop30">
                                    <kr-field
                                        :form="form"
                                        name="applicationInstruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="applicationAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h2 class="panel-content-subHeader">Student App Preview</h2>

                                <!-- <div id="message-area" aria-live="assertive">
                                    test
                                </div> -->

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="application_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.others.applicationType == 'team'"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> This is a team-based activity </label>
                            </div>

                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationIsSyncToTeamQuestion"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Team members will see what the team reporter sees </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.others.isApplicationGraded"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> This activity is graded </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowStudentsToUploadFiles"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> can upload files
                                </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> can change their answers
                                </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.applicationAllowViewQuestionsAhead"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> must answer questions in sequence
                                </label>
                            </div>
                            <!--<div class="marginBottom15">
                <label class="marginRight10">
                  <template
                    v-if="form.model.applicationRandomizeAnswerOptionOrder"
                  >
                    YES
                  </template>
                  <template v-else>NO</template>
                </label>
                <label class="control-label fontBlack">
                  Randomise choices for MCQ
                </label>
              </div>-->

                            <!--<div class="marginBottom15">
                <label class="marginRight10">
                  <template
                    v-if="form.model.applicationHideQuestionOptionLabels"
                  >
                    YES
                  </template>
                  <template v-else>NO</template>
                </label>
                <label class="control-label fontBlack">
                  Hide choice key (e.g. A, B, C ...)
                </label>
              </div>-->

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationConfidenceBasedTesting"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Use Confidence-based Testing for MCQ </label>
                            </div>

                            <div v-if="!hideSafeExamBrowserField" class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowSafeExamBrowserOnly"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer using Safe Exam Browser </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowPdfDownload"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationEnableImagePointerEvents"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> to download and/or print images from questions
                                </label>
                            </div>
                            <div v-if="hasMcqmQuestions">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationShowRequiredOptionsForMcqm"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> For MCQM, require to select exact number of correct answers </label>
                            </div>
                        </div>

                        <div class="marginTop30">
                            <div>
                                <label class="control-label">INSTRUCTION</label>
                                <template v-if="form.model.applicationInstruction">
                                    <div v-html="form.model.applicationInstruction" />
                                </template>
                                <template v-else>
                                    <div>-</div>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <div class="control-label">ATTACHMENTS</div>
                                <div>
                                    <div v-if="form.model.applicationAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in form.model.applicationAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="marginBottom15">
              <label class="marginRight10">
                <template
                  v-if="form.model.applicationAllowStudentsToUploadFiles"
                >
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow students to upload files
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template
                  v-if="
                    form.model
                      .applicationAllowChangeAnswersBeforeFinalSubmission
                  "
                >
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow students to change answers before final submission
              </label>
            </div>

            <div class="marginBottom15">
              <label class="marginRight10">
                <template v-if="!form.model.applicationAllowViewQuestionsAhead">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Students must answer questions in sequence
              </label>
            </div>

            <div
              class="marginBottom15"
              v-if="
                !form.model
                  .applicationAllowChangeAnswersBeforeFinalSubmission &&
                  !form.model.applicationAllowViewQuestionsAhead
              "
            >
              <label class="marginRight10">
                <template
                  v-if="form.model.applicationAllowViewPreviousQuestions"
                >
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Allow students to view previous questions
              </label>
            </div>

            <div
              v-if="activityObj.others.applicationType != 'team'"
              class="marginBottom15 marginTop15"
            >
              <label class="marginRight10">
                <template v-if="form.model.applicationConfidenceBasedTesting">
                  YES
                </template>
                <template v-else>NO</template>
              </label>
              <label class="control-label fontBlack">
                Confidence-Based Testing
              </label>
            </div> -->

                        <!--<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>-->
                    </div>
                </template>
            </template>
        </kr-panel>

        <!-- <kr-panel
      :withFooter="false"
      :options="{ canToggle: false }"
      v-if="activityObj.type == 'application'"
      :class="{ marginTop20: pageType != 'form' }"
    >
      <template #title>
        <div class="col-xs-6">
          INSTRUCTIONS TO STUDENTS
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && applicationMode == 'edit'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('application')"
              aria-label="Update"
            >
              Update
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="applicationMode = 'view'"
              aria-label="Cancel"
            >
              Cancel
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-success mainBtn"
              @click.prevent="submitActivitySettings('application')"
              aria-label="Update"
            >
              <i class="fa fa-floppy-o"></i>
            </button>
            <button
              class="btn btn-default mainBtn"
              exact
              @click.prevent="applicationMode = 'view'"
              aria-label="Cancel"
            >
              <i class="fa fa-caret-left"></i>
            </button>
          </div>
        </div>
        <div
          class="col-xs-6 flexRight"
          v-if="pageType == 'confirmation' && applicationMode == 'view'"
        >
          <div class="hidden-xs hidden-sm">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                applicationMode = 'edit';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
            </button>
          </div>
          <div class="hidden-md hidden-lg">
            <button
              class="btn btn-default mainBtn"
              @click.prevent="
                applicationMode = 'edit';
                init();
              "
              aria-label="Edit"
            >
              <i class="fa fa-pencil-square-o"></i>
            </button>
          </div>
        </div>
      </template>
      <template #content>
        <template
          v-if="
            !isSet && applicationMode == 'view' && pageType == 'confirmation'
          "
        >
          NOT SET
        </template>
        <template v-else>
          <div
            v-if="pageType == 'form' || applicationMode == 'edit'"
          >
            <kr-field
              :form="form"
              name="applicationInstruction"
              :options="{
                htmlEditor: true,
                placeholder: 'Enter instructions for this activity here',
                hasLabel: false,
                showError: false,
              }"
            ></kr-field>
          </div>
          <div v-else>
            <label class="control-label">INSTRUCTION</label>
            <template v-if="form.model.applicationInstruction">
              <div v-html="form.model.applicationInstruction"></div>
            </template>
            <template v-else>
              <div>-</div>
            </template>
          </div>
          <div
            class="marginTop15"
            v-if="pageType == 'form' || applicationMode == 'edit'"
          >
            <kr-file-multiple
              :options="{ reverse: true }"
              :form="form"
              name="applicationAttachments"
              @preview-attachment="showAttachment"
            ></kr-file-multiple>
          </div>
          <div v-else class="marginTop15">
            <div class="control-label">
              ATTACHMENTS
            </div>
            <div>
              <div
                class="flex align-items"
                v-if="form.model.applicationAttachments.length != 0"
              >
                <div class="col-xs-12">
                  <div
                    v-for="(file, idx) in form.model.applicationAttachments"
                    class="flexSpaceBetween"
                    :key="idx"
                  >
                    <div>
                      <span>{{ idx + 1 }}.&nbsp;</span>
                      <span class="marginRight5">
                        <i class="fa fa-file-image-o"></i>
                      </span>
                      <a :href="file.url" target="_blank" download>{{
                        file.filename
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <div>-</div>
              </template>
            </div>
          </div>
        </template>
      </template>
    </kr-panel> -->

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='application'">
			<template #title>
				<div class="col-xs-6">
					APPLICATION SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&applicationMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('application')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="applicationMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('application')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="applicationMode='view'" aria-label="Cancel">
				            <i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&applicationMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="applicationMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="applicationMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&applicationMode=='view'&&pageType=='confirmation'">
					NOT SET
				</template>
				<template v-else>
					<div class="control-label">
					WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="pageType=='form'||(pageType=='confirmation'&&applicationMode=='edit')">
						<div class="col-xs-12 col-md-6 marginBottom30">
							<kr-toggle name="applicationAllowStudentsToUploadFiles" display="Allow students to upload files" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween">
							</kr-toggle>	

							<kr-toggle name="applicationLimitTimeByPages" display="Limit time by pages" :form="form" class="marginBottom15">
							</kr-toggle>

							<kr-toggle name="applicationAllowChangeAnswersBeforeFinalSubmission" display="Allow students to change answers before final submission" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>

							<kr-toggle name="applicationAllowViewQuestionsAhead" display="Students must answer questions in sequence" :form="form" class="marginBottom15 flexSpaceBetween" :options="{trueval:false,falseval:true}">
							</kr-toggle>

							<kr-toggle  v-if="!form.model.applicationAllowViewQuestionsAhead" name="applicationAllowViewPreviousQuestions" display="Allow students to view previous questions" :form="form" class="marginBottom15 flexSpaceBetween">
							</kr-toggle>
							<kr-toggle v-if="activityObj.others.applicationType=='team'" name="applicationIsSyncToTeamQuestion" display="During Team-based activities, team members will see what the team leader sees" :form="form" class="flexSpaceBetween">
							</kr-toggle>
						</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween marginBottom15 marginTop15">
						    <label class="control-label fontBlack">
						    	Allow students to upload files
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationAllowStudentsToUploadFiles"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label">
						    	Limit time by pages
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationLimitTimeByPages"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Allow students to change answers before final submission
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	Students must answer questions in sequence
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="!form.model.applicationAllowViewQuestionsAhead"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>

						<div class="flexSpaceBetween marginBottom15"  v-if="!form.model.applicationAllowViewQuestionsAhead">
						    <label class="control-label fontBlack">
						    	Allow students to view previous questions
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationAllowViewPreviousQuestions"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div v-if="activityObj.others.applicationType=='team'" class="flexSpaceBetween marginBottom15">
						    <label class="control-label fontBlack">
						    	During Team-based activities, team members will see what the team leader sees
						    </label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.applicationIsSyncToTeamQuestion"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
					
					</div>
					<div class="marginBottom10" v-if="pageType=='form'||applicationMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="applicationAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else>
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.applicationAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.applicationAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="pageType=='form'||applicationMode=='edit'">
						<kr-field :form="form" name="applicationInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else class="marginTop30">	
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.applicationInstruction">
							<div v-html="form.model.applicationInstruction"></div>
						</template>
						<template v-else>
							-
						</template>
					</div>
				</template>
			</template>
		</kr-panel> -->

        <kr-panel v-if="['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">Interaction Settings</div>
                <div v-if="pageType == 'confirmation' && evaluationMode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('evaluation')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="evaluationMode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('evaluation')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="evaluationMode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && evaluationMode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && evaluationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || (pageType == 'confirmation' && evaluationMode == 'edit')">
                        <div v-if="activityObj.type == 'evaluation'">
                            <kr-toggle
                                :options="{ reverse: true }"
                                name="evaluationEnableMichaelsenPointDistribution"
                                display="Michaelsen Point Distribution"
                                :form="form"
                            />
                        </div>
                        <div v-if="form.model.evaluationEnableMichaelsenPointDistribution" class="col-xs-12 marginTop10 marginBottom15">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>Section</th>
                                        <th>Team</th>
                                        <th>Team Size</th>
                                        <th>Points To Distribute</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(team, team_idx) in form.model.evaluationMichaelsenPointDistribution" :key="team_idx">
                                            <td>{{ team.section }}</td>
                                            <td>{{ team.name }}</td>
                                            <td>{{ team.members.length }}</td>
                                            <td>{{ team.point }}</td>
                                        </tr>
                                        <tr v-if="form.model.evaluationMichaelsenPointDistribution.length == 0">
                                            <td colspan="4" style="text-align: center">NO TEAM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template
                            v-if="
                                activityObj.type == 'michaelsenEvaluation' ||
                                (activityObj.type == 'evaluation' && form.model.evaluationEnableMichaelsenPointDistribution)
                            "
                        >
                            <div>
                                <kr-field
                                    :options="{ type: 'number' }"
                                    name="evaluationPointsAllocatedPerMember"
                                    display="How many points are allocated per member?"
                                    :form="form"
                                    @changed="initMichaelsenTable"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationShouldDistributePointsWithoutRepeatingValues"
                                    display="Students must distribute points without repeating values"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationShouldDistributeAllPoints"
                                    display="Students must distribute ALL points"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :disabled="form.model.evaluationPointsAllocatedPerMember != 10 ? true : undefined"
                                    :options="{ reverse: true }"
                                    name="evaluationShouldGiveOneTeammateAboveTenPoints"
                                    display="Students must give at least one teammate a point above 10"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :disabled="form.model.evaluationPointsAllocatedPerMember != 10 ? true : undefined"
                                    :options="{ reverse: true }"
                                    name="evaluationShouldGiveOneTeammateBelowTenPoints"
                                    display="Students must give at least one teammate a point below 10"
                                    :form="form"
                                />
                            </div>
                            <div>
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationAllowToGiveThemselvesPoints"
                                    display="Allow students to give points to themselves"
                                    :form="form"
                                />
                            </div>
                        </template>
                        <div class="marginBottom15 marginTop15 flex">
                            <div class="checkboxField marginRight10">
                                <input
                                    id="evaluationEnableImagePointerEvents"
                                    v-model="form.model.evaluationEnableImagePointerEvents"
                                    type="checkbox"
                                    class="checkbox-field"
                                    @input="waitToSave()"
                                />
                            </div>
                            <label for="evaluationEnableImagePointerEvents" class="control-label marginBottom0 paddingTop2">
                                Allow students to download and/or print images from questions
                            </label>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="activityObj.type == 'evaluation'">
                            <label class="marginRight10">
                                <template v-if="form.model.evaluationEnableMichaelsenPointDistribution"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Michaelsen Point Distribution </label>
                        </div>
                        <div v-if="form.model.evaluationEnableMichaelsenPointDistribution" class="col-xs-12 marginTop10 marginBottom15">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>SECTION</th>
                                        <th>TEAM</th>
                                        <th>TEAM SIZE</th>
                                        <th>POINTS TO DISTRIBUTE</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(team, team_idx) in form.model.evaluationMichaelsenPointDistribution" :key="team_idx">
                                            <td>{{ team.section }}</td>
                                            <td>{{ team.name }}</td>
                                            <td>{{ team.members.length }}</td>
                                            <td>{{ team.point }}</td>
                                        </tr>
                                        <tr v-if="form.model.evaluationMichaelsenPointDistribution.length == 0">
                                            <td colspan="4" style="text-align: center">NO TEAM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template
                            v-if="
                                activityObj.type == 'michaelsenEvaluation' ||
                                (activityObj.type == 'evaluation' && form.model.evaluationEnableMichaelsenPointDistribution)
                            "
                        >
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    {{ form.model.evaluationPointsAllocatedPerMember }}
                                </label>
                                <label class="control-label fontBlack"> How many points are allocated per member? </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldDistributePointsWithoutRepeatingValues"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must distribute points without repeating values </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldDistributeAllPoints"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must distribute ALL points </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldGiveOneTeammateAboveTenPoints"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must give at least one teammate a point above 10 </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldGiveOneTeammateBelowTenPoints"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Students must give at least one teammate a point below 10 </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationAllowToGiveThemselvesPoints"> YES </template>
                                    <template v-else>NO</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to give points to themselves </label>
                            </div>
                        </template>
                        <div>
                            <label class="marginRight10">
                                <template v-if="form.model.evaluationEnableImagePointerEvents"> YES </template>
                                <template v-else>NO</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'evaluation'" :with-footer="false" :options="{ canToggle: false }" :class="{ marginTop20: pageType != 'form' }">
            <template #title>
                <div class="col-xs-6">Instructions To Students</div>
                <div v-if="pageType == 'confirmation' && evaluationMode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('evaluation')">Update</button>
                        <button class="btn btn-default mainBtn" exact @click.prevent="evaluationMode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivitySettings('evaluation')">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="evaluationMode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && evaluationMode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && evaluationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || evaluationMode == 'edit'">
                        <kr-field
                            :form="form"
                            name="evaluationInstruction"
                            :options="{
                                htmlEditor: true,
                                placeholder: 'Enter instructions for this activity here',
                                hasLabel: false,
                                showError: false,
                            }"
                        />
                    </div>
                    <div v-else>
                        <label class="control-label">INSTRUCTION</label>
                        <template v-if="form.model.evaluationInstruction">
                            <div v-html="form.model.evaluationInstruction" />
                        </template>
                        <template v-else>
                            <div>-</div>
                        </template>
                    </div>

                    <div v-if="pageType == 'form' || evaluationMode == 'edit'" class="marginTop15">
                        <kr-file-multiple :form="form" :options="{ reverse: true }" name="evaluationAttachments" @preview-attachment="showAttachment" />
                    </div>
                    <div v-else class="marginTop15">
                        <div class="control-label">Attachments</div>
                        <div>
                            <div v-if="form.model.evaluationAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in form.model.evaluationAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.&nbsp;</span>
                                            <span class="marginRight5">
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <!-- <kr-panel :withFooter="false" :options="{canToggle:false}" v-if="activityObj.type=='evaluation'">
			<template #title>
				<div class="col-xs-6">
					EVALUATION SETTINGS
				</div>
				<div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&evaluationMode=='edit'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('evaluation')" aria-label="Update">
			            	Update
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="evaluationMode='view'" aria-label="Cancel">
				            Cancel
				        </button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings('evaluation')" aria-label="Update">
			            	<i class="fa fa-floppy-o"></i>
			            </button>
			            <button class="btn btn-default mainBtn" exact @click.prevent="evaluationMode='view'" aria-label="Cancel">
				            <i class="fa fa-caret-left"></i>
				        </button>
			        </div>
			    </div> 
			    <div class="col-xs-6 flexRight" v-if="pageType=='confirmation'&&evaluationMode=='view'">
					<div class="hidden-xs hidden-sm">
			            <button class="btn btn-default mainBtn" @click.prevent="evaluationMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
						</button>
			        </div>
			        <div class="hidden-md hidden-lg">
			            <button class="btn btn-default mainBtn" @click.prevent="evaluationMode='edit';init()" aria-label="Edit">
							<i class="fa fa-pencil-square-o"></i>
						</button>
			        </div>
			    </div> 
			</template>
			<template #content>
				<template v-if="!isSet&&evaluationMode=='view'&&pageType=='confirmation'">
					NOT SET
				</template>
				<template v-else>
					<div class="control-label">
						WHEN ANSWERING QUESTIONS
					</div>
					<template v-if="pageType=='form'||(pageType=='confirmation'&&evaluationMode=='edit')">
						<div class="col-xs-12 col-md-6">
							<kr-toggle name="evaluationEnableMichaelsenPointDistribution" display="Michaelsen Point Distribution" :form="form">
							</kr-toggle>
						</div>
						<div class="col-xs-12 marginTop10" v-if="form.model.evaluationEnableMichaelsenPointDistribution">
							<div class="table-responsive">
		  						<table class="table">
		  							<thead>
		  								<th>SECTION</th>
		  								<th>TEAM</th>
		  								<th>TEAM SIZE</th>
		  								<th>POINTS TO DISTRIBUTE</th>
		  							</thead>
		  							<tbody>
		  								<tr v-for="(team,team_idx) in form.model.evaluationMichaelsenPointDistribution">
		  									<td>{{team.section}}</td>
		  									<td>{{team.name}}</td>
		  									<td>{{team.members.length}}</td>
		  									<td>{{team.point}}</td>
		  								</tr>
		  								<tr v-if="form.model.evaluationMichaelsenPointDistribution.length==0">
		  									<td colspan="4" style="text-align:center">NO TEAM</td>
		  								</tr>

		  							</tbody>
		  						</table>
		  					</div>
		  				</div>
					</template>
					<div class="col-xs-12 col-md-6" v-else>
						<div class="flexSpaceBetween">
						    <label class="control-label fontBlack">
						    	Michaelsen Point Distribution
						   	</label>
						    <label class="static-label-toggle">
						    	<template v-if="form.model.evaluationEnableMichaelsenPointDistribution"> YES </template>
						    	<template v-else>NO</template>
						    </label>
						</div>
						<div class="col-xs-12 marginTop10" v-if="form.model.evaluationEnableMichaelsenPointDistribution">
							<div class="table-responsive">
		  						<table class="table">
		  							<thead>
		  								<th>SECTION</th>
		  								<th>TEAM</th>
		  								<th>TEAM SIZE</th>
		  								<th>POINTS TO DISTRIBUTE</th>
		  							</thead>
		  							<tbody>
		  								<tr v-for="(team,team_idx) in form.model.evaluationMichaelsenPointDistribution">
		  									<td>{{team.section}}</td>
		  									<td>{{team.name}}</td>
		  									<td>{{team.members.length}}</td>
		  									<td>{{team.point}}</td>
		  								</tr>
		  								<tr v-if="form.model.evaluationMichaelsenPointDistribution.length==0">
		  									<td colspan="4" style="text-align:center">NO TEAM</td>
		  								</tr>

		  							</tbody>
		  						</table>
		  					</div>
		  				</div>
					</div>
					<div class="marginTop30" v-if="pageType=='form'||evaluationMode=='edit'">
						<kr-file-multiple display="TEST INSTRUCTIONS TO STUDENTS" :form="form" uploadMsg="&nbsp;OR input instructions in the text area below" name="evaluationAttachments" @preview-attachment="showAttachment"></kr-file-multiple>
					</div>
					<div v-else :class="{'marginTop30':pageType=='form','marginTop20':pageType!='form'}">
						<div class="control-label">
							TEST INSTRUCTIONS TO STUDENTS
						</div>
						<div>
							<div class="flex align-items"  v-if="form.model.evaluationAttachments.length!=0">
								<div class="col-xs-12">
								    <span style="font-weight:bold;">Uploaded Files</span>
								    <div v-for="(file,idx) in form.model.evaluationAttachments" class="flexSpaceBetween">
								    	<div>
								    		<span>{{idx+1}}.</span>
									    	<span>
									    		<i class="fa fa-file-image-o"></i>
									    	</span>
									    	<a :href="file.url" target="_blank" download>{{file.filename}}</a>
								    	</div>
								    </div>
								</div>
							</div>
							<template v-else>
								<div>-</div>
							</template>
						</div>
					</div>
					<div v-if="pageType=='form'||evaluationMode=='edit'">
						<kr-field :form="form" name="evaluationInstruction" :options="{htmlEditor:true,placeholder:'Enter instructions for this activity here',hasLabel:false,showError:false}"></kr-field>
					</div>
					<div v-else :class="{'marginTop30':pageType=='form','marginTop20':pageType!='form'}">	
						<label class="control-label">INSTRUCTION</label>
						<template v-if="form.model.evaluationInstruction">
							<div v-html="form.model.evaluationInstruction"></div>
						</template>
						<template v-else>
							<div>-</div>
						</template>
					</div>
				</template>
			</template>
		</kr-panel> -->

        <div v-if="pageType == 'form'" class="marginTop10">
            <!--<button class="btn btn-default mainBtn">
                <i class="fas fa-caret-left"></i>&nbsp;Back
            </button>-->
            <button class="btn btn-success mainBtn" @click.prevent="submitActivitySettings()">
                Review your Activity<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default mainBtn" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
            </button>
        </div>

        <div id="previewModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="previewModal-title">
            <div class="modal-dialog modal-lg">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="previewModal-title" class="modal-title">Preview Attachment</h2>
                    </div>
                    <div class="modal-body" style="height: 75vh">
                        <div class="row" style="height: 100%">
                            <div class="col-xs-12" style="text-align: center; height: 100%">
                                <iframe :src="preview_url" width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import krRadioMultiple from '../../../../../../components/forms/components/kr-radio-multiple.vue';
import KrForm from '../../../../../../components/forms/form';
import KrAuth from '../../../../../../components/auth/auth';
export default {
    components: { krRadioMultiple },
    props: ['activityObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            preview_url: '',
            auth: new KrAuth(),
            form: new KrForm({
                fields: {
                    allowViewQuestionsAhead: '', //peerEvaluationV2
                    allowChangeAnswersBeforeFinalSubmission: '',
                    allowViewPreviousQuestions: '',

                    iratType: '',
                    iratConfidenceBasedTesting: '',
                    iratAllowStudentsToUploadFiles: '',
                    iratLimitTimeByPages: '',
                    iratAllowChangeAnswersBeforeFinalSubmission: '',
                    iratAllowViewPreviousQuestions: '',
                    iratAllowViewQuestionsAhead: '',
                    iratInstructionIsHTML: '',
                    iratInstruction: '',
                    iratAttachments: 'type!:array',
                    iratRandomizeAnswerOptionOrder: '',
                    iratHideQuestionOptionLabels: '',
                    iratImmediateFeedback: '',
                    iratEnableHintAndExplanation: '',
                    iratAllowSafeExamBrowserOnly: '',
                    iratAllowPdfDownload: '',
                    iratEnableImagePointerEvents: '',

                    tratType: '',
                    tratAllowStudentsToUploadFiles: '',
                    tratLimitTimeByPages: '',
                    tratAllowChangeAnswersBeforeFinalSubmission: '',
                    tratAllowViewQuestionsAhead: '',
                    tratAllowViewPreviousQuestions: '',
                    tratInstructionIsHTML: '',
                    tratInstruction: '',
                    tratAttachments: 'type!:array',
                    tratRandomizeAnswerOptionOrder: '',
                    tratHideQuestionOptionLabels: '',
                    tratAllowTeamClarifications: '',
                    tratIsSyncToTeamQuestion: '',
                    tratEnableHintAndExplanation: '',
                    tratAllowSafeExamBrowserOnly: '',
                    tratAllowPdfDownload: '',
                    tratEnableImagePointerEvents: '',

                    preworkType: 'default!:synchronous',
                    preworkStartDate: '',
                    preworkEndDate: '',
                    preworkInstructionIsHTML: '',
                    preworkInstruction: '',
                    preworkAttachments: 'type!:array',
                    preworkAllowPdfDownload: '',

                    applicationType: '',
                    applicationAllowStudentsToUploadFiles: '',
                    applicationLimitTimeByPages: '',
                    applicationAllowChangeAnswersBeforeFinalSubmission: '',
                    applicationAllowViewQuestionsAhead: '',
                    applicationAllowViewPreviousQuestions: '',
                    applicationInstructionIsHTML: '',
                    applicationInstruction: '',
                    applicationAttachments: 'type!:array',
                    applicationIsSyncToTeamQuestion: '',
                    applicationConfidenceBasedTesting: '',
                    applicationRandomizeAnswerOptionOrder: '',
                    applicationHideQuestionOptionLabels: '',
                    isApplicationGraded: '',
                    applicationAllowSafeExamBrowserOnly: '',
                    applicationAllowPdfDownload: '',
                    applicationEnableImagePointerEvents: '',
                    applicationShowRequiredOptionsForMcqm: '',

                    evaluationEnableMichaelsenPointDistribution: '',
                    evaluationMichaelsenPointDistribution: 'default!:array',
                    evaluationInstructionIsHTML: '',
                    evaluationInstruction: '',
                    evaluationAttachments: 'type!:array',

                    evaluationPointsAllocatedPerMember: '',
                    evaluationShouldDistributePointsWithoutRepeatingValues: '',
                    evaluationShouldDistributeAllPoints: '',
                    evaluationShouldGiveOneTeammateAboveTenPoints: '',
                    evaluationShouldGiveOneTeammateBelowTenPoints: '',
                    evaluationAllowToGiveThemselvesPoints: '',
                    evaluationEnableImagePointerEvents: '',

                    // peer evaluation v2
                    peerEvaluationV2InstructionIsHTML: '',
                    peerEvaluationV2Instruction: '',
                    peerEvaluationV2Attachments: 'type!:array',

                    others: 'type!:object',
                },
                props: {
                    successMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            pageType: 'form',
            iratMode: 'view',
            tratMode: 'view',
            iratandtratMode: 'view',
            applicationMode: 'view',
            preworkMode: 'view',
            evaluationMode: 'view',
            peerEvaluationV2Mode: 'view',
            isSet: true,
            teams: [],
            APP_URL: process.env.VUE_APP_APP_URL,
            timeout: null,
            waitTime: 1000,
            iframe: {
                loaded: false,
                hideBanner: false,
            },
        };
    },
    computed: {
        hideSafeExamBrowserField() {
            if (
                process.env.VUE_APP_ENV == 'staging' ||
                (process.env.VUE_APP_ENV == 'production' &&
                    [
                        '08299f88-6223-49aa-84e7-1958d703de6e',
                        'c5a47ef7-eba8-4d7c-805c-3b6cc6abb53c',
                        '00524266-6661-44a3-a2ee-6b5b36ddc286',
                        '436b4f1e-1e48-41fe-845f-9655c2a56646',
                        'eba54676-816f-4eea-a3a2-d5c8ff69f122',
                    ].includes(this.auth.user().account.uuid))
            ) {
                return false;
            }
            return true;
        },
        hasMcqmQuestions() {
            if (this.activityObj.type == 'application') {
                for (var i = 0; i < this.activityObj.questions.length; i++) {
                    let group = this.activityObj.questions[i];
                    for (var j = 0; j < group.length; j++) {
                        if (group[j].type == 'mcqm') {
                            return true;
                        }
                    }
                }
                return false;
            } else {
                return false;
            }
        },
    },
    watch: {
        options: {
            handler: function () {
                this.pageType = _.get(this.options, 'pageType', 'form');
            },
            deep: true,
        },
    },
    mounted() {
        Vue.nextTick(function () {
            $('#irat_iframe').contents().find('.community-banner-container').hide();
            $('#trat_iframe').contents().find('.community-banner-container').hide();
            $('#application_iframe').contents().find('.community-banner-container').hide();
            $('#peerEvaluationV2_iframe').contents().find('.community-banner-container').hide();
        });
    },
    created() {
        this.pageType = _.get(this.options, 'pageType', 'form');
        this.init();
    },
    methods: {
        fetchTeams() {
            return axios.get('/courses/' + this.activityObj.course.uuid + '/teams');
        },
        saveAndExit() {
            this.submitActivitySettings(false, true);
        },
        initMichaelsenTable() {
            if (this.form.model.evaluationPointsAllocatedPerMember != 10) {
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = false;
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = false;
            }
            this.form.model.evaluationMichaelsenPointDistribution = [];
            var teams = this.teams;
            for (var i = 0; i < teams.length; i++) {
                this.form.model.evaluationMichaelsenPointDistribution.push({
                    name: teams[i].name,
                    members: teams[i].members,
                    point:
                        teams[i].members.length != 0
                            ? (teams[i].members.length - (this.form.model.evaluationAllowToGiveThemselvesPoints ? 0 : 1)) *
                              this.form.model.evaluationPointsAllocatedPerMember
                            : 0,
                    section: teams[i].section,
                });
            }
        },
        init() {
            if (!this.activityObj.isActivitySettingsCompleted) {
                this.isSet = false;
            } else {
                this.isSet = true;
            }

            if (this.activityObj) {
                this.form.model.others = this.activityObj.others;
            }
            if (this.activityObj.type == 'peerEvaluationV2') {
                this.form.model.allowViewPreviousQuestions = _.get(this.activityObj, 'settings.peerEvaluationV2.allowViewPreviousQuestions', true);
                this.form.model.allowViewQuestionsAhead = _.get(this.activityObj, 'settings.peerEvaluationV2.allowViewQuestionsAhead', true);
                this.form.model.allowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.peerEvaluationV2.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.peerEvaluationV2InstructionIsHTML = _.get(this.activityObj, 'settings.peerEvaluationV2.instructionIsHTML', true);
                this.form.model.peerEvaluationV2Instruction = _.get(this.activityObj, 'settings.peerEvaluationV2.instruction', '');
                this.form.model.peerEvaluationV2Attachments = _.get(this.activityObj, 'settings.peerEvaluationV2.attachments', []);
            }
            if (this.activityObj.type == 'prework') {
                this.form.model.preworkAllowPdfDownload = _.get(this.activityObj, 'settings.prework.allowPdfDownload', false);
            }
            if (this.activityObj.type == 'irat') {
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'synchronous');
                this.form.model.iratConfidenceBasedTesting = _.get(this.activityObj, 'settings.irat.confidenceBasedTesting', false);
                this.form.model.iratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.irat.allowStudentsToUploadFiles', true);
                this.form.model.iratLimitTimeByPages = _.get(this.activityObj, 'settings.irat.limitTimeByPages', false);
                this.form.model.iratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.irat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.iratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.irat.allowViewPreviousQuestions', true);
                this.form.model.iratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.irat.allowViewQuestionsAhead', true);
                this.form.model.iratInstructionIsHTML = _.get(this.activityObj, 'settings.irat.instructionIsHTML', true);
                this.form.model.iratInstruction = _.get(this.activityObj, 'settings.irat.instruction', '');
                this.form.model.iratAttachments = _.get(this.activityObj, 'settings.irat.attachments', []);
                this.form.model.iratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.irat.randomizeAnswerOptionOrder', false);
                this.form.model.iratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.irat.hideQuestionOptionLabels', false);
                this.form.model.iratImmediateFeedback = _.get(this.activityObj, 'settings.irat.immediateFeedback', false);
                this.form.model.iratEnableHintAndExplanation = _.get(this.activityObj, 'settings.irat.enableHintAndExplanation', false);
                this.form.model.iratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.irat.allowSafeExamBrowserOnly', false);
                this.form.model.iratAllowPdfDownload = _.get(this.activityObj, 'settings.irat.allowPdfDownload', false);
                this.form.model.iratEnableImagePointerEvents = _.get(this.activityObj, 'settings.irat.enableImagePointerEvents', true);
            }
            if (this.activityObj.type == 'trat') {
                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'synchronous');
                this.form.model.tratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.trat.allowStudentsToUploadFiles', true);
                this.form.model.tratLimitTimeByPages = _.get(this.activityObj, 'settings.trat.limitTimeByPages', false);
                this.form.model.tratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.trat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.tratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.trat.allowViewPreviousQuestions', true);
                this.form.model.tratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.trat.allowViewQuestionsAhead', true);
                this.form.model.tratInstructionIsHTML = _.get(this.activityObj, 'settings.trat.instructionIsHTML', true);
                this.form.model.tratInstruction = _.get(this.activityObj, 'settings.trat.instruction', '');
                this.form.model.tratAttachments = _.get(this.activityObj, 'settings.trat.attachments', []);
                this.form.model.tratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.trat.randomizeAnswerOptionOrder', false);
                this.form.model.tratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.trat.hideQuestionOptionLabels', false);
                this.form.model.tratAllowTeamClarifications = _.get(this.activityObj, 'settings.trat.allowTeamClarifications', true);
                this.form.model.tratIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.trat.isSyncToTeamQuestion', true);
                this.form.model.tratEnableHintAndExplanation = _.get(this.activityObj, 'settings.trat.enableHintAndExplanation', false);
                this.form.model.tratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.trat.allowSafeExamBrowserOnly', false);
                this.form.model.tratAllowPdfDownload = _.get(this.activityObj, 'settings.trat.allowPdfDownload', false);
                this.form.model.tratEnableImagePointerEvents = _.get(this.activityObj, 'settings.trat.enableImagePointerEvents', true);
            }
            if (this.activityObj.type == 'iratntrat') {
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'synchronous');
                this.form.model.iratConfidenceBasedTesting = _.get(this.activityObj, 'settings.irat.confidenceBasedTesting', false);
                this.form.model.iratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.irat.allowStudentsToUploadFiles', true);
                this.form.model.iratLimitTimeByPages = _.get(this.activityObj, 'settings.irat.limitTimeByPages', false);
                this.form.model.iratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.irat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.iratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.irat.allowViewPreviousQuestions', true);
                this.form.model.iratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.irat.allowViewQuestionsAhead', true);
                this.form.model.iratInstructionIsHTML = _.get(this.activityObj, 'settings.irat.instructionIsHTML', true);
                this.form.model.iratInstruction = _.get(this.activityObj, 'settings.irat.instruction', '');
                this.form.model.iratAttachments = _.get(this.activityObj, 'settings.irat.attachments', []);
                this.form.model.iratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.irat.randomizeAnswerOptionOrder', false);
                this.form.model.iratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.irat.hideQuestionOptionLabels', false);
                this.form.model.iratImmediateFeedback = _.get(this.activityObj, 'settings.irat.immediateFeedback', false);
                this.form.model.iratEnableHintAndExplanation = _.get(this.activityObj, 'settings.irat.enableHintAndExplanation', false);
                this.form.model.iratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.irat.allowSafeExamBrowserOnly', false);
                this.form.model.iratAllowPdfDownload = _.get(this.activityObj, 'settings.irat.allowPdfDownload', false);
                this.form.model.iratEnableImagePointerEvents = _.get(this.activityObj, 'settings.irat.enableImagePointerEvents', true);

                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'synchronous');
                this.form.model.tratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.trat.allowStudentsToUploadFiles', true);
                this.form.model.tratLimitTimeByPages = _.get(this.activityObj, 'settings.trat.limitTimeByPages', false);
                this.form.model.tratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.trat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.tratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.trat.allowViewPreviousQuestions', true);
                this.form.model.tratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.trat.allowViewQuestionsAhead', true);
                this.form.model.tratInstructionIsHTML = _.get(this.activityObj, 'settings.trat.instructionIsHTML', true);
                this.form.model.tratInstruction = _.get(this.activityObj, 'settings.trat.instruction', '');
                this.form.model.tratAttachments = _.get(this.activityObj, 'settings.trat.attachments', []);
                this.form.model.tratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.trat.randomizeAnswerOptionOrder', false);
                this.form.model.tratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.trat.hideQuestionOptionLabels', false);
                this.form.model.tratAllowTeamClarifications = _.get(this.activityObj, 'settings.trat.allowTeamClarifications', true);
                this.form.model.tratIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.trat.isSyncToTeamQuestion', true);
                this.form.model.tratEnableHintAndExplanation = _.get(this.activityObj, 'settings.trat.enableHintAndExplanation', false);
                this.form.model.tratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.trat.allowSafeExamBrowserOnly', false);
                this.form.model.tratAllowPdfDownload = _.get(this.activityObj, 'settings.trat.allowPdfDownload', false);
                this.form.model.tratEnableImagePointerEvents = _.get(this.activityObj, 'settings.trat.enableImagePointerEvents', true);
            }
            if (this.activityObj.type == 'prework') {
                this.form.model.preworkType = _.get(this.activityObj, 'settings.prework.type', 'synchronous');
                this.form.model.preworkInstructionIsHTML = _.get(this.activityObj, 'settings.prework.instructionIsHTML', true);
                this.form.model.preworkInstruction = _.get(this.activityObj, 'settings.prework.instruction', '');
                this.form.model.preworkAttachments = _.get(this.activityObj, 'settings.prework.attachments', []);
            }
            if (this.activityObj.type == 'application') {
                this.form.model.applicationType = _.get(this.activityObj, 'settings.application.type', 'synchronous');
                this.form.model.applicationAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.application.allowStudentsToUploadFiles', true);
                this.form.model.applicationLimitTimeByPages = _.get(this.activityObj, 'settings.application.limitTimeByPages', false);
                this.form.model.applicationAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.application.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.applicationAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.application.allowViewPreviousQuestions', true);
                this.form.model.applicationAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.application.allowViewQuestionsAhead', true);
                this.form.model.applicationInstructionIsHTML = _.get(this.activityObj, 'settings.application.instructionIsHTML', true);
                this.form.model.applicationInstruction = _.get(this.activityObj, 'settings.application.instruction', '');
                this.form.model.applicationAttachments = _.get(this.activityObj, 'settings.application.attachments', []);
                this.form.model.applicationIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.application.isSyncToTeamQuestion', true);
                this.form.model.applicationConfidenceBasedTesting = _.get(this.activityObj, 'settings.application.confidenceBasedTesting', false);
                this.form.model.applicationRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.application.randomizeAnswerOptionOrder', false);
                this.form.model.applicationHideQuestionOptionLabels = _.get(this.activityObj, 'settings.application.hideQuestionOptionLabels', false);
                this.form.model.others.isApplicationGraded = _.get(this.activityObj, 'others.isApplicationGraded', true);
                this.form.model.others.applicationType = _.get(this.activityObj, 'others.applicationType', true);
                this.form.model.applicationAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.application.allowSafeExamBrowserOnly', false);
                this.form.model.applicationAllowPdfDownload = _.get(this.activityObj, 'settings.application.allowPdfDownload', false);
                this.form.model.applicationEnableImagePointerEvents = _.get(this.activityObj, 'settings.application.enableImagePointerEvents', true);
                this.form.model.applicationShowRequiredOptionsForMcqm = _.get(this.activityObj, 'settings.application.showRequiredOptionsForMcqm', false);
            }
            if (this.activityObj.type == 'evaluation') {
                this.form.model.evaluationEnableMichaelsenPointDistribution = _.get(
                    this.activityObj,
                    'settings.evaluation.enableMichaelsenPointDistribution',
                    true
                );
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.evaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.evaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.evaluation.attachments', []);
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.evaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.evaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.evaluation.attachments', []);

                this.form.model.evaluationPointsAllocatedPerMember = _.get(this.activityObj, 'settings.evaluation.pointsAllocatedPerMember', 10);
                this.form.model.evaluationShouldDistributePointsWithoutRepeatingValues = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldDistributePointsWithoutRepeatingValues',
                    true
                );
                this.form.model.evaluationShouldDistributeAllPoints = _.get(this.activityObj, 'settings.evaluation.shouldDistributeAllPoints', true);
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldGiveOneTeammateAboveTenPoints',
                    true
                );
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldGiveOneTeammateBelowTenPoints',
                    true
                );
                this.form.model.evaluationAllowToGiveThemselvesPoints = _.get(this.activityObj, 'settings.evaluation.allowToGiveThemselvesPoints', false);
                var that = this;
                that.form.model.evaluationMichaelsenPointDistribution = [];
                this.fetchTeams().then(function (response) {
                    that.teams = response.data.data;
                    that.initMichaelsenTable();
                    that.component_done_loading = true;
                });
                this.form.model.evaluationEnableImagePointerEvents = _.get(this.activityObj, 'settings.evaluation.enableImagePointerEvents', true);
            }
            if (this.activityObj.type == 'michaelsenEvaluation') {
                this.form.model.evaluationEnableMichaelsenPointDistribution = true;
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.michaelsenEvaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.michaelsenEvaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.michaelsenEvaluation.attachments', []);

                this.form.model.evaluationPointsAllocatedPerMember = _.get(this.activityObj, 'settings.michaelsenEvaluation.pointsAllocatedPerMember', 10);
                this.form.model.evaluationShouldDistributePointsWithoutRepeatingValues = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldDistributePointsWithoutRepeatingValues',
                    true
                );
                this.form.model.evaluationShouldDistributeAllPoints = _.get(this.activityObj, 'settings.michaelsenEvaluation.shouldDistributeAllPoints', true);
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldGiveOneTeammateAboveTenPoints',
                    true
                );
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldGiveOneTeammateBelowTenPoints',
                    true
                );
                this.form.model.evaluationAllowToGiveThemselvesPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.allowToGiveThemselvesPoints',
                    false
                );
                var that = this;
                this.fetchTeams().then(function (response) {
                    that.teams = response.data.data;
                    that.initMichaelsenTable();
                    that.component_done_loading = true;
                });
            } else {
                this.component_done_loading = true;
            }
        },
        showAttachment(url) {
            this.preview_url = url;
            $('#previewModal').modal('show');
        },
        submitActivitySettings(mode = 'irat', exitOnSave = false) {
            var that = this;
            let url = '/activities/' + this.activityObj.uuid + '/settings?settings=activity';
            // let url = "/activities/" + this.activityObj.uuid;
            if (this.form.model.iratAllowChangeAnswersBeforeFinalSubmission) {
                this.form.model.iratAllowViewPreviousQuestions = true;
            }

            if (
                this.form.model.applicationAllowChangeAnswersBeforeFinalSubmission ||
                (this.form.model.applicationAllowViewQuestionsAhead && !this.form.model.iratAllowChangeAnswersBeforeFinalSubmission)
            ) {
                this.form.model.applicationAllowViewPreviousQuestions = true;
            }
            let data = _.cloneDeep(this.form.model);
            if (this.activityObj.type == 'peerEvaluationV2') {
                url = '/activities/' + this.activityObj.uuid + '/peer-evaluation/settings';
                data = {
                    settings: {
                        allowViewPreviousQuestions: this.form.model.allowViewPreviousQuestions,
                        allowViewQuestionsAhead: this.form.model.allowViewQuestionsAhead,
                        allowChangeAnswersBeforeFinalSubmission: this.form.model.allowChangeAnswersBeforeFinalSubmission,
                        instructionIsHTML: this.form.model.peerEvaluationV2InstructionIsHTML,
                        instruction: this.form.model.peerEvaluationV2Instruction,
                        attachments: this.form.model.peerEvaluationV2Attachments,
                    },
                };
            }
            if (that.activityObj.type == 'application') {
                this.form.put('/activities/' + this.activityObj.uuid).then(function (response) {
                    if (!response.krFormError) {
                        that.$emit('activity-updated', response.data.data);
                    }
                });
            }

            this.form.put(url, { data: data }).then(function (response) {
                if (!response.krFormError) {
                    that.$emit('activity-updated', response.data.data);
                    if (exitOnSave === true) {
                        Vue.nextTick(function () {
                            that.$emit('save-and-exit');
                        });
                    } else {
                        if (that.pageType == 'form') {
                            Vue.nextTick(function () {
                                that.$emit('next-step');
                            });
                        } else {
                            if (mode == 'irat') {
                                that.iratMode = 'view';
                            }
                            if (mode == 'trat') {
                                that.tratMode = 'view';
                            }
                            if (mode == 'iratntrat') {
                                that.iratandtratMode = 'view';
                            }
                            if (mode == 'prework') {
                                that.preworkMode = 'view';
                            }
                            if (mode == 'application') {
                                that.applicationMode = 'view';
                            }
                            if (mode == 'evaluation') {
                                that.evaluationMode = 'view';
                            }
                            if (mode == 'peerEvaluationV2') {
                                that.peerEvaluationV2Mode = 'view';
                            }
                        }
                        Vue.nextTick(function () {
                            that.init();
                        });
                    }
                }
            });
        },
        waitToSave() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.autoSave.bind(this), this.waitTime);
        },
        autoSave() {
            var that = this;
            let url = '/activities/' + this.activityObj.uuid + '/settings?settings=activity';
            let data = _.cloneDeep(this.form.model);
            if (this.activityObj.type == 'peerEvaluationV2') {
                url = '/activities/' + this.activityObj.uuid + '/peer-evaluation/settings';
                data = {
                    settings: {
                        allowViewPreviousQuestions: this.form.model.allowViewPreviousQuestions,
                        allowViewQuestionsAhead: this.form.model.allowViewQuestionsAhead,
                        allowChangeAnswersBeforeFinalSubmission: this.form.model.allowChangeAnswersBeforeFinalSubmission,
                    },
                };
            }
            if (that.activityObj.type == 'application') {
                this.form.put('/activities/' + this.activityObj.uuid).then(function (response) {
                    document.getElementById(that.activityObj.type + '_iframe').src += '';
                });
            }

            this.form
                .put(url, { data: data })
                .then((response) => {
                    if (this.activityObj.type == 'iratntrat') {
                        var splitArray = that.activityObj.type.split('n');
                        for (var i = 0; i < splitArray.length; i++) {
                            document.getElementById(splitArray[i] + '_iframe').src += '';
                        }
                    } else {
                        document.getElementById(that.activityObj.type + '_iframe').src += '';
                    }
                })
                .catch((e) => {
                    console.log('Error... ');
                });
        },
        loadIframe() {
            this.iframe.loaded = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.hideDiv.bind(this), 100);
        },
        hideDiv() {
            if (this.activityObj.type == 'iratntrat') {
                var splitArray = this.activityObj.type.split('n');
                for (var i = 0; i < splitArray.length; i++) {
                    var iframe = $('#' + splitArray[i] + '_iframe').contents();

                    iframe.find('body').removeClass('modal-open');
                    iframe.find('.community-banner-container').hide();
                    // iframe.find('.test_topBar').hide();
                    // iframe.find('.test_topBar_placeholder').hide();
                    iframe.find('.col-md-3.left_col.no-print').hide();
                    iframe.find('footer').hide();
                    iframe.find('#launcher').hide();
                    iframe.find('.statusBar').hide();
                    iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                    iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                    iframe.find('.test_content').attr('style', 'padding: 20px');
                }
            } else {
                var iframe = $('#' + this.activityObj.type + '_iframe').contents();

                iframe.find('body').removeClass('modal-open');
                iframe.find('.community-banner-container').hide();
                // iframe.find('.test_topBar').hide();
                // iframe.find('.test_topBar_placeholder').hide();
                iframe.find('.col-md-3.left_col.no-print').hide();
                iframe.find('footer').hide();
                iframe.find('#launcher').hide();
                iframe.find('.statusBar').hide();
                iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                iframe.find('.test_content').attr('style', 'padding: 20px');
            }
        },
        /*changing from team to individidual*/
        changedApplicationType() {
            if (this.form.model.others.applicationType == 'team') {
                this.form.model.applicationIsSyncToTeamQuestion = true;
                this.form.model.applicationConfidenceBasedTesting = false;
            } else {
                this.form.model.applicationIsSyncToTeamQuestion = false;
            }
        },
        handleIframeFocusIn() {
            console.log('focus in');
            // Announce a message when the focus enters the iframe
            const messageArea = document.getElementById('message-area');
            messageArea.innerText = 'Focus entered the iframe';
            console.log('message updated');
        },
        handleIframeFocusOut() {
            // Announce a message when the focus exits the iframe
            const messageArea = document.getElementById('message-area');
            messageArea.innerText = 'Focus exited the iframe';
        },
    },
};
</script>

<style lang="scss" scoped>
.static-label-toggle {
    line-height: 10px;
    margin-left: 20px;
}
.optionDiv {
    margin-bottom: 15px;
}

.expectation-paragraph {
    border: 4px solid #0071be;
    max-width: 940px;
}
</style>
