<template>
    <div class="three-column-grid-dashboard-evaluation">
        <div class="studentNameList">
            <h3>Students</h3>
            <button
                v-for="(student, studentIdx) in selectedTeam"
                class="btn margin0"
                :class="{ active: selectedStudentIdx == student.id, marginTop10: studentIdx > 0 }"
                @click="
                    selectedStudentIdx = student.id;
                    selectedStudent = student;
                "
            >
                {{ student.displayName }}
            </button>
        </div>
        <div class="student-answers-left-column">
            <div class="student-answers-left-column-header">
                <h3>Status</h3>
            </div>
            <div class="student-answers-left-column-content">
                <div
                    class="tableStatusTag"
                    :class="{
                        'tableStatusTag-danger': ['timed out', 'not started', null].includes(selectedStudent.status),
                        'tableStatusTag-warning': ['ongoing', 'started'].includes(selectedStudent.status),
                        'tableStatusTag-success': ['completed', 'finished'].includes(selectedStudent.status),
                    }"
                >
                    <template v-if="selectedStudent.status == 'timed out'"> Timed Out </template>
                    <template v-else-if="selectedStudent.status == 'not started' || selectedStudent.status == null"> Not Started </template>
                    <template v-else-if="selectedStudent.status == 'ongoing' || selectedStudent.status == 'started'"> Ongoing </template>
                    <template v-else-if="selectedStudent.status == 'completed'"> Submitted </template>
                    <template v-else-if="selectedStudent.status == 'finished'"> Finished </template>
                </div>
            </div>
        </div>
        <div class="student-answers-right-column">
            <div class="student-answers-right-column-header">
                <h3>Answers To Questions</h3>
            </div>
            <div class="student-answers-left-column-content">
                <template v-if="selectedCriteria.question.type == 'pointDistribution'">
                    <div class="newEvaluationTable">
                        <div class="sub-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="minWidth200px width200px maxWidth200px">Assessee</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                        <td>
                                            {{ student.displayName }}
                                        </td>
                                        <td>
                                            {{
                                                getAnswerFromGiverAndReceiverId(selectedCriteria.pointDistributions[student.id], selectedStudentIdx, student.id)
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
                <template v-for="(stem, stemIdx) in selectedCriteria.question.questionStems" v-else>
                    <div class="accordion-item-light-primary-dashboard borderRadius0" :class="{ marginTop20: stemIdx != 1 }">
                        <div :id="'openEnded-' + stemIdx" class="accordion-header">
                            <div class="inlineFlex">
                                <span class="material-icons marginRight8" aria-hidden="true">
                                    <template v-if="selectedCriteria.question.type == 'openendedV2'"> format_align_left </template>
                                    <template v-else-if="selectedCriteria.question.type == 'pointDistribution'"> tune </template>
                                    <template v-else-if="selectedCriteria.question.type == 'ratingV2'"> star </template>
                                </span>

                                <div>
                                    <label class="fs-18px fw-normal">
                                        {{ stemIdx }}. {{ stem.stem }}

                                        <template v-if="stem.isRequired">
                                            <span class="visible-hidden">Required</span>
                                            <span class="important" aria-hidden="true">*</span>
                                        </template>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-content" :aria-labelledby="'openEnded-' + stemIdx">
                            <template v-if="selectedCriteria.question.type == 'ratingV2'">
                                <div class="table-responsive newEvaluationTable">
                                    <div class="sub-table">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        v-if="
                                                            testObj.evaluationSettings.target != 'self' &&
                                                            selectedCriteria.question.settings.answerQuestionsForEachTeammate
                                                        "
                                                        class="minWidth200px width200px maxWidth200px"
                                                    >
                                                        Assessee
                                                    </th>
                                                    <th class="minWidth68px maxWidth68px width68px">Scale</th>
                                                    <th v-if="ratingHaveLabel" class="minWidth100px maxWidth100px width100px">Label</th>
                                                    <th v-if="ratingHaveDescription" class="minWidth400px maxWidth400px width400px">Description</th>
                                                    <th class="minWidth400px width400px maxWidth400px">Instructor Feedback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template
                                                    v-if="
                                                        testObj.evaluationSettings.target != 'self' &&
                                                        selectedCriteria.question.settings.answerQuestionsForEachTeammate
                                                    "
                                                >
                                                    <tr v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                                        <td>
                                                            {{ student.displayName }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                getAnswerFromGiverAndReceiverId(
                                                                    selectedCriteria.questionStems[stemIdx],
                                                                    selectedStudentIdx,
                                                                    student.id
                                                                )
                                                            }}
                                                        </td>
                                                        <td v-if="ratingHaveLabel">
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        student.id
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                student.id
                                                                            )
                                                                        ).label != null
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                student.id
                                                                            )
                                                                        ).label
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                        <td v-if="ratingHaveDescription">
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        student.id
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                student.id
                                                                            )
                                                                        ).rubric != null
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                student.id
                                                                            )
                                                                        ).rubric
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        student.id
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <div class="input-group inlineFlex width100">
                                                                    <input
                                                                        v-model="comments[stemIdx][student.id].comment"
                                                                        placeholder="Type comment"
                                                                        class="form-control zIndex1"
                                                                    />
                                                                    <span class="input-group-addon widthAuto">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            :disabled="!canSendComment(stemIdx, student.id)"
                                                                            aria-label="submit comment"
                                                                            @click="
                                                                                submitComment(
                                                                                    'ratingV2',
                                                                                    stemIdx,
                                                                                    getAnswerFromGiverAndReceiverId(
                                                                                        selectedCriteria.questionStems[stemIdx],
                                                                                        selectedStudentIdx,
                                                                                        student.id,
                                                                                        'object'
                                                                                    ).id,
                                                                                    comments[stemIdx][student.id].comment
                                                                                )
                                                                            "
                                                                        >
                                                                            <i class="fa fa-floppy-o" aria-hidden="hidden"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td>
                                                            {{
                                                                getAnswerFromGiverAndReceiverId(
                                                                    selectedCriteria.questionStems[stemIdx],
                                                                    selectedStudentIdx,
                                                                    null
                                                                )
                                                            }}
                                                        </td>
                                                        <td v-if="ratingHaveLabel">
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        null
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                null
                                                                            )
                                                                        ).label != null
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                null
                                                                            )
                                                                        ).label
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                        <td v-if="ratingHaveDescription">
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        null
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                null
                                                                            )
                                                                        ).rubric != null
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(
                                                                            selectedCriteria.question,
                                                                            getAnswerFromGiverAndReceiverId(
                                                                                selectedCriteria.questionStems[stemIdx],
                                                                                selectedStudentIdx,
                                                                                null
                                                                            )
                                                                        ).rubric
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        null
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <div class="input-group inlineFlex width100">
                                                                    <input
                                                                        v-model="comments[stemIdx].comment"
                                                                        placeholder="Type comment"
                                                                        class="form-control zIndex1"
                                                                    />
                                                                    <span class="input-group-addon widthAuto">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            :disabled="!canSendComment(stemIdx, null)"
                                                                            aria-label="submit comment"
                                                                            @click="
                                                                                submitComment(
                                                                                    'ratingV2',
                                                                                    stemIdx,
                                                                                    getAnswerFromGiverAndReceiverId(
                                                                                        selectedCriteria.questionStems[stemIdx],
                                                                                        selectedStudentIdx,
                                                                                        null,
                                                                                        'object'
                                                                                    ).id,
                                                                                    comments[stemIdx].comment
                                                                                )
                                                                            "
                                                                        >
                                                                            <i class="fa fa-floppy-o" aria-hidden="hidden"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </template>

                            <template v-if="selectedCriteria.question.type == 'openendedV2'">
                                <div class="table-responsive newEvaluationTable">
                                    <div class="sub-table">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        v-if="
                                                            testObj.evaluationSettings.target != 'self' &&
                                                            selectedCriteria.question.settings.answerQuestionsForEachTeammate
                                                        "
                                                        class="minWidth200px width200px maxWidth200px"
                                                    >
                                                        Assessee
                                                    </th>
                                                    <th class="minWidth400px width400px maxWidth400px">Response</th>
                                                    <th class="minWidth400px width400px maxWidth400px">Instructor Feedback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template
                                                    v-if="
                                                        testObj.evaluationSettings.target != 'self' &&
                                                        selectedCriteria.question.settings.answerQuestionsForEachTeammate
                                                    "
                                                >
                                                    <tr v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                                        <td>
                                                            {{ student.displayName }}
                                                        </td>
                                                        <td class="whiteSpacePreWrap">
                                                            {{
                                                                getAnswerFromGiverAndReceiverId(
                                                                    selectedCriteria.questionStems[stemIdx],
                                                                    selectedStudentIdx,
                                                                    student.id
                                                                )
                                                            }}
                                                        </td>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        selectedStudentIdx,
                                                                        student.id
                                                                    ) != '-'
                                                                "
                                                            >
                                                                <div class="input-group inlineFlex width100">
                                                                    <input
                                                                        v-model="comments[stemIdx][student.id].comment"
                                                                        placeholder="Type comment"
                                                                        class="form-control zIndex1"
                                                                    />
                                                                    <span class="input-group-addon widthAuto">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            :disabled="!canSendComment(stemIdx, student.id)"
                                                                            aria-label="submit comment"
                                                                            @click="
                                                                                submitComment(
                                                                                    'openendedV2',
                                                                                    stemIdx,
                                                                                    getAnswerFromGiverAndReceiverId(
                                                                                        selectedCriteria.questionStems[stemIdx],
                                                                                        selectedStudentIdx,
                                                                                        student.id,
                                                                                        'object'
                                                                                    ).id,
                                                                                    comments[stemIdx][student.id].comment
                                                                                )
                                                                            "
                                                                        >
                                                                            <i class="fa fa-floppy-o" aria-hidden="hidden"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr v-else>
                                                    <td class="whiteSpacePreWrap">
                                                        {{ getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], selectedStudentIdx, null) }}
                                                    </td>
                                                    <td>
                                                        <template
                                                            v-if="
                                                                getAnswerFromGiverAndReceiverId(
                                                                    selectedCriteria.questionStems[stemIdx],
                                                                    selectedStudentIdx,
                                                                    null
                                                                ) != '-'
                                                            "
                                                        >
                                                            <div class="input-group inlineFlex width100">
                                                                <input
                                                                    v-model="comments[stemIdx].comment"
                                                                    placeholder="Type comment"
                                                                    class="form-control zIndex1"
                                                                />
                                                                <span class="input-group-addon widthAuto">
                                                                    <button
                                                                        class="btn btn-primary"
                                                                        :disabled="!canSendComment(stemIdx, null)"
                                                                        aria-label="submit comment"
                                                                        @click="
                                                                            submitComment(
                                                                                'openendedV2',
                                                                                stemIdx,
                                                                                getAnswerFromGiverAndReceiverId(
                                                                                    selectedCriteria.questionStems[stemIdx],
                                                                                    selectedStudentIdx,
                                                                                    null,
                                                                                    'object'
                                                                                ).id,
                                                                                comments[stemIdx].comment
                                                                            )
                                                                        "
                                                                    >
                                                                        <i class="fa fa-floppy-o" aria-hidden="hidden"></i>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useNotification } from '@kyvg/vue3-notification';
import { ref, watch, computed, onMounted, nextTick } from 'vue';
const { notify } = useNotification();
const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    selectedCriteria: {
        type: Object,
        default: () => ({}),
    },
    selectedTeam: {
        type: Array,
        default: () => [],
    },
});

const selectedStudent = ref(props.selectedTeam[0]);
const selectedStudentIdx = ref(selectedStudent.value.id);
const comments = ref({});

watch(
    () => props.selectedTeam,
    (selectedTeam) => {
        selectedStudent.value = selectedTeam[0];
        selectedStudentIdx.value = selectedStudent.value.id;
    }
);

watch(
    () => [selectedStudentIdx.value, props.selectedCriteria],
    () => {
        initComments();
    }
);

const getRatingObjectFromWeight = (question, weight) => {
    let idx = _.findIndex(question.ratingSettings, function (o) {
        return o.weight == weight;
    });

    if (idx != -1) {
        return question.ratingSettings[idx];
    } else return null;
};

const getAnswerIdxFromGiverAndReceiverId = (answers, giverId, receiverId) => {
    return _.findIndex(answers, function (o) {
        if (giverId && receiverId) {
            return o.giverId == giverId && o.receiverId == receiverId;
        } else if (giverId && !receiverId) {
            return o.giverId == giverId;
        } else if (!giverId && receiverId) {
            return o.receiverId == receiverId;
        }
    });
};

const getAnswerFromGiverAndReceiverId = (answers, giverId, receiverId, type = 'answer') => {
    let idx = getAnswerIdxFromGiverAndReceiverId(answers, giverId, receiverId);

    if (idx != -1) {
        if (type == 'object') {
            return answers[idx];
        } else {
            return answers[idx].answer;
        }
    }
    if (type == 'object') {
        return null;
    } else {
        return '-';
    }
};

const ratingHaveLabel = computed(() => {
    if (props.selectedCriteria.question.type == 'ratingV2') {
        for (var i = 0; i < props.selectedCriteria.question.ratingSettings.length; i++) {
            if (props.selectedCriteria.question.ratingSettings[i].label) {
                return true;
            }
        }
    }
    return false;
});

const ratingHaveDescription = computed(() => {
    if (props.selectedCriteria.question.type == 'ratingV2') {
        for (var i = 0; i < props.selectedCriteria.question.ratingSettings.length; i++) {
            if (props.selectedCriteria.question.ratingSettings[i].rubric) {
                return true;
            }
        }
    }
    return false;
});

const initComments = () => {
    if (props.selectedCriteria.question.type != 'pointDistribution') {
        let stemKeys = Object.keys(props.selectedCriteria.question.questionStems);
        for (var i = 0; i < stemKeys.length; i++) {
            let stem = props.selectedCriteria.questionStems[stemKeys[i]];
            let answer = '-';

            if (!props.selectedCriteria.question.settings.answerQuestionsForEachTeammate || props.testObj.evaluationSettings.target == 'self') {
                answer = getAnswerFromGiverAndReceiverId(stem, selectedStudentIdx.value, null, 'object');
                if (answer) {
                    comments.value[stemKeys[i]] = { comment: answer.comments.length > 0 ? answer.comments[answer.comments.length - 1].comment : '' };
                }
            } else {
                for (var j = 0; j < props.selectedTeam.length; j++) {
                    let student = props.selectedTeam[j];
                    answer = getAnswerFromGiverAndReceiverId(stem, selectedStudentIdx.value, student.id, 'object');
                    if (answer) {
                        if (!comments.value[stemKeys[i]]) {
                            comments.value[stemKeys[i]] = {};
                        }
                        comments.value[stemKeys[i]][student.id] = {
                            comment: answer.comments.length > 0 ? answer.comments[answer.comments.length - 1].comment : '',
                        };
                    }
                }
            }
        }
    }
};

const canSendComment = (stemId, receiverId = null) => {
    let currentComment = '';
    let answer = getAnswerFromGiverAndReceiverId(props.selectedCriteria.questionStems[stemId], selectedStudentIdx.value, receiverId, 'object');
    let comment = answer.comments.length > 0 ? answer.comments[answer.comments.length - 1].comment : '';
    if (receiverId) {
        currentComment = _.get(comments.value, `${stemId}.${receiverId}.comment`);
    } else {
        currentComment = _.get(comments.value, `${stemId}.comment`);
    }
    return currentComment != comment;
};

const submitComment = (questionType, stemId, answerId, comment) => {
    axios
        .post(`tests/${props.testObj.uuid}/dashboard/peer-evaluation/comments`, {
            questionType: questionType,
            comment: comment,
            answerId: answerId,
        })
        .then(function (response) {
            let answerIdx = _.findIndex(props.selectedCriteria.questionStems[stemId], function (o) {
                return o.id == answerId;
            });
            if (answerIdx != -1) {
                props.selectedCriteria.questionStems[stemId][answerIdx].comments.push({ comment: comment });
            }
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Comment submitted',
            });
        });
};

onMounted(() => {
    initComments();
});
</script>
