<template>
    <div>
        <div class="inlineFlex">
            <button
                class="btn btn-default mainBtn padding16px width36px pagination-left"
                :disabled="!canChangeQuestion(previous_question_group_idx, previous_question_idx) ? true : undefined"
                :aria-label="canChangeQuestion(previous_question_group_idx, previous_question_idx) ? 'Back' : 'Back Button Disabled'"
                @click.prevent="moveToDifferentQuestion(previous_question_group_idx, previous_question_idx)"
            >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
            </button>
            <div class="marginLeft10">
                <ul class="flexOnly pagination">
                    <template v-for="(question, idx) in pagination">
                        <li
                            :class="{
                                active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                                first: question.question_idx == 0,
                                last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                            }"
                        >
                            <template v-if="question.isDot"><a>...</a></template>
                            <template v-else>
                                <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                    <a
                                        :aria-label="question.label"
                                        @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx)"
                                        >{{ question.label }}</a
                                    >
                                </template>
                                <template v-else>
                                    <a class="disabled" :aria-label="question.label">{{ question.label }}</a>
                                </template>
                            </template>
                        </li>
                    </template>
                </ul>
            </div>
            <button
                class="btn btn-default mainBtn padding16px width36px marginLeft10 pagination-right"
                :disabled="!canChangeQuestion(next_question_group_idx, next_question_idx) ? true : undefined"
                :aria-label="canChangeQuestion(next_question_group_idx, next_question_idx) ? 'Forward' : 'Forward Button Disabled'"
                @click.prevent="moveToDifferentQuestion(next_question_group_idx, next_question_idx)"
            >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
            </button>
        </div>
        <!-- <div class="col-xs-12 hidden-md hidden-lg flexSpaceBetween align-items paginator">
			<button class="btn btn-default mainBtn"  @click.prevent="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)" :disabled="(!canChangeQuestion(previous_question_group_idx,previous_question_idx)) ? true:undefined"></i>
				<i class="fa fa-caret-left"></i>
			</button>	
			<div class="btn padding0">
				<ul class="flexOnly pagination text-listing-pagination justifyCenter">
					<template v-for="(question,idx) in pagination">
						<li :class="{'active':question.question_group_idx==current_question_group_idx&&question.question_idx==question.question_idx}">
							<template v-if="question.isDot"><a>...</a></template>
							<template v-else>
								<template v-if="canChangeQuestion(question.question_group_idx,question.question_idx)">
									<a @click.prevent="moveToDifferentQuestion(question.question_group_idx,question.question_idx)">{{question.label}}</a>
								</template>	
								<template v-else>
									<a class="disabled">{{question.label}}</a>
								</template>	
							</template>
						</li>
					</template>	
		        </ul>	
		    </div>
			<button class="btn btn-default mainBtn" @click.prevent="moveToDifferentQuestion(next_question_group_idx,next_question_idx)" :disabled="(!canChangeQuestion(next_question_group_idx,next_question_idx)) ? true:undefined">
				<i class="fa fa-caret-right"></i>
			</button>
		</div> -->
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
    methods: {
        //override from mixin check if we can change to different question
        canChangeQuestion(question_group_idx, question_idx) {
            if (question_group_idx == this.current_question_group_idx && question_idx == this.current_question_idx) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style>
.paginator button,
.paginator .buttons,
.paginator .btn,
.paginator router-link {
    font-weight: normal;
}

.presentation-mode-cr .pagination-left .fa-caret-left:before {
    content: '\f053';
}

.presentation-mode-cr .pagination-right .fa-caret-right:before {
    content: '\f054';
}

.presentation-mode-cr .pagination-left.btn[disabled],
.presentation-mode-cr .pagination-right.btn[disabled] {
    background: none;
    color: #c5d0de;
    border: none;
    box-shadow: none;
}

.presentation-mode-cr .pagination-left.btn,
.presentation-mode-cr .pagination-right.btn {
    background: none;
    color: #0071be;
    border: none;
    box-shadow: none;
}

.presentation-mode-cr .pagination-left,
.presentation-mode-cr .pagination-right {
    width: 24px;
    height: 24px;
    padding: 5px 0 !important;
}

.presentation-mode-cr .pagination > li > a,
.presentation-mode-cr .pagination > li > span {
    border: none;
    background: none;
    color: #0071be;
    width: 24px;
    height: 24px;
    padding: 8px 0;
}

.presentation-mode-cr .pagination > .active > a,
.presentation-mode-cr .pagination > .active > a:hover,
.presentation-mode-cr .pagination > .active > a:focus,
.presentation-mode-cr .pagination > .active > span,
.presentation-mode-cr .pagination > .active > span:hover,
.presentation-mode-cr .pagination > .active > span:focus {
    border: none;
    box-shadow: none;
    background: #0071be;
    color: #fff;
    border-radius: 5px;
}
</style>
<style scoped>
a.disabled {
    opacity: 0.3;
}
</style>
