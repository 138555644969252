import { computed } from 'vue';
import useQuestion from './question.ts';
import KrAuth from '../../components/auth/auth';
export default function useTest(test) {
    const auth = new KrAuth();
    const { isQuestionAnswered } = useQuestion();
    const canSubmitTest = computed({
        get() {
            if (test.type == 'peerEvaluationV2') {
                let keys = Object.keys(test.questions);
                for (var i = 0; i < keys.length; i++) {
                    let group = test.questions[keys[i]];
                    for (var j = 0; j < group.length; j++) {
                        let question = group[j];
                        let receiverIds = question.settings.answerQuestionsForEachTeammate ? test.team.map((member) => member.id) : [];
                        if (!isQuestionAnswered(question, receiverIds)) {
                            return false;
                        }
                    }
                }
                return true;
            }
        },
    });
    const isTestCompleted = computed({
        get() {
            if (auth.user().role != 'Student') {
                return null;
            }
            if (
                ['completed', 'ended'].includes(test.status) ||
                ['finished', 'incomplete'].includes(_.get(test, 'myProgression.userTestStatus')) ||
                ['submitted'].includes(_.get(test, 'myProgression.teamStatus'))
            ) {
                return true;
            }
            return false;
        },
    });
    const isTestStarted = computed({
        get() {
            return _.get(test, 'myProgression.userTestStatus') == 'started';
        },
    });
    function isReceiverCompletedAnswers(receiverId) {
        let keys = Object.keys(test.questions);
        for (var i = 0; i < keys.length; i++) {
            let group = test.questions[keys[i]];
            for (var j = 0; j < group.length; j++) {
                let question = group[j];
                if (
                    ['openendedV2', 'ratingV2'].includes(question.type) &&
                    question.settings.answerQuestionsForEachTeammate &&
                    !isQuestionAnswered(question, [receiverId])
                ) {
                    return false;
                }
            }
        }
        return true;
    }
    return { canSubmitTest, isTestCompleted, isReceiverCompletedAnswers, isTestStarted };
}
