<template>
    <div class="create row">
        <div class="col-xs-12">
            <div>
                <kr-field
                    display="Name"
                    :form="form"
                    name="name"
                    :options="{
                        placeholder: 'e.g., My new course',
                        autofocus: 'autofocus',
                        required: true,
                    }"
                />
            </div>
            <div>
                <kr-field display="Course Code" :form="form" name="code" :options="{ placeholder: 'e.g., CR001' }" />
            </div>
            <div>
                <label class="control-label">Period <span class="important">*</span></label>
            </div>
            <div class="col-xs-12 flex marginBottom20">
                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="startDate"
                        :options="{
                            maxDate: form.model.endDate,
                            time: false,
                            placeholder: 'Select course start date',
                            hasLabel: false,
                        }"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="endDate"
                        :options="{
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select course end date',
                            hasLabel: false,
                        }"
                    />
                </div>
            </div>

            <kr-field
                display="Course Overview"
                :form="form"
                name="description"
                :options="{
                    type: 'text-area',
                    rows: 5,
                    placeholder: 'e.g., Knowledge is power',
                }"
            />

            <div>
                <label class="control-label" for="coverImage_preview_div"> Course Thumbnail </label>
                <kr-file-picture :form="form" name="coverImage" :options="{ width: '600', height: '600' }" />
            </div>
        </div>
        <!--<div class="objectiveContainer">
                <label class="control-label">LEARNING OBJECTIVES</label>
                <template v-for="(objective,idx) in form.model.objectives">
                    <div class="objectiveRow">
                        <span>{{idx+1}}</span>
                        <kr-field :form="form" :name="'objectives.'+idx" :options="{placeholder:'e.g., Explain relativity',hasLabel:false,showError:false}" @keyup.enter="addObjective()"></kr-field>
                        <button class="btn btn-default addBtn" v-if="form.model.objectives.length-1==idx" @click.prevent="addObjective()">
                            <i class="fa fa-plus"></i>
                        </button>
                        <button v-if="form.model.objectives.length!=1" class="btn btn-default deleteBtn" @click.prevent="deleteObjective(idx)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template>    
            </div>-->
    </div>
</template>
<script>
export default {
    props: ['form'],
    watch: {
        form: {
            handler: function () {
                /*FORCE*/
                if (this.form.model.objectives.length == 0) {
                    this.addObjective();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.form.model.objectives.length == 0) {
            this.addObjective();
        }
    },
    methods: {
        addObjective() {
            this.form.model.objectives.push('');
        },
        deleteObjective(idx) {
            this.form.model.objectives.splice(idx, 1);
        },
    },
};
</script>
<style scoped>
.objectiveContainer {
    margin-bottom: 30px;
}

.objectiveRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.objectiveRow:first-child {
    margin-bottom: 0;
}

.objectiveRow span {
    font-weight: bold;
    margin-right: 10px;
}
</style>
