<template>
    <!--PUT HTML HERE-->
    <div>
        <kr-search
            ref="students_listing"
            name="students_listing"
            :url="{ url: search_url }"
            :columns="columns"
            :options="options"
            class="overflowXinherit"
            @retrieve-data="getSearchData"
        >
            <template #ndata="props">
                <tr>
                    <td :colspan="props.model.total_column_count" class="borderNone">No students match your search / filter</td>
                </tr>
            </template>
            <template #lower-left>
                <div style="display: flex">
                    <div v-if="[2, 3].includes(auth.user().roleId)" class="marginLeft20">
                        <div class="form-group displayContent">
                            <div class="form-dropdown viewFilter marginLeft0">
                                <select
                                    v-model="query_fields.status"
                                    class="form-control"
                                    :aria-label="'Show ' + query_fields.status + ' status'"
                                    @change="$refs['students_listing'].debounced_search(true)"
                                >
                                    <option value="">All Status</option>
                                    <option value="Invite Sent">Invite Sent</option>
                                    <option value="Not Yet Invited">Not Yet Invited</option>
                                    <option v-if="![3].includes(hasPaidSettings)" value="Active">Active</option>
                                    <option v-if="[1, 3].includes(hasPaidSettings)" value="Active (Not Yet Paid)">Active (Not Yet Paid)</option>
                                    <option v-if="[1, 3].includes(hasPaidSettings)" value="Active (Paid)">Active (Paid)</option>
                                    <option v-if="[1, 3].includes(hasPaidSettings)" value="Lapsed">Lapsed</option>
                                    <option v-if="[1, 3].includes(hasPaidSettings)" value="Expired">Expired</option>
                                    <option value="Suspended">Suspended</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="[2, 3].includes(auth.user().roleId)" class="dropdown marginLeft20 new-custom-search-select">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn mainBtn"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-label="Search Course"
                        >
                            <template v-if="course === 'all'"> All Enrollments </template>
                            <template v-else-if="course === 'enrolled'"> Enrolled in any course </template>
                            <template v-else-if="course === 'not enrolled'"> Not enrolled in any course </template>
                            <template v-else>
                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                            </template>

                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                            <div class="form-group form-search has-feedback has-feedback-left">
                                <input v-model="searchData.q" type="text" class="form-control" placeholder="Search..." @keyup="search" />
                                <i class="fa fa-search form-control-feedback" aria-hidden="true"></i>
                            </div>

                            <ul>
                                <div
                                    v-if="searchData.q && (resultQuery.length / perPage).toFixed() >= 2 && resultQuery.length != 0"
                                    class="padding10 paddingTop0 flexSpaceBetween align-items"
                                >
                                    <a class="link noBg flexOnly align-items lineHeight1" href="#" :class="{ disabled: pageNumber == 0 }" @click="previous">
                                        <i class="fa fa-angle-double-left marginRight8" aria-hidden="true"></i>Prev
                                    </a>

                                    <span class="lineHeight1">
                                        Page {{ pageNumber + 1 }} of
                                        {{ (resultQuery.length / perPage).toFixed() }}
                                    </span>

                                    <a
                                        class="link noBg flexOnly align-items lineHeight1"
                                        href="#"
                                        :class="{
                                            disabled: pageNumber >= (resultQuery.length / perPage).toFixed() - 1,
                                        }"
                                        @click="next"
                                    >
                                        Next<i class="fa fa-angle-double-right marginLeft8" aria-hidden="true"></i>
                                    </a>
                                </div>

                                <template v-if="searchData.isSearching">
                                    <template v-for="course in resultQuery.slice(pageNumber * perPage, pageNumber * perPage + perPage)" :key="course.uuid">
                                        <li
                                            @click="
                                                $refs['students_listing'].debounced_search(true);
                                                select(course);
                                            "
                                            v-html="courseHighlight(course.code ? course.code + ' - ' + course.name : course.name)"
                                        ></li>
                                    </template>
                                </template>
                                <template v-else>
                                    <li
                                        key="allCourse"
                                        @click="
                                            select('all');
                                            $refs['students_listing'].debounced_search(true);
                                        "
                                    >
                                        All Enrollments
                                    </li>
                                    <li
                                        key="enrolledCourse"
                                        @click="
                                            select('enrolled');
                                            $refs['students_listing'].debounced_search(true);
                                        "
                                    >
                                        Enrolled in any course
                                    </li>
                                    <li
                                        key="notEnrolledCourse"
                                        @click="
                                            select('not enrolled');
                                            $refs['students_listing'].debounced_search(true);
                                        "
                                    >
                                        Not enrolled in any course
                                    </li>
                                    <label v-if="coursesList.length > 0" class="dropdownHeading">
                                        Course (Showing 8 of
                                        {{ coursesList.length }})
                                    </label>
                                    <template v-if="coursesList.length > 0">
                                        <template v-for="course in coursesList.slice(0, 8)" :key="course.uuid">
                                            <li
                                                @click="
                                                    select(course);
                                                    $refs['students_listing'].debounced_search(true);
                                                "
                                            >
                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                            </li>
                                        </template>
                                    </template>
                                </template>
                            </ul>
                        </div>
                    </div>

                    <div v-if="[2, 3].includes(auth.user().roleId)" class="viewFilter floatLeft marginLeft20">
                        <div class="form-group displayContent">
                            <div class="form-dropdown viewFilter marginLeft0">
                                <select
                                    v-model="query_fields.origin"
                                    class="form-control"
                                    :aria-label="query_fields.origin == '' ? 'Show ' + query_fields.origin : 'Show ' + query_fields.origin + ' origin'"
                                    @change="$refs['students_listing'].debounced_search(true)"
                                >
                                    <option value="">All Origins</option>
                                    <option value="Express Sign-in">Express Sign-in</option>
                                    <option value="LTI">LTI</option>
                                    <option value="Email">Email</option>
                                    <option value="Generic">Generic</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #upper-right>
                <div class="form-group displayContent">
                    <div class="form-dropdown viewFilter marginLeft0">
                        <select
                            v-model="selectedOption"
                            class="form-control"
                            @change="
                                $refs['students_listing'].debounced_search(true);
                                changeValue();
                            "
                        >
                            <option value="">Firstname Lastname</option>
                            <option value="Lastname, Firstname">Lastname, Firstname</option>
                        </select>
                    </div>
                </div>

                <button class="btn btn-primary" aria-label="Export" @click="downloadStudents">
                    <i class="fa fa-download marginRight8" aria-hidden="true"></i>Export
                </button>
            </template>
            <template #table-title="props">
                <th colspan="6">
                    <template v-if="props.model.meta.paginator.total > 0">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </template>
                </th>
            </template>
            <template #bottom-display-from-to="props">
                <div v-if="props.model.meta.paginator.total > 0" class="displayNo fontBold">
                    Showing {{ props.model.from }} - {{ props.model.to }} of
                    {{ props.model.meta.paginator.total }}
                </div>
            </template>
            <template #cdata="props">
                <td>
                    <!-- <router-link
            v-if="props.model.uuid != auth.user().uuid"
            v-tooltip="{ content: 'View Student' }"
            :to="{
              name: 'students.view',
              params: { id: props.model.uuid },
            }"
            :class="{ link: props.model.uuid != auth.user().uuid }"
            :aria-label="
              'click here to View student' +
                props.model.displayName +
                ' profile'
            "
            v-html="highlight(props.model.displayName)"
          >
          </router-link>
          <template v-else>
            {{ props.model.displayName }}
          </template> -->
                    <template v-if="selectedOption == 'Lastname, Firstname'">
                        <router-link
                            v-if="props.model.uuid != auth.user().uuid"
                            v-tooltip="{ content: 'View Student' }"
                            :to="{
                                name: 'students.view',
                                params: { id: props.model.uuid },
                            }"
                            :class="{
                                link: props.model.uuid != auth.user().uuid,
                            }"
                            :aria-label="
                                'click here to View student' + (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname + ' profile'
                            "
                            v-html="highlight((props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname)"
                        >
                        </router-link>
                        <template v-else>
                            {{ (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname }}
                        </template>
                    </template>
                    <template v-else>
                        <router-link
                            v-if="props.model.uuid != auth.user().uuid"
                            v-tooltip="{ content: 'View Student' }"
                            :to="{
                                name: 'students.view',
                                params: { id: props.model.uuid },
                            }"
                            :class="{
                                link: props.model.uuid != auth.user().uuid,
                            }"
                            :aria-label="
                                'click here to View student' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + ' profile'
                            "
                            v-html="highlight(props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : ''))"
                        >
                        </router-link>
                        <template v-else>
                            {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                        </template>
                    </template>
                    <template v-if="props.model.hasTeacherAccount">
                        <div style="color: #717171; font-style: italic; font-size: 14px">(Also a teacher)</div>
                    </template>
                </td>
                <td v-if="[1].includes(auth.user().roleId)" class="hidden-xs hidden-sm" tabindex="0">
                    <router-link
                        :to="{
                            name: 'accounts.view',
                            params: { id: props.model.account.uuid },
                        }"
                        class="link"
                        :aria-label="'student organisation name ' + props.model.account.organisationName"
                    >
                        {{ props.model.account.organisationName }}
                    </router-link>
                </td>
                <td
                    class="hidden-xs hidden-sm"
                    tabindex="0"
                    :aria-label="'student email ' + props.model.email ? props.model.email : '-'"
                    v-html="highlight(props.model.email ? props.model.email : '-')"
                ></td>
                <td
                    class="hidden-xs hidden-sm"
                    tabindex="0"
                    :aria-label="'student organisation id ' + props.model.organisationID ? props.model.organisationID : ''"
                    v-html="highlight(props.model.organisationID ? props.model.organisationID : '')"
                ></td>
                <td
                    v-if="[1, 2, 3].includes(auth.user().roleId)"
                    class="hidden-xs hidden-sm whiteSpaceNowrap"
                    tabindex="0"
                    :aria-label="'student account status ' + props.model.status"
                >
                    <i
                        class="fa fa-circle"
                        :class="{
                            suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(props.model.status),
                            readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(props.model.status),
                            activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(props.model.status),
                        }"
                    >
                    </i>
                    &nbsp;{{ props.model.status }}
                </td>
                <td class="hidden-xs hidden-sm whiteSpaceNowrap" tabindex="0" :aria-label="'student account origin ' + props.model.origin">
                    {{ props.model.origin }}
                </td>
                <td class="py-9px">
                    <div class="dropdown setting dropdown-new">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn drop-down"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-lable="Actions"
                            @click="dropdownToggle"
                        >
                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                            <ul>
                                <li>
                                    <router-link
                                        v-tooltip="{ content: 'View Student' }"
                                        class="primary-state"
                                        :to="{
                                            name: 'students.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        :aria-label="'View student ' + props.model.displayName + ' profile'"
                                    >
                                        <span> <i class="fas fa-eye marginRight8" aria-hidden="true"></i>View </span>

                                        <!-- <span>⇧⌘D</span> -->
                                    </router-link>
                                </li>
                                <li v-if="[1, 2, 3].includes(auth.user().roleId) && props.model.email">
                                    <a
                                        v-tooltip="{
                                            content: 'Reset Password',
                                        }"
                                        class="primary-state"
                                        href="#"
                                        :aria-labelledby="'Reset ' + props.model.displayName + ' password'"
                                        aria-label="Reset Password"
                                        @click.prevent="openResetPasswordModal(props.model)"
                                    >
                                        <span> <i class="fa fa-key marginRight8" aria-hidden="true"></i>Reset Password </span>

                                        <!-- <span>⇧⌘D</span> -->
                                    </a>
                                </li>

                                <hr />

                                <li v-if="props.model.status != 'Suspended' && canSuspend">
                                    <a
                                        v-tooltip="{
                                            content: 'Suspend Student',
                                        }"
                                        class="danger-state"
                                        href="#"
                                        :class="{
                                            disabled: props.model.hasTeacherAccount,
                                        }"
                                        aria-label="Suspend Student"
                                        @click.prevent="openSuspendModal(props.model)"
                                    >
                                        <span> <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend </span>
                                    </a>
                                </li>

                                <li v-else-if="props.model.status == 'Suspended' && canSuspend">
                                    <a
                                        v-tooltip="{
                                            content: 'Reinstate Student',
                                        }"
                                        class="primary-state"
                                        href="#"
                                        aria-label="Reinstate Student"
                                        @click.prevent="openSuspendModal(props.model)"
                                    >
                                        <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>
                                    </a>
                                </li>

                                <li v-else-if="auth.user().roleId == 1">
                                    <a
                                        v-tooltip="{
                                            content: 'Set Student As Paid',
                                        }"
                                        class="primary-state"
                                        href="#"
                                        aria-label="Set Student As Paid"
                                        @click.prevent="openSetStudentAsPaidModal(props.model)"
                                    >
                                        <span> <i class="fa fa-credit-card marginRight8" aria-hidden="true"></i>Set Student As Paid </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
            </template>
        </kr-search>
        <div id="studentSuspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="studentSuspendModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="studentSuspendModal-title" class="modal-title">
                            <template v-if="user.status != 'Suspended'"> Suspend </template>
                            <template v-else> Reinstate </template>
                            {{ user.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="user.status != 'Suspended'" class="col-xs-12">
                                <div class="textAlignCenter">
                                    Are you sure you want to suspend
                                    <span class="fontBold">{{ user.displayName }}</span
                                    >?
                                </div>
                                <div class="textAlignCenter">
                                    He/She will no longer be able to sign in to InteDashboard. No notifications will be sent to him/her.
                                </div>
                            </div>
                            <div v-else class="col-xs-12">
                                <div class="textAlignCenter">
                                    You are about to reinstate
                                    {{ user.displayName }}
                                </div>
                                <div class="textAlignCenter">Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row marginTop30">
                            <div class="flex">
                                <div v-if="user.status != 'Suspended'" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Suspend" @click.prevent="suspend()">
                                        <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Reinstate" @click.prevent="unsuspend()">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <kr-reset-password></kr-reset-password> -->
    </div>
</template>

<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            columns: {},
            options: {
                newServerMode: {
                    includes: ['q', 'isSuspended', 'sort', 'order', 'origin', 'courseUuid', 'status', 'enrolled'],
                },
            },
            query_fields: { status: '' },
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: {},
            },
            coursesList: [],
            course: 'all',
            search_url: '/students',
            user: {},
            resultQuery: [],
            perPage: 10,
            pageNumber: 0,
            selectedOption: '',
        };
    },
    computed: {
        hasPaidSettings() {
            if (this.auth.user().roleId == 1) {
                return 1;
            } else if (['Paid', 'Paid-Trial'].includes(_.get(this.auth.user(), 'account.type'))) {
                if (['Institution'].includes(_.get(this.auth.user(), 'account.paymentMethod'))) {
                    return 2;
                } else {
                    return 3;
                }
            }
            return false;
        },
        canSuspend() {
            return this.auth.user().roleId == 2 || (this.auth.user().roleId == 3 && this.auth.user().canAddTeacher);
        },
    },
    created() {
        var that = this;
        if ([1, 2, 3].includes(this.auth.user().roleId)) {
            if ([1].includes(this.auth.user().roleId)) {
                this.columns = {
                    firstName: 'display:FIRST NAME|sortable:true|width:20%|subDisplay:LAST NAME|subSortable:true|src:firstname|subSrc:lastname',
                    account: 'display:ACCOUNT|sortable:true|mobile:hide',
                    email: 'display:EMAIL|sortable:true|mobile:hide|minWidth:20%',
                    organisationID: 'display:STUDENT ID|sortable:true|mobile:hide|minWidth:20%',
                    status: 'display:STATUS|sortable:true|mobile:hide|width:100px|maxWidth:192px|minWidth:100px',
                    origin: 'display:ORIGIN|sortable:true|mobile:hide|width:100px|maxWidth:138px|minWidth:100px',
                    action: 'display:|sortable:false|width:132px',
                };
            } else {
                this.query_fields.origin = '';
                this.query_fields.courseUuid = '';
                this.query_fields.enrolled = '';
                /*this.$set(this.query_fields, 'origin', '');
                this.$set(this.query_fields, 'courseUuid', '');
                this.$set(this.query_fields, 'enrolled', '');*/

                this.columns = {
                    firstName: 'display:FIRST NAME|sortable:true|width:auto|subDisplay:LAST NAME|subSortable:true|src:firstname|subSrc:lastname',
                    email: 'display:EMAIL|sortable:true|mobile:hide|minWidth:20%',
                    organisationID: 'display:STUDENT ID|sortable:true|mobile:hide|minWidth:20%',
                    status: 'display:STATUS|sortable:true|mobile:hide|width:100px|maxWidth:192px|minWidth:100px',
                    origin: 'display:ORIGIN|sortable:true|mobile:hide|width:100px|maxWidth:138px|minWidth:100px',
                    action: 'display:|sortable:false|width:132px',
                };
            }
        } else {
            this.columns = {
                firstName: 'display:FIRST NAME|sortable:true|width:34.5%|subDisplay:LAST NAME|subSortable:true|src:firstname|subSrc:lastname',
                email: 'display:EMAIL|sortable:true|mobile:hide',
                organisationID: 'display:STUDENT ID|sortable:true|mobile:hide',
                action: 'display:|sortable:false|width:132px',
            };
        }

        if (this.hasPaidSettings == 3) {
            this.query_fields.status = 'Active (Paid)';
        }
        if ([1].includes(this.auth.user().roleId)) {
            this.search_url = '/users';
            this.query_fields.role = 4;
            this.component_done_loading = true;
        } else {
            axios.get('courses/list').then(function (response) {
                that.coursesList = response.data.data;
                that.component_done_loading = true;
            });
        }
        this.options = {
            per_page: 10,
            query_fields: this.query_fields,
            default_query_fields: { sort: 'dateCreated', order: 'desc' },
            action_column: false,
            pageName: 'Students',
            newServerMode: {
                includes: ['q', 'isSuspended', 'sort', 'order', 'origin', 'courseUuid', 'status', 'enrolled'],
            },
        };
    },
    methods: {
        downloadStudents() {
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: `students?filter[q]=${this.searchData.query.q}&filter[origin]=${this.searchData.query.origin}&filter[status]=${this.searchData.query.status}&filter[courseUuid]=${this.searchData.query.courseUuid}&filter[enrolled]=${this.searchData.query.enrolled}&export=true`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Students_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openSuspendModal(user) {
            let that = this;
            that.user = user;
            $('#studentSuspendModal').modal('show');
        },
        highlight(text) {
            if (this.searchData.query.q != '') {
                if (text != null) {
                    return text.replace(new RegExp(this.searchData.query.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
                }
            } else {
                return text;
            }
        },
        getSearchData(data) {
            this.searchData = data;
        },
        openResetPasswordModal(user) {
            Events.fire('open-reset-password-modal', { user: user });
        },
        select(option) {
            var that = this;
            this.course = false;
            this.query_fields.courseUuid = '';
            if (option === 'all') {
                this.course = 'all';
                this.query_fields.enrolled = '';
            } else if (option === 'enrolled') {
                this.course = 'enrolled';
                this.query_fields.enrolled = 1;
            } else if (option === 'not enrolled') {
                this.course = 'not enrolled';
                this.query_fields.enrolled = 0;
            } else {
                this.query_fields.courseUuid = option.uuid;
                if (this.query_fields.courseUuid != '') {
                    this.course = _.find(this.coursesList, function (o) {
                        return o.uuid == that.query_fields.courseUuid;
                    });
                }
            }
        },
        next(event) {
            this.pageNumber++;
            event.stopPropagation();
        },
        previous(event) {
            this.pageNumber--;
            event.stopPropagation();
        },
        courseHighlight(text) {
            if (this.searchData.q != '') {
                if (text != null) {
                    return text.replace(new RegExp(this.searchData.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
                }
            } else {
                return text;
            }
        },
        search() {
            this.resultQuery = [];
            this.pageNumber = 0;

            if (this.searchData.q.match(/[a-z]/i) != null) {
                this.searchData.isSearching = true;
                this.searchData.matched = 0;
                for (var i = 0; i < this.coursesList.length; i++) {
                    this.coursesList[i]._matched = 0;
                    //Vue.set(this.coursesList[i], '_matched', 0);
                    let course = this.coursesList[i];
                    let haystack = (course.name + course.code + '').toLowerCase();
                    let doesContain = haystack.indexOf(this.searchData.q.toLowerCase());
                    if (doesContain != -1) {
                        course._searchDisplay = true;
                        //Vue.set(course, '_searchDisplay', true);
                        this.searchData.matched++;
                        this.coursesList[i]._matched++;

                        this.resultQuery.push(this.coursesList[i]);
                    } else {
                        course._searchDisplay = false;
                        //Vue.set(course, '_searchDisplay', false);
                    }
                }
            } else {
                this.searchData.isSearching = false;
            }
        },
        dropdownToggle() {
            if ($('table tbody tr').length >= 10) {
                $('table tbody tr:nth-last-child(-n+4) td:last-child div').addClass('dropup');
            } else if ($('table tbody tr').length >= 5) {
                $('table tbody tr:nth-last-child(-n+2) td:last-child div').addClass('dropup');
            }
        },
        suspend() {
            $('#studentSuspendModal').modal('hide');

            var success = function (response) {
                this.user.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
                Events.fire('students_listing_refresh');
            }.bind(this);
            var del = function () {
                this.user.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.user.uuid + '/suspend', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#studentSuspendModal').modal('hide');
            var success = function (response) {
                var that = this;
                this.user = response.data.data;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been reinstated',
                });
                Events.fire('students_listing_refresh');
            }.bind(this);
            var del = function () {
                this.user.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.user.uuid + '/reinstate', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        async openSetStudentAsPaidModal(user) {
            let that = this;
            await axios.get('/users/' + user.uuid).then(function (response) {
                that.user = response.data.data;
            });

            $('#studentAsPaidModal').modal('show');
        },
        setAsPaid() {
            var success = function (response) {
                this.user.status = 'Active (Paid)';
                this.user.paidDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been set as paid',
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            this.form.put(`users/${this.user.uuid}/paid`).then(function (response) {
                if (!response.krFormError) {
                    success(response);
                } else {
                    del();
                }
            });
        },
        changeValue() {
            if ([1, 2, 3].includes(this.auth.user().roleId)) {
                if (this.selectedOption == '') {
                    const propertyPosition = Object.keys(this.columns).indexOf('lastName');
                    this.columns.firstName = this.columns.lastName;
                    //Vue.set(this.columns, 'firstName', this.columns.lastName);
                    delete this.columns.lastName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        firstName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.firstName = 'display:FIRST NAME|sortable:true|width:20%|subDisplay:LAST NAME|subSortable:true|src:firstname|subSrc:lastname';
                    /*Vue.set(
                        this.columns,
                        'firstName',
                        'display:FIRST NAME|sortable:true|width:20%|subDisplay:LAST NAME|subSortable:true|subSrc:lastName'
                    );*/
                } else {
                    const propertyPosition = Object.keys(this.columns).indexOf('firstName');
                    this.columns.lastName = this.columns.firstName;
                    //Vue.set(this.columns, 'lastName', this.columns.firstName);
                    delete this.columns.firstName;
                    this.columns = {
                        ...Object.keys(this.columns)
                            .slice(0, propertyPosition)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                        lastName: Object.values(this.columns)[propertyPosition],
                        ...Object.keys(this.columns)
                            .slice(this.columns.length)
                            .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    };
                    this.columns.lastName = 'display:LAST NAME|sortable:true|width:20%|subDisplay:FIRST NAME|subSortable:true|src:firstname|subSrc:firstname';
                    /*Vue.set(
                        this.columns,
                        'lastName',
                        'display:LAST NAME|sortable:true|width:20%|subDisplay:FIRST NAME|subSortable:true|subSrc:firstName'
                    );*/
                }
            }
        },
    },
};
</script>
