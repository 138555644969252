<template>
    <div>
        <textarea v-model="value" placeholder="Type your answers here" rows="5" :disabled="disabled ? true : undefined"></textarea>
        <div
            class="fontSize16 marginTop10"
            :class="{
                dangerColor: !isCorrect(value),
                greyColor: isCorrect(value),
            }"
            aria-role="alert"
        >
            <template v-if="computeNoOfWords(value) < question.minimum && question.minimum != 0">
                Please enter
                {{ question.minimum - computeNoOfWords(value) }}
                more
                {{ question.minimum - computeNoOfWords(value) == 1 ? 'word' : 'words' }}
            </template>
            <template v-else-if="computeNoOfWords(value) <= question.maximum">
                {{ computeNoOfWords(value) }}
                {{ computeNoOfWords(value) < 2 ? 'word' : 'words' }}
                (max {{ question.maximum }})
            </template>
            <template v-else-if="computeNoOfWords(value) > question.maximum">
                {{ computeNoOfWords(value) }}
                {{ computeNoOfWords(value) < 2 ? 'word' : 'words' }}
                (max {{ question.maximum }})
            </template>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { computed } from 'vue';
const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    question: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelModifiers: { default: () => ({}) },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const isCorrect = (str = '') => {
    let noOfWords = 0;
    noOfWords = computeNoOfWords(str);
    if ((noOfWords < props.question.minimum && props.question.minimum != 0) || (noOfWords > props.question.maximum && props.question.maximum != null)) {
        return false;
    }
    return true;
};

const computeNoOfWords = (str = '') => {
    str = String(str ? str : '');
    var length = str.split(/[^\s]+/).length - 1;
    return length;
};
</script>
