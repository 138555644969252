<template>
    <div v-if="componentDoneLoading">
        <div class="topBar_activity_details_placeholder" @load="updateTopBarActivityDetailsHeight"></div>
        <div ref="topBarActivityDetails" class="top-bar-activity-details-container">
            <div class="top-bar-activity-details">
                <label>
                    <template v-if="testObj.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                    | {{ testObj.name }}
                </label>
            </div>

            <div class="top-bar-current-evaluate">
                Evaluating:
                <template v-if="testSettings.currentStudentIdx != null">
                    <label :class="{ colorSelf: testObj.team[testSettings.currentStudentIdx].self }">
                        {{ testObj.team[testSettings.currentStudentIdx].displayName }}
                        <template v-if="testObj.team[testSettings.currentStudentIdx].self"> (Me) </template>
                    </label>
                </template>
                <template v-else>
                    <label>
                        {{ testObj.questions[testSettings.currentQuestionGroupIdx + 1][0].question.name }}
                    </label>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue';

const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    testSettings: {
        type: Object,
        default: () => ({}),
    },
});

const componentDoneLoading = ref(false);
const topBarActivityDetails = ref(null);

const updateTopBarActivityDetailsHeight = () => {
    $('.topBar_activity_details_placeholder').height(topBarActivityDetails.value.clientHeight + 'px');
    $('.top-bar-activity-details-container').css('top', $('.test_topBar_v2').height() + 32 + 'px');
};

const questionBreakIdx = ref(0);
const initQuestions = () => {
    for (const [key, value] of Object.entries(props.testObj.questions)) {
        let qGroup = value[0];
        if (!qGroup.settings.answerQuestionsForEachTeammate || qGroup.type == 'pointDistribution') {
            questionBreakIdx.value = key;
        } else {
            break;
        }
    }
};

watch(props.testObj, () => {
    initQuestions();
});

watch(props.testSettings, () => {
    nextTick(() => {
        updateTopBarActivityDetailsHeight();
    });
});

onMounted(() => {
    componentDoneLoading.value = true;

    nextTick(() => {
        updateTopBarActivityDetailsHeight();
    });

    window.addEventListener('resize', updateTopBarActivityDetailsHeight);
    initQuestions();
});

onUnmounted(() => {
    window.removeEventListener('resize', updateTopBarActivityDetailsHeight);
});
</script>
