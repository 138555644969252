<template>
    <div v-if="component_done_loading" id="account-edit" class="col-xs-12 right-container">
        <!-- <div class="page-title">
	        <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
	        	<router-link :to="{name:'accounts.index'}">Accounts</router-link>
	            / Edit Account
	            <h3 style="margin-bottom:0;">Edit Account</h3>
	        </div>
    	</div>
    	<div class="clearfix"></div> -->
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">EDIT ACCOUNT</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <!--<router-link   id="top-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                            <!--<router-link  id="top-mobile-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <account-form :form="form" mode="update" :account="account" :superusers="superusers"></account-form>
                        <div class="profileBtns buttonGroup marginTop30">
                            <button
                                id="bottom-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
        <!-- <div class="col-md-12 form-div">
			<kr-panel>
				<template #title>
					<div class="x_title">
						EDIT Account
					</div>	
				</template>
			    <template #content>
			        <account-form :form="form" mode="update"></account-form>
			    </template>

			    <template slot="footer">
			      <div class="col-xs-12">
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Update </button>
			      </div>  
			    </template>
		    </kr-panel>
		</div> -->
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            account: {},
            form: new KrForm({
                fields: {
                    avatar: '',
                    organisationName: '',
                    //accountName:'',
                    type: 'sendType!:readonly',
                    paymentMethod: 'sendType!:readonly',
                    firstname: '',
                    lastname: '',
                    email: '',
                    identity: '',
                    startDate: '',
                    expiryDate: '',
                    //paymentPlans:'type!:array',
                    jobDescription: '',
                    influencerLevel: 'default!:Nano-influencers',
                    segment: 'default!:Higher Ed',
                    fieldOfDiscipline: 'sendType!:readonly|default!:Business',
                    otherFieldOfDiscipline: '',
                    country: '',
                    stateOrCity: '',
                    timezone: '',
                    accountOwnerUuid: '',
                    version: 'default!:full',
                    lms: '',
                },
                props: {},
                urls: {
                    put: 'accounts/' + this.$route.params.id,
                },
            }),
            superusers: [],
        };
    },
    components: {
        'account-form': require(`./form-partials/partial.account.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.organisationName && this.form.model.type && this.form.model.firstname && this.form.model.lastname && this.form.model.email) {
                if ((this.form.model.type == 'Paid' || this.form.model.type == 'Paid Trial') && this.form.model.paymentMethod == 'Student-Paid') {
                    /*if(!(this.form.model.paymentPlans[0].name!=''&&this.form.model.paymentPlans[0].licensePeriod!=''&&this.form.model.paymentPlans[0].price!='')){
                        	return false;
                    	}*/
                    /*if(!this.form.model.startDate||!this.form.model.expiryDate||!this.form.model.paymentMethod){
	    					return false;
	    				}*/
                }
                if ((this.form.model.type == 'Paid' || this.form.model.type == 'Paid Trial') && this.form.model.paymentMethod == 'Institution') {
                    if (!this.form.model.startDate || !this.form.model.expiryDate || !this.form.model.paymentMethod) {
                        return false;
                    }
                }
                if (this.form.model.type == 'Demo') {
                    if (!this.form.model.startDate || !this.form.model.expiryDate) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }, { name: 'Edit Account' }],
        });

        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            axios.all([axios.get('/accounts/' + this.$route.params.id), axios.get('/superusers')]).then(
                axios.spread(function (account, superusers) {
                    that.form.setModel(account.data.data);
                    that.form.model.firstname = _.get(account.data.data, 'adminTeacherAccount.firstname');
                    that.form.model.lastname = _.get(account.data.data, 'adminTeacherAccount.lastname');
                    that.form.model.email = _.get(account.data.data, 'adminTeacherAccount.email');
                    that.form.model.jobDescription = _.get(account.data.data, 'adminTeacherAccount.jobDescription');
                    that.form.model.segment = _.get(account.data.data, 'adminTeacherAccount.segment');
                    that.form.model.fieldOfDiscipline = _.get(account.data.data, 'adminTeacherAccount.fieldOfDiscipline');
                    that.account = account.data.data;
                    that.superusers = superusers.data;
                    that.component_done_loading = true;
                })
            );
        },
        submit() {
            var that = this;
            //this.form.model.paymentPlans.splice(this.form.model.paymentPlans.length-1,1);
            /*if((this.form.model.type=='Paid'||this.form.model.type=='Paid Trial')&&this.form.model.paymentMethod=='Student-Paid'){
					if(['',null,0].includes(this.form.model.paymentPlans[1].price)){
						this.form.model.paymentPlans[1].price = 0;
					}
				}*/
            this.form.put().then(function (response) {
                //Events.fire('addPaymentPlan');
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.id },
                    });
                }
            });
        },
    },
};
</script>
