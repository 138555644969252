<template>
    <div class="x_panel">
        <slot name="heading">
            <div v-if="hasTitle" class="x_title">
                <div v-if="canToggle" class="krpanelToggle flexSpaceBetween align-items">
                    <slot name="title" />
                </div>
                <template v-else>
                    <slot name="title" />
                </template>
                <!-- <div class="pull-left">
                	<slot name="upper-left">
                	</slot>
                </div> -->
                <div v-if="canToggle" class="pull-right">
                    <slot name="upper-right" />
                    <a class="collapse-link" @click.prevent="toggle">
                        <template v-if="isOpened"> Less </template>
                        <template v-else> More </template>
                        <i
                            :class="{
                                'fa fa-caret-up': isOpened,
                                'fa fa-caret-down': !isOpened,
                            }"
                            aria-hidden="true"
                        />
                    </a>
                </div>
            </div>
        </slot>
        <div v-show="isOpened" class="x_content">
            <!-- <p  v-if="hasTitle">{{secondary_title}}</p> -->
            <slot name="content"> </slot>
        </div>
        <div v-show="isOpened" v-if="hasFooter" class="x_footer" style="pull-right">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'secondary_title', 'withTitle', 'withFooter', 'options'], //pass to inside component
    data() {
        return {
            hasTitle: _.get(this, 'withTitle', true),
            hasFooter: _.get(this, 'withFooter', true),
            canToggle: false,
            isOpened: true,
        };
    },
    watch: {
        withFooter: {
            deep: true,
            handler() {
                this.hasFooter = _.get(this, 'withFooter', true);
            },
        },
    },
    mounted() {
        this.isOpened = _.get(this.options, 'isOpened', true);
        this.canToggle = _.get(this.options, 'canToggle', false);
    },
    methods: {
        toggle(event) {
            /*var $BOX_PANEL = $(event.target).closest('.x_panel'),
	            $ICON = $(event.target).find('i').prevObject,
	            $BOX_CONTENT = $BOX_PANEL.find('.x_content');
	        	console.log($ICON);
	        // fix for some div with hardcoded fix class
	        if ($BOX_PANEL.attr('style')) {
	            $BOX_CONTENT.slideToggle(200, function(){
	                $BOX_PANEL.removeAttr('style');
	            });
	        } else {
	            $BOX_CONTENT.slideToggle(200); 
	            $BOX_PANEL.css('height', 'auto');  
	        }*/
            this.isOpened = !this.isOpened;
            //$ICON.toggleClass('fa-chevron-up fa-chevron-down');
        },
    },
};
</script>
<style lang="scss" scoped>
.x_panel {
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    border: solid 1px #d8d8d8;
    padding-left: 0px;
    margin-bottom: 20px;
}

.x_panel:first-child {
    padding-left: 0;
}

.x_panel:last-child,
.marginBottom0 .x_panel {
    margin-bottom: 0;
}

.x_title {
    padding: 15px 20px;
    margin: 0;
    // font-size: 16px;
    font-size: 0.889rem;
    font-weight: bold;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-bottom: solid 1px #d8d8d8;
    display: flex;
    align-items: center;
    line-height: 1;
    min-height: 67px;

    .dropdown {
        &.open #viewDropdownMenuButton {
            margin-bottom: 0;
        }

        button {
            span {
                color: #222;
            }
        }
    }

    .caret {
        color: #fff;
    }

    h1,
    h2 {
        font-size: 0.88rem;
        margin: 0;
    }
}

.x_title p {
    margin: 0;
    font-weight: normal;
}

.x_content {
    padding: 15px 20px;
    position: inherit;
    float: none;
    margin-top: 0;
}

.cardsCon .x_content {
    padding: 20px 20px 0 0;
}

#lmsIntegration .x_content {
    padding: 0;
}

.questionIndex .x_title {
    line-height: 2.3;
}

.pull-right {
    position: absolute;
    right: 20px;
    font-size: 1rem;
}

.pull-right a {
    color: #222;
}

.krpanelToggle {
    width: calc(100% - 48px);
}

.new-panel {
    .x_content {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .questionIndexPanel .x_panel {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .dashboardCols .x_panel {
        margin-bottom: 0;
    }
}
</style>
