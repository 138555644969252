<template>
    <div class="table-responsive newEvaluationTable aggregatedScoreTable">
        <table class="table">
            <thead>
                <tr>
                    <th rowspan="3" class="width150px">Assessee</th>
                    <th rowspan="3" class="width150px">Status</th>
                    <th :colspan="5 * countNumbersOfCriteriaRating">Breakdown of Scores By Criteria</th>
                    <th rowspan="3" class="width85px minWidth85px maxWidth85px">Sum Of All Criteria Scores</th>
                    <th rowspan="3" class="width85px minWidth85px maxWidth85px">Team Sum Of All Criteria Scores</th>
                    <th rowspan="3" class="warningColor width85px minWidth85px maxWidth85px">Average Score By Self</th>
                    <th rowspan="3" class="width108px minWidth108px maxWidth108px">Average Score By Teammates</th>
                    <th rowspan="3" class="width80px minWidth80px maxWidth80px">PAF</th>
                    <th rowspan="3" class="width80px minWidth80px maxWidth80px">SAPA</th>
                </tr>

                <tr>
                    <template v-for="(qGroup, gGroupIdx) in testObj.questions">
                        <th :colspan="5" class="tableHeaderMaxWidth400px">
                            {{ qGroup[0].question.name }}
                        </th>
                    </template>
                </tr>

                <tr>
                    <template v-for="(qGroup, gGroupIdx) in testObj.questions">
                        <th class="width85px minWidth85px maxWidth85px">Sum Of Ratings</th>
                        <th class="width85px minWidth85px maxWidth85px">Team Average Sum Of Ratings</th>
                        <th class="width85px minWidth85px maxWidth85px">Average Rating</th>
                        <th class="width85px minWidth85px maxWidth85px">Team Average Rating</th>
                        <th class="width85px minWidth85px maxWidth85px">Team Sum Of Ratings</th>
                    </template>
                </tr>
            </thead>

            <tbody>
                <template v-for="(student, studentIdx) in testObj.teams.students[parseInt(selectedTeamId)]">
                    <tr :class="{ notFirstRow: studentIdx != 0, isFirstRow: studentIdx == 0 }">
                        <td>
                            <span
                                v-if="checkPAFSAPA(student)"
                                v-tooltip="'PAF not in the range of 0.75 - 1.5 or SAPA is not 1'"
                                class="marginRight8"
                                tabindex="0"
                                role="button"
                                aria-label="PAF not in the range of 0.75 - 1.5 or SAPA is not 1"
                            >
                                <i class="fa fa-flag important" aria-hidden="true" />
                            </span>
                            {{ student.displayName }}
                        </td>

                        <td>
                            <div
                                class="tableStatusTag"
                                :class="{
                                    'tableStatusTag-danger': ['timed out', 'not started', null].includes(student.status),
                                    'tableStatusTag-warning': ['ongoing', 'started'].includes(student.status),
                                    'tableStatusTag-success': ['completed', 'finished'].includes(student.status),
                                }"
                            >
                                <template v-if="student.status == 'timed out'"> Timed Out </template>
                                <template v-else-if="student.status == 'not started' || student.status == null"> Not Started </template>
                                <template v-else-if="student.status == 'ongoing' || student.status == 'started'"> Ongoing </template>
                                <template v-else-if="student.status == 'completed'"> Submitted </template>
                                <template v-else-if="student.status == 'finished'"> Finished </template>
                            </div>
                        </td>

                        <template v-for="(qGroup, gGroupIdx) in testObj.questions">
                            <template v-if="['pointDistribution', 'ratingV2'].includes(qGroup[0].type)">
                                <template v-if="getCriteriaById(qGroup[0].id)">
                                    <td>
                                        {{
                                            getCriteriaById(qGroup[0].id).summary.perReceiver[student.id]
                                                ? getCriteriaById(qGroup[0].id).summary.perReceiver[student.id].totalPoints
                                                : '-'
                                        }}
                                    </td>

                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">
                                        {{ getCriteriaById(qGroup[0].id).summary.teamAverageSum }}
                                    </td>

                                    <td>
                                        {{
                                            getCriteriaById(qGroup[0].id).summary.perReceiver[student.id]
                                                ? getCriteriaById(qGroup[0].id).summary.perReceiver[student.id].average
                                                : '-'
                                        }}
                                    </td>

                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">
                                        {{ getCriteriaById(qGroup[0].id).summary.teamAverage }}
                                    </td>

                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">
                                        {{ getCriteriaById(qGroup[0].id).summary.teamSumRatings }}
                                    </td>
                                </template>
                                <template v-else>
                                    <td>-</td>
                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">-</td>
                                    <td>-</td>
                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">-</td>
                                    <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">-</td>
                                </template>
                            </template>
                            <template v-else>
                                <td>N/A</td>
                                <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">N/A</td>
                                <td>N/A</td>
                                <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">N/A</td>
                                <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">N/A</td>
                            </template>
                        </template>
                        <template v-if="Object.keys(scores).length == 0 || !scores.aggregateScores.totalPerReceiver[student.id]">
                            <td>-</td>
                            <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">-</td>
                            <td class="warningColor">-</td>
                            <td :class="{ disabled: props.testObj.evaluationSettings.target == 'self' }">-</td>
                            <td :class="{ disabled: props.testObj.evaluationSettings.target == 'self' }">-</td>
                            <td :class="{ disabled: props.testObj.evaluationSettings.target == 'self' }">-</td>
                        </template>
                        <template v-else-if="scores.aggregateScores.totalPerReceiver[student.id]">
                            <td>{{ scores.aggregateScores.totalPerReceiver[student.id].totalPointsForAllCriteria }}</td>
                            <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[parseInt(selectedTeamId)].length">
                                {{ scores.aggregateScores.totalTeamPoints }}
                            </td>
                            <td class="warningColor">{{ scores.aggregateScores.totalPerReceiver[student.id].averageScoreBySelf }}</td>
                            <td :class="{ disabled: props.testObj.evaluationSettings.target == 'self' }">
                                {{
                                    props.testObj.evaluationSettings.target == 'self'
                                        ? '-'
                                        : scores.aggregateScores.totalPerReceiver[student.id].averageScoreByTeammates
                                }}
                            </td>
                            <td
                                :class="{
                                    disabled: props.testObj.evaluationSettings.target == 'self',
                                    important:
                                        scores.aggregateScores.totalPerReceiver[student.id].averagePaf > 1.5 ||
                                        scores.aggregateScores.totalPerReceiver[student.id].averagePaf < 0.75,
                                }"
                            >
                                {{
                                    props.testObj.evaluationSettings.target == 'self'
                                        ? '-'
                                        : scores.aggregateScores.totalPerReceiver[student.id].averagePaf !== null
                                        ? scores.aggregateScores.totalPerReceiver[student.id].averagePaf
                                        : '-'
                                }}
                            </td>
                            <td
                                :class="{
                                    disabled: props.testObj.evaluationSettings.target == 'self',
                                    important: scores.aggregateScores.totalPerReceiver[student.id].averageSapa != 1,
                                }"
                            >
                                {{
                                    props.testObj.evaluationSettings.target == 'self'
                                        ? '-'
                                        : scores.aggregateScores.totalPerReceiver[student.id].averageSapa !== null
                                        ? scores.aggregateScores.totalPerReceiver[student.id].averageSapa
                                        : '-'
                                }}
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    selectedTeamId: {
        type: Number,
        default: -1,
    },
});

const scores = ref({});
const updatedScore = ref(false);
const fetchScores = () => {
    axios
        .post(`tests/${props.testObj.uuid}/dashboard/peer-evaluation/aggregate-scores`, {
            teamId: props.selectedTeamId,
        })
        .then((response) => {
            scores.value = response.data.data;
            updatedScore.value = true;
        })
        .catch((errors) => {});
};

const getCriteriaById = (criteriaId) => {
    let idx = _.findIndex(scores.value.criteria, function (o) {
        return o.id == criteriaId;
    });
    if (idx != -1) {
        return scores.value.criteria[idx];
    } else {
        return null;
    }
};

const countNumbersOfCriteriaRating = computed(() => {
    var count = 0;
    count = Object.keys(props.testObj.questions).length;
    return count;
});

const checkPAFSAPA = (student) => {
    if (updatedScore.value) {
        if (scores.value.aggregateScores.totalTeamPoints != 0) {
            if (
                scores.value.aggregateScores.totalPerReceiver[student.id].averagePaf > 1.5 ||
                scores.value.aggregateScores.totalPerReceiver[student.id].averagePaf < 0.75 ||
                scores.value.aggregateScores.totalPerReceiver[student.id].averageSapa != 1
            ) {
                return true;
            }
        }
    }

    return false;
};

onMounted(() => {
    updatedScore.value = false;
    fetchScores();
});

watch(
    () => props.selectedTeamId,
    (selectedTeamId, prevSelectedTeamId) => {
        updatedScore.value = false;
        fetchScores();
    }
);
</script>
