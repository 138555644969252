<template>
    <div v-if="component_done_loading" id="superuser-edit" class="col-xs-12 right-container">
        <!-- <div class="page-title">
	        <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
	        	<router-link :to="{name:'superusers.index'}">Superusers</router-link>
	            / Edit Superuser
	            <h3 style="margin-bottom:0;">Edit Superuser</h3>
	        </div>
    	</div>
    	<div class="clearfix"></div> -->
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT SUPER USER</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <!--<router-link id="top-back-btn" :to="{name:'superusers.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                            <!--<router-link id="top-mobile-back-btn"  :to="{name:'superusers.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <superuser-form :form="form" mode="update"></superuser-form>
                        <div class="profileBtns buttonGroup marginTop30">
                            <button
                                id="bottom-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link v-slot="{ navigate }" :to="{ name: 'superusers.index' }" custom>
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import { useAuthUserStore } from '../../../stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            superuser: {},
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    identity: '',
                    displayName: '',
                    email: '',
                    roleId: 'default!:1',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    accountType: '',
                },
                props: {},
                urls: {
                    put: '/users/' + this.$route.params.id,
                },
            }),
            store: useAuthUserStore(),
        };
    },
    components: {
        'superuser-form': require(`./form-partials/partial.superuser.vue`).default,
    },
    computed: {
        canSend() {
            if (!this.form.errors.any() && this.form.model.email && this.form.model.firstname && this.form.model.lastname && this.form.model.accountType) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-crown"></i>&nbsp;Superusers',
            breadcrumb: [{ name: 'Home' }, { name: 'Superusers', link: 'superusers.index' }, { name: 'Edit Superuser' }],
        });
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            axios.get('/users/' + this.$route.params.id).then(function (response) {
                that.form.setModel(response.data.data);
                that.superuser = response.data.data;
                that.component_done_loading = true;
            });
        },
        submit() {
            var that = this;
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    if (that.auth.user().uuid == response.data.data.uuid) {
                        that.store.updateProfile({
                            profile: response.data.data,
                        });
                    }
                    that.$router.push({
                        name: 'superusers.view',
                        params: { id: that.$route.params.id },
                    });
                }
            });
        },
    },
};
</script>
