<template>
    <!-- <div v-if="component_done_loading" style="width:100%;">
        <div class="fixedTopbar" id="fixedTopbar">
          <div class="test-topbar-wrapper">
            <div class="container-fluid test-topbar" style="padding:0;margin:0;">
              <div class="col-xs-12" style="padding:0;">
                <div class="col-xs-12 col-md-4 test-info-div">
                  <span class="test-name" v-if="testObj.name.length<=40"><i class="fa fa-compass"></i>&nbsp;{{testObj.name}}</span>
                  <span class="test-name" v-else><i class="fa fa-compass"></i>&nbsp;{{testObj.name.slice(0,40)}}...</span>
                  <span class="course-name" style="margin-top:10px;margin-bottom:0;">
                    <i class="fa fa-graduation-cap"></i><span>&nbsp;</span> 
                    <span class="courseName"><i class="fa fa-user"></i><span>&nbsp;{{getDisplayName()}}</span></span>
                  </span>
                </div>
                <div class="col-xs-12 col-md-4">
                	<topbar-timer :test="testObj"></topbar-timer>
                </div>
                <div class='displayOptions hidden-md hidden-lg' style="font-size:14px;text-align:center;margin-top:10px;display:inline-block;width:100%;">
                  <span id="moreDetails" @click.prevent="moreDetails">More Details<i class="fa fa-caret-down" style="margin-left:5px;"></i></span>
                  <span id="lessDetails" @click.prevent="lessDetails">Less Details<i class="fa fa-caret-up" style="margin-left:5px;"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>  -->
    <div v-if="component_done_loading">
        <div class="test_topBar_placeholder" @load="updateTopNavHeight"></div>
        <div ref="testTopBar" class="test_topBar flex align-items no-print" :class="{ timer: isTimerShown }">
            <a href="#" class="d-block test_logo">
                <img src="/img/icons/_newicons_intedashboardlogo.png" width="25px" alt="Intedashboard Logo" />
            </a>
            <div v-if="testObj.presentationMode && testObj.status != 'ongoing'" class="test_topBarContent">
                <div class="flexSpaceBetween align-items hidden-xs hidden-sm">
                    <div>
                        <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                            <button class="btn btn-default" @click="navigate">
                                <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back to My Activities
                            </button>
                        </router-link>
                    </div>
                    <div class="test_title">{{ testObj.name }} Presentation Screen</div>

                    <test-status
                        v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                        :test="testObj.status"
                        role="topBarStatus"
                    />

                    <!-- <template v-if="testObj.status == 'not started'">
          <div
            class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger"
            :aria-label="'Activity Status ' + testObj.status"
            tabindex="0"
          >
            Not Started
          </div>
        </template>
        <template v-else-if="testObj.status == 'paused'">
          <div
            class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning"
            :aria-label="'Activity Status ' + testObj.status"
            tabindex="0"
          >
            Paused
          </div>
        </template>
        <template v-else-if="testObj.status == 'ongoing'">
          <div
            class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning"
            :aria-label="'Activity Status ' + testObj.status"
            tabindex="0"
          >
            Ongoing
          </div>
        </template>
        <template v-else-if="['ended', 'completed'].includes(testObj.status)">
          <div
            class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning"
            :aria-label="'Activity Status ' + testObj.status"
            tabindex="0"
          >
            Ended
          </div>
        </template> -->
                </div>
                <div class="hidden-md hidden-lg">
                    <div class="flexSpaceBetween">
                        <div>
                            <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                <button class="btn btn-default" @click="navigate">
                                    <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back to My Activities
                                </button>
                            </router-link>
                        </div>

                        <test-status
                            v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                            :test="testObj.status"
                            role="topBarStatus"
                        />

                        <!-- <template v-if="testObj.status == 'not started'">
            <div
              class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger"
              :aria-label="'Activity Status ' + testObj.status"
              tabindex="0"
            >
              Not Started
            </div>
          </template>
          <template v-else-if="testObj.status == 'paused'">
            <div
              class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning"
              :aria-label="'Activity Status ' + testObj.status"
              tabindex="0"
            >
              Paused
            </div>
          </template>
          <template v-else-if="testObj.status == 'ongoing'">
            <div
              class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning"
              :aria-label="'Activity Status ' + testObj.status"
              tabindex="0"
            >
              Ongoing
            </div>
          </template>
          <template v-else-if="['ended', 'completed'].includes(testObj.status)">
            <div
              class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger"
              :aria-label="'Activity Status ' + testObj.status"
              tabindex="0"
            >
              Ended
            </div>
          </template> -->
                    </div>

                    <div class="test_title marginTop10">{{ testObj.name }} Presentation Screen</div>
                </div>
            </div>

            <div v-else class="test_topBarContent flexOnly align-items">
                <div class="col-xs-12">
                    <template v-if="!forceHideTimer && testObj.settings.hasTimeLimit">
                        <topbar-timer v-show="isTimerShown" :test-obj="testObj" @is-shown="processTimerShown" />
                    </template>
                    <div v-if="isTimerShown && !forceHideTimer" class="textAlignCenter viewInstructions">
                        <a v-tooltip="testObj.name.length >= 20 ? `View ${testObj.name} Instruction` : ''" href="#" class="whiteText" @click="openInstructions">
                            View {{ truncate(testObj.name) }} Instructions
                        </a>
                    </div>
                    <template v-else>
                        <div class="test_title">
                            {{ testObj.name }}
                        </div>
                        <div class="viewInstructions viewInstructionsEnded">
                            <a href="#" class="whiteText" @click="openInstructions"> View Activity Instructions </a>
                        </div>
                    </template>
                    <!-- <template>
            <div class="loadingText">
              Loading… 35%  Please wait, meanwhile do remember to read test instructions below.
            </div>
          </template> -->
                </div>
                <template v-if="testObj._isTeacherPreview">
                    <!-- <div class="hidden-xs hidden-sm no-print">
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'courses.modules.activities.continue',
                                params: { id: testObj.uuid },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                        </router-link>
                    </div>
                    <div class="hidden-md hidden-lg no-print">
                        <router-link
                            v-slot="{ navigate }"
                            v-tooltip="'Copy'"
                            :to="{
                                name: 'courses.modules.activities.continue',
                                params: { id: testObj.uuid },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate">
                                <i class="fas fa-caret-left" aria-hidden="true" />
                            </button>
                        </router-link>
                    </div> -->
                </template>
                <template v-else-if="testObj.type != 'evaluation'">
                    <div>
                        <template v-if="$route.name == 'tests.clarification'">
                            <div class="hidden-xs hidden-sm">
                                <template v-if="$route.meta.previousRoute.name == 'tests.index'">
                                    <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                        </button>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <router-link
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'tests.proper',
                                            params: { id: testObj.uuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                        </button>
                                    </router-link>
                                </template>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <template v-if="$route.meta.previousRoute.name == 'tests.index'">
                                    <router-link v-slot="{ navigate }" v-tooltip="'Back to Dashboard'" :to="{ name: 'tests.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fas fa-caret-left" aria-hidden="true" />
                                        </button>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <router-link
                                        v-slot="{ navigate }"
                                        v-tooltip="'Back to Dashboard'"
                                        :to="{
                                            name: 'tests.proper',
                                            params: { id: testObj.uuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fas fa-caret-left" aria-hidden="true" />
                                        </button>
                                    </router-link>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="hidden-xs hidden-sm">
                                <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link v-slot="{ navigate }" v-tooltip="'Back to Index Page'" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </template>
                    </div>
                </template>
                <!--
          <div class="col-xs-6 flexRight">
            <div class="buttonGroup flexRight">
              <div class="hidden-xs hidden-sm">
                <button class="btn btn-default mainBtn">
                  <i class="fa fa-floppy-o"></i>&nbsp;Save as Draft
                </button>
                <button class="btn btn-success mainBtn">
                  Submit Evaluation&nbsp;<i class="fa fa-check"></i>
                </button>
              </div>
              <div class="hidden-md hidden-lg">
                <button class="btn btn-default mainBtn">
                  <i class="fa fa-floppy-o"></i>
                </button>
                <button class="btn btn-success mainBtn">
                  <i class="fa fa-check"></i>
                </button>
              </div>
            </div>
          </div>
        -->
                <template v-else>
                    <div>
                        <div class="buttonGroup flexRight">
                            <div class="hidden-xs hidden-sm">
                                <!--<button @click="downloadReport"class="btn btn-primary mainBtn" v-if="testObj.type=='evaluation'&&['ended','completed'].includes(testObj.status)">
                  <i class="fa fa-download"></i>&nbsp;Download Report
                </button>-->
                                <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <!--<button  @click="downloadReport" class="btn btn-primary mainBtn" v-if="testObj.type=='evaluation'&&['ended','completed'].includes(testObj.status)">
                  <i class="fa fa-download"></i>
                </button>-->
                                <router-link v-slot="{ navigate }" v-tooltip="'Back To Index Page'" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['testObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            isTimerShown: true,
            auth: new KrAuth(),
            forceHideTimer: false,
        };
    },
    created() {
        this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);

        window.addEventListener('load', () => {
            this.updateTopNavHeight();
        });
    },
    mounted() {
        var that = this;

        this.component_done_loading = true;

        this.$nextTick(() => {
            this.updateTopNavHeight();
        });

        window.addEventListener('resize', this.updateTopNavHeight);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateTopNavHeight);
    },
    methods: {
        downloadReport() {
            var that = this;
            axios({
                url: '/student/tests/' + this.testObj.uuid + '/report/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.testObj.type + ')' + that.testObj.name + '_' + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        processTimerShown(isShown) {
            this.isTimerShown = isShown;
            this.$emit('timer-shown', this.isTimerShown);
        },
        openInstructions() {
            Events.fire('show-stopping-div');
        },
        getDisplayName() {
            return (this.auth.user().displayName && this.auth.user().displayName.trim()) != ''
                ? this.auth.user().displayName
                : this.getStudentName(this.auth.user(), false);
        },
        getStudentName(student, limit = true) {
            var name = student.firstname + (student.lastname ? ' ' + student.lastname : '');
            if (name.length > 20 && limit) {
                return name.slice(0, 17) + '...';
            }
            return name;
        },
        init() {
            var ret = [];
            for (var i = 0; i < this.testObj.questions.length; i++) {
                ret.push(this.testObj.questions[i][0]);
            }

            var j = ret.findIndex((z) => z.uuid === this.testObj.eGalleryProgression.currentQuestionUuid);

            return (this.current_question_data = this.testObj.questions[j][0]);
        },
        updateTopNavHeight() {
            if (!this.$refs.testTopBar) {
                return;
            }
            $('.test_topBar_placeholder').height(this.$refs.testTopBar.offsetHeight + 'px');
        },
        truncate(str, characters = 20) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
    },
    components: {
        'topbar-timer': require(`./topbar-timer.vue`).default,
    },
    watch: {
        options: {
            handler: function () {
                this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.test_topBarContent {
    padding: 15px 25px;
    background-color: #495060;
    flex: 1;
}

.test_topBar {
    background-color: #273142;
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;
}

.loadingText {
    font-style: italic;
    text-align: center;
    color: #fff;
}

.test_topBarContent .col-xs-10 {
    text-align: center;
}

.test_title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
}

@media (min-width: 992px) {
    .test_logo {
        padding: 15px 35px;
    }

    .loadingText {
        font-size: 16px;
    }

    .timer_Div {
        margin-bottom: 15px;
    }
    .viewInstructionsEnded {
        margin-top: -25px;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .test_logo {
        padding: 15px;
    }

    .loadingText {
        font-size: 12px;
    }

    .timer_Div {
        margin-bottom: 10px;
    }

    .viewInstructions {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .test_topBarContent {
        padding: 15px;
    }
}
</style>
