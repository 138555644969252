import KrAuth from '../components/auth/auth';
export var testMixins = {
    props: {
        testObj: {
            type: Object,
            required: false,
        },
    },
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,

            previous_question_group_idx: 0,
            previous_question_idx: 0,

            current_question_group_idx: 0,
            current_question_idx: 0,

            next_question_group_idx: 0,
            next_question_idx: 0,
        };
    },
    created() {
        var that = this;
        if (this.testObj.type != 'evaluation') {
            this.currentQuestionChangedListener({
                question_group_idx: this.current_question_group_idx,
                question_idx: this.current_question_idx,
            });
            Events.listen('current-question-changed', function (current) {
                that.currentQuestionChangedListener(current);
            });
        }
    },
    methods: {
        currentQuestionChangedListener(current) {
            if (!this.testObj.questions) {
                return;
            }
            let indexes = this.getPreviousAndNextQuestionIndexes(current.question_group_idx, current.question_idx);
            this.previous_question_group_idx = indexes.previous_question_group_idx;
            this.previous_question_idx = indexes.previous_question_idx;

            this.current_question_group_idx = current.question_group_idx;
            this.current_question_idx = current.question_idx;

            this.next_question_group_idx = indexes.next_question_group_idx;
            this.next_question_idx = indexes.next_question_idx;
        },
        getPreviousAndNextQuestionIndexes(question_group_idx, question_idx) {
            if (this.testObj.questions.length == 0) {
                return {
                    previous_question_group_idx: '',
                    previous_question_idx: '',
                    next_question_group_idx: '',
                    next_question_idx: '',
                };
            }
            //get previous
            let previous_question_group_idx = question_group_idx;
            let previous_question_idx = question_idx;
            if (previous_question_idx > 0) {
                previous_question_idx--;
            } else {
                if (previous_question_group_idx > 0) {
                    previous_question_group_idx -= 1;
                    previous_question_idx = this.testObj.questions[previous_question_group_idx].length - 1;
                }
            }
            let next_question_group_idx = question_group_idx;
            let next_question_idx = question_idx;
            if (next_question_idx < this.testObj.questions[next_question_group_idx].length - 1) {
                next_question_idx += 1;
            } else {
                if (next_question_group_idx < this.testObj.questions.length - 1) {
                    next_question_group_idx += 1;
                    next_question_idx = 0;
                }
            }
            let ret = {};
            ret.previous_question_group_idx = previous_question_group_idx;
            ret.previous_question_idx = previous_question_idx;

            ret.next_question_group_idx = next_question_group_idx;
            ret.next_question_idx = next_question_idx;
            return ret;
        },
        //check if question has been answered already
        isQuestionAnswered(question_group_idx, question_idx) {
            if (this.getQuestionStatus(question_group_idx, question_idx) == 1) {
                return true;
            }
            return false;
        },
        getQuestionStatus(question_group_idx, question_idx) {
            let question = this.testObj.questions[question_group_idx][question_idx];
            let attempts = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.studentAnswer.attempts', []);
            let storedAnswer = -1;
            if (attempts.length != 0) {
                storedAnswer = attempts[attempts.length - 1];
            }
            if ((this.testObj.type == 'irat' && !_.get(this.testObj, 'settings.immediateFeedback')) || this.testObj.type == 'application') {
                if (['mcqs', 'mcqm', 'openended', 'vsa'].includes(question.type)) {
                    if (storedAnswer != -1) {
                        return 1;
                    }
                }
            }
            if (this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback'))) {
                if (['mcqs', 'mcqm'].includes(question.type)) {
                    let isCorrect = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.studentAnswer.isCorrect', false);
                    if (!isCorrect && storedAnswer != -1) {
                        return -1;
                    } else if (isCorrect) {
                        return 1;
                    }
                }
            }
            return 0;
        },
        //get question number for display
        getQuestionNo(question_group_idx, question_idx) {
            let count = 0;
            for (var i = 0; i < question_group_idx; i++) {
                count += this.testObj.questions[i].length;
            }
            count += question_idx + 1;
            return count;
        },
        //call a change to different question
        moveToDifferentQuestion(question_group_idx, question_idx, scrollToQns) {
            //console.log('q_group:'+question_group_idx+'| q_idx' + question_idx);
            var question_uuid = this.testObj.questions[question_group_idx][question_idx].uuid;

            if (scrollToQns == 'scrollToQns') {
                setTimeout(() => {
                    const section = document.getElementById(question_uuid + '-question');

                    const topBar = document.querySelector('.test_topBar');
                    const topBarHeight = topBar ? topBar.clientHeight : 0;

                    setTimeout(function () {
                        const yOffset = topBarHeight;
                        const y = section.getBoundingClientRect().top + window.pageYOffset - yOffset - 30;

                        window.scrollTo({
                            top: y,
                            behavior: 'smooth',
                        });
                    }, 100);

                    section.focus();
                }, 100);
            }

            Events.fire('change-question', {
                question_group_idx: question_group_idx,
                question_idx: question_idx,
            });
        },
        //check if we can change to different question
        canChangeQuestion(question_group_idx, question_idx) {
            //    if (
            //        question_group_idx == this.current_question_group_idx &&
            //        question_idx == this.current_question_idx
            //    ) {
            //        return false;
            //    }
            if (this.isPreview && !this.isTeacherPreview) {
                return true;
            }
            if (!this.isTestCompleted) {
                if (
                    _.get(this.testObj, 'settings.allowViewQuestionsAhead', true) == false &&
                    _.get(this.testObj, 'settings.allowViewPreviousQuestions', true) == false
                ) {
                    if (
                        question_group_idx < this.current_question_group_idx ||
                        (question_group_idx == this.current_question_group_idx && question_idx < this.current_question_idx)
                    ) {
                        if (this.isQuestionAnswered(question_group_idx, question_idx)) {
                            return false;
                        }
                    }
                }
                if (_.get(this.testObj, 'settings.allowViewQuestionsAhead', true) == false) {
                    if (
                        question_group_idx > this.current_question_group_idx ||
                        (question_group_idx == this.current_question_group_idx && question_idx > this.current_question_idx)
                    ) {
                        let indexes = this.getPreviousAndNextQuestionIndexes(question_group_idx, question_idx);
                        if (!this.isQuestionAnswered(indexes.previous_question_group_idx, indexes.previous_question_idx)) {
                            return false;
                        }
                    }
                }
                if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                    if (!this.isTeamLeader && _.get(this.testObj, 'settings.isSyncToTeamQuestion', true)) {
                        return false;
                    }
                }
            } else {
                /*if(this.testObj.type=='trat'||(this.testObj.type=='application'&&this.testObj.others.applicationType=='team')){
                    if(!this.isTeamLeader&&_.get(this.testObj,'settings.isSyncToTeamQuestion',true)){
            			return false;
            		}
            	}*/
            }
            return true;
        },
    },
    computed: {
        //number of questions answered
        answered_count() {
            if (this.testObj.type == 'evaluation') {
                return null;
            }
            let a_count = 0;
            for (var i = 0; i < this.testObj.questions.length; i++) {
                for (var j = 0; j < this.testObj.questions[i].length; j++) {
                    if (this.isQuestionAnswered(i, j)) {
                        a_count++;
                    }
                }
            }
            return a_count;
        },
        //percentage of questions answered
        percentage() {
            if (this.total_questions == 0) {
                return 0;
            }
            return Math.floor((this.answered_count / this.total_questions) * 100);
        },
        //get own credentials.
        myOwnCredentials() {
            if (this.testObj.type == 'evaluation') {
                return null;
            }
            if (!['demo', 'Student'].includes(this.auth.user().role)) {
                return null;
            }
            if (this.testObj.type == 'irat' || (this.testObj.type == 'application' && this.testObj.others.applicationType != 'team')) {
                return null;
            }

            var that = this;
            return _.find(this.testObj.myProgression.team.students, function (o) {
                return o.studentUuid == that.auth.user().uuid;
            });
        },
        //check if user is team leader
        isTeamLeader() {
            if (this.testObj.type == 'evaluation') {
                return null;
            }
            if (this.isTeacherPreview) {
                return true;
            }
            if (this.auth.user().role != 'Student' && this.auth.user().role != 'demo') {
                return null;
            }
            if (this.testObj.type == 'irat' || (this.testObj.type == 'application' && this.testObj.others.applicationType != 'team')) {
                return null;
            }
            return this.myOwnCredentials.uuid == this.testObj.myProgression.team.teamLeaderUuid;
        },
        //check if test is completed
        isTestCompleted() {
            if (!['demo', 'Student'].includes(this.auth.user().role)) {
                return null;
            }
            if (
                (['completed', 'ended'].includes(this.testObj.status) && !this.isTimerExtended) ||
                ['finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus) ||
                ['submitted'].includes(this.testObj.myProgression.teamStatus)
            ) {
                return true;
            }
            return false;
        },
        isTestStarted() {
            return ['started', 'ongoing'].includes(_.get(this.testObj, 'myProgression.userTestStatus'));
        },
        isPreview() {
            if (this.testObj._isPreview) {
                return true;
            }
            return false;
        },
        isTeacherPreview() {
            if (this.testObj._isTeacherPreview) {
                return true;
            }
            return false;
        },
        total_questions() {
            if (this.testObj.type == 'evaluation') {
                return 0;
            }
            if (!this.testObj.questions) {
                this.testObj.questions = [];
            }
            let count = 0;
            for (var i = 0; i < this.testObj.questions.length; i++) {
                let qGroup = this.testObj.questions[i];
                count += qGroup.length;
            }
            return count;
        },
        isTimerExtended() {
            if (!this.testObj) {
                return false;
            } else {
                if (
                    _.get(this.testObj, 'extraTimeValues.durationDays', 0) != 0 ||
                    _.get(this.testObj, 'extraTimeValues.durationHours', 0) != 0 ||
                    _.get(this.testObj, 'extraTimeValues.durationMinutes', 0) != 0
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    beforeUnmount() {
        //alert('destroy');
        //Events.off('current-question-changed',this.currentQuestionChangedListener);
    },
};
export const testPaginationMixins = {
    mixins: [testMixins],
    data() {
        return {
            delta: 1,
        };
    },
    created() {
        //window.addEventListener("resize", this.handleResize);
    },
    mounted() {
        // this.handleResize();
    },
    methods: {
        handleResize() {
            // if($(window).width()>991){
            //     this.delta = 5;
            // }else{
            //     this.delta = 1;
            // }
        },
    },
    computed: {
        pagination() {
            if (this.testObj.type == 'evaluation') {
                return [];
            }
            var delta = this.delta;
            var question_list = [];
            var current = this.getQuestionNo(this.current_question_group_idx, this.current_question_idx);
            question_list = _.flatten(this.testObj.questions);
            var last = question_list.length,
                left = current - delta,
                right = current + delta + 1,
                range = [],
                rangeWithDots = [],
                l = null;

            for (let i = 1; i <= last; i++) {
                if (i == 1 || i == last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            //return range;
            for (let i of range) {
                if (l) {
                    if (i - l >= 2) {
                        rangeWithDots.push({ isDot: true });
                    }
                }
                rangeWithDots.push({
                    label: i,
                    question_group_idx: question_list[i - 1].group - 1,
                    question_idx: question_list[i - 1].order - 1,
                    question_uuid: question_list[i - 1].uuid,
                });
                l = i;
            }
            return rangeWithDots;
        },
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
};
