<template>
    <div>
        <canvas id="barChart"></canvas>
    </div>
</template>
<script>
export default {
    props: {
        chartdata: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            chart: {},
        };
    },
    watch: {
        chartdata: {
            handler: function () {
                this.renderChart(_.cloneDeep(this.chartdata), this.options);
            },
            deep: true,
        },
    },
    mounted() {
        this.renderChart(_.cloneDeep(this.chartdata), this.options);
    },
    methods: {
        renderChart(data, options) {
            const ctx = document.getElementById('barChart').getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: options,
            });
        },
    },
};
</script>
<!-- <script>
import { Bar } from 'vue-chartjs';
export default {
    extends: Bar,
    props: {
        chartdata: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    watch: {
        chartdata: {
            handler: function () {
                this.renderChart(this.chartdata, this.options);
            },
        },
    },
    mounted() {
        this.renderChart(this.chartdata, this.options);
    },
};
</script> -->
