<template>
    <div v-show="component_done_loading && display" class="col-xs-12 col-md-offset-2 col-md-8">
        <div role="alert" aria-live="assertive" class="visible-hidden">
            You are now at {{ testObj.name }} Instruction Page, activity status
            {{ statusName }}
        </div>

        <kr-panel :options="{ canToggle: false }" :with-footer="false">
            <template #title>
                <div class="col-xs-12">
                    <div class="hidden-xs hidden-sm flexSpaceBetween">
                        <label>
                            <span class="visible-hidden"> Activity Name &nbsp; </span>
                            {{ testObj.name }}
                        </label>

                        <div class="flexRight">
                            <label>
                                <span class="visible-hidden"> Activity&nbsp; </span>
                                Status:&nbsp;
                                <span
                                    :class="{
                                        darkGreen: isTestStartedEarly || ['ready', 'ongoing'].includes(testObj.status),
                                        important: ['not started', 'scheduled', 'paused', 'ended'].includes(testObj.status),
                                    }"
                                >
                                    <template v-if="isTestStartedEarly"> Test has been started early </template>
                                    <template v-else-if="['not started', 'scheduled'].includes(testObj.status)"> Test has not started </template>
                                    <template v-else-if="testObj.status == 'ready'"> Test is ready </template>
                                    <template v-else-if="testObj.status == 'ongoing'"> Test has started </template>
                                    <template v-else-if="testObj.status == 'paused'"> Test has been paused </template>
                                    <template v-else-if="testObj.status == 'ended'"> Test has ended </template>
                                    <template v-else> NULL TEST STATUS </template>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="hidden-md hidden-lg">
                        <label>
                            <span class="visible-hidden"> Activity Name &nbsp; </span>
                            {{ testObj.name }}
                        </label>

                        <div class="marginTop10">
                            <label>
                                <span class="visible-hidden"> Activity&nbsp; </span>
                                Status:&nbsp;
                                <span
                                    :class="{
                                        darkGreen: isTestStartedEarly || ['ready', 'ongoing'].includes(testObj.status),
                                        important: ['not started', 'scheduled', 'paused', 'ended'].includes(testObj.status),
                                    }"
                                >
                                    <template v-if="isTestStartedEarly"> Test has been started early </template>
                                    <template v-else-if="['not started', 'scheduled'].includes(testObj.status)"> Test has not started </template>
                                    <template v-else-if="testObj.status == 'ready'"> Test is ready </template>
                                    <template v-else-if="testObj.status == 'ongoing'"> Test has started </template>
                                    <template v-else-if="testObj.status == 'paused'"> Test has been paused </template>
                                    <template v-else-if="testObj.status == 'ended'"> Test has ended </template>
                                    <template v-else> NULL TEST STATUS </template>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </template>

            <template #content>
                <div>
                    <label id="activityTypeLabel" class="control-label"> Activity Type </label>
                    <p for="activityTypeLabel" class="form-control-static">
                        <template v-if="testObj.type == 'prework'"> Prework </template>
                        <template v-if="testObj.type == 'irat'"> IRAT </template>
                        <template v-if="testObj.type == 'trat'"> TRAT </template>
                        <template v-if="testObj.type == 'iratntrat'"> IRAT & TRAT </template>
                        <template v-if="testObj.type == 'application'"> Application Case </template>
                        <template v-if="testObj.type == 'evaluation'"> 360 Degree Evaluaton </template>
                        <template v-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                    </p>
                </div>

                <div v-if="testObj.settings.hasTimeLimit">
                    <label id="totalTimeLabel" class="control-label"> Total Time </label>
                    <p for="totalTimeLabel" class="form-control-static">
                        {{ testObj.plannedDuration }}
                    </p>
                </div>

                <template v-if="testObj.type != 'evaluation'">
                    <label id="noOfQuestionsLabel" class="control-label"> Number of Questions </label>
                    <p for="noOfQuestionsLabel" class="form-control-static">
                        {{ total_questions }}
                    </p>
                </template>

                <template v-if="['irat', 'trat'].includes(testObj.type) || (testObj.type == 'application' && testObj.others.isApplicationGraded)">
                    <label id="totalMarkLabel" class="control-label"> Total Marks </label>
                    <p for="totalMarkLabel" class="form-control-static">
                        {{ testObj.totalMarks }}
                    </p>
                </template>

                <div>
                    <label class="control-label">Instructions</label>
                    <template v-if="testObj.settings.instruction">
                        <!-- <p v-if="testObj.settings.instructionIsHTML" class="form-control-static" v-html="testObj.settings.instruction"></p>
                        <p v-else class="form-control-static">
                            {{ testObj.settings.instruction }}
                        </p> -->

                        <div v-html="testObj.settings.instruction"></div>
                    </template>
                    <template v-else>
                        <p class="form-control-static">-</p>
                    </template>
                </div>

                <div v-if="testObj.settings.attachments.length > 0" class="col-xs-12 col-md-6 marginBottom20">
                    <div class="flex align-items attachmentFile">
                        <div class="col-xs-12">
                            <template v-for="(file, idx) in testObj.settings.attachments" :key="idx">
                                <div class="flexSpaceBetween">
                                    <div>
                                        <span>{{ idx + 1 }}.</span>
                                        <span>
                                            <i class="far fa-file-pdf" aria-hidden="true"></i>
                                        </span>
                                        <template v-if="isPdf(file)">
                                            <template v-if="testObj.settings.allowPdfDownload">
                                                <a class="attachmentLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                                    {{ file.filename }}
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a class="attachmentLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{
                                                    file.filename
                                                }}</a>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <a class="attachmentLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                file.filename
                                            }}</a>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <template v-if="(testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')) && !testObj._isPreview">
                    <label class="control-label"> Team Information </label>
                    <div class="marginBottom20">
                        <team-leadership :test-obj="testObj"></team-leadership>
                    </div>
                </template>

                <div v-if="showPasswordField != 0" class="col-xs-12 col-md-6 marginBottom20" :class="{ paddingRight10: showPasswordField != 0 }">
                    <!--<p v-if="showPasswordField==1" class="darkGreen">Password has been entered correctly.<template v-if="['not started','scheduled','paused'].includes(testObj.status)"> Wait for the test to be started by your teacher.</template></p>-->
                    <kr-field
                        v-if="
                            showPasswordField == -1 &&
                            ((testObj.type == 'trat' && isTeamLeader) ||
                                testObj.type == 'irat' ||
                                (testObj.type == 'application' && testObj.others.applicationType != 'team') ||
                                (testObj.type == 'application' && isTeamLeader) ||
                                ['evaluation', 'michaelsenEvaluation'].includes(testObj.type))
                        "
                        :form="form"
                        display="Activity Password"
                        name="password"
                        aria-label="activity password"
                        :options="{
                            placeholder: 'Please enter password',
                            hideHelpBlockIfNoErrors: true,
                            required: true,
                        }"
                    ></kr-field>
                    <p
                        v-else-if="
                            showPasswordField == -1 &&
                            ((testObj.type == 'trat' && !isTeamLeader) ||
                                (testObj.type == 'application' && !isTeamLeader && testObj.others.applicationType == 'team'))
                        "
                        class="important"
                    >
                        Only the team reporter can enter the password
                    </p>
                </div>

                <div v-if="testObj.type == 'trat' && testObj.canStartTrat % 2 == 0" class="col-xs-12 alert alert-warning marginBottom20">
                    <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true"></i>
                    <div>
                        Please wait for all team members to complete their IRATs before the TRAT becomes available. Alternatively, on
                        {{ convertToReadableDate(testObj.iratEndDate, 'DD MMM YYYY, h:mm a').date }}
                        ({{ convertToReadableDate(testObj.iratEndDate, 'DD MMM YYYY, h:mm a').current_timezone }}) the TRAT will be available regardless
                    </div>
                </div>

                <button
                    v-if="isDisabledDueToSafeExamBrowser != -1"
                    v-tooltip="isStartButtonDisabled || (form.errors.has('password') && showPasswordField == -1) ? statusName : ''"
                    class="btn btn-success mainBtn marginLeft0"
                    :disabled="isStartButtonDisabled || (form.errors.has('password') && showPasswordField == -1) ? true : undefined"
                    :aria-label="!isStarted(testObj) ? 'Start' + testObj.name + ' activity' : 'Continue' + testObj.name + ' activity'"
                    @click.prevent="processTakeTheTestButton()"
                >
                    <template v-if="!isStarted(testObj)"> Start </template>
                    <template v-else> Continue </template>
                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                </button>
                <template v-else>
                    <p class="important">To access this activity, you must be using Safe Exam Browser. Please consult your instructor.</p>
                </template>
            </template>
        </kr-panel>
    </div>
</template>
<script>
import { testMixins } from './../../../../../mixins/test';
import KrForm from '../../../../../components/forms/form';
export default {
    mixins: [testMixins],
    data() {
        return {
            component_done_loading: true,
            on_init_test_status: false,
            display: null,
            form: new KrForm({
                fields: {
                    password: '',
                    userPlacementTestUuid: 'default!:' + this.testObj.uuid,
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                    manualReset: true,
                },
            }),
            startIsRan: false,
            isSafeExamBrowser: false,
            statusName: '',
        };
    },
    computed: {
        isTestStartedEarly() {
            return _.get(this.testObj, 'expectedEndingTime') && this.testObj.canEarlyStart && this.testObj.status == 'not started';
        },
        showPasswordField() {
            if (!this.testObj.settings.password) {
                return 0;
            }
            if (!_.get(this, 'testObj.myProgression.enteredPassword')) {
                return -1;
            } else {
                return 1;
            }
        },
        isDisabledDueToSafeExamBrowser() {
            if (!this.testObj.settings.allowSafeExamBrowserOnly) {
                return 0;
            } else {
                if (!this.isSafeExamBrowser) {
                    return -1;
                } else {
                    return 1;
                }
            }
        },
        isStartButtonDisabled() {
            if (this.isDisabledDueToSafeExamBrowser == -1) {
                return true;
            }
            if (
                (this.testObj.type == 'trat' && !this.testObj.canStartTrat) ||
                (['not started', 'scheduled', 'ready', 'paused', null].includes(this.testObj.status) && !this.isTestStartedEarly) ||
                (this.showPasswordField == -1 && !this.form.model.password)
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        testObj: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
        display: {
            handler: function () {
                if (this.display) {
                    return;
                } else {
                    if (!this.isTestStarted && !this.isTestCompleted && this.testObj.type != 'prework' && !this.startIsRan) {
                        this.startIsRan = true;
                        axios.put('student/tests/' + this.testObj.uuid + '/start').then(function () {
                            //processTest();
                        });
                    }
                }
            },
        },
        'testObj.status': {
            handler: function () {
                if (_.get(this.testObj.expectedEndingTime) && this.testObj.canEarlyStart && this.testObj.status == 'not started') {
                    this.statusName = 'Test has been started early';
                } else if (['not started', 'scheduled'].includes(this.testObj.status)) {
                    this.statusName = 'Test has not started';
                } else if (this.testObj.status == 'ready') {
                    this.statusName = 'Test is ready';
                } else if (this.testObj.status == 'ongoing') {
                    this.statusName = 'Test has started';
                } else if (this.testObj.status == 'paused') {
                    this.statusName = 'Test has been paused';
                } else if (this.testObj.status == 'ended') {
                    this.statusName = 'Test has ended';
                }
            },
        },
    },
    mounted() {
        var that = this;
        Events.listen('show-stopping-div', function (o) {
            that.display = true;
            that.$emit('display', that.display);
        });
        Events.listen('hide-stopping-div', function (o) {
            that.display = false;
            that.$emit('display', that.display);
        });
        this.on_init_test_status = this.testObj.status;
        that.init();
        //this.display = true;
        //this.$emit("display", this.display);

        let agent = navigator.userAgent;
        if (agent.indexOf('SEB') !== -1) {
            this.isSafeExamBrowser = true;
        } else {
            this.isSafeExamBrowser = false;
        }
        this.$nextTick(function () {
            if (that.isDisabledDueToSafeExamBrowser == -1) {
                axios
                    .post(`student/tests/${that.testObj.uuid}/validate-seb`)
                    .then(function (response) {})
                    .catch(function (errors) {});
            }

            if (_.get(this.testObj.expectedEndingTime) && this.testObj.canEarlyStart && this.testObj.status == 'not started') {
                this.statusName = 'Test has been started early';
            } else if (['not started', 'scheduled'].includes(this.testObj.status)) {
                this.statusName = 'Test has not started';
            } else if (this.testObj.status == 'ready') {
                this.statusName = 'Test is ready';
            } else if (this.testObj.status == 'ongoing') {
                this.statusName = 'Test has started';
            } else if (this.testObj.status == 'paused') {
                this.statusName = 'Test has been paused';
            } else if (this.testObj.status == 'ended') {
                this.statusName = 'Test has ended';
            }

            var toUpperCase = this.testObj.type.toUpperCase();
            document.title = `${this.statusName} | ${toUpperCase} | InteDashboard | TBL Makes Teams Work`;
        });
    },
    methods: {
        processTakeTheTestButton() {
            if (_.get(this, 'testObj.settings.password') && this.showPasswordField == -1) {
                this.postPassword();
            } else {
                this.display = false;
                this.$emit('display', this.display);
                console.log('that');
            }
        },
        postPassword() {
            var that = this;
            axios
                .post('student/tests/store-password', that.form.model, {
                    _internal: { hasLoadingBar: false },
                })
                .then(function (response) {
                    that.testObj.myProgression.enteredPassword = that.form.model.password;
                    that.display = false;
                    that.$emit('display', this.display);
                })
                .catch(function (errors) {
                    that.form.errors.record(
                        _.get(errors, 'response.data.errors', {
                            password: ['Something went wrong, please contact administrator'],
                        })
                    );
                });
        },
        init() {
            if (this.isPreview) {
                this.display = false;
            } else {
                // if (this.on_init_test_status != this.testObj.status) {
                //   this.on_init_test_status = this.testObj.status;
                //   if (this.isStartButtonDisabled) {
                //     if (!this.isTestCompleted) {
                //       this.display = true;
                //     }
                //   }
                // } else {
                //   if (this.isStartButtonDisabled) {
                //     if (!this.isTestCompleted) {
                //       this.display = true;
                //     }
                //   } else {
                //     this.display = false;
                //   }
                // }
                //
                if (this.isStartButtonDisabled) {
                    if (!this.isTestCompleted) {
                        this.display = true;
                    }
                } else {
                    if (this.showPasswordField != -1) {
                        this.display = false;
                    }
                }
            }
            if (this.display === null) {
                this.display = false;
            }
            this.$emit('display', this.display);
        },
        isStarted(test) {
            return ['started', 'ongoing'].includes(_.get(test, 'myProgression.userTestStatus'));
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.testObj.settings.allowPdfDownload,
            });
        },
    },
    components: {
        'team-leadership': require(`./team-leadership.vue`).default,
    },
    beforeUnmount() {
        Events.off('show-stopping-div');
        Events.off('hide-stopping-div');
    },
};
</script>
<style scoped>
.x_title span.darkGreen {
    color: #3e7c5b;
}

.x_title span.important {
    color: #ca3333;
}

@media (min-width: 992px) {
    #stoppingModal .modal-dialog {
        width: 600px;
    }
}
.attachmentFile > div {
    margin-top: 10px;
}

.attachmentFile > div:first-child {
    margin-top: 0;
}
</style>
