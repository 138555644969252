<template>
    <div class="content0">
        <!-- <div v-if="testObj.evaluationSettings.target != 'self'" class="stepper-container">
            <div class="stepper-wrapper">
                <template v-for="(qGroup, qGroupIdx) in testObj.questions">
                    <template v-if="parseInt(qGroupIdx) <= parseInt(questionBreakIdx)">
                        <button
                            class="stepper-item"
                            :class="{
                                active: testSettings.currentQuestionGroupUuid == qGroup[0].uuid,
                                completed: isQuestionAnswered(testObj.questions[qGroupIdx][0]),
                            }"
                            @click="changePage(qGroupIdx - 1, null, qGroup[0].uuid)"
                        >
                            <template v-if="testSettings.currentQuestionGroupUuid == qGroup[0].uuid">
                                <div class="step-marker-container">
                                    <div class="step-marker"></div>
                                </div>
                                <div class="visible-hidden">Current at step {{ qGroupIdx }}</div>
                            </template>
                            <template v-else-if="isQuestionAnswered(testObj.questions[qGroupIdx][0])">
                                <div class="step-completed material-icons" aria-hidden="true">check</div>
                                <div class="visible-hidden">Completed Step {{ qGroupIdx }}</div>
                            </template>
                            <template v-else>
                                <div class="visible-hidden">Step</div>
                                <div class="step-counter">
                                    {{ qGroupIdx }}
                                </div>
                            </template>

                            <label> {{ qGroup[0].question.name }} </label>
                        </button>
                    </template>
                    <template v-else-if="parseInt(qGroupIdx) == parseInt(questionBreakIdx) + 1">
                        <template v-for="(member, memberIdx) in testObj.team">
                            <button
                                class="stepper-item"
                                :class="{ active: testSettings.currentStudentIdx == memberIdx, completed: isReceiverCompletedAnswers(member.id) }"
                                @click="changeStudent(qGroupIdx - 1, memberIdx, qGroup[0].uuid)"
                            >
                                <template v-if="testSettings.currentStudentIdx == memberIdx">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step {{ parseInt(qGroupIdx) + parseInt(memberIdx) }}</div>
                                </template>
                                <template v-else-if="isReceiverCompletedAnswers(member.id)">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step {{ parseInt(qGroupIdx) + parseInt(memberIdx) }}</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">
                                        {{ parseInt(qGroupIdx) + parseInt(memberIdx) }}
                                    </div>
                                </template>
                                <label> {{ member.displayName }} </label>
                            </button>
                        </template>
                    </template>
                    <template v-else-if="!qGroup[0].settings.answerQuestionsForEachTeammate">
                        <button
                            class="stepper-item"
                            :class="{
                                active: testSettings.currentQuestionGroupUuid == qGroup[0].uuid,
                                completed: isQuestionAnswered(testObj.questions[qGroupIdx][0]),
                            }"
                            @click="changePage(qGroupIdx - 1, null, qGroup[0].uuid)"
                        >
                            <template v-if="testSettings.currentQuestionGroupUuid == qGroup[0].uuid">
                                <div class="step-marker-container">
                                    <div class="step-marker"></div>
                                </div>
                                <div class="visible-hidden">
                                    Current at step {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>
                            <template v-else-if="isQuestionAnswered(testObj.questions[qGroupIdx][0])">
                                <div class="step-completed material-icons" aria-hidden="true">check</div>
                                <div class="visible-hidden">
                                    Completed Step {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="visible-hidden">Step</div>
                                <div class="step-counter">
                                    {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>

                            <label> {{ qGroup[0].question.name }} </label>
                        </button>
                    </template>
                    <template v-else-if="qGroup[0].type == 'pointDistribution'">
                        <button
                            class="stepper-item"
                            :class="{
                                active: testSettings.currentQuestionGroupUuid == qGroup[0].uuid,
                                completed: isQuestionAnswered(testObj.questions[qGroupIdx][0]),
                            }"
                            @click="changePage(qGroupIdx - 1, null, qGroup[0].uuid)"
                        >
                            <template v-if="testSettings.currentQuestionGroupUuid == qGroup[0].uuid">
                                <div class="step-marker-container">
                                    <div class="step-marker"></div>
                                </div>
                                <div class="visible-hidden">
                                    Current at step {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>
                            <template v-else-if="isQuestionAnswered(testObj.questions[qGroupIdx][0])">
                                <div class="step-completed material-icons" aria-hidden="true">check</div>
                                <div class="visible-hidden">
                                    Completed Step {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="visible-hidden">Step</div>
                                <div class="step-counter">
                                    {{ parseInt(qGroupIdx) + (parseInt(testObj.team.length) - parseInt(questionBreakIdx)) }}
                                </div>
                            </template>

                            <label> {{ qGroup[0].question.name }} </label>
                        </button>
                    </template>
                </template>
            </div>
        </div> -->

        <div class="testProgression marginBottom20">
            <div class="hidden-xs hidden-sm flex alignFlexStart">
                <h5 class="margin0 fontBlack marginRight20">
                    <b> You’ve completed {{ progressionPercentage(testObj.questions, questionBreakIdx, testObj.team) }}% of the evaluation </b>
                </h5>

                <div class="bar">
                    <div class="barProgress" :style="'width:' + progressionPercentage(testObj.questions, questionBreakIdx, testObj.team) + '%'" />
                </div>
            </div>

            <div class="hidden-md hidden-lg">
                <div class="col-xs-12 flexSpaceBetween marginBottom5 alignBaseline">
                    <h5 class="margin0 fontBlack">
                        <b> You’ve completed {{ progressionPercentage(testObj.questions, questionBreakIdx, testObj.team) }}% of the evaluation </b>
                    </h5>
                </div>
                <div class="bar">
                    <div class="barProgress" :style="'width:' + progressionPercentage(testObj.questions, questionBreakIdx, testObj.team) + '%'" />
                </div>
            </div>
        </div>

        <kr-panel :options="{ canToggle: false }">
            <template #title>
                <div>
                    <h3 class="margin0">
                        {{ toRoman(testObj.questions[testSettings.currentQuestionGroupIdx + 1 + ''][0].group) }}.
                        {{ testObj.questions[testSettings.currentQuestionGroupIdx + 1 + ''][0].question.name }}
                    </h3>
                    <p
                        v-if="testObj.questions[testSettings.currentQuestionGroupIdx + 1 + ''][0].question.description"
                        class="marginTop10 fw-normal whiteSpaceBreakSpacesText"
                    >
                        {{ testObj.questions[testSettings.currentQuestionGroupIdx + 1 + ''][0].question.description }}
                    </p>
                </div>
            </template>
            <template #content>
                <question
                    :question="testObj.questions[testSettings.currentQuestionGroupIdx + 1 + ''][0]"
                    :test-obj="testObj"
                    :test-settings="testSettings"
                ></question>
            </template>
        </kr-panel>

        <div v-if="!isTestCompleted && !testSettings.isPreview && canSubmitTest" class="inlineFlex marginTop10 align-items">
            <button class="btn btn-success mainBtn marginRight20" aria-label="Submit Evaluation" @click.prevent="submitTest">
                <i class="fas fa-check marginRight8" aria-hidden="true"></i>Submit Evaluation
            </button>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { parse } from 'uuid';
import useQuestion from '../../../../../../../src/composables/studentTest/question.ts';
import useTest from '../../../../../../../src/composables/studentTest/test.ts';
import question from './question/question-V2.vue';
import { ref, onMounted, watch, nextTick, computed } from 'vue';
const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    testSettings: {
        type: Object,
        default: () => ({}),
    },
});
const { canSubmitTest, isTestCompleted, isReceiverCompletedAnswers } = useTest(props.testObj);
const { isQuestionAnswered, isStemAnswered, accumulateCriteria, accumulateStudentsCriteria, progressionPercentage } = useQuestion();
const emit = defineEmits(['submitTest']);

const changeStudent = (groupIdx, studentIdx, questionGroupUuid) => {
    props.testSettings.currentQuestionGroupIdx = groupIdx;
    props.testSettings.currentStudentIdx = studentIdx;
    props.testSettings.currentQuestionGroupUuid = questionGroupUuid;
    props.testSettings.currentQuestionStemIdx = 0;
};
const changePage = (groupIdx, studentIdx, questionGroupUuid) => {
    props.testSettings.currentQuestionGroupIdx = groupIdx;
    props.testSettings.currentStudentIdx = studentIdx;
    props.testSettings.currentQuestionGroupUuid = questionGroupUuid;
    props.testSettings.currentQuestionStemIdx = 0;
};
const submitTest = () => {
    emit('submitTest');
};

const questionBreakIdx = ref(0);

const initQuestions = () => {
    for (const [key, value] of Object.entries(props.testObj.questions)) {
        let qGroup = value[0];
        if (!qGroup.settings.answerQuestionsForEachTeammate) {
            questionBreakIdx.value = key;
        } else {
            break;
        }
    }
};

watch(props.testObj, () => {
    initQuestions();
});

onMounted(() => {
    initQuestions();
});
</script>
