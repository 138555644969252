<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container reviewSetting">
        <div class="stepsNavi stepsNaviFixed flexOnly overFlowAuto align-items">
            <a
                class="viewed"
                :class="{ currentStep: step == 1 }"
                href="#"
                :aria-label="step == 1 ? 'Current step Basic Settings' : 'Basic Settings'"
                @click.prevent="max_current_step > 0 ? changeStep(1) : false"
            >
                <div>
                    <!-- <span class="hidden-xs hidden-sm"><b>STEP</b></span> -->
                    <span class="fa-stack">
                        <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                        <strong class="fa-stack-1x"> 1 </strong>
                    </span>
                </div>
                <div class="hidden-xs hidden-sm fs-18px">Basic Settings</div>
            </a>

            <template v-if="step > 1 || max_current_step > 1">
                <template v-if="!['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
                    <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                </template>

                <a
                    v-if="!['evaluation', 'michaelsenEvaluation'].includes(activity.type)"
                    :class="{
                        viewed: step > 1 || max_current_step > 1,
                        currentStep: step == 2,
                    }"
                    href="#"
                    :aria-label="
                        activity.type != 'prework'
                            ? step == 2
                                ? 'Current step Questions'
                                : 'Questions'
                            : step == 2
                            ? 'Current step Upload Materials'
                            : 'Upload Materials'
                    "
                    @click.prevent="max_current_step > 1 ? changeStep(2) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x"> 2 </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">
                        <template v-if="activity.type != 'prework'"> Questions </template>
                        <template v-else> Upload Materials </template>
                    </div>
                </a>

                <template
                    v-if="
                        ['evaluation', 'michaelsenEvaluation'].includes(activity.type) &&
                        (activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves)
                    "
                >
                    <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                </template>

                <a
                    v-if="
                        ['evaluation', 'michaelsenEvaluation'].includes(activity.type) &&
                        (activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves)
                    "
                    :class="{
                        viewed: step > 1 || max_current_step > 1,
                        currentStep: step == 2,
                    }"
                    href="#"
                    :aria-label="step == 2 ? 'Current step Peer Questions' : 'Peer Questions'"
                    @click.prevent="max_current_step > 1 ? changeStep(2) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x"> 2 </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Peer Questions</div>
                </a>

                <template v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type) && activity.evaluationSettings.evaluateTeam">
                    <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                </template>

                <a
                    v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type) && activity.evaluationSettings.evaluateTeam"
                    :class="{
                        viewed: step > 2 || max_current_step > 2,
                        currentStep: step == 3,
                    }"
                    href="#"
                    :aria-label="step == 3 ? 'current step Team Questions' : 'Team Questions'"
                    @click.prevent="max_current_step > 2 ? changeStep(3) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x">
                                <template v-if="activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves"> 3 </template>
                                <template v-else> 2 </template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Team Questions</div>
                </a>

                <template v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type) && activity.evaluationSettings.evaluateInstructor">
                    <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                </template>

                <a
                    v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type) && activity.evaluationSettings.evaluateInstructor"
                    :class="{
                        viewed: step > 3 || max_current_step > 3,
                        currentStep: step == 4,
                    }"
                    href="#"
                    :aria-label="step == 4 ? 'current step Instructor Questions' : 'Instructor Questions'"
                    @click.prevent="max_current_step > 3 ? changeStep(4) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x">
                                <template
                                    v-if="
                                        (activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateTeam ? 1 : 0) ==
                                        2
                                    "
                                >
                                    4
                                </template>
                                <template
                                    v-else-if="
                                        (activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateTeam ? 1 : 0) ==
                                        1
                                    "
                                >
                                    3
                                </template>
                                <template v-else> 2 </template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Instructor Questions</div>
                </a>

                <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>

                <template
                    v-if="
                        !['peerEvaluationV2', 'instructorEvaluation', 'prework', 'evaluation', 'michaelsenEvaluation'].includes(activity.type) &&
                        !(activity.type == 'application' && !activity.others.isApplicationGraded)
                    "
                >
                    <a
                        :class="{
                            viewed: step > 4 || max_current_step > 4,
                            currentStep: step == 5,
                        }"
                        href="#"
                        :aria-label="step == 5 ? 'current step Scoring' : 'Scoring'"
                        @click.prevent="max_current_step > 4 ? changeStep(5) : false"
                    >
                        <div>
                            <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                            <span class="fa-stack">
                                <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                                <strong class="fa-stack-1x"> 3 </strong>
                            </span>
                        </div>
                        <div class="hidden-xs hidden-sm fs-18px">Scoring</div>
                    </a>

                    <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                </template>

                <a
                    :class="{
                        viewed: step > 5 || max_current_step > 5,
                        currentStep: step == 6,
                    }"
                    href="#"
                    :aria-label="step == 6 ? 'current step Optional Settings' : 'Optional Settings'"
                    @click.prevent="max_current_step > 5 ? changeStep(6) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x">
                                <template v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
                                    {{
                                        2 +
                                        ((activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateTeam ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateInstructor ? 1 : 0))
                                    }}
                                </template>
                                <template
                                    v-else-if="
                                        ['peerEvaluationV2', 'prework'].includes(activity.type) ||
                                        (activity.type == 'application' && !activity.others.isApplicationGraded)
                                    "
                                    >3</template
                                >
                                <template v-else>4</template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Optional Settings</div>
                </a>

                <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                <!--<div :class="{'viewed':step>4}">
                    <div>
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x"></i>
                            <strong class="fa-stack-1x">
                                 <template v-if="activity.type=='prework'">4</template>
                                <template v-else>5</template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm">Students</div>
                </div>
                <div>
                    <i class="fas fa-long-arrow-alt-right"></i>
                </div>-->
                <a
                    :class="{
                        viewed: step > 6 || max_current_step > 6,
                        currentStep: step == 7,
                    }"
                    href="#"
                    :aria-label="step == 7 ? 'Current step Review' : 'Review'"
                    @click.prevent="max_current_step > 6 ? changeStep(7) : false"
                >
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x">
                                <template v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
                                    {{
                                        3 +
                                        ((activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateTeam ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateInstructor ? 1 : 0))
                                    }}
                                </template>
                                <template
                                    v-else-if="
                                        ['peerEvaluationV2', 'prework'].includes(activity.type) ||
                                        (activity.type == 'application' && !activity.others.isApplicationGraded)
                                    "
                                >
                                    4
                                </template>
                                <template v-else>5</template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Review</div>
                </a>

                <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>

                <a :class="{ viewed: step > 7, currentStep: step == 8 }" href="#" :aria-label="step == 8 ? 'current step publish' : 'Publish disabled'">
                    <div>
                        <!--<span class="hidden-xs hidden-sm"><b>STEP</b></span>-->
                        <span class="fa-stack">
                            <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i>
                            <strong class="fa-stack-1x">
                                <template
                                    v-if="
                                        ['peerEvaluationV2', 'prework'].includes(activity.type) ||
                                        (activity.type == 'application' && !activity.others.isApplicationGraded)
                                    "
                                >
                                    5
                                </template>
                                <template v-else-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
                                    {{
                                        4 +
                                        ((activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateTeam ? 1 : 0) +
                                            (activity.evaluationSettings.evaluateInstructor ? 1 : 0))
                                    }}
                                </template>
                                <template v-else>6</template>
                            </strong>
                        </span>
                    </div>
                    <div class="hidden-xs hidden-sm fs-18px">Publish</div>
                </a>
            </template>
        </div>
        <div v-if="step == 1">
            <activity-basic-form-new
                :activity-obj="activity"
                @activity-updated="syncActivityObject"
                @next-step="
                    initSteps();
                    changeStep(2);
                "
                @save-and-exit="processSaveAndExit"
            ></activity-basic-form-new>
        </div>
        <div v-if="step == 2">
            <template v-if="activity.type == 'prework'">
                <manage-prework-materials-form
                    :activity-obj="activity"
                    @activity-updated="syncActivityObject"
                    @next-step="changeStep(step + 4)"
                    @save-and-exit="processSaveAndExit"
                ></manage-prework-materials-form>
            </template>
            <template v-else-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
                <!-- <peer-evaluation :activityObj="activity" @activity-updated="syncActivityObject" @next-step="changeStep(step+1)" @save-and-exit="processSaveAndExit" :options="{target:'peer'}"></peer-evaluation> -->
                <manage-questions
                    :activity-obj="activity"
                    :options="{ target: 'peer' }"
                    @activity-updated="syncActivityObject"
                    @next-step="changeStep(step + 1)"
                    @save-and-exit="processSaveAndExit"
                ></manage-questions>
            </template>
            <template v-else-if="['peerEvaluationV2', 'instructorEvaluation', 'survey'].includes(activity.type)">
                <!-- <peer-evaluation :activityObj="activity" @activity-updated="syncActivityObject" @next-step="changeStep(step+1)" @save-and-exit="processSaveAndExit" :options="{target:'peer'}"></peer-evaluation> -->
                <manage-questions-new
                    :activity-obj="activity"
                    :options="{ target: 'peer' }"
                    @activity-updated="syncActivityObject"
                    @next-step="changeStep(step + 4)"
                    @save-and-exit="processSaveAndExit"
                ></manage-questions-new>
            </template>
            <template v-else>
                <manage-questions
                    :activity-obj="activity"
                    @activity-updated="syncActivityObject"
                    @next-step="changeStep(step + 3)"
                    @save-and-exit="processSaveAndExit"
                ></manage-questions>
            </template>
        </div>
        <div v-if="step == 3">
            <manage-questions
                :activity-obj="activity"
                :options="{ target: 'team' }"
                @activity-updated="syncActivityObject"
                @next-step="changeStep(step + 1)"
                @save-and-exit="processSaveAndExit"
            ></manage-questions>
        </div>
        <div v-if="step == 4">
            <manage-questions
                :activity-obj="activity"
                :options="{ target: 'instructor' }"
                @activity-updated="syncActivityObject"
                @next-step="changeStep(step + 2)"
                @save-and-exit="processSaveAndExit"
            ></manage-questions>
        </div>

        <div v-if="step == 5 && !['evaluation', 'michaelsenEvaluation'].includes(activity.type)">
            <manage-points-form
                :activity-obj="activity"
                @activity-updated="syncActivityObject"
                @next-step="changeStep(step + 1)"
                @save-and-exit="processSaveAndExit"
            ></manage-points-form>
        </div>
        <div v-if="step == 6">
            <manage-settings-form
                :activity-obj="activity"
                @activity-updated="syncActivityObject"
                @next-step="changeStep(step + 1)"
                @save-and-exit="processSaveAndExit"
            ></manage-settings-form>
        </div>
        <!--<div v-if="step==5">
            <div class="marginBottom30">
                <button class="col-xs-12 btn btn-success mainBtn" @click.prevent="changeStep(step+1)">
                    <i class="fa fa-check"></i>&nbsp; Proceed to Confirm
                </button>
            </div>
            <manage-students :courseUuid="activity.course.uuid" :options="{canInviteStudents:false,canAddStudents:false}"></manage-students>
            <div class="marginTop10">
                <button class="col-xs-12 btn btn-success mainBtn" @click.prevent="changeStep(step+1)">
                    <i class="fa fa-check"></i>&nbsp; Proceed to Confirm
                </button>
            </div>
        </div>-->
        <div v-if="step == 7">
            <div class="marginBottom30">
                <div class="hidden-xs hidden-sm">
                    <div class="buttonGroupInline">
                        <!--<router-link v-if="activity.type!='prework'" :to="{name:'teachers.tests.preview',params:{id:activity.uuid}}" tag="a" class="btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                        </router-link>
                         <router-link v-else :to="{name:'teachers.tests.preview.prework',params:{id:activity.uuid}}" tag="a" class="btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                        </router-link>-->
                        <button class="btn btn-success mainBtn" :disabled="!canGoToPublish ? true : undefined" @click.prevent="changeStep(step + 1)">
                            <i class="fa fa-check marginRight8" aria-hidden="true"></i>Go to Publish your activity
                        </button>
                        <button class="btn btn-default mainBtn" aria-label="Save and Exit" @click.prevent="processSaveAndExit">
                            <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Save and Exit
                        </button>
                    </div>
                </div>
                <div class="hidden-md hidden-lg">
                    <!--<router-link v-if="activity.type!='prework'" :to="{name:'teachers.tests.preview',params:{id:activity.uuid}}" tag="a" class="col-xs-12 btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                    </router-link>
                    <router-link v-else :to="{name:'teachers.tests.preview.prework',params:{id:activity.uuid}}" tag="a" class="col-xs-12 btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                       Preview Questions in a New Tab
                    </router-link>-->
                    <button
                        class="col-xs-12 btn btn-success mainBtn"
                        :disabled="!canGoToPublish ? true : undefined"
                        aria-label="Confirm Activity Set Up"
                        @click.prevent="changeStep(step + 1)"
                    >
                        <i class="fa fa-check marginRight8" aria-hidden="true"></i>Confirm Activity Set Up
                    </button>
                </div>
            </div>
            <div
                v-if="
                    !['prework', 'evaluation', 'michaelsenEvaluation', 'peerEvaluationV2'].includes(activity.type) &&
                    !(activity.type == 'application' && !activity.others.isApplicationGraded) &&
                    (activity.pointSettingsVersion != activity.questionsVersion || !activity.pointSettingsVersion)
                "
                class="alert alert-warning marginBottom20"
            >
                <i class="fas fa-exclamation-triangle"></i>&nbsp;
                <div>
                    You have made changes in your questions and we need to sync it. Please go back to
                    <a class="link" aria-label="Step 3" @click.prevent="changeStep(5)">Step 3</a>
                    and update your point settings. Click
                    <span style="font-weight: 900"> Save and Exit </span> or
                    <span style="font-weight: 900">Go to Optional Settings</span>
                    (Step 4) after.
                </div>
            </div>
            <!--<div class="alert alert-warning marginBottom20" v-if="unassigned_students_length!=0">
                 <i class="fas fa-exclamation-triangle"></i>&nbsp;
                <div>
                   Please make sure all students are assigned to a team
                </div>
            </div>-->
            <div class="create">
                <activity-basic-form
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation' }"
                    @activity-updated="syncActivityObject"
                ></activity-basic-form>
                <manage-settings-form
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation' }"
                    @activity-updated="syncActivityObject"
                ></manage-settings-form>
                <manage-prework-materials-form
                    v-if="activity.type == 'prework'"
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation' }"
                    @activity-updated="syncActivityObject"
                ></manage-prework-materials-form>
                <manage-points-form
                    v-if="
                        !['prework', 'evaluation', 'michaelsenEvaluation'].includes(activity.type) &&
                        !(activity.type == 'application' && !activity.others.isApplicationGraded)
                    "
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation' }"
                    @activity-updated="syncActivityObject"
                ></manage-points-form>

                <manage-questions
                    v-if="
                        ['evaluation', 'michaelsenEvaluation'].includes(activity.type) &&
                        (activity.evaluationSettings.evaluateThemselves || activity.evaluationSettings.evaluateTeammates)
                    "
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation', target: 'peer' }"
                    @activity-updated="syncActivityObject"
                ></manage-questions>

                <manage-questions
                    v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type) && activity.evaluationSettings.evaluateTeam"
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation', target: 'team' }"
                    @activity-updated="syncActivityObject"
                ></manage-questions>

                <manage-questions
                    v-if="
                        ['evaluation', ['evaluation', 'michaelsenEvaluation'].includes(activity.type)].includes(activity.type) &&
                        activity.evaluationSettings.evaluateInstructor
                    "
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{
                        pageType: 'confirmation',
                        target: 'instructor',
                    }"
                    @activity-updated="syncActivityObject"
                ></manage-questions>

                <template v-if="['peerEvaluationV2', 'instructorEvaluation', 'survey'].includes(activity.type)">
                    <manage-questions-new
                        :activity-obj="activity"
                        class="marginBottom0 marginTop20"
                        :options="{ pageType: 'confirmation' }"
                        @activity-updated="syncActivityObject"
                    ></manage-questions-new>
                </template>
                <manage-questions
                    v-else-if="!['prework', 'evaluation', 'michaelsenEvaluation'].includes(activity.type)"
                    class="marginBottom0 marginTop20"
                    :activity-obj="activity"
                    :options="{ pageType: 'confirmation' }"
                    @activity-updated="syncActivityObject"
                ></manage-questions>
                <!--<manage-students  :courseUuid="activity.course.uuid" :options="{canInviteStudents:false,canAddStudents:false}" @unassigned_students_length="processUnassignedStudentsLength"></manage-students>-->
            </div>
            <div class="marginTop30">
                <div class="hidden-xs hidden-sm">
                    <div class="buttonGroupInline">
                        <!--<router-link v-if="activity.type!='prework'" :to="{name:'teachers.tests.preview',params:{id:activity.uuid}}" tag="a" class="btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                        </router-link>
                         <router-link v-else :to="{name:'teachers.tests.preview.prework',params:{id:activity.uuid}}" tag="a" class="btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                        </router-link>-->
                        <button class="btn btn-success mainBtn" :disabled="!canGoToPublish ? true : undefined" @click.prevent="changeStep(step + 1)">
                            <i class="fa fa-check marginRight8" aria-hidden="true"></i>Go to Publish your activity
                        </button>
                        <button class="btn btn-default mainBtn" aria-label="Save And Exit" @click.prevent="processSaveAndExit">
                            <i class="fa fa-floppy-o marginRight8" aria-hidden="true"></i>Save and Exit
                        </button>
                    </div>
                </div>
                <div class="hidden-md hidden-lg">
                    <!--<router-link v-if="activity.type!='prework'" :to="{name:'teachers.tests.preview',params:{id:activity.uuid}}" tag="a" class="col-xs-12 btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                           Preview Questions in a New Tab
                    </router-link>
                    <router-link v-else :to="{name:'teachers.tests.preview.prework',params:{id:activity.uuid}}" tag="a" class="col-xs-12 btn btn-primary mainBtn" style="line-height:25px" target="_blank">
                       Preview Questions in a New Tab
                    </router-link>-->
                    <button class="col-xs-12 btn btn-success mainBtn" :disabled="!canGoToPublish ? true : undefined" @click.prevent="changeStep(step + 1)">
                        <i class="fa fa-check marginRight8" aria-hidden="true"></i>Go to Publish your activity
                    </button>
                </div>
            </div>
        </div>
        <div v-if="step == 8">
            <template
                v-if="
                    activity &&
                    (['trat', 'iratntrat'].includes(activity.type) || (activity.type == 'application' && activity.others.applicationType == 'team')) &&
                    activity.course.unassignedStudents &&
                    activity.course.unassignedStudents.length != 0
                "
            >
                <div class="flexSpaceBetween marginBottom20 hidden-xs hidden-sm">
                    <div class="alert alert-danger" style="font-size: 16px">
                        <div><i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;</div>
                        <div>
                            <div>
                                <b>Warning!</b> There are
                                <b
                                    ><a
                                        class="fontBlack"
                                        :aria-label="activity.course.unassignedStudents.length"
                                        @click.prevent="openUnassignedStudentsModal"
                                        >{{ activity.course.unassignedStudents.length }}</a
                                    ></b
                                >
                                student(s) in your course who are not yet assigned to teams. If you continue to publish this activity, these student(s)
                                <strong>MIGHT NOT</strong> be able to see the activity in their student dashboard.
                            </div>
                            <!-- <div>
                                Once you have published this activity, any changes to team assignments will require you to republish this activity again for those changes to take effect; student/team answers will be reset.
                            </div> -->
                            <div>
                                Click
                                <router-link
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: activity.course.uuid },
                                        query: { tab: 'student' },
                                    }"
                                    style="text-decoration: underline"
                                    class="fontBlack"
                                    aria-label="here"
                                    >here</router-link
                                >
                                to manage student teams first.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="marginBottom20 hidden-md hidden-lg">
                    <div class="alert alert-danger" style="font-size: 16px">
                        <div>
                            <i class="fa fa-info-circle marginRight8" aria-hidden="true"></i>
                        </div>
                        <div>
                            <div>
                                <b>Warning!</b> There are
                                <b
                                    ><a class="fontBlack" @click.prevent="openUnassignedStudentsModal">{{ activity.course.unassignedStudents.length }}</a></b
                                >
                                student(s) in your course who are not yet assigned to teams. If you continue to publish this activity, these student(s)
                                <strong>MIGHT NOT</strong> be able to see the activity in their student dashboard.
                            </div>
                            <!-- <div>
                                Once you have published this activity, any changes to team assignments will require you to republish this activity again for those changes to take effect; student/team answers will be reset.
                            </div> -->
                            <div>
                                Click
                                <router-link
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: activity.course.uuid },
                                        query: { tab: 'student' },
                                    }"
                                    style="text-decoration: underline"
                                    class="fontBlack"
                                    aria-label="here"
                                    >here</router-link
                                >
                                to manage student teams first.
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <publish :activity-obj="activity" @published="handlePublishedEvent" @errors="changeStep(2)"></publish>
        </div>
        <div id="noStudentErrorModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="noStudentErrorModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="noStudentErrorModal-title" class="modal-title">No Students in Course</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>Please add students to your course before publishing this activity.</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="hidden-xs hidden-sm flex marginTop20">
                                <div class="col-xs-12 col-md-6 paddingRight10">
                                    <router-link
                                        id="modal-dismiss-btn"
                                        :to="{
                                            name: 'courses.view',
                                            params: {
                                                id: activity ? activity.course.uuid : $route.params.course_id,
                                            },
                                            query: { tab: 'student' },
                                        }"
                                    >
                                        <button class="col-xs-12 btn btn-primary mainBtn" aria-label="Manage Students">Manage Students</button>
                                    </router-link>
                                </div>
                                <div class="col-xs-12 col-md-6 paddingLeft10">
                                    <button
                                        id="modal-dismiss-btn"
                                        class="col-xs-12 btn btn-default mainBtn"
                                        aria-label="Close"
                                        @click="closeNoStudentErrorModal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                            <div class="hidden-mg hidden-lg marginTop20">
                                <router-link
                                    id="modal-dismiss-btn"
                                    :to="{
                                        name: 'courses.view',
                                        params: {
                                            id: activity ? activity.course.uuid : $route.params.course_id,
                                        },
                                        query: { tab: 'student' },
                                    }"
                                    aria-label="Manage Students"
                                    type="button"
                                    class="col-xs-12 btn btn-primary mainBtn"
                                >
                                    Manage Students
                                </router-link>
                                <button id="modal-dismiss-btn" class="col-xs-12 btn btn-default mainBtn" aria-label="Close" @click="closeNoStudentErrorModal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="unassignedStudentsModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="unassignedStudentsModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="unassignedStudentsModal-title" class="modal-title">Unassigned Students</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="activity" class="col-xs-12">
                                <ul v-for="(student, idx) in activity.course.unassignedStudents">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-12">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Close">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../components/forms/form';
import KrAuth from '../../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            step: 1,
            max_current_step: 1,
            activity: null,
            isNewlyCreated: true,
            unassigned_students_length: 0,
            component_done_loading: false,
        };
    },
    created() {
        var that = this;
        if (this.auth.isImpersonation()) {
            this.$router.push({ name: 'errors.403' });
        }
        if (this.$route.name == 'courses.modules.activities.create') {
            this.component_done_loading = true;
        }
        if (this.$route.name == 'courses.modules.activities.continue') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-graduation-cap" aria-hidden="true"></i>&nbsp;Courses',
            });
            this.isNewlyCreated = false;
            this.fetchActivity()
                .then(function (response) {
                    that.activity = response.data.data;
                    if (that.activity.isPublished) {
                        that.$nextTick(function () {
                            that.$router.push({
                                name: 'courses.view',
                                params: { id: that.activity.course.uuid },
                                query: { tab: 'module' },
                            });
                        });
                    }
                    let codeStr = '';
                    if (that.activity.course.code) {
                        codeStr = ' (' + that.activity.course.code + ')';
                    }
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Courses', link: 'courses.index' },
                            {
                                name: that.activity.course.name + codeStr,
                                link: 'courses.view',
                                params: { id: that.activity.course.uuid },
                            },
                            { name: that.activity.name },
                        ],
                    });
                    that.initSteps();
                    that.component_done_loading = true;
                })
                .catch(function (errors) {});
        }
        if (this.$route.name == 'courses.modules.activities.republish') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-graduation-cap" aria-hidden="true"></i>&nbsp;Courses',
            });
            this.isNewlyCreated = false;
            this.fetchActivity()
                .then(function (response) {
                    that.activity = response.data.data;
                    if (that.activity.isPublished) {
                        that.$nextTick(function () {
                            that.$router.push({
                                name: 'courses.view',
                                params: { id: that.activity.course.uuid },
                                query: { tab: 'module' },
                            });
                        });
                    }
                    let codeStr = '';
                    if (that.activity.course.code) {
                        codeStr = ' (' + that.activity.course.code + ')';
                    }
                    Events.fire('topbar_update', {
                        title: '<i class="fas fa-graduation-cap" aria-hidden="true"></i>&nbsp;Courses',
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Courses', link: 'courses.index' },
                            {
                                name: that.activity.course.name + codeStr,
                                link: 'courses.view',
                                params: { id: that.activity.course.uuid },
                            },
                            { name: that.activity.name },
                        ],
                    });
                    that.initSteps();
                    that.component_done_loading = true;
                })
                .catch(function (errors) {});
        }
    },
    methods: {
        initSteps() {
            if (this.activity.type == 'prework') {
                if (!this.activity.isActivitySettingsCompleted) {
                    this.step = 6;
                } else {
                    this.step = 7;
                }
            } else if (!['evaluation', 'michaelsenEvaluation'].includes(this.activity.type)) {
                //irat trat application
                if (!this.activity.questionsVersion) {
                    this.step = 2;
                } else if (!this.activity.pointSettingsVersion) {
                    this.step = 5;
                } else if (!this.activity.isActivitySettingsCompleted) {
                    this.step = 6;
                } else {
                    this.step = 7;
                }
            } else {
                //evaluation
                if (
                    (this.activity.evaluationSettings.evaluateThemselves || this.activity.evaluationSettings.evaluateTeammates) &&
                    this.activity.peerQuestions.length == 0
                ) {
                    this.step = 2;
                } else if (this.activity.evaluationSettings.evaluateTeam && this.activity.teamQuestions.length == 0) {
                    this.step = 3;
                } else if (this.activity.evaluationSettings.evaluateInstructor && this.activity.instructorQuestions.length == 0) {
                    this.step = 4;
                } else if (!this.activity.isActivitySettingsCompleted) {
                    this.step = 6;
                } else {
                    this.step = 7;
                }
            }
            this.max_current_step = this.step;
            if (this.activity.currentStep) {
                this.step = this.activity.currentStep;
            }
        },
        openUnassignedStudentsModal() {
            $('#unassignedStudentsModal').modal('show');
        },
        openNoStudentErrorModal() {
            $('#noStudentErrorModal').modal('show');
        },
        closeNoStudentErrorModal() {
            $('#noStudentErrorModal').modal('hide');
            //this.$router.push({name:'courses.view',params:{id:this.activity.course.uuid},query:{activityUuid:this.activity.uuid}});
        },
        processSaveAndExit(activity = null) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Activity has been saved',
            });
            let a = this.activity ? this.activity : activity;
            this.$router.push({
                name: 'courses.view',
                params: { id: a.course.uuid },
                query: { activityUuid: a.uuid },
            });
        },
        processUnassignedStudentsLength(length) {
            this.unassigned_students_length = length;
        },
        async handlePublishedEvent(event) {
            let testsData;
            await axios
                .get('activities/' + this.activity.uuid)
                .then((response) => {
                    testsData = response.data.data.tests;
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Activity has been successfully published',
            });

            if (this.$route.name == 'courses.modules.activities.republish') {
                let uuid = _.get(this, '$route.query.testUuid', false);
                if (uuid) {
                    this.$router.push({
                        name: 'activities.tests.dashboard',
                        params: { id: uuid },
                    });
                } else {
                    // this.$router.push({name:'activities.tests.dashboard',params:{id:this.activity.tests[0].uuid}});
                    if (event == 'dashboard') {
                        if (testsData[0].type == 'peerEvaluationV2') {
                            this.$router.push({
                                name: 'activities.tests.dashboard.peer-evaluation',
                                params: { id: testsData[0].uuid },
                            });
                        } else {
                            this.$router.push({
                                name: 'activities.tests.dashboard',
                                params: { id: testsData[0].uuid },
                            });
                        }
                    } else {
                        this.$router.push({
                            name: 'courses.view',
                            params: { id: this.activity.course.uuid },
                            query: { activityUuid: this.activity.uuid },
                        });
                    }
                }
            } else {
                if (event == 'dashboard') {
                    if (testsData[0].type == 'peerEvaluationV2') {
                        this.$router.push({
                            name: 'activities.tests.dashboard.peer-evaluation',
                            params: { id: testsData[0].uuid },
                        });
                    } else {
                        this.$router.push({
                            name: 'activities.tests.dashboard',
                            params: { id: testsData[0].uuid },
                        });
                    }
                } else {
                    this.$router.push({
                        name: 'courses.view',
                        params: { id: this.activity.course.uuid },
                        query: { activityUuid: this.activity.uuid },
                    });
                }
            }
        },
        fetchActivity() {
            return axios.get('/activities/' + this.$route.params.id);
            //prework
            //return axios.get('/activities/d30f9664-36dc-40aa-a1c2-d091c24f219a');
            //iratntrat
            //return axios.get('/activities/f207f8e6-e539-4ba3-8305-8fe4df941cf2');
        },
        changeStep(step) {
            var that = this;
            if (this.activity.type == 'application' && !this.activity.others.isApplicationGraded && step == 5) {
                step++;
            }

            if (['evaluation', 'michaelsenEvaluation'].includes(this.activity.type) && step > 1 && step <= 5) {
                if (!(this.activity.evaluationSettings.evaluateThemselves || this.activity.evaluationSettings.evaluateTeammates) && step == 2) {
                    step++;
                }
                if (!this.activity.evaluationSettings.evaluateTeam && step == 3) {
                    step++;
                }
                if (!this.activity.evaluationSettings.evaluateInstructor && step == 4) {
                    step++;
                }
                if (step == 5) {
                    step++;
                }
            }
            var tempStep = step;

            if (tempStep == 8) {
                tempStep--;
            }
            /*if(step==8&&this.activity.course.students.length===0){
                    this.openNoStudentErrorModal();
                    return;
                }*/
            if (that.activity) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                axios
                    .put('activities/' + that.activity.uuid, {
                        currentStep: tempStep,
                    })
                    .then(function (response) {
                        Vue.nextTick(function () {
                            that.step = step;
                            if (that.step > that.max_current_step) {
                                that.max_current_step = that.step;
                            }
                        });
                    });
            }
        },
        syncActivityObject(data) {
            this.activity = data;
            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    {
                        name: this.activity.course.name + (this.activity.course.code ? ' (' + this.activity.course.code + ')' : ''),
                        link: 'courses.view',
                        params: { id: this.activity.course.uuid },
                    },
                    { name: this.activity.name },
                ],
            });
        },
    },
    components: {
        'activity-basic-form': require(`./form-partials/partial.activity-basic-info.vue`).default,
        'activity-basic-form-new': require(`./form-partials/partial.activity-basic-info-new.vue`).default,
        'manage-questions': require(`./questions/manage.vue`).default,
        'manage-questions-new': require(`./questions/manage-new.vue`).default,
        'manage-prework-materials-form': require(`./form-partials/partial.prework-materials.vue`).default,
        'manage-points-form': require(`./form-partials/partial.point-settings.vue`).default,
        'manage-settings-form': require(`./form-partials/partial.activity-settings.vue`).default,
        'manage-students': require(`./../../students/manage.vue`).default,
        publish: require(`./form-partials/partial.publish.vue`).default,
    },
    computed: {
        canGoToPublish() {
            let activity = this.activity;
            activity = activity ? activity : {};
            if (activity.type == 'prework') {
                /*let attachments = _.get(activity,'materials',[]);
                    attachments = attachments?attachments:[];*/
                if (activity.isActivitySettingsCompleted) {
                    return true;
                }
            }
            if (activity.type == 'irat') {
                if (
                    activity.pointSettingsVersion != activity.questionsVersion ||
                    activity.pointSettingsVersion == null ||
                    !activity.isActivitySettingsCompleted
                ) {
                    return false;
                }
                return true;
            }

            if (activity.type == 'trat') {
                if (
                    activity.pointSettingsVersion != activity.questionsVersion ||
                    activity.pointSettingsVersion == null ||
                    !activity.isActivitySettingsCompleted
                ) {
                    return false;
                }
                return true;
            }
            if (activity.type == 'iratntrat') {
                if (
                    activity.pointSettingsVersion != activity.questionsVersion ||
                    activity.pointSettingsVersion == null ||
                    !activity.isActivitySettingsCompleted
                ) {
                    return false;
                }
                return true;
            }
            if (activity.type == 'application') {
                if (
                    (activity.pointSettingsVersion != activity.questionsVersion && activity.others.isApplicationGraded) ||
                    (activity.pointSettingsVersion == null && activity.others.isApplicationGraded) ||
                    !activity.isActivitySettingsCompleted
                ) {
                    return false;
                }
                return true;
            }
            if (['evaluation', 'michaelsenEvaluation'].includes(activity.type)) {
                if (!activity.isActivitySettingsCompleted) {
                    return false;
                }
                if (activity.evaluationSettings.evaluateTeam) {
                    if (activity.teamQuestions.length == 0) {
                        return false;
                    }
                }
                if (activity.evaluationSettings.evaluateTeammates || activity.evaluationSettings.evaluateThemselves) {
                    if (activity.peerQuestions.length == 0) {
                        return false;
                    }
                }
                if (activity.evaluationSettings.evaluateInstructor) {
                    if (activity.instructorQuestions.length == 0) {
                        return false;
                    }
                }
                return true;
            }
            if (['peerEvaluationV2'].includes(activity.type)) {
                if (!activity.isActivitySettingsCompleted) {
                    return false;
                }
                if (activity.questions.length == 0) {
                    return false;
                }
                return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.create .col-md-6 div,
.create .col-md-6 .x_panel {
    height: 100%;
}
.viewed {
    cursor: pointer;
}
</style>
