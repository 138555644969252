<template>
    <div v-if="component_done_loading && isShown" class="timer_Div darkGreen flex align-items justifyCenter" role="timer" aria-live="polite">
        <div class="visible-hidden">
            {{ time.days }}
            <template v-if="time.days > 1"> days and </template>
            <template v-else> day and </template>
            {{ time.hours }}
            <template v-if="time.hours > 1"> hours and </template>
            <template v-else> hour and </template>
            {{ time.minutes }}
            <template v-if="time.minutes > 1"> minutes </template>
            <template v-else> minute </template>
        </div>

        <div class="time flex" aria-hidden="true">
            <div class="number-div">
                {{ time.days.charAt(0) }}
            </div>
            <div class="number-div">
                {{ time.days.charAt(1) }}
            </div>
        </div>

        <div class="colon" aria-hidden="true">:</div>

        <div class="time flex" aria-hidden="true">
            <div class="number-div">
                {{ time.hours.charAt(0) }}
            </div>
            <div class="number-div">
                {{ time.hours.charAt(1) }}
            </div>
        </div>

        <div class="colon" aria-hidden="true">:</div>

        <div class="time flex" aria-hidden="true">
            <div class="number-div">
                {{ time.minutes.charAt(0) }}
            </div>
            <div class="number-div">
                {{ time.minutes.charAt(1) }}
            </div>
        </div>

        <div class="colon" aria-hidden="true">:</div>

        <div class="time flex" aria-hidden="true">
            <div class="number-div">
                {{ time.seconds.charAt(0) }}
            </div>
            <div class="number-div">
                {{ time.seconds.charAt(1) }}
            </div>
        </div>
        <!-- <div class="clock green">
		  <div>
		    <div class="label-div text-uppercase">Hours</div>
		     <div class="number-div">
		      <div class="number">0</div>
		     </div>
		     <div class="number-div">
		      <div class="number">9</div>
		     </div>
		  </div>
		    <div>
		      <div>
		        <div class="label-div text-uppercase"></div>
		         <div class="number-div" style="background:none;width:20px;">
		          <div class="colon" style="line-height:1;">:</div>
		         </div>
		      </div>
		    </div>
		    <div>
		      <div>
		        <div class="label-div text-uppercase">Minutes</div>
		         <div class="number-div">
		          <div class="number">3</div>
		         </div>
		         <div class="number-div">
		          <div class="number">3</div>
		         </div>
		      </div>
		    </div>
		    <div>
		      <div>
		        <div class="label-div text-uppercase"></div>
		         <div class="number-div" style="background:none;width:20px;">
		          <div class="colon" style="line-height:1;">:</div>
		         </div>
		      </div>
		    </div>
		    <div>
		      <div>
		        <div class="label-div text-uppercase">Seconds</div>
		         <div class="number-div">
		          <div class="number">59</div>
		         </div>
		         <div class="number-div">
		          <div class="number">59</div>
		         </div>
		      </div>
		    </div>
		</div> -->
    </div>
</template>
<script>
export default {
    props: ['testObj', 'currentQuestion'],
    data() {
        return {
            component_done_loading: false,
            timer: null,
            time: { days: '00', hours: '00', minutes: '00', seconds: '00' },
            isShown: false,
        };
    },
    computed: {
        isTimerExtended() {
            if (!this.testObj) {
                return false;
            } else {
                return _.get(this.testObj, 'expectedEndingTime', false);
            }
        },
        isStartedEarly() {
            if (!this.test) {
                return false;
            } else {
                if (this.isTimerExtended && _.get(this.testObj, 'testStudent.canEarlyStart', false)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    watch: {
        'testObj.status': {
            handler: function () {
                if (
                    (this.testObj.status == 'ongoing' || (this.isTimerExtended && this.testObj.status != 'paused')) &&
                    !['paused', 'finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus)
                ) {
                    if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                        if (!['submitted'].includes(this.testObj.myProgression.teamStatus)) {
                            this.isShown = true;
                            this.initTimer();
                        } else {
                            this.stopTimer();
                            this.isShown = false;
                        }
                    } else {
                        this.isShown = true;
                        this.initTimer();
                    }
                } else {
                    this.isShown = false;
                    this.stopTimer();
                }
                this.$emit('isShown', this.isShown);
            },
        },
        'testObj.myProgression.startTime': {
            handler: function () {
                if (
                    (this.testObj.status == 'ongoing' || (this.isTimerExtended && this.testObj.status != 'paused')) &&
                    !['paused', 'finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus)
                ) {
                    if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                        if (!['submitted'].includes(this.testObj.myProgression.teamStatus)) {
                            this.isShown = true;
                            this.initTimer();
                        } else {
                            this.stopTimer();
                            this.isShown = false;
                        }
                    } else {
                        this.isShown = true;
                        this.initTimer();
                    }
                } else {
                    this.isShown = false;
                    this.stopTimer();
                }
                this.$emit('isShown', this.isShown);
            },
        },
        'testObj.myProgression.endTime': {
            handler: function () {
                if (
                    (this.testObj.status == 'ongoing' || (this.isTimerExtended && this.testObj.status != 'paused')) &&
                    !['paused', 'finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus)
                ) {
                    if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                        if (!['submitted'].includes(this.testObj.myProgression.teamStatus)) {
                            this.isShown = true;
                            this.initTimer();
                        } else {
                            this.stopTimer();
                            this.isShown = false;
                        }
                    } else {
                        this.isShown = true;
                        this.initTimer();
                    }
                } else {
                    this.stopTimer();
                    this.isShown = false;
                }
                this.$emit('isShown', this.isShown);
            },
        },
        'testObj.extraTimeValues': {
            handler: function () {
                if (
                    (this.testObj.status == 'ongoing' || (this.isTimerExtended && this.testObj.status != 'paused')) &&
                    !['paused', 'finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus)
                ) {
                    if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                        if (!['submitted'].includes(this.testObj.myProgression.teamStatus)) {
                            this.isShown = true;
                            setTimeout(() => {
                                this.initTimer();
                            }, 2000);
                        } else {
                            this.stopTimer();
                            this.isShown = false;
                        }
                    } else {
                        this.isShown = true;
                        setTimeout(() => {
                            this.initTimer();
                        }, 2000);
                    }
                } else {
                    this.stopTimer();
                    this.isShown = false;
                }
                this.$emit('isShown', this.isShown);
            },
        },
    },
    created() {},
    mounted() {
        if (
            (this.testObj.status == 'ongoing' || (this.isTimerExtended && this.testObj.status != 'paused')) &&
            !['paused', 'finished', 'incomplete'].includes(this.testObj.myProgression.userTestStatus) &&
            !this.testObj._isPreview
        ) {
            if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                if (!['submitted'].includes(this.testObj.myProgression.teamStatus)) {
                    this.initTimer();
                } else {
                    this.isShown = false;
                }
            } else {
                this.initTimer();
            }
        } else {
            this.isShown = false;
        }

        this.$emit('isShown', this.isShown);
        this.component_done_loading = true;
    },
    beforeUnmount() {
        this.stopTimer();
    },
    methods: {
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initTimer() {
            var that = this;
            this.isShown = true;

            if (
                !(_.get(this.testObj, 'myProgression.startTime') && _.get(this.testObj, 'myProgression.endTime')) &&
                !_.get(this.testObj, 'expectedEndingTime')
            ) {
                return;
            }

            //sync or async uses same time.
            var startTime = this.convertToReadableDate(_.get(this.testObj, 'myProgression.startTime', '')).dateObj;
            var endTime = this.convertToReadableDate(_.get(this.testObj, 'myProgression.endTime', '')).dateObj;

            if (this.isTimerExtended) {
                endTime = this.convertToReadableDate(_.get(this.testObj, 'expectedEndingTime')).dateObj;
            }
            var totalDistance = moment.duration(endTime.diff(startTime));
            this.stopTimer();
            this.timer = setInterval(function () {
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.days = ('0' + distance.days()).slice(-2);
                    that.time.hours = ('0' + distance.hours()).slice(-2);
                    that.time.minutes = ('0' + distance.minutes()).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    /*if(_.get(that.testObj,'settings.type','synchronous')=='asynchronous'){
						axios.put('student/tests/'+that.testObj.uuid+'/end').then(function(response){
							that.testObj.myProgression.userTestStatus = 'ended';
						}).catch(function(errors){
						});
					}*/
                    that.time.days = '00';
                    that.time.hours = '00';
                    that.time.minutes = '00';
                    that.time.seconds = '00';
                    that.stopTimer();
                }
                //totalDistance.subtract(1,'seconds');
            }, 1000);
        },
    },
};
</script>
<style scoped>
.time .number-div {
    background: #fff;
    padding: 6px 12px;
    border-radius: 3px;
    line-height: 0.8;
}

.time .number-div:last-child {
    margin-left: 5px;
}

.colon {
    color: #fff;
}

@media (min-width: 992px) {
    .timer_Div {
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
    }

    .colon {
        padding: 0 10px;
    }
}

@media (max-width: 991px) {
    .timer_Div {
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
    }

    .colon {
        padding: 0 5px;
    }

    .time .number-div {
        padding: 6px 10px 5px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .timer_Div {
        font-size: 15px;
    }

    .time .number-div {
        padding: 8px 6px 4px;
    }
}
</style>
