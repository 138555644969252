<template><div></div></template>
<script>
import KrAuth from '../../components/auth/auth';
import { useAuthUserStore } from '@/stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            authStore: useAuthUserStore(),
        };
    },
    created() {
        var that = this;
        if (this.$route.query.session_id) {
            if (this.auth.isLoggedIn()) {
                if (this.auth.user().role == 'Student') {
                    this.authStore.client.profile.status = 'Active (Paid)';
                }
            }
            let ret = { ...this.$route.query };
            if (ret.gift) {
                ret.gift = true;
            }
            axios
                .post(`/payments/stripe/checkout-success`, ret)
                .then(function (response) {
                    that.$router.push({ name: '/' });
                })
                .catch(function (errors) {
                    that.$router.push({ name: '/' });
                });
        }
    },
};
</script>
