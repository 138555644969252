<template>
    <div v-if="component_done_loading">
        <div class="marginBottom30">
            <div class="width100">
                <div class="floatLeft">
                    <label class="control-label d-block"> Select students to set accommodations </label>
                    <div class="inlineFlex">
                        <div class="dropdown">
                            <button
                                v-tooltip="{
                                    content: selectedStudents.length == 0 ? 'You need to select at least 1 student to enable the button' : '',
                                }"
                                class="btn btn-default"
                                :class="{
                                    disabled: selectedStudents.length == 0,
                                }"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                :aria-label="selectedStudents.length == 0 ? 'More Actions disabled' : 'More Actions'"
                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                            >
                                <template v-if="extraTimeForm.model.multiple">
                                    <template v-if="extraTimeForm.model.multiple == '1.25x'"> Has 25% extra time </template>
                                    <template v-else-if="extraTimeForm.model.multiple == '1.5x'"> Has 50% extra time </template>
                                    <template v-else-if="extraTimeForm.model.multiple == '1.75x'"> Has 75% extra time </template>
                                    <template v-else-if="extraTimeForm.model.multiple == '2x'"> Has 100% extra time </template>
                                </template>
                                <template v-else-if="extraTimeForm.model.multiple == null"> Select Accommodations </template>
                                <template v-if="extraTimeForm.model.multiple == 'none'"> None </template>
                                <i
                                    :class="{
                                        'fa fa-caret-up': isMoreOptionsOpened,
                                        'fa fa-caret-down': !isMoreOptionsOpened,
                                    }"
                                    class="marginLeft8"
                                    aria-hidden="true"
                                />
                            </button>

                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <a
                                            class="danger-state"
                                            href="#"
                                            @click.prevent="
                                                extraTimeForm.model.multiple = 'none';
                                                openModal();
                                            "
                                        >
                                            None
                                        </a>
                                    </li>
                                    <hr />
                                    <li>
                                        <a
                                            class="primary-state"
                                            href="#"
                                            @click.prevent="
                                                extraTimeForm.model.multiple = '1.25x';
                                                openModal();
                                            "
                                        >
                                            Has 25% extra time
                                        </a>
                                    </li>
                                    <hr />
                                    <li>
                                        <a
                                            class="primary-state"
                                            href="#"
                                            @click.prevent="
                                                extraTimeForm.model.multiple = '1.5x';
                                                openModal();
                                            "
                                        >
                                            Has 50% extra time
                                        </a>
                                    </li>
                                    <hr />
                                    <li>
                                        <a
                                            class="primary-state"
                                            href="#"
                                            @click.prevent="
                                                extraTimeForm.model.multiple = '1.75x';
                                                openModal();
                                            "
                                        >
                                            Has 75% extra time
                                        </a>
                                    </li>
                                    <hr />
                                    <li>
                                        <a
                                            class="primary-state"
                                            href="#"
                                            @click.prevent="
                                                extraTimeForm.model.multiple = '2x';
                                                openModal();
                                            "
                                        >
                                            Has 100% extra time
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!--<button
                            class="btn btn-success"
                            :class="{
                                disabled:selectedStudents.length == 0 || extraTimeForm.model.multiple == null,
                            }"
                            :disabled="(selectedStudents.length == 0 || extraTimeForm.model.multiple == null) ? true:undefined"
                            @click.prevent="openModal()"
                        > Update
                        </button>-->
                    </div>
                </div>

                <!-- <div class="floatRight autoFlex">
                    <button 
                        class="btn btn-danger " 
                        @click="reset"
                    >
                        <i 
                            class="fa fa-refresh marginRight8" 
                            aria-hidden="true"
                        />
                        Undo 
                    </button>
                </div> -->

                <div class="clear"></div>
            </div>
        </div>

        <div class="table table-responsive overflowYHidden">
            <table class="table">
                <thead>
                    <tr>
                        <th class="minWidth132px">
                            <div class="checkboxField justifyCenter">
                                <input id="selectAllAccomodationsStudent" v-model="selectedAll" type="checkbox" @change="selectAllStudents" />
                                <label for="selectAllAccomodationsStudent" class="paddingTop2">Select All</label>
                            </div>
                        </th>
                        <th class="minWidth132px">Name</th>
                        <th class="minWidth194px">Accommodations</th>
                        <th class="minWidth250px">Email</th>
                        <th class="minWidth250px">Student ID</th>
                        <th>Section</th>
                        <th>Team</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="student in studentDetails">
                        <tr>
                            <td>
                                <div class="checkboxField justifyCenter">
                                    <input v-model="selectedStudents" type="checkbox" :value="student.id" />
                                </div>
                            </td>
                            <td class="break-word">
                                {{ student.displayName }}
                            </td>
                            <td class="color-dark-orange">
                                <b>
                                    <template v-if="student.accommodations.multiple != null">
                                        Has
                                        <template v-if="student.accommodations.multiple == '1.25x'"> 25% </template>
                                        <template v-else-if="student.accommodations.multiple == '1.5x'"> 50% </template>
                                        <template v-else-if="student.accommodations.multiple == '1.75x'"> 75% </template>
                                        <template v-else> 100% </template>
                                        extra time
                                    </template>
                                    <template v-else> None </template>
                                </b>
                            </td>
                            <td class="break-word">
                                {{ student.email }}
                            </td>
                            <td>
                                {{ student.studentID }}
                            </td>
                            <td>
                                {{ student.section }}
                            </td>
                            <td>
                                {{ student.team }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div
            id="students-accommodationModal"
            class="modal students-accommodationModal"
            :class="{
                'primary-modal': extraTimeForm.model.multiple != 'none',
                'danger-modal': extraTimeForm.model.multiple == 'none',
            }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="students-accommodationModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button v-if="extraTimeForm.model.multiple != 'none'" type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="students-accommodationModal-title" class="modal-title">Extra Time Accommodations</h2>
                    </div>
                    <div class="modal-body">
                        <h2 class="justifyLeft">Extra Time Accommodations for Students</h2>
                        <p class="greyText">Confirm the accommodation settings for the students listed below</p>

                        <table class="width100 accommodationsStudentList marginTop30">
                            <caption>
                                Selected Students ({{
                                    filteredStudents.length
                                }})
                            </caption>
                            <thead>
                                <th>Name</th>
                                <th>Accommodations</th>
                            </thead>
                            <tbody>
                                <tr v-for="student in filteredStudents">
                                    <td>
                                        {{ student.displayName }}
                                    </td>
                                    <td class="color-dark-orange">
                                        <b>
                                            <template v-if="extraTimeForm.model.multiple != 'none'">
                                                Has
                                                <template v-if="extraTimeForm.model.multiple == '1.25x'"> 25% </template>
                                                <template v-else-if="extraTimeForm.model.multiple == '1.5x'"> 50% </template>
                                                <template v-else-if="extraTimeForm.model.multiple == '1.75x'"> 75% </template>
                                                <template v-else> 100% </template>
                                                extra time
                                            </template>
                                            <template v-else> None </template>
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal">Go Back</button>
                        <button
                            v-if="extraTimeForm.model.multiple != 'none'"
                            class="btn btn-success"
                            data-dismiss="modal"
                            @click.prevent="processTimeAccommodations()"
                        >
                            Confirm
                        </button>
                        <button
                            v-if="extraTimeForm.model.multiple == 'none'"
                            class="btn btn-danger"
                            data-dismiss="modal"
                            @click.prevent="removeTimeAccommodations()"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
export default {
    data() {
        return {
            component_done_loading: false,
            course: {},
            students: [],
            selectedStudents: [],
            selectedAll: false,
            isMoreOptionsOpened: false,
            extraTimeForm: new KrForm({
                fields: {
                    multiple: '',
                    userPlacementsIds: '',
                },
                props: {},
            }),
            detailStudents: [],
            placementIdToApiA: {},
            studentDetails: [],
        };
    },
    computed: {
        filteredStudents() {
            if (this.selectedStudents != []) {
                return this.studentDetails.filter((student) => {
                    return this.selectedStudents.includes(student.id);
                });
            } else {
                return false;
            }
        },
    },
    watch: {
        'detailStudents.sections': {
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.mergeData(newValue);
                }
            },
            immediate: true,
            deep: true,
        },
        students: {
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.mergeData(this.detailStudents.sections, newValue);
                }
            },
            immediate: true,
            deep: true,
        },
        selectedStudent: {
            handler: function () {
                if (this.students.length == this.selectedStudent.length && this.students.length != 0) {
                    this.selectedAll = true;
                } else {
                    this.selectedAll = false;
                }
            },
        },
    },
    created() {
        var that = this;
        this.fetchCourse().then(function (response) {
            that.course = response.data.data;
            // Events.fire("topbar_update", {
            //     breadcrumb: [
            //         { name: "Home" },
            //         { name: "Courses", link: "courses.index" },
            //         {
            //             name:
            //                 that.course.name +
            //                 (that.course.code ? " (" + that.course.code + ")" : ""),
            //             link: "courses.view",
            //             params:{id:that.course.uuid},
            //             query:{tab:'student'}
            //         },
            //         { name: "Student Accommodations" },
            //     ]
            // })

            document.title = `Student Accommodations | Students | ${that.course.name}`;

            that.fetchStudents();
            that.fetchAccommodations();
        });
    },
    methods: {
        reset() {
            this.extraTimeForm.model.multiple = null;
            this.selectedStudents = [];
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Form has been reset',
            });
        },
        fetchCourse() {
            return axios.get(`courses/${this.$route.params.id}`);
        },
        fetchAccommodations() {
            var that = this;
            return axios.get(`courses/${this.$route.params.id}/accommodations`).then(function (response) {
                that.students = response.data.data;
                that.component_done_loading = true;
            });
        },
        fetchStudents() {
            var that = this;
            return axios.get(`courses/${this.$route.params.id}/students?display=group`).then(function (response) {
                that.detailStudents = response.data;
            });
        },
        mergeData(data) {
            this.studentDetails = [];
            this.students.forEach((studentDetail) => {
                this.detailStudents.unassigned.forEach((student) => {
                    if (student.placementId == studentDetail.id) {
                        this.studentDetails.push({
                            id: studentDetail.id,
                            accommodations: studentDetail.accommodations,
                            displayName: studentDetail.displayName,
                            email: student.email,
                            studentID: student.studentID,
                            team: student.team,
                            section: student.section,
                        });
                    }
                });
                data.forEach((group) => {
                    group.teams.forEach((team) => {
                        team.students.forEach((student) => {
                            if (student.placementId == studentDetail.id) {
                                this.studentDetails.push({
                                    id: studentDetail.id,
                                    accommodations: studentDetail.accommodations,
                                    displayName: studentDetail.displayName,
                                    email: student.email,
                                    studentID: student.studentID,
                                    team: student.team.replace('Team ', ''),
                                    section: student.section,
                                });
                            }
                        });
                    });
                });
            });
        },
        selectAllStudents() {
            this.selectedStudents = [];
            if (this.selectedAll) {
                for (var i = 0; i < this.students.length; i++) {
                    this.selectedStudents.push(this.students[i].id);
                }
            }
        },
        processTimeAccommodations() {
            var that = this;
            if (this.selectedStudents.length > 0) {
                this.extraTimeForm.model.userPlacementsIds = this.selectedStudents;
                this.extraTimeForm
                    .post(`courses/${this.$route.params.id}/accommodations`)
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Successfully updated time accommodations',
                        });
                        that.fetchAccommodations();
                        that.selectedAll = false;
                        that.selectedStudents = [];
                        that.mergeData(that.detailStudents.sections);
                    })
                    .catch(function (errors) {
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong please try again later',
                        });
                    });
            }
        },
        removeTimeAccommodations() {
            var that = this;
            if (this.selectedStudents.length > 0) {
                axios
                    .delete(`courses/${this.$route.params.id}/accommodations`, {
                        data: {
                            userPlacementsIds: this.selectedStudents,
                            multiple: null,
                        },
                    })
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Successfully removed time accommodations',
                        });
                        that.fetchAccommodations();
                        that.selectedAll = false;
                        that.selectedStudents = [];
                        that.mergeData(that.detailStudents.sections);
                    })
                    .catch(function (errors) {
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong please try again later',
                        });
                    });
            }
        },
        openModal() {
            $('.students-accommodationModal').modal('show');
        },
    },
};
</script>
<style scoped>
.title {
    background: white;
    padding: 20px;
}
</style>
