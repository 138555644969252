<template>
    <div v-if="component_done_loading">
        <div class="password setPassword">
            <kr-field
                display="Enter Current Password"
                :form="form"
                name="old_password"
                aria-label="enter current password"
                :tooltip-message="
                    auth.credential().isUsingTemporaryPassword && !auth.credential().password_modal_shown
                        ? 'You recently requested a temporary password; please use that.'
                        : ''
                "
                :options="{
                    type: 'password',
                    placeholder: '',
                    hideHelpBlockIfNoErrors: true,
                    required: true,
                    newPasswordField: true,
                }"
            >
            </kr-field>
        </div>
        <div class="marginTop30 password setPassword">
            <kr-field
                display="Enter New Password"
                :form="form"
                name="password"
                aria-label="enter new password"
                :options="{
                    type: 'password',
                    placeholder: 'E.g. P@ssw0rd4321',
                    hideHelpBlockIfNoErrors: true,
                    required: true,
                    newPasswordField: true,
                }"
                @keyup="passwordStrength"
            >
            </kr-field>
        </div>
        <!-- <div style="margin-top:10px;background-color:white;margin-top:10px;margin-bottom:30px">
        <div style="display:flex;justify-content:space-between;">
            <span style="font-weight:bold;">Password Acceptability ("Pass" is required):</span>
            <span><span :class="strengthLabel+'Txt'" style="font-weight:bold;">{{strengthLabel}}</span> 
                <i class="fa fa-info-circle" style="margin-left:5px;" v-on:mouseover="openPopover = true;"v-on:mouseleave="openPopover = false;"></i> 
            </span>
        </div>
        <div style="height:0;text-align:right;">
            <v-popover :open="openPopover" popoverBaseClass="tooltip white-bg popover">
                <div class="checksStrength" style="background:#fff;border-radius:3px;padding:5px;" slot="popover">
                    <div>Password must include:</div>
                    <div>
                        <span :class="{'hide':!lengthFlag}"style="color:#47a647"><i class="fa fa-check-circle" style="margin-right:5px;"></i>minimum {{minChars}} characters</span>
                        <span :class="{'hide':lengthFlag}" style="color:#b40020;"><i class="fa fa-times-circle " style="margin-right:5px;"></i>minimum {{minChars}} characters</span>
                    </div>
                    <div v-if="requireUppercase">
                        <span :class="{'hide':!capitalFlag}" style="color:#47a647;"><i class="fa fa-check-circle" style="margin-right:5px;"></i>At least <b>one capital letter</b></span>
                        <span :class="{'hide':capitalFlag}" style="color:#b40020;"><i class="fa fa-times-circle " style="margin-right:5px;"></i>At least <b>one capital letter</b></span>
                    </div>
                    <div v-if="requireLowercase">
                        <span :class="{'hide':!lowerFlag}" style="color:#47a647;"><i class="fa fa-check-circle" style="margin-right:5px;"></i>At least <b>one lowercase letter</b></span>
                        <span :class="{'hide':lowerFlag}" style="color:#b40020;"><i class="fa fa-times-circle " style="margin-right:5px;"></i>At least <b>one lowercase letter</b></span>
                    </div>
                    <div v-if="requireNumber">
                        <span :class="{'hide':!numberFlag}" style="color:#47a647;"><i class="fa fa-check-circle" style="margin-right:5px;"></i>At least <b>one number</b></span>
                        <span :class="{'hide':numberFlag}" style="color:#b40020;"><i class="fa fa-times-circle" style="margin-right:5px;"></i>At least <b>one number</b></span>
                    </div>
                    <div v-if="requireSpace">
                        <span :class="{'hide':!spaceFlag}" style="color:#47a647;"><i class="fa fa-check-circle" style="margin-right:5px;"></i>No spaces</span>
                        <span :class="{'hide':spaceFlag}" style="color:#b40020;"><i class="fa fa-times-circle" style="margin-right:5px;"></i>No spaces</span>
                    </div>
                    <div v-if="requireSymbol">
                        <span :class="{'hide':!symbolFlag}" style="color:#47a647;"><i class="fa fa-check-circle" style="margin-right:5px;"></i>At least <b>one symbol</b></span>
                        <span :class="{'hide':symbolFlag}" style="color:#b40020;"><i class="fa fa-times-circle " style="margin-right:5px;"></i>At least <b>one symbol</b></span>
                    </div>
                </div>
            </v-popover>
        </div>
        <div class="strengthBar">
            <span :class="strengthLabel+'Bar'"></span>
        </div>
    </div> -->

        <div class="marginBottom20 marginTop20">
            <div v-if="!isStrengthSufficient" class="alert alert-warning-bg" role="alert">
                <i class="fa fa-exclamation-triangle marginRight8" aria-hidden="true"></i>
                <p>
                    Password should be made up of at least
                    {{ minChars }} characters and satisfy all 5 categories:
                </p>
            </div>
            <div v-else class="alert alert-primary-bg flexOnly" role="alert">
                <i class="fa fa-check-circle dateCircle marginRight8" aria-hidden="true"></i>
                <p>
                    Password should be made up of at least
                    {{ minChars }} characters and satisfy all 5 categories:
                </p>
            </div>
        </div>
        <div>
            <div>
                <span class="fontBold">Must satisfy all 6</span>
            </div>
            <div class="flexOnly disabledColor marginTop10 hidden-xs hidden-sm">
                <div class="col-xs-6">
                    <div v-if="requireLength" class="flexOnly marginTop10" :class="{ correct: lengthFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !lengthFlag,
                                'fa-solid fa-circle-check': lengthFlag,
                            }"
                            :aria-label="lengthFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: lengthFlag }"> At least {{ minChars }} characters long </label>
                    </div>

                    <div v-if="requireUppercase" class="flexOnly marginTop10" :class="{ correct: capitalFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !capitalFlag,
                                'fa-solid fa-circle-check': capitalFlag,
                            }"
                            :aria-label="capitalFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: capitalFlag }"> At least one capital letter </label>
                    </div>

                    <div v-if="requireLowercase" class="flexOnly marginTop10" :class="{ correct: lowerFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !lowerFlag,
                                'fa-solid fa-circle-check': lowerFlag,
                            }"
                            :aria-label="lowerFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: lowerFlag }"> At least one lowercase letter </label>
                    </div>

                    <div v-if="requireNotUsername" class="flexOnly marginTop10" :class="{ correct: usernameFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !usernameFlag,
                                'fa-solid fa-circle-check': usernameFlag,
                            }"
                            :aria-label="usernameFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: usernameFlag }"> Should not contain your username/email </label>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div v-if="requireNumber" class="flexOnly marginTop10" :class="{ correct: numberFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !numberFlag,
                                'fa-solid fa-circle-check': numberFlag,
                            }"
                            :aria-label="numberFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: numberFlag }"> At least one number </label>
                    </div>

                    <div v-if="requireSpecialChar" class="flexOnly marginTop10" :class="{ correct: symbolFlag }">
                        <i
                            class="marginRight8"
                            :class="{
                                'fa-regular fa-circle-check': !symbolFlag,
                                'fa-solid fa-circle-check': symbolFlag,
                            }"
                            :aria-label="symbolFlag ? 'checked' : 'not check'"
                        ></i>

                        <label class="control-label fw-normal mb-10" :class="{ correct: symbolFlag }"> At least one special character </label>
                    </div>
                    <!--<div class="marginTop10 flexOnly" :class="{'correct':spaceFlag}" v-if="requireSpace"><i class="fa fa-check-circle marginRight5 marginTop2"></i>Do not use spaces and symbols</div>-->
                </div>
            </div>
            <div class="disabledColor marginTop10 hidden-md hidden-lg">
                <div v-if="requireUppercase" class="flexOnly marginTop10" :class="{ correct: capitalFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !capitalFlag,
                            'fa-solid fa-circle-check': capitalFlag,
                        }"
                        :aria-label="capitalFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: capitalFlag }"> At least one capital letter </label>
                </div>

                <div v-if="requireLowercase" class="flexOnly marginTop10" :class="{ correct: lowerFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !lowerFlag,
                            'fa-solid fa-circle-check': lowerFlag,
                        }"
                        :aria-label="lowerFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: lowerFlag }"> At least one lowercase letter </label>
                </div>

                <div v-if="requireLength" class="flexOnly marginTop10" :class="{ correct: lengthFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !lengthFlag,
                            'fa-solid fa-circle-check': lengthFlag,
                        }"
                        :aria-label="lengthFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: lengthFlag }"> At least {{ minChars }} characters long </label>
                </div>

                <div v-if="requireNumber" class="flexOnly marginTop10" :class="{ correct: numberFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !numberFlag,
                            'fa-solid fa-circle-check': numberFlag,
                        }"
                        :aria-label="numberFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: numberFlag }"> At least one number </label>
                </div>

                <div v-if="requireSpecialChar" class="flexOnly marginTop10" :class="{ correct: symbolFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !symbolFlag,
                            'fa-solid fa-circle-check': symbolFlag,
                        }"
                        :aria-label="symbolFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: symbolFlag }"> At least one special character </label>
                </div>

                <div v-if="requireNotUsername" class="flexOnly marginTop10" :class="{ correct: usernameFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !usernameFlag,
                            'fa-solid fa-circle-check': usernameFlag,
                        }"
                        :aria-label="usernameFlag ? 'checked' : 'not check'"
                    ></i>

                    <label class="control-label fw-normal mb-10" :class="{ correct: usernameFlag }"> Should not contain your username/email </label>
                </div>
                <!--<div class="marginTop10" :class="{'correct':capitalFlag}" v-if="requireUppercase">
                    <i class="fa fa-check-circle"></i>&nbsp;At least one capital letter
                </div>
                <div class="marginTop10" :class="{'correct':spaceFlag}" v-if="requireSpace">
                    <i class="fa fa-check-circle"></i>&nbsp;Do not use spaces and symbols
                </div>-->
            </div>
        </div>
        <span v-if="!isStrengthSufficient" class="password">
            <div class="form-group marginTop30 marginBottom30">
                <label class="control-label">
                    Retype New Password
                    <span class="important" aria-label="required">*</span>
                </label>
                <input type="password" class="form-control" placeholder="Re-enter password here" disabled />
            </div>
        </span>
        <span v-else class="password">
            <kr-field
                class="marginTop30"
                display="Retype New Password"
                :form="form"
                name="password_confirmation"
                aria-label="retype new password"
                :options="{
                    type: 'password',
                    placeholder: 'Re-enter password here',
                    required: true,
                    newPasswordField: true,
                }"
                @keyup="isConfirmationMatch"
            ></kr-field>
        </span>
    </div>
</template>

<script>
import KrAuth from '../../../components/auth/auth';
export default {
    props: ['form', 'options'],
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            usernameFlag: 1,
            capitalFlag: 0,
            lengthFlag: 0,
            numberFlag: 0,
            spaceFlag: 0,
            lowerFlag: 0,
            symbolFlag: 0,
            strengthLabel: '',
            openPopover: false,
            requireUppercase: true,
            requireLowercase: true,
            requireSpecialChar: true,
            requireNumber: true,
            requireSpace: false,
            requireLength: true,
            requireNotUsername: auth.isLoggedIn(),
            minChars: 12,
            maxChars: 30,
            isStrengthSufficient: false,
            confirmation_match_error_function: null,
        };
    },
    created() {
        var that = this;
        this.confirmation_match_error_function = _.debounce(function (type = 'append') {
            if (type == 'append') {
                this.form.errors.append('password_confirmation', ["Passwords don't match"]);
            } else {
                this.form.errors.clear('password_confirmation');
            }
        }, 200);
        this.password_strength_error_function = _.debounce(function (type = 'append') {
            if (type == 'append') {
                //this.form.errors.append('password',['Password is too weak']);
            } else {
                this.form.errors.clear('password');
            }
        }, 200);
        //check if query in api url.
        //let url = _.get(this.$route,'query.api_url','');
        //url +='/security-settings';
        //axios.get(url).then(function(response){
        //let temp = response.data;
        // that.requireUppercase = temp.requireUppercase;
        // that.requireLowercase = temp.requireLowerCase;
        // that.requireSpecialChar = temp.requireSpecialChar;
        // that.requireNumber = temp.requireNumber;
        // that.requireSymbol = temp.requireSymbol;
        // that.minChars = temp.minChars;
        // that.maxChars = temp.maxChars;
        //that.component_done_loading = true;
        //});
        this.component_done_loading = true;
    },
    methods: {
        isConfirmationMatch() {
            var str = document.getElementById('password_confirmation').value;
            if (str != this.form.model.password) {
                this.confirmation_match_error_function();
                return false;
            }
            this.confirmation_match_error_function('clear');
            return true;
        },
        passwordStrength() {
            this.capitalFlag = 0;
            this.lengthFlag = 0;
            this.numberFlag = 0;
            this.spaceFlag = 0;
            this.lowerFlag = 0;
            this.symbolFlag = 0;
            this.usernameFlag = 0;
            var labels = ['', 'Weak', 'Fair', 'Almost', 'Pass'];
            var splChars = '*|,":<>[]{}`\';()@&$#%-!^_?/';
            var str = document.getElementById('password').value;
            if (str.length >= this.minChars) {
                this.lengthFlag = 1;
            }
            for (var i = 0; i < str.length; i++) {
                let c = str.charAt(i);
                if (c >= '0' && c <= '9') {
                    this.numberFlag = 1;
                } else if (splChars.indexOf(c) != -1) {
                    this.symbolFlag = 1;
                } else if (c === c.toUpperCase()) {
                    this.capitalFlag = 1;
                } else if (c === c.toLowerCase()) {
                    this.lowerFlag = 1;
                }
            }
            if (str.length > 0) {
                if (str.trim().length == str.length) {
                    this.spaceFlag = 1;
                }
                if (!this.auth.user().email || !str.toLowerCase().includes(this.auth.user().email.toLowerCase())) {
                    this.usernameFlag = 1;
                }
            }
            let totalFlag = 0;
            let totalRequirements = 0;

            if (this.requireUppercase) {
                totalFlag += this.capitalFlag;
                totalRequirements++;
            }
            if (this.requireNumber) {
                totalFlag += this.numberFlag;
                totalRequirements++;
            }
            if (this.requireLength) {
                totalFlag += this.lengthFlag;
                totalRequirements++;
            }
            if (this.requireSpace) {
                totalFlag += this.spaceFlag;
                totalRequirements++;
            }
            if (this.requireLowercase) {
                totalFlag += this.lowerFlag;
                totalRequirements++;
            }
            if (this.requireSpecialChar) {
                totalFlag += this.symbolFlag;
                totalRequirements++;
            }
            if (this.requireNotUsername) {
                totalFlag += this.usernameFlag;
                totalRequirements++;
            }
            if (totalFlag / totalRequirements == 0) {
                this.strengthLabel = labels[0];
            } else if (totalFlag / totalRequirements < 0.26) {
                this.strengthLabel = labels[1];
            } else if (totalFlag / totalRequirements < 0.51) {
                this.strengthLabel = labels[2];
            } else if (totalFlag / totalRequirements < 0.76) {
                this.strengthLabel = labels[3];
            } else if (totalFlag / totalRequirements == 1) {
                this.strengthLabel = labels[4];
            } //==totalRequirements
            if (totalFlag == totalRequirements) {
                //console.log(totalFlag+" / "+totalRequirements)
                this.password_strength_error_function('clear');
                this.isStrengthSufficient = true;
                return true;
            } else {
                if (str.length > 0) {
                    this.password_strength_error_function();
                } else {
                    this.password_strength_error_function('clear');
                }
            }
            this.isStrengthSufficient = false;
            return false;
        },
    },
};
</script>
<style scoped>
.correct {
    color: #3e7c5b;
}
</style>
