<template>
    <footer class="no-print">
        <div class="hidden-max-436">
            Copyright &copy CognaLearn Pte Ltd 2015 ~
            <span id="year">{{ year }}</span> (build: {{ BUILD_DATE }}) &nbsp;&nbsp;|&nbsp;&nbsp;<a
                href="https://www.intedashboard.com/terms-of-use/"
                target="_blank"
                class="pointer"
                >Terms of Use</a
            >&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.intedashboard.com/privacy-policy/" target="_blank" class="pointer">Privacy Policy</a>
        </div>

        <div class="hidden-min-436">
            <div>
                Copyright &copy CognaLearn Pte Ltd 2015 ~
                <span id="year">{{ year }}</span>
                (build: {{ BUILD_DATE }})
            </div>
            <div>
                <a href="https://www.intedashboard.com/terms-of-use/" target="_blank" class="pointer">Terms of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a
                    href="https://www.intedashboard.com/privacy-policy/"
                    target="_blank"
                    class="pointer"
                    >Privacy Policy</a
                >
            </div>
        </div>

        <div class="clearfix"></div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            BUILD_DATE: process.env.VUE_APP_BUILD_DATE,
            year: moment().format('YYYY'),
        };
    },
    watch: {
        $route: {
            handler: function () {
                if (_.get(this.$route.params, 'otp', false)) {
                    $('footer').hide();
                } else {
                    $('footer').show();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (_.get(this.$route.params, 'otp', false)) {
            $('footer').hide();
        }
    },
};
</script>
<style scoped>
body.test footer {
    margin-left: 0px;
}
</style>
