<template>
    <div v-if="component_done_loading" id="lti-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">ADD PLATFORM</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-create-btn"
                                class="btn btn-primary mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true"></i>Save
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-create-btn"
                                v-tooltip="{ content: 'Save' }"
                                class="btn btn-primary mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <lti-form :form="form" mode="create"></lti-form>
                        <div class="col-xs-6 buttonGroup marginTop30">
                            <button
                                id="bottom-create-btn"
                                class="btn btn-primary mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Save"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <router-link v-slot="{ navigate }" :to="{ name: 'settings.edit' }" custom>
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    lms_type: 'default!:Canvas',
                    domain: '',
                    record_name: '',
                    //issuer_id: "",
                    client_id: '',
                    //deployment_id: "",
                    //authorization_url: "",
                    //authentication_url: "",
                    //json_webkey_url: "",
                    signature_method: 'default!:RS256',
                },
                props: {},
                urls: {
                    post: 'platforms',
                },
            }),
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: 'Settings',
            breadcrumb: [{ name: 'Home' }, { name: 'Global Settings', link: 'settings.edit' }, { name: 'Add Platform' }],
        });
        this.component_done_loading = true;
    },
    components: {
        'lti-form': require(`./form-partials/partial.lti.vue`).default,
    },
    methods: {
        openEmailSampleModal() {
            $('#emailSampleModal').modal('show');
        },
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({ name: 'settings.edit' });
                }
            });
        },
    },
    computed: {
        canSend() {
            if (
                !this.form.errors.any() &&
                this.form.model.record_name &&
                this.form.model.lms_type &&
                //this.form.model.issuer_id
                this.form.model.client_id
                //this.form.model.deployment_id &&
                //this.form.model.authorization_url &&
                //this.form.model.authentication_url &&
                //this.form.model.json_webkey_url &&
                //this.form.model.signature_method
            ) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped></style>
