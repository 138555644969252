import _ from 'lodash';
export default function useQuestion() {
    function isQuestionAnswered(question, receiverIds = []) {
        if (['openendedV2', 'ratingV2'].includes(question.type)) {
            const keys = Object.keys(question.questionStems);
            for (var i = 0; i < keys.length; i++) {
                let stem = question.questionStems[keys[i]];
                if (!isStemAnswered(stem, receiverIds) && stem.isRequired) {
                    return false;
                }
            }
            return true;
        } else if (question.type == 'pointDistribution') {
            if (!question.isRequired) {
                return true;
            } else if (Array.isArray(question.pointDistributions) && question.pointDistributions.length > 0) {
                return true;
            } else return false;
        }
        return false;
    }

    function isStemAnswered(stem, receiverIds = []) {
        if (Array.isArray(stem.studentAnswer) && stem.studentAnswer.length > 0) {
            if (receiverIds.length == 0) {
                return true;
            } else {
                for (var i = 0; i < receiverIds.length; i++) {
                    let receiverId = receiverIds[i];
                    let a = _.findLast(stem.studentAnswer, function (o) {
                        return o.receiverId == receiverId;
                    });
                    if (!a) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    function accumulateCriteria(question, receiverIds = []) {
        var count = 0;
        if (['openendedV2', 'ratingV2'].includes(question.type)) {
            const keys = Object.keys(question.questionStems);
            for (var i = 0; i < keys.length; i++) {
                let stem = question.questionStems[keys[i]];

                if (receiverIds.length > 0) {
                    if (!isStemAnswered(stem, receiverIds)) {
                        count++;
                    }
                } else {
                    if (!isStemAnswered(stem)) {
                        count++;
                    }
                }
            }
        } else if (question.type == 'pointDistribution') {
            if (Array.isArray(question.pointDistributions) && question.pointDistributions.length == 0) {
                count++;
            }
        }

        if (count > 0) {
            return count;
        }
    }

    function accumulateStudentsCriteria(questions, questionBreakIdx, receiverId = []) {
        var count = {
            totalQuestions: 0,
            notAnswered: 0,
        };

        for (const [key, value] of Object.entries(questions)) {
            let qGroup = value[0];
            if (qGroup.type != 'pointDistribution') {
                if (parseInt(key) == parseInt(questionBreakIdx) + 1 || qGroup.settings.answerQuestionsForEachTeammate) {
                    const QSkeys = Object.keys(qGroup.questionStems);
                    for (var i = 0; i < QSkeys.length; i++) {
                        count.totalQuestions++;

                        let stem = qGroup.questionStems[QSkeys[i]];
                        if (!isStemAnswered(stem, receiverId)) {
                            count.notAnswered++;
                        }
                    }
                }
            }
        }

        return count;
    }

    function progressionPercentage(questions, questionBreakIdx, teammates) {
        var count = {
            totalQuestion: 0,
            questionDone: 0,
            numberOfStandaloneQuestion: 0,
            numberOfQuestionForEachTeammate: 0,
        };

        if (Object.keys(questions).length == 0) {
            return 0;
        }

        for (const [key, value] of Object.entries(questions)) {
            let qGroup = value[0];
            if (qGroup.type != 'pointDistribution') {
                if (parseInt(key) <= parseInt(questionBreakIdx)) {
                    const QSkeys = Object.keys(qGroup.questionStems);
                    for (var i = 0; i < QSkeys.length; i++) {
                        count.numberOfStandaloneQuestion++;

                        let stem = qGroup.questionStems[QSkeys[i]];
                        if (isStemAnswered(stem)) {
                            count.questionDone++;
                        }
                    }
                } else if (parseInt(key) == parseInt(questionBreakIdx) + 1 || qGroup.settings.answerQuestionsForEachTeammate) {
                    const QSkeys = Object.keys(qGroup.questionStems);
                    for (var i = 0; i < QSkeys.length; i++) {
                        count.numberOfQuestionForEachTeammate++;

                        let stem = qGroup.questionStems[QSkeys[i]];

                        teammates.forEach((value) => {
                            if (isStemAnswered(stem, [value.id])) {
                                count.questionDone++;
                            }
                        });
                    }
                }
            } else {
                count.numberOfStandaloneQuestion++;
                if (Array.isArray(qGroup.pointDistributions) && qGroup.pointDistributions.length > 0) {
                    count.questionDone++;
                }
            }
        }

        count.totalQuestion = count.numberOfQuestionForEachTeammate * teammates.length + count.numberOfStandaloneQuestion;
        return Math.floor((count.questionDone / count.totalQuestion) * 100);
    }

    return { isQuestionAnswered, isStemAnswered, accumulateCriteria, accumulateStudentsCriteria, progressionPercentage };
}
