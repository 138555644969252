<template>
    <div class="activitySetup">
        <div v-if="pageType == 'form'" class="marginBottom30">
            <button
                class="btn btn-success mainBtn"
                :disabled="!canSendStepOne ? true : undefined"
                :aria-label="form.model.type != 'prework' ? 'Go To Questions' : 'Go To Prework Materials'"
                @click.prevent="submitActivity()"
            >
                <template v-if="form.model.type != 'prework'"> Go to Questions </template>
                <template v-else> Go to Prework Materials </template>
                <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default mainBtn" :disabled="!canSendStepOne ? true : undefined" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
            </button>
        </div>

        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">Basic Settings</div>
                <div v-if="pageType == 'confirmation' && mode == 'edit'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivity()">Update</button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="mode = 'view'">Cancel</button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitActivity()">
                            <i class="fa fa-floppy-o" aria-hidden="true" />
                        </button>
                        <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="mode = 'view'">
                            <i class="fa fa-caret-left" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="pageType == 'confirmation' && mode == 'view'" class="col-xs-6 flexRight">
                    <div class="hidden-xs hidden-sm">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                mode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            class="btn btn-default mainBtn"
                            aria-label="Edit"
                            @click.prevent="
                                mode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </template>

            <template #content>
                <div v-if="pageType == 'form' || (pageType == 'confirmation' && mode == 'edit')" class="create flex">
                    <div class="col-xs-12" :class="{ 'col-md-6': pageType == 'form' }">
                        <!-- <div class="form-group">
              <label class="control-label">COURSE</label>
              <p class="form-control-static">
                  {{course.name?course.name:''}}
                  <span class="codeContainer">
                      {{course.code?course.code:''}}
                  </span>
              </p>
            </div> -->

                        <div class="marginBottom30">
                            <kr-select
                                v-if="pageType == 'form'"
                                display="Module <span class='important'>*</span> - select a module to add activity to:"
                                :form="form"
                                name="moduleUuid"
                                aria-label="module - select a module to add activity to"
                                :items="moduleList"
                                @changed="changedModule"
                            >
                                <template #option="props">
                                    <template v-for="(module, idx) in props.model" :key="'changedModule_' + idx">
                                        <option :value="module.uuid">
                                            {{ module.name }}
                                            <template v-if="module.isArchived"> (Archived) </template>
                                        </option>
                                    </template>
                                </template>
                            </kr-select>
                            <div v-else class="form-group">
                                <label class="control-label"> Module <span class="important">*</span> </label>
                                <p class="form-control-static">
                                    {{ module.name }}
                                </p>
                            </div>
                        </div>

                        <div class="marginBottom20">
                            <kr-radio-multiple
                                v-if="pageType == 'form'"
                                class="activityType"
                                display="Activity Type  <span class='important'>*</span>"
                                :form="form"
                                name="type"
                                aria-label="activity type"
                                :items="activityTypes"
                                item_value_idx="value"
                                item_desc_idx="description"
                                @changed="!activityObj ? changedActivityType() : openActivityTypeConfirmationModal()"
                            />

                            <div v-else class="form-group">
                                <label class="control-label">
                                    Activity Type
                                    <span class="important">*</span>
                                </label>
                                <template v-if="form.model.type == 'michaelsenEvaluation'">
                                    <p class="form-control-static">Peer Evaluation: Michaelsen’s Method</p>
                                </template>
                                <template v-else>
                                    <p class="form-control-static">
                                        {{ form.model.type }}
                                    </p>
                                </template>
                            </div>
                            <div
                                v-if="auth.user().account.version == 'lite'"
                                class="alert alert-info alert-dismissible"
                                role="alert"
                                style="margin-bottom: 10px"
                            >
                                Prework, Applications Case and 360 Degree Evaluation activities are only available in our full version. Click here to upgrade.
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                            </div>
                        </div>

                        <template v-if="['evaluation'].includes(form.model.type) && pageType == 'form'">
                            <div class="marginBottom30">
                                <label class="control-label">
                                    360 Degree Evaluation
                                    <span class="important">*</span>
                                </label>
                                <div class="marginBottom10 col-xs-12">
                                    <kr-toggle
                                        :form="form"
                                        class="flexSpaceBetween"
                                        name="evaluationSettings.evaluateTeammates"
                                        display="Students must evaluate their teammates"
                                    />
                                </div>
                                <div class="marginBottom10 col-xs-12">
                                    <kr-toggle
                                        :form="form"
                                        class="flexSpaceBetween"
                                        name="evaluationSettings.evaluateThemselves"
                                        display="Students must evaluate themselves"
                                    />
                                </div>
                                <div class="marginBottom10 col-xs-12">
                                    <kr-toggle
                                        :form="form"
                                        class="flexSpaceBetween"
                                        name="evaluationSettings.evaluateTeam"
                                        display="Students must evaluate their team"
                                    />
                                </div>
                                <div class="marginBottom10 col-xs-12">
                                    <kr-toggle
                                        :form="form"
                                        class="flexSpaceBetween"
                                        name="evaluationSettings.evaluateInstructor"
                                        display="Students must evaluate their instructor(s)"
                                    />
                                </div>
                            </div>
                            <div v-if="form.model.evaluationSettings.evaluateInstructor && component_done_loading" class="marginBottom30">
                                <label class="control-label">Instructors to Evaluate</label>
                                <template v-for="(teacher, teacher_idx) in course.teachers" :key="'instructorEvaluate_' + teacher_idx">
                                    <div class="loopCheckbox lineHeight08">
                                        <div class="checkboxField flex align-items greyText">
                                            <input
                                                :id="teacher.uuid"
                                                v-model="form.model.evaluationSettings.instructorsToEvaluate"
                                                type="checkbox"
                                                :value="teacher.uuid"
                                            />&nbsp;
                                            <label :for="teacher.uuid">
                                                <span>
                                                    {{ teacher.displayName }}
                                                    <template v-if="teacher.accountType == 'Owner'"> (Owner) </template>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <div v-if="pageType == 'confirmation' && form.model.type == 'evaluation'" class="form-group">
                            <label class="control-label"> Students must evaluate their teammates </label>
                            <p class="form-control-static">
                                {{ form.model.evaluationSettings.evaluateTeammates }}
                            </p>
                        </div>

                        <div v-if="pageType == 'confirmation' && form.model.type == 'evaluation'" class="form-group">
                            <label class="control-label"> Students must evaluate themselves </label>
                            <p class="form-control-static">
                                {{ form.model.evaluationSettings.evaluateThemselves }}
                            </p>
                        </div>

                        <div v-if="pageType == 'confirmation' && form.model.type == 'evaluation'" class="form-group">
                            <label class="control-label"> Students must evaluate their team </label>
                            <p class="form-control-static">
                                {{ form.model.evaluationSettings.evaluateTeam }}
                            </p>
                        </div>

                        <div v-if="pageType == 'confirmation' && form.model.type == 'evaluation'" class="form-group">
                            <label class="control-label"> Students must evaluate their instructor(s) </label>
                            <p class="form-control-static">
                                {{ form.model.evaluationSettings.evaluateInstructor }}
                            </p>
                        </div>

                        <div
                            v-if="
                                pageType == 'confirmation' &&
                                form.model.type == 'evaluation' &&
                                form.model.evaluationSettings.evaluateInstructor &&
                                component_done_loading
                            "
                            class="marginTop10 marginBottom30"
                        >
                            <label class="control-label">Instructors to Evaluate</label>
                            <template v-for="(teacher, teacher_idx) in course.teachers" :key="'teacher_' + teacher_idx">
                                <div class="loopCheckbox lineHeight08">
                                    <div class="checkboxField flex align-items greyText">
                                        <input
                                            :id="teacher.uuid"
                                            v-model="form.model.evaluationSettings.instructorsToEvaluate"
                                            type="checkbox"
                                            :value="teacher.uuid"
                                        />&nbsp;
                                        <label :for="teacher.uuid">
                                            <span>
                                                {{ teacher.displayName }}
                                                <template v-if="teacher.accountType == 'Owner'"> (Owner)</template>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <template v-if="form.model.type == 'prework' && pageType == 'form'">
                            <div v-if="pageType == 'form'" class="optionDiv marginBottom30">
                                <kr-radio-multiple
                                    name="others.preworkType"
                                    display="PREWORK TYPE <span class='important'>*</span>"
                                    aria-label="prework type"
                                    :form="form"
                                    :items="[
                                        {
                                            value: 'simple',
                                            description: 'Simple',
                                        },
                                        //{value:'adaptive',description:'Adaptive'},
                                    ]"
                                    item_value_idx="value"
                                    item_desc_idx="description"
                                />
                            </div>
                        </template>

                        <div v-if="pageType == 'confirmation' && form.model.type == 'prework'" class="form-group">
                            <label class="control-label"> Prework Type <span class="important">*</span> </label>
                            <p class="form-control-static">
                                {{ form.model.others.preworkType }}
                            </p>
                        </div>

                        <!-- <template
              v-if="form.model.type == 'application' && pageType == 'form'"
            >
              <div class="optionDiv" v-if="pageType == 'form'">
                <kr-radio-multiple
                  name="others.applicationType"
                  display="APPLICATION TYPE <span class='important'>*</span>"
                  :form="form"
                  :items="[
                    { value: 'individual', description: 'Individual' },
                    { value: 'team', description: 'Team' },
                  ]"
                  item_value_idx="value"
                  item_desc_idx="description"
                >
                </kr-radio-multiple>
              </div>
              <div class="marginTop10 marginBottom30">
                <kr-toggle
                  :form="form"
                  name="others.isApplicationGraded"
                  display="WOULD YOU LIKE TO GRADE THIS ACTIVITY? <span class='important'>*</span>"
                ></kr-toggle>
              </div>
            </template> -->

                        <!-- <div
              class="form-group marginBottom30"
              v-if="
                pageType == 'confirmation' && form.model.type == 'application'
              "
            >
              <label class="control-label">APPLICATION TYPE</label>
              <p class="form-control-static">
                {{ form.model.others.applicationType }}
              </p>
            </div> -->

                        <!-- <div
              class="form-group marginBottom30"
              v-if="
                pageType == 'confirmation' && form.model.type == 'application'
              "
            >
              <label class="control-label">
                WOULD YOU LIKE TO GRADE THIS ACTIVITY?
              </label>
              <p class="form-control-static">
                {{ form.model.others.isApplicationGraded ? "YES" : "NO" }}
              </p>
            </div> -->

                        <!-- <template v-if="form.model.type=='evaluation'&&pageType=='form'">
              <div class="optionDiv" v-if="pageType=='form'">
                <kr-radio-multiple name="others.evaluationType" display="EVALUATION TYPE <span class='important'>*</span>" :form="form" 
                :items="[
                    {value:'michaelsen',description:'Michaelsen'},
                    {value:'fink',description:'Fink'},
                    {value:'michaelsennfink',description:'Michaelsen and Fink'},
                    {value:'koles',description:'Koles'},
                    {value:'grid',description:'Grid-based'},
                ]"
                item_value_idx="value"
                item_desc_idx="description">
                </kr-radio-multiple>
              </div>
            </template> -->

                        <!-- <div class="form-group" v-if="pageType=='confirmation'&&form.model.type=='evaluation'">
              <label class="control-label">EVALUATION TYPE</label>
              <p class="form-control-static">{{form.model.others.evaluationType}}</p>
            </div> -->

                        <kr-field
                            display="Name"
                            :form="form"
                            name="name"
                            aria-label="name"
                            :options="{
                                placeholder: 'e.g., My activity',
                                required: true,
                            }"
                        />

                        <!--<div>
                <kr-date-range display="Period" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range>
            </div>-->

                        <kr-field
                            display="Description"
                            :form="form"
                            name="description"
                            aria-label="DESCRIPTION"
                            :options="{
                                type: 'text-area',
                                rows: '5',
                                placeholder: 'e.g., This one will not be easy',
                                showError: false,
                            }"
                        />

                        <!-- <div class="objectiveContainer">
              <label class="control-label">LEARNING OBJECTIVES</label>
              <template v-for="(objective,idx) in form.model.objectives">
                <div class="objectiveRow">
                  <span>{{idx+1}}</span>
                  <kr-field :form="form" :name="'objectives.'+idx" :options="{placeholder:'e.g., Explain relativity',hasLabel:false,showError:false}" @keyup.enter="addObjective()"></kr-field>
                  <button class="btn btn-default addBtn" v-if="form.model.objectives.length-1==idx" @click.prevent="addObjective()">
                      <i class="fa fa-plus"></i>
                  </button>
                  <button v-if="form.model.objectives.length!=1" class="btn btn-default deleteBtn" @click.prevent="deleteObjective(idx)">
                      <i class="fa fa-trash"></i>
                  </button>
                </div>
              </template>    
            </div> -->
                    </div>

                    <div v-if="pageType == 'form'" class="col-xs-12 col-md-6 hidden-xs hidden-sm">
                        <div class="rightPanel">
                            <label class="control-label"> Selected Course Info </label>
                            <div class="content">
                                <div>
                                    <label class="control-label"> Name </label>
                                    <p>
                                        {{ course.name }}
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label"> Course Code </label>
                                    <p>
                                        {{ course.code }}
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label"> Start From </label>
                                    <p>
                                        {{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label"> Ends On </label>
                                    <p>
                                        {{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </p>
                                </div>
                                <div v-if="course.description">
                                    <label class="control-label"> Course Overview </label>
                                    <p class="whiteSpacePreLine" v-html="course.description"></p>
                                </div>
                            </div>
                        </div>

                        <div class="rightPanel" style="margin-top: 20px">
                            <label class="control-label"> Selected Module Info </label>
                            <div class="content">
                                <div>
                                    <label class="control-label"> Name </label>
                                    <p>
                                        {{ module.name }}
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label"> Start From </label>
                                    <p>
                                        {{ convertToReadableDate(module.startDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(module.startDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label"> Ends On </label>
                                    <p>
                                        {{ convertToReadableDate(module.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(module.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </p>
                                </div>
                                <div v-if="module.description">
                                    <label class="control-label"> Description </label>
                                    <p class="whiteSpacePreLine" v-html="module.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="col-xs-12">
                        <label class="control-label">Parent Course</label>
                        <p class="form-control-static">
                            {{ course.name }}
                            <template v-if="course.code"> ({{ course.code }}) </template>
                        </p>
                    </div>

                    <div class="col-xs-12">
                        <label class="control-label">Parent Module</label>
                        <p class="form-control-static">
                            {{ module.name }}
                        </p>
                    </div>

                    <div class="col-xs-12">
                        <label class="control-label">Activity Type</label>
                        <p class="form-control-static">
                            <template v-if="activityObj.type == 'prework'"> Prework </template>
                            <template v-if="activityObj.type == 'irat'"> IRAT </template>
                            <template v-if="activityObj.type == 'trat'"> TRAT </template>
                            <template v-if="activityObj.type == 'iratntrat'"> IRAT & TRAT </template>
                            <template v-if="activityObj.type == 'application'"> Application </template>
                            <template v-if="activityObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                            <template v-if="activityObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-if="activityObj.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                        </p>
                    </div>

                    <div v-if="activityObj.type == 'prework'" class="col-xs-12">
                        <label class="control-label">Prework Type</label>
                        <p class="form-control-static">
                            {{ form.model.others.preworkType }}
                        </p>
                    </div>

                    <div v-if="activityObj.type == 'application'" class="col-xs-12">
                        <label class="control-label">Application Type</label>
                        <p class="form-control-static">
                            {{ form.model.others.applicationType }}
                        </p>
                    </div>

                    <div v-if="activityObj.type == 'application'" class="col-xs-12">
                        <label class="control-label"> Would You Like To Grade This Activity? </label>
                        <p class="form-control-static">
                            {{ form.model.others.isApplicationGraded ? 'YES' : 'NO' }}
                        </p>
                    </div>

                    <div class="col-xs-12">
                        <label class="control-label">Activity Name</label>
                        <p class="form-control-static">
                            {{ activityObj.name }}
                        </p>
                    </div>

                    <div class="col-xs-12">
                        <label class="control-label">Descriptions</label>
                        <p class="form-control-static marginBottom0">
                            {{ activityObj.description ? activityObj.description : '-' }}
                        </p>
                    </div>

                    <!-- <div class="col-xs-12">
            <label class="control-label">LEARNING OBJECTIVES</label>
            <template v-for="(objective,idx) in activityObj.objectives">
                <p class="form-control-static">
                    {{idx+1}}. {{objective}}
                </p>
            </template>
          </div> -->

                    <!-- <div class="mainCourseBtn">
            <button class="btn btn-custom mainBtn" data-toggle="collapse" data-target="#mainCourse" aria-expanded="true">
              <span class="fa-stack">
                <i class="far fa-circle fa-stack-2x"></i>
                <i class="fa fa-graduation-cap fa-stack-1x"></i>
              </span>&nbsp;
              {{course.name}} ({{course.code}})
            </button>
          </div> -->

                    <!-- <div class="collapse multi-collapse card-container in" id="mainCourse" aria-expanded="true">
            <div class="mainModulesBtn">
              <button class="btn btn-custom mainBtn" data-toggle="collapse" data-target="#mainModule">
                <span class="fa-stack">
                  <i class="far fa-circle fa-stack-2x"></i>
                  <i class="fa fa-book fa-stack-1x"></i>
                </span>&nbsp;
                {{module.name}}
              </button>
            </div>
          </div> -->

                    <!-- <div class="collapse multi-collapse card-container in" id="mainModule">
            <div class="mainActivityBtn">
              <button class="btn btn-custom mainBtn" data-toggle="collapse" data-target="#mainActivity">
                <span class="fa-stack">
                  <i class="far fa-circle fa-stack-2x"></i>
                  <i class="fas fa-pencil-ruler fa-stack-1x"></i>
                </span>&nbsp;
                {{activityObj.name}}
              </button>
            </div>

            <div class="collapse multi-collapse card-container in" id="mainActivity">
              <div class="col-xs-12 col-md-10 card card-body">
                <div class="col-xs-12">
                  <label class="control-label">DESCRIPTIONS</label>
                  <p class="form-control-static">
                      {{activityObj.description}}
                  </p>
                </div>
                <div class="col-xs-12">
                  <label class="control-label">LEARNING OBJECTIVES</label>
                  <template v-for="(objective,idx) in activityObj.objectives.selected">
                    <div class="flexOnly form-control-static">
                      <b>{{idx+1}}.</b> {{objective}}
                    </div>
                  </template>
                </div>
              </div>  
            </div>
          </div> -->
                </div>
            </template>
        </kr-panel>
        <div
            id="changeActivityTypeModal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="changeActivityTypeModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="changeActivityTypeModal-title" class="modal-title">Change Activity Type</h2>
                    </div>
                    <div v-if="activityObj" class="modal-body textAlignCenter">
                        <img src="/img/warning.png" width="50px" alt="" class="warning-icon" />

                        <h1 class="marginTop40">Change Activity Type From</h1>

                        <h2
                            :aria-label="
                                (activityObj.type == 'iratntrat' ? 'IRAT and TRAT to ' : activityObj.type + ' to ') +
                                (form.model.type == 'iratntrat' ? 'IRAT and TRAT ' : form.model.type)
                            "
                        >
                            <span class="important" aria-hidden="true">
                                <template v-if="activityObj.type == 'prework'"> Prework </template>
                                <template v-if="activityObj.type == 'irat'"> IRAT </template>
                                <template v-if="activityObj.type == 'trat'"> TRAT </template>
                                <template v-if="activityObj.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="activityObj.type == 'application'"> Application </template>
                                <template v-if="activityObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="activityObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                            </span>

                            <i class="fa-solid fa-arrow-right" aria-hidden="true"></i>

                            <span class="important" aria-hidden="true">
                                <template v-if="form.model.type == 'prework'"> Prework </template>
                                <template v-if="form.model.type == 'irat'"> IRAT </template>
                                <template v-if="form.model.type == 'trat'"> TRAT </template>
                                <template v-if="form.model.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="form.model.type == 'application'"> Application </template>
                                <template v-if="form.model.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="form.model.type == 'evaluation'"> 360 Degree Evaluation </template>
                            </span>
                        </h2>

                        <br />

                        <p>Any questions that are not applicable</p>

                        <p>
                            with
                            <b>
                                <template v-if="form.model.type == 'prework'"> Prework </template>
                                <template v-if="form.model.type == 'irat'"> IRAT </template>
                                <template v-if="form.model.type == 'trat'"> TRAT </template>
                                <template v-if="form.model.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="form.model.type == 'application'"> Application </template>
                                <template v-if="form.model.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="form.model.type == 'evaluation'"> 360 Degree Evaluation </template>
                            </b>
                            will be
                            <b class="important">permanently deleted</b>.
                        </p>

                        <br />

                        <p>
                            This action is
                            <b class="important">irreversible</b>.
                        </p>
                        <p>Do you wish to proceed with this irreversible action?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="form.model.type = activityObj.type">No, cancel</button>

                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="changedActivityType()">Yes, Change It</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../../components/auth/auth';
import KrForm from '../../../../../../components/forms/form';
export default {
    props: ['activityObj', 'options'],
    data() {
        var auth = new KrAuth();
        let activityTypes = [
            {
                value: 'prework',
                description: 'Prework',
                _disabled: auth.user().account.version == 'lite',
            },
            { value: 'irat', description: 'IRAT only' },
            { value: 'trat', description: 'TRAT only' },
            { value: 'iratntrat', description: 'IRAT & TRAT' },
            {
                value: 'application',
                description: 'Application',
                _disabled: auth.user().account.version == 'lite',
            },
            {
                value: 'michaelsenEvaluation',
                description: 'Peer Evaluation: Michaelsen’s Method',
                _disabled: auth.user().account.version == 'lite',
            },
            {
                value: 'evaluation',
                description: '360 Degree Evaluation',
                _disabled: auth.user().account.version == 'lite',
            },
        ];
        /*if(process.env.VUE_APP_APP_ENV!='production'){
                activityTypes.push({value:'evaluation',description:'360 Degree Evaluation'});
            }*/
        return {
            auth: auth,
            moduleList: [],
            course: {},
            module: {},
            pageType: _.get(this.options, 'pageType', 'form'),
            mode: 'view',
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    courseUuid: '',
                    moduleUuid: '',
                    name: '',
                    type: 'default!:iratntrat',
                    description: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    descriptionIsHTML: '',
                    others: 'type!:object', //json
                    evaluationSettings: 'type!:object', //json
                },
                props: {
                    successMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            activityTypes: activityTypes,
        };
    },
    computed: {
        canSendStepOne() {
            if (this.form.model.courseUuid && this.form.model.moduleUuid && this.form.model.name) {
                return true;
            }
            return false;
        },
    },
    watch: {
        form: {
            handler: function () {
                /*FORCE*/
                if (!this.form.model.objectives) {
                    this.form.model.objectives = [];
                }
                if (this.form.model.objectives.length == 0) {
                    this.addObjective();
                }
            },
            deep: true,
        },
        options: {
            handler: function () {
                this.pageType = _.get(this.options, 'pageType', 'form');
            },
            deep: true,
        },
    },
    created() {
        //set others
        this.form.model.others = {};
        this.form.model.evaluationSettings = {
            evaluateTeam: false,
            evaluateTeammates: false,
            evaluateInstructor: false,
            evaluateThemselves: false,
            instructorsToEvaluate: [],
        };
        this.init();
    },
    methods: {
        init() {
            var that = this;
            this.form.model.descriptionIsHTML = false;
            let linked_course_id = _.get(this, '$route.params.course_id', false);
            let linked_module_id = _.get(this, '$route.params.module_id', false);
            if (this.$route.name == 'courses.modules.activities.create') {
                this.form.model.courseUuid = linked_course_id;
                this.form.model.moduleUuid = linked_module_id;
            }
            if (this.activityObj) {
                //already created
                this.form.setModel(_.get(this, 'activityObj', {}));
                this.form.model.others = this.activityObj.others;
                this.form.model.courseUuid = this.activityObj.course.uuid;
                this.form.model.moduleUuid = this.activityObj.module.uuid;
            }
            var initRequirements = function () {
                axios.get('/courses/' + that.form.model.courseUuid).then(function (response) {
                    that.course = response.data.data;
                    that.moduleList = that.course.modules;
                    that.changedModule();
                    if (!that.form.model.objectives) {
                        that.form.model.objectives = [];
                    }
                    if (that.form.model.objectives.length == 0) {
                        that.addObjective();
                    }
                    if (that.$route.name == 'courses.modules.activities.create') {
                        let codeStr = '';
                        if (that.course.code) {
                            codeStr = ' (' + that.course.code + ')';
                        }
                        Events.fire('topbar_update', {
                            breadcrumb: [
                                { name: 'Home' },
                                { name: 'Courses', link: 'courses.index' },
                                {
                                    name: that.course.name + codeStr,
                                    link: 'courses.view',
                                    params: { id: that.course.uuid },
                                },
                                { name: 'New Activity' },
                            ],
                        });
                    }
                    that.component_done_loading = true;
                });
            }.bind(that);
            initRequirements();
        },
        openActivityTypeConfirmationModal() {
            $('#changeActivityTypeModal').modal('show');
        },
        changedActivityType() {
            if (this.form.model.type == 'prework') {
                this.form.model.others.preworkType = 'simple';
            } else if (this.form.model.type == 'application') {
                this.form.model.others.applicationType = 'team';
                this.form.model.others.isApplicationGraded = true;
            } else if (this.form.model.type == 'evaluation') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.evaluateTeam = false;
                this.form.model.evaluationSettings.evaluateInstructor = false;
                this.form.model.evaluationSettings.instructorsToEvaluate = [this.course.owner.uuid];
            } else if (this.form.model.type == 'michaelsenEvaluation') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.evaluateTeam = false;
                this.form.model.evaluationSettings.evaluateInstructor = false;
                this.form.model.evaluationSettings.instructorsToEvaluate = [];
            } else {
                //this.form.model.others = {};
                //this.form.model.evaluationSettings = {};
            }
        },
        changedModule() {
            let module_id = this.form.model.moduleUuid; // The value of the selected option
            if (module_id) {
                this.module = _.find(this.moduleList, function (o) {
                    return o.uuid == module_id;
                });
            }
        },
        addObjective() {
            this.form.model.objectives.push('');
        },
        deleteObjective(idx) {
            this.form.model.objectives.splice(idx, 1);
        },
        saveAndExit() {
            this.submitActivity(true);
        },
        submitActivity(exitOnSave = false) {
            var that = this;
            that.form.model.descriptionIsHTML = false;
            let url = '/activities';

            if (!this.activityObj) {
                this.form.post(url).then(function (response) {
                    if (!response.krFormError) {
                        that.$router.push({
                            name: 'courses.modules.activities.continue',
                            params: { id: response.data.data.uuid },
                        });
                        that.form.setModel(response.data.data);
                        Vue.nextTick(function () {
                            if (exitOnSave === false) {
                                that.$emit('activity-updated', response.data.data);
                                that.$emit('next-step');
                            } else {
                                that.$emit('save-and-exit', response.data.data);
                            }
                        });
                    }
                });
            } else {
                url = '/activities/' + this.activityObj.uuid;
                this.form.put(url).then(function (response) {
                    if (!response.krFormError) {
                        that.$emit('activity-updated', response.data.data);
                        if (exitOnSave === true) {
                            Vue.nextTick(function () {
                                that.$emit('save-and-exit');
                            });
                        } else {
                            if (that.pageType != 'form') {
                                that.mode = 'view';
                            } else {
                                Vue.nextTick(function () {
                                    that.$emit('next-step');
                                });
                            }
                        }
                    }
                });
            }
        },
    },
};
</script>
<style scoped>
.objectiveRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.objectiveRow:last-child {
    margin-bottom: 0;
}

.objectiveRow span {
    font-weight: bold;
    margin-right: 10px;
}

.create.flex .col-md-6:first-child {
    padding-right: 10px;
}

.create.flex .col-md-6:last-child {
    padding-left: 10px;
}

.card-container {
    padding: 0 20px;
}

.card-body {
    padding-bottom: 20px;
}

.card-container .btn {
    margin-left: 0;
}

.card-body > .col-xs-12:last-child > .form-control-static:last-child {
    margin-bottom: 0;
}

.card-container .mainModulesBtn,
.card-container .mainActivityBtn,
#courseOverview .x_content .mainCourseBtn {
    margin-bottom: 20px;
}

.card-body .btn.subBtn {
    margin-bottom: 20px;
}

#courseOverview .x_content .btn-custom {
    background-color: #f6f8fa;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #717171;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom .fa-stack {
    font-size: 8px;
}

#courseOverview .x_content .btn-custom .fa-circle {
    color: #d8d8d8;
}

#courseOverview .x_content .btn-custom:active,
#courseOverview .x_content .btn-custom:hover,
#courseOverview .x_content .btn-custom:visited,
#courseOverview .x_content .btn-custom:focus,
#courseOverview .x_content .btn-custom:active:hover,
#courseOverview .x_content .btn-custom:active:focus {
    border: solid 1px #1d83c7;
    background-color: rgba(255, 255, 255, 0.05);
    color: #222222;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom:active .fa,
#courseOverview .x_content .btn-custom:hover .fa,
#courseOverview .x_content .btn-custom:visited .fa,
#courseOverview .x_content .btn-custom:focus .fa,
#courseOverview .x_content .btn-custom:active:hover .fa,
#courseOverview .x_content .btn-custom:active:focus .fa,
#courseOverview .x_content .btn-custom:active .far,
#courseOverview .x_content .btn-custom:hover .far,
#courseOverview .x_content .btn-custom:visited .far,
#courseOverview .x_content .btn-custom:focus .far,
#courseOverview .x_content .btn-custom:active:hover .far,
#courseOverview .x_content .btn-custom:active:focus .far,
#courseOverview .x_content .btn-custom:active .fas,
#courseOverview .x_content .btn-custom:hover .fas,
#courseOverview .x_content .btn-custom:visited .fas,
#courseOverview .x_content .btn-custom:focus .fas,
#courseOverview .x_content .btn-custom:active:hover .fas,
#courseOverview .x_content .btn-custom:active:focus .fas {
    color: #0071be;
}

.width360px {
    width: 360px;
}

.loopCheckbox:not(:first-of-type) {
    margin-top: 5px;
}
</style>
