<template>
    <!--PUT HTML HERE-->
    <div id="superuser-index">
        <div class="col-xs-12 right-container">
            <kr-search
                ref="superusers_listing"
                name="superusers_listing"
                :url="{ url: '/superusers2' }"
                :columns="columns"
                :options="{
                    suspended_field: true,
                    newServerMode: {
                        includes: ['q', 'isSuspended', 'sort', 'order'],
                    },
                    default_query_fields: { sort: 'firstname' },
                }"
                @retrieve-data="getSearchData"
            >
                <template #upper-right>
                    <div class="form-group displayContent">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select
                                v-model="selectedOption"
                                class="form-control"
                                @change="
                                    $refs['superusers_listing'].debounced_search(true);
                                    changeValue();
                                "
                            >
                                <option value="">Firstname Lastname</option>
                                <option value="Lastname, Firstname">Lastname, Firstname</option>
                            </select>
                        </div>
                    </div>

                    <router-link v-if="!auth.user().isDeletable" v-slot="{ navigate }" :to="{ name: 'superusers.create' }" custom>
                        <button class="btn btn-primary" @click="navigate">
                            <i class="fa fa-plus marginRight8" aria-hidden="true"></i>
                            New Superuser
                        </button>
                    </router-link>
                </template>
                <!-- <template slot="top-display-from-to" slot-scope="props">
                    <div class="displayNo">
                        {{props.model.from}} - {{props.model.to}} of {{props.model.meta.paginator.total}}
                    </div>
                </template> -->
                <template #table-title="props">
                    <th colspan="6">
                        <template v-if="searchData.query.isSuspended == '0'">Active</template>
                        <template v-if="searchData.query.isSuspended == '1'">Suspended</template>
                        <template v-if="searchData.query.isSuspended == '*'">All</template>
                        Superusers ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                    </th>
                </template>
                <template #bottom-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>
                <template #cdata="props">
                    <td>
                        <template v-if="selectedOption == 'Lastname, Firstname'">
                            <router-link
                                v-if="props.model.uuid != auth.user().uuid"
                                v-tooltip="{ content: 'View Account' }"
                                tag="a"
                                :to="{
                                    name: 'superusers.view',
                                    params: { id: props.model.uuid },
                                }"
                                class="link"
                                :aria-label="(props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname"
                            >
                                {{ (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname }}
                            </router-link>
                            <router-link
                                v-else
                                v-tooltip="{ content: 'View' }"
                                tag="a"
                                :to="{
                                    name: 'profile.view',
                                    params: { id: props.model.uuid },
                                }"
                                class="link"
                                :aria-label="(props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname"
                            >
                                {{ (props.model.lastname ? props.model.lastname + ', ' : '') + props.model.firstname }}
                            </router-link>
                        </template>
                        <template v-else>
                            <router-link
                                v-if="props.model.uuid != auth.user().uuid"
                                v-tooltip="{ content: 'View Account' }"
                                tag="a"
                                :to="{
                                    name: 'superusers.view',
                                    params: { id: props.model.uuid },
                                }"
                                class="link"
                                :aria-label="props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')"
                            >
                                {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                            </router-link>
                            <router-link
                                v-else
                                v-tooltip="{ content: 'View' }"
                                tag="a"
                                :to="{
                                    name: 'profile.view',
                                    params: { id: props.model.uuid },
                                }"
                                class="link"
                                :aria-label="props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')"
                            >
                                {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                            </router-link>
                        </template>
                    </td>
                    <td class="hidden-xs hidden-sm">{{ props.model.email }}</td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="!props.model.isDeletable">
                            Admin
                            <template v-if="auth.user().uuid == props.model.uuid">(ME)</template>
                        </template>
                        <template v-else>
                            Non-Admin
                            <template v-if="auth.user().uuid == props.model.uuid">(ME)</template>
                        </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="!props.model.isSuspended"> <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active </template>
                        <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.dateLastLogin">
                            {{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                    </td>
                    <td>
                        <div class="buttonGroupInline hidden-xs hidden-sm">
                            <router-link
                                v-if="auth.user().uuid != props.model.uuid"
                                v-slot="{ navigate }"
                                v-tooltip="{
                                    content: 'View Superuser',
                                }"
                                :to="{
                                    name: 'superusers.view',
                                    params: { id: props.model.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-primary" @click="navigate"><i class="fas fa-eye marginRight8" aria-hidden="true"></i>View</button>
                            </router-link>
                            <!--<router-link id="edit-btn" v-if="auth.user().uuid!=props.model.uuid&&!auth.user().isDeletable" v-tooltip="{
                            content: 'Edit'}" :to="{name:'superusers.edit',params:{id:props.model.uuid}}" class="btn btn-default subBtn" tag="button">
                                Edit&nbsp;<i class="fa fa-pencil-square-o"></i>
                            </router-link>-->
                            <router-link
                                v-if="auth.user().uuid == props.model.uuid"
                                v-slot="{ navigate }"
                                v-tooltip="{
                                    content: 'View My Profile',
                                }"
                                :to="{
                                    name: 'profile.view',
                                    params: { id: props.model.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-primary" @click="navigate"><i class="fas fa-eye marginRight8" aria-hidden="true"></i>View</button>
                            </router-link>

                            <button
                                v-if="!auth.user().isDeletable && !props.model.isSuspended && auth.user().uuid != props.model.uuid"
                                id="reset-pwd-btn"
                                v-tooltip="{ content: 'Reset Password' }"
                                class="btn btn-default subBtn"
                                aria-label="Reset Password"
                                @click.prevent="openResetPasswordModal(props.model)"
                            >
                                <i class="fas fa-key" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="buttonGroupInline hidden-md hidden-lg">
                            <router-link
                                v-if="auth.user().uuid != props.model.uuid"
                                v-slot="{ navigate }"
                                v-tooltip="{
                                    content: 'View Superuser',
                                }"
                                :to="{
                                    name: 'superusers.view',
                                    params: { id: props.model.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-primary" @click="navigate">
                                    <i class="fas fa-eye" aria-hidden="true"></i>
                                </button>
                            </router-link>
                            <!--<router-link id="mobile-edit-btn" v-if="auth.user().uuid!=props.model.uuid&&!auth.user().isDeletable" v-tooltip="{
                            content: 'Edit'}" :to="{name:'superusers.edit',params:{id:props.model.uuid}}" class="btn btn-default subBtn" tag="button">
                                <i class="fa fa-pencil-square-o"></i>
                            </router-link>-->
                            <router-link
                                v-if="auth.user().uuid == props.model.uuid"
                                v-slot="{ navigate }"
                                v-tooltip="{
                                    content: 'View My Profile',
                                }"
                                :to="{
                                    name: 'profile.view',
                                    params: { id: props.model.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-primary" @click="navigate">
                                    <i class="fas fa-eye" aria-hidden="true"></i>
                                </button>
                            </router-link>
                            <button
                                v-if="!auth.user().isDeletable && !props.model.isSuspended && auth.user().uuid != props.model.uuid"
                                id="mobile-reset-pwd-btn"
                                v-tooltip="{ content: 'Reset Password' }"
                                class="btn btn-default subBtn"
                                aria-label="Reset Password"
                                @click.prevent="openResetPasswordModal(props.model)"
                            >
                                <i class="fa fa-key" aria-hidden="true"></i>
                            </button>
                        </div>
                    </td>
                </template>
            </kr-search>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
            columns: {},
            selectedOption: '',
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-crown"></i>&nbsp;Superusers',
            breadcrumb: [{ name: 'Home' }, { name: 'Superusers', link: 'superusers.index' }],
        });
        this.init();
    },
    methods: {
        init() {
            this.columns = {
                firstname: 'display:FIRST NAME|sortable:true|width:20%|subDisplay:LAST NAME|subSortable:true|subSrc:lastname',
                email: 'display:EMAIL|sortable:false|width:15%|mobile:hide',
                type: 'display:TYPE|sortable:false|width:10%|mobile:hide',
                isSuspended: 'display:STATUS|sortable:false|width:10%|mobile:hide',
                dateLastLogin: 'display:LAST LOGIN|sortable:false|width:20%|mobile:hide',
            };
        },
        getSearchData(data) {
            this.searchData = data;
        },
        openResetPasswordModal(user) {
            Events.fire('open-reset-password-modal', {
                user: user,
                organisation: 'superusers',
            });
        },
        changeValue() {
            if (this.selectedOption == '') {
                const propertyPosition = Object.keys(this.columns).indexOf('lastname');
                this.columns.firstname = this.columns.lastname;
                //Vue.set(this.columns, 'firstname', this.columns.lastname);
                delete this.columns.lastname;
                this.columns = {
                    ...Object.keys(this.columns)
                        .slice(0, propertyPosition)
                        .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    firstname: Object.values(this.columns)[propertyPosition],
                    ...Object.keys(this.columns)
                        .slice(this.columns.length)
                        .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                };
                this.columns.firstname = 'display:FIRST NAME|sortable:true|width:auto|subDisplay:LAST NAME|subSortable:true|subSrc:lastname';
                /*Vue.set(
                    this.columns,
                    'firstname',
                    'display:FIRST NAME|sortable:true|width:auto|subDisplay:LAST NAME|subSortable:true|subSrc:lastname'
                );*/
            } else {
                const propertyPosition = Object.keys(this.columns).indexOf('firstname');
                this.columns.lastname = this.columns.firstname;
                //Vue.set(this.columns, 'lastname', this.columns.firstname);
                delete this.columns.firstname;
                this.columns = {
                    ...Object.keys(this.columns)
                        .slice(0, propertyPosition)
                        .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                    lastname: Object.values(this.columns)[propertyPosition],
                    ...Object.keys(this.columns)
                        .slice(this.columns.length)
                        .reduce((obj, key) => ((obj[key] = this.columns[key]), obj), {}),
                };
                this.columns.lastname = 'display:LAST NAME|sortable:true|width:auto|subDisplay:FIRST NAME|subSortable:true|subSrc:firstname';
                /*Vue.set(
                    this.columns,
                    'lastname',
                    'display:LAST NAME|sortable:true|width:auto|subDisplay:FIRST NAME|subSortable:true|subSrc:firstname'
                );*/
            }
        },
    },
};
</script>

<style scoped>
.link {
    color: #0071be;
    cursor: pointer;
}
</style>
