<template>
    <div class="content0">
        <div class="stickyTop66px">
            <div v-if="!isTestCompleted && !testObj._isPreview" class="inlineFlex marginBottom20 align-items">
                <button class="btn btn-success mainBtn marginRight20" :disabled="!canSubmitTest ? true : undefined" @click.prevent="submitTest">
                    <i class="fas fa-check marginRight8" aria-hidden="true"></i>Submit Evaluation
                </button>
                <!--<button class="btn btn-default mainBtn">
					<i class="far fa-save"></i>&nbsp;Save as Draft
				</button>-->
                <!--<div class="fontItalic">
					Last saved on 19 May 2019, 12:12 PM
				</div>-->
            </div>
            <div v-if="!canSubmitTest && !isTestCompleted" class="alert alert-warning marginBottom20 d-block" tabindex="0">
                <div class="flexSpaceBetween">
                    <div>
                        <button
                            class="btn"
                            data-toggle="collapse"
                            data-target="#collapseEvaluationWarning"
                            aria-expanded="false"
                            aria-controls="collapseEvaluationWarning"
                        >
                            Question Incomplete ({{ countNumberOfQuestionUndone }})
                        </button>
                    </div>

                    <div class="evaluationShowMoreIcon">
                        <button
                            class="btn"
                            data-toggle="collapse"
                            data-target="#collapseEvaluationWarning"
                            aria-expanded="false"
                            aria-controls="collapseEvaluationWarning"
                            @click.prevent="toggle"
                        >
                            <span class="marginRight8">
                                <template v-if="isOpened"> Less </template>
                                <template v-else> More </template>
                            </span>
                            <i
                                class="fa marginTop0"
                                :class="{
                                    'fa-caret-up': isOpened,
                                    'fa-caret-down': !isOpened,
                                }"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </div>
                <div id="collapseEvaluationWarning" class="collapse" aria-labelledby="headingOne">
                    <div class="flexOnly">
                        <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true"></i>
                        <div>
                            In order to submit the evaluation, you must complete these section(s).Clicking the button beside section name will direct you to the
                            first required question that was not answered on said section
                            <div v-if="testObj.michaelsenPoints.length != 0 && testObj.settings.enableMichaelsenPointDistribution" class="marginTop10">
                                <i
                                    class="fas marginTop0"
                                    :class="{
                                        'fa-circle greyText': !isMichaelsenDone,
                                        'fa-check-circle darkGreen': isMichaelsenDone,
                                    }"
                                    aria-hidden="true"
                                ></i>
                                <span :aria-label="isMichaelsenDone ? 'Michaelsen Points is Completed' : 'Michaelsen Points is InComplete'" tabindex="0">
                                    Michaelsen Points
                                </span>
                                <button
                                    v-if="!isMichaelsenDone"
                                    class="btn btn-primary"
                                    aria-label="Go To Michaelsen Points Section"
                                    @click="focusSection('michaelsen')"
                                >
                                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div
                                v-if="
                                    testObj.peerQuestions.length != 0 &&
                                    (testObj.evaluationSettings.evaluateTeammates || testObj.evaluationSettings.evaluateThemselves)
                                "
                                class="marginTop10"
                            >
                                <i
                                    class="fas marginTop0"
                                    :class="{
                                        'fa-circle greyText': !isPeerDone,
                                        'fa-check-circle darkGreen': isPeerDone,
                                    }"
                                    aria-hidden="true"
                                ></i>
                                <span :aria-label="isPeerDone ? 'Individual Evaluation is Completed' : 'Individual Evaluation is InComplete'" tabindex="0">
                                    Individual Evaluation
                                </span>
                                <button
                                    v-if="!isPeerDone"
                                    class="btn btn-primary"
                                    aria-label="Go to Individual Evaluation Section"
                                    @click="focusSection('peer')"
                                >
                                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div v-if="testObj.teamQuestions.length != 0 && testObj.evaluationSettings.evaluateTeam" class="marginTop10">
                                <i
                                    class="fas marginTop0"
                                    :class="{
                                        'fa-circle greyText': !isTeamDone,
                                        'fa-check-circle darkGreen': isTeamDone,
                                    }"
                                    aria-hidden="true"
                                ></i>
                                <span :aria-label="isTeamDone ? 'Teams Evaluation is Completed' : 'Teams Evaluation is InComplete'" tabindex="0">
                                    Teams Evaluation
                                </span>
                                <button v-if="!isTeamDone" class="btn btn-primary" aria-label="Go to Teams Evaluation Section" @click="focusSection('team')">
                                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div v-if="testObj.instructorQuestions.length != 0 && testObj.evaluationSettings.evaluateInstructor" class="marginTop10">
                                <i
                                    class="fas marginTop0"
                                    :class="{
                                        'fa-circle greyText': !isInstructorDone,
                                        'fa-check-circle darkGreen': isInstructorDone,
                                    }"
                                    aria-hidden="true"
                                ></i>
                                <span
                                    :aria-label="isInstructorDone ? 'Instructors Evaluation is Completed' : 'Instructors Evaluation is InComplete'"
                                    tabindex="0"
                                >
                                    Instructors Evaluation
                                </span>
                                <button
                                    v-if="!isInstructorDone"
                                    class="btn btn-primary"
                                    aria-label="Go to Instructors Evaluation Section"
                                    @click="focusSection('instructor')"
                                >
                                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <kr-panel v-if="testObj.michaelsenPoints.length != 0 && testObj.settings.enableMichaelsenPointDistribution" :options="{ canToggle: true }">
            <template #title>
                <div :aria-label="isMichaelsenDone ? 'Michaelsen Points is Completed' : 'Michaelsen Points is InComplete'" tabindex="0">
                    <i
                        class="fas marginRight8"
                        :class="{
                            'fa-circle greyText': !isMichaelsenDone,
                            'fa-check-circle darkGreen': isMichaelsenDone,
                        }"
                        aria-hidden="true"
                    ></i
                    >Michaelsen Points
                </div>
            </template>
            <template #content>
                <div id="michaelsen-div">
                    <michaelsen :test-obj="testObj" :disabled="!canAnswer ? true : undefined" @can-submit="processMichaelsenStatus"></michaelsen>
                </div>
            </template>
        </kr-panel>

        <kr-panel
            v-if="testObj.peerQuestions.length != 0 && (testObj.evaluationSettings.evaluateTeammates || testObj.evaluationSettings.evaluateThemselves)"
            :options="{ canToggle: true }"
        >
            <template #title>
                <div :aria-label="isMichaelsenDone ? 'Individual Evaluation is Completed' : 'Individual Evaluation is InComplete'" tabindex="0">
                    <!--{{(testObj.michaelsenPoints.length!=0?1:0)+1}}.&nbsp; -->
                    <i
                        class="fas marginRight8"
                        :class="{
                            'fa-circle greyText': !isPeerDone,
                            'fa-check-circle darkGreen': isPeerDone,
                        }"
                        aria-hidden="true"
                    ></i
                    >Individual Evaluation
                </div>
            </template>
            <template #content>
                <div id="peer-div">
                    <template v-for="(question, question_idx) in testObj.peerQuestions[0]">
                        <question :question_idx="question_idx" :question="question" :test-obj="testObj" type="peer"></question>
                    </template>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="testObj.teamQuestions.length != 0 && testObj.evaluationSettings.evaluateTeam" :options="{ canToggle: true }">
            <template #title>
                <div>
                    <!--&nbsp;{{(testObj.michaelsenPoints.length!=0?1:0)+(testObj.peerQuestions.length!=0?1:0)+1}}. -->
                    <i
                        class="fas marginRight8"
                        :class="{
                            'fa-circle greyText': !isTeamDone,
                            'fa-check-circle darkGreen': isTeamDone,
                        }"
                    ></i
                    >Team Evaluation
                </div>
            </template>
            <template #content>
                <div id="team-div">
                    <template v-for="(question, question_idx) in testObj.teamQuestions[0]">
                        <question :question_idx="question_idx" :question="question" :test-obj="testObj" type="team"></question>
                    </template>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="testObj.instructorQuestions.length != 0 && testObj.evaluationSettings.evaluateInstructor" :options="{ canToggle: true }">
            <template #title>
                <div>
                    <!--&nbsp;{{(testObj.michaelsenPoints.length!=0?1:0)+(testObj.peerQuestions.length!=0?1:0)+(testObj.teamQuestions.length!=0?1:0)+1}}. -->
                    <i
                        class="fas marginRight8"
                        :class="{
                            'fa-circle greyText': !isInstructorDone,
                            'fa-check-circle darkGreen': isInstructorDone,
                        }"
                    ></i
                    >Instructors Evaluation
                </div>
            </template>
            <template #content>
                <div id="instructor-div">
                    <template v-for="(question, question_idx) in testObj.instructorQuestions[0]">
                        <question :question_idx="question_idx" :question="question" :test-obj="testObj" type="instructor"></question>
                    </template>
                </div>
            </template>
        </kr-panel>
        <div v-if="!isTestCompleted && !testObj._isPreview" class="inlineFlex marginTop10 align-items">
            <button
                class="btn btn-success mainBtn marginRight20"
                :disabled="!canSubmitTest ? true : undefined"
                aria-label="Submit Evaluation"
                @click.prevent="submitTest"
            >
                <i class="fas fa-check marginRight8" aria-hidden="true"></i>Submit Evaluation
            </button>
            <!--<button class="btn btn-default mainBtn">
				<i class="far fa-save"></i>&nbsp;Save as Draft
			</button>-->
            <!-- <div class="fontItalic">
				Last saved on 19 May 2019, 12:12 PM
			</div> -->
        </div>
    </div>
</template>

<script>
import { testMixins } from './../../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['testObj'],
    components: {
        question: require(`./question/question.vue`).default,
        michaelsen: require(`./michaelsen.vue`).default,
    },
    data() {
        return {
            isPeerDone: false,
            isInstructorDone: true,
            isTeamDone: false,
            isMichaelsenDone: false,
            isOpened: false,
        };
    },
    computed: {
        canSubmitTest() {
            if (
                (this.testObj.peerQuestions.length != 0 &&
                    !this.isPeerDone &&
                    (this.testObj.evaluationSettings.evaluateTeammates || this.testObj.evaluationSettings.evaluateThemselves)) ||
                (this.testObj.teamQuestions.length != 0 && !this.isTeamDone && this.testObj.evaluationSettings.evaluateTeam) ||
                (this.testObj.instructorQuestions.length != 0 && !this.isInstructorDone && this.testObj.evaluationSettings.evaluateInstructor) ||
                (this.testObj.michaelsenPoints.length != 0 && !this.isMichaelsenDone && this.testObj.settings.enableMichaelsenPointDistribution)
            ) {
                return false;
            }
            return true;
        },
        canAnswer() {
            if (this.isTestCompleted) {
                return false;
            }
            return true;
        },
        countNumberOfQuestionUndone() {
            let count = 0;

            if (this.testObj.michaelsenPoints.length != 0 && this.testObj.settings.enableMichaelsenPointDistribution) {
                if (!this.isMichaelsenDone) {
                    count++;
                }
            }

            if (
                this.testObj.peerQuestions.length != 0 &&
                (this.testObj.evaluationSettings.evaluateTeammates || this.testObj.evaluationSettings.evaluateThemselves)
            ) {
                if (!this.isPeerDone) {
                    count++;
                }
            }

            if (this.testObj.teamQuestions.length != 0 && this.testObj.evaluationSettings.evaluateTeam) {
                if (!this.isTeamDone) {
                    count++;
                }
            }

            if (this.testObj.instructorQuestions.length != 0 && this.testObj.evaluationSettings.evaluateInstructor) {
                if (!this.isInstructorDone) {
                    count++;
                }
            }

            return count;
        },
    },
    watch: {
        'testObj.peerQuestions': {
            handler: function () {
                if (
                    this.testObj.peerQuestions.length != 0 &&
                    (this.testObj.evaluationSettings.evaluateTeammates || this.testObj.evaluationSettings.evaluateThemselves)
                ) {
                    for (var i = 0; i < this.testObj.peerQuestions[0].length; i++) {
                        if (!this.testObj.peerQuestions[0][i]._isCorrect && this.testObj.peerQuestions[0][i].isRequired) {
                            this.isPeerDone = false;
                            return;
                        }
                    }
                }
                this.isPeerDone = true;
            },
            deep: true,
        },
        'testObj.instructorQuestions': {
            handler: function () {
                if (this.testObj.instructorQuestions.length != 0 && this.testObj.evaluationSettings.evaluateInstructor) {
                    for (var i = 0; i < this.testObj.instructorQuestions[0].length; i++) {
                        if (!this.testObj.instructorQuestions[0][i]._isCorrect && this.testObj.instructorQuestions[0][i].isRequired) {
                            this.isInstructorDone = false;
                            return;
                        }
                    }
                }
                this.isInstructorDone = true;
            },
            deep: true,
        },
        'testObj.teamQuestions': {
            handler: function () {
                if (this.testObj.teamQuestions.length != 0 && this.testObj.evaluationSettings.evaluateTeam) {
                    for (var i = 0; i < this.testObj.teamQuestions[0].length; i++) {
                        if (!this.testObj.teamQuestions[0][i]._isCorrect && this.testObj.teamQuestions[0][i].isRequired) {
                            this.isTeamDone = false;
                            return;
                        }
                    }
                }
                this.isTeamDone = true;
            },
            deep: true,
        },
    },
    methods: {
        focusSection(type) {
            var th_hgt = $('.stickyTop66px').height() + 96;

            if (type == 'michaelsen') {
                $('html, body').animate(
                    {
                        scrollTop: $('#michaelsen-div').offset().top - 310,
                    },
                    1000
                );
            } else if (type == 'peer') {
                for (var i = 0; i < this.testObj.peerQuestions[0].length; i++) {
                    if (!this.testObj.peerQuestions[0][i]._isCorrect && this.testObj.peerQuestions[0][i].isRequired) {
                        $('html, body').animate(
                            {
                                scrollTop: $('#' + this.testObj.peerQuestions[0][i].uuid + '-question-peer').offset().top - th_hgt,
                            },
                            1000
                        );
                        i = this.testObj.peerQuestions[0].length;
                    }
                }
            } else if (type == 'team') {
                for (var i = 0; i < this.testObj.teamQuestions[0].length; i++) {
                    if (!this.testObj.teamQuestions[0][i]._isCorrect && this.testObj.teamQuestions[0][i].isRequired) {
                        $('html, body').animate(
                            {
                                scrollTop: $('#' + this.testObj.teamQuestions[0][i].uuid + '-question-team').offset().top - th_hgt,
                            },
                            1000
                        );
                        i = this.testObj.teamQuestions[0].length;
                    }
                }
            } else {
                for (var i = 0; i < this.testObj.instructorQuestions[0].length; i++) {
                    if (!this.testObj.instructorQuestions[0][i]._isCorrect && this.testObj.instructorQuestions[0][i].isRequired) {
                        $('html, body').animate(
                            {
                                scrollTop: $('#' + this.testObj.instructorQuestions[0][i].uuid + '-question-instructor').offset().top - th_hgt,
                            },
                            1000
                        );
                        return;
                    }
                }
            }
        },
        processMichaelsenStatus(tag) {
            this.isMichaelsenDone = tag;
        },
        submitTest() {
            Events.fire('submit-test');
        },
        toggle() {
            this.isOpened = !this.isOpened;
        },
    },
};
</script>

<style>
.content0 .x_content {
    padding: 0 !important;
}
</style>

<style scoped>
body.test .gridBase .panel-body,
.gridBase .x_content {
    padding: 0;
}

.stickyTop66px {
    position: sticky;
    top: 66px;
    background: #f7f8fa;
    z-index: 10;
    padding-top: 30px;
}
</style>
