<template>
    <div ref="modal" class="modal default-modal copyTemplateModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyTemplateModal-title">
        <div class="modal-dialog">
            <div v-if="loaded" class="preview modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="copyTemplateModal-title" class="modal-title">Copy Template</h2>
                </div>
                <div class="modal-body">
                    <h2 class="margin0 marginTop20 textAlignCenter">You are making a copy of this template</h2>
                    <br />
                    <p class="textAlignCenter">{{ template.name }}</p>
                    <br />
                    <p class="textAlignCenter">
                        <b>Continue?</b>
                    </p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click="closeModal">Cancel</button>
                    <button class="btn btn-success" @click="submit">Make a Copy</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['copied']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const modal = ref(null);
const template = ref(null);

const route = useRoute();
const router = useRouter();

const loaded = ref(false);

function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function copy(data = {}) {
    template.value = data;
    loaded.value = true;
    openModal();
}
function submit() {
    axios
        .post(`templates/${template.value.uuid}/duplicate`)
        .then(function (response) {
            emit('copied');
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Template successfully duplicated',
            });
            closeModal();

            if (route.name == 'templates.view') {
                router.push({ name: 'questions.index' });
            }
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
            closeModal();
        });
}
defineExpose({ copy });
</script>
