<template>
    <div class="row">
        <div>
            <div>
                <label id="criteriaName" class="control-label"> Criteria </label>
                <p aria-labelledby="criteriaName">
                    <template v-if="question.name">
                        {{ question.name }}
                    </template>
                    <template v-else-if="question.question && question.question.name && $route.name == 'activities.tests.dashboard.peer-evaluation'">
                        {{ question.question.name }}
                    </template>
                </p>

                <template v-if="$route.name == 'activities.tests.dashboard.peer-evaluation'">
                    <template v-if="question.question.description">
                        <label id="criteriaDescription" class="control-label marginTop20"> Description </label>
                        <p>
                            <kr-math :input="question.question.description" :safe="!question.question.questionIsHTML" class="whiteSpaceBreakSpacesText" />
                        </p>
                    </template>
                </template>
                <template v-else>
                    <template v-if="question.description">
                        <label id="criteriaDescription" class="control-label marginTop20"> Description </label>
                        <p>
                            <kr-math :input="question.description" :safe="!question.questionIsHTML" class="whiteSpaceBreakSpacesText" />
                        </p>
                    </template>
                </template>
            </div>

            <template v-if="['pointDistribution'].includes(question.type)">
                <label class="control-label marginTop20"> Requirements </label>

                <div class="flexOnly alignFlexStart">
                    <span
                        class="marginRight8 material-symbols-outlined"
                        :class="{
                            'requirements-complete': question.settings.shouldDistributePointsWithoutRepeatingValues,
                            'requirements-notComplete': !question.settings.shouldDistributePointsWithoutRepeatingValues,
                        }"
                    >
                        <template v-if="question.settings.shouldDistributePointsWithoutRepeatingValues">check</template><template v-else>cancel</template>
                    </span>
                    <p
                        :class="{
                            'requirements-complete': question.settings.shouldDistributePointsWithoutRepeatingValues,
                            'requirements-notComplete': !question.settings.shouldDistributePointsWithoutRepeatingValues,
                        }"
                    >
                        Must distribute points without repeating values
                    </p>
                </div>

                <div class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8 material-symbols-outlined"
                        :class="{
                            'requirements-complete': question.settings.shouldDistributeAllPoints,
                            'requirements-notComplete': !question.settings.shouldDistributeAllPoints,
                        }"
                    >
                        <template v-if="question.settings.shouldDistributeAllPoints">check</template><template v-else>cancel</template>
                    </span>
                    <p
                        :class="{
                            'requirements-complete': question.settings.shouldDistributeAllPoints,
                            'requirements-notComplete': !question.settings.shouldDistributeAllPoints,
                        }"
                    >
                        Must distribute <b>ALL</b> points
                    </p>
                </div>

                <div class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8 material-symbols-outlined"
                        :class="{
                            'requirements-complete': question.settings.shouldGiveOneTeammateAboveTenPoints,
                            'requirements-notComplete': !question.settings.shouldGiveOneTeammateAboveTenPoints,
                        }"
                    >
                        <template v-if="question.settings.shouldGiveOneTeammateAboveTenPoints">check</template><template v-else>cancel</template>
                    </span>
                    <p
                        :class="{
                            'requirements-complete': question.settings.shouldGiveOneTeammateAboveTenPoints,
                            'requirements-notComplete': !question.settings.shouldGiveOneTeammateAboveTenPoints,
                        }"
                    >
                        Must give at least one teammate a point above {{ question.settings.pointsAllocatedPerMember }}
                    </p>
                </div>

                <div class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8 material-symbols-outlined"
                        :class="{
                            'requirements-complete': question.settings.shouldGiveOneTeammateBelowTenPoints,
                            'requirements-notComplete': !question.settings.shouldGiveOneTeammateBelowTenPoints,
                        }"
                    >
                        <template v-if="question.settings.shouldGiveOneTeammateBelowTenPoints">check</template><template v-else>cancel</template>
                    </span>
                    <p
                        :class="{
                            'requirements-complete': question.settings.shouldGiveOneTeammateBelowTenPoints,
                            'requirements-notComplete': !question.settings.shouldGiveOneTeammateBelowTenPoints,
                        }"
                    >
                        Must give at least one teammate a point below {{ question.settings.pointsAllocatedPerMember }} <b>(0 is allowed)</b>
                    </p>
                </div>

                <div class="flexOnly alignFlexStart marginTop5">
                    <span
                        class="marginRight8 material-symbols-outlined"
                        :class="{
                            'requirements-complete': question.settings.allowToGiveThemselvesPoints,
                            'requirements-notComplete': !question.settings.allowToGiveThemselvesPoints,
                        }"
                    >
                        <template v-if="question.settings.allowToGiveThemselvesPoints">check</template><template v-else>cancel</template>
                    </span>
                    <p
                        :class="{
                            'requirements-complete': question.settings.allowToGiveThemselvesPoints,
                            'requirements-notComplete': !question.settings.allowToGiveThemselvesPoints,
                        }"
                    >
                        Give points to self <b>(0 is allowed)</b>
                    </p>
                </div>
            </template>

            <template v-if="['ratingV2', 'openendedV2'].includes(question.type)">
                <div
                    v-for="(questionStem, question_idx) in question.questionStems"
                    class="question-stem-card-container marginTop20"
                    :class="{ marginTop10: question_idx != 0 }"
                >
                    <div class="question-stem-card-header">
                        <span class="material-icons marginRight8" aria-hidden="true">
                            <template v-if="question.type == 'ratingV2'"> star </template>
                            <template v-if="question.type == 'openendedV2'"> format_align_left </template>
                            <template v-if="question.type == 'pointDistribution'"> tune </template>
                        </span>
                        <label class="fw-normal">
                            {{ question_idx }}. {{ questionStem.stem }}
                            <template v-if="questionStem.isRequired">
                                <span class="visible-hidden">Required</span>
                                <span class="important" aria-hidden="true">*</span>
                            </template>
                        </label>
                    </div>

                    <div v-if="['ratingV2', 'pointDistribution'].includes(question.type)" class="question-stem-card-content">
                        <div v-if="question.type == 'ratingV2'" class="rating-flex-layout">
                            <template v-for="(rating, ratingIdx) in question.ratingSettings">
                                <div class="newRadioField rating-scale-wrapper">
                                    <div class="radioField">
                                        <input id="radio-label" type="radio" name="ratingV2" disabled />
                                    </div>
                                    <label for="radio-label" class="rating-label"> {{ rating.weight }} </label>
                                    <label> {{ rating.label }} </label>
                                    <p>
                                        {{ rating.rubric }}
                                    </p>
                                </div>
                            </template>
                        </div>

                        <template v-if="question.type == 'pointDistribution'">
                            <div>
                                <label>Requirements</label>
                                <p class="important">Must distribute points without repeating values</p>
                                <p class="important">Must distribute <b>ALL</b> points</p>
                                <p class="important">Must give at least one teammate a point above 10</p>
                                <p class="important">Must give at least one teammate a point below 10</p>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <div v-for="details in defaultSliderData" class="marginTop20">
                                    <div class="flexSpaceBetween">
                                        <div class="flexOnly align-items">
                                            <div class="profile_image">
                                                <div class="badge_profile img-circle">
                                                    <span>
                                                        {{ details.initial }}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="marginLeft10">
                                                {{ details.name }}
                                            </p>
                                        </div>

                                        <b class="color-purple fs-20px">
                                            {{ details.point }}
                                        </b>
                                    </div>

                                    <cl-slider v-model:custom-value="details.point" :show-number-input="false" :is-range-only="false" :set-max="'50'" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    props: ['question'],
    setup(props) {
        const defaultSliderData = ref([
            {
                name: 'James Yap',
                initial: 'JY',
                point: 10,
            },
            {
                name: 'Natalie Tye',
                initial: 'NT',
                point: 5,
            },
            {
                name: 'Trisha Wickersheim',
                initial: 'TW',
                point: 6,
            },
            {
                name: 'Joey King',
                initial: 'JK',
                point: 12,
            },
            {
                name: 'Joe Kim',
                initial: 'JK',
                point: 17,
            },
        ]);

        return {
            defaultSliderData,
        };
    },
};
</script>
