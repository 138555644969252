<template>
    <div>
        <template v-if="manage">
            <div class="manageStudents">
                <div class="flexSpaceBetween hidden-xs hidden-sm">
                    <div class="col-xs-4 buttonGroup">
                        <button class="btn btn-default mainBtn primaryHover" aria-label="Shuffle All Students" @click.prevent="shuffleStudents()">
                            <i class="fa fa-random marginRight8" aria-hidden="true" />Shuffle All Students
                        </button>
                        <button
                            class="btn btn-default mainBtn primaryHover"
                            aria-label="Shuffle Exclude Unassigned"
                            @click.prevent="shuffleStudents('exclude-unassigned')"
                        >
                            <i class="fa fa-random marginRight8" aria-hidden="true" />Shuffle Exclude Unassigned
                        </button>
                    </div>
                    <div class="col-xs-2 alignRight">
                        <button class="btn btn-danger mainBtn" aria-label="Reset" @click.prevent="reset">
                            <i class="fa fa-repeat marginRight8" aria-hidden="true" />Reset
                        </button>
                    </div>
                </div>
                <div class="hidden-md hidden-lg">
                    <button class="col-xs-12 btn btn-default mainBtn primaryHover" aria-label="Shuffle All Students" @click.prevent="shuffleStudents()">
                        <i class="fa fa-random marginRight8" aria-hidden="true" />Shuffle All Students
                    </button>
                    <button
                        class="col-xs-12 btn btn-default mainBtn primaryHover"
                        aria-label="Shuffle Exclude Unassigned"
                        @click.prevent="shuffleStudents('exclude-unassigned')"
                    >
                        <i class="fa fa-random marginRight8" aria-hidden="true" />Shuffle Exclude Unassigned
                    </button>
                    <button class="col-xs-12 btn btn-danger mainBtn" aria-label="Reset" @click.prevent="reset">
                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Reset
                    </button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="manageStudents">
                <div class="flexSpaceBetween align-items hidden-xs hidden-sm">
                    <div class="col-md-5">
                        <div class="form-group form-search has-feedback has-feedback-left width310px">
                            <input v-model="searchData.q" type="text" class="form-control" placeholder="Search..." />
                            <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                        </div>
                    </div>
                    <div class="col-md-7 flexSpaceBetween align-items">
                        <div class="tabButtons marginRight20">
                            <button :class="{ active: !teamDisplay }" aria-label="Individual View" @click.prevent="teamDisplay = false">Individual</button>
                            <button :class="{ active: teamDisplay }" aria-label="Team View" @click.prevent="teamDisplay = true">Team</button>
                        </div>
                        <!-- <div class="buttons">
		    				<div class="button mainBtn" :class="{'active':!studentsDetailedView}" @click.prevent="studentsDetailedView=false">
		    					Basic 
		    				</div>
		    				<div class="button mainBtn" :class="{'active':studentsDetailedView}"@click.prevent="studentsDetailedView=true">
		    					Detailed
		    				</div>
		    			</div> -->
                        <div class="flexSpaceBetween align-items">
                            <label for="viewStudentDetails" class="control-label fontBlack marginBottom0 marginRight10"> View students in detail </label>
                            <label class="switch">
                                <input
                                    id="viewStudentDetails"
                                    v-model="studentsDetailedView"
                                    type="checkbox"
                                    @change="$emit('studentsDetailedView', studentsDetailedView)"
                                />
                                <span class="slider round" />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="hidden-md hidden-lg">
                    <div class="col-xs-12">
                        <div class="form-group form-search has-feedback has-feedback-left">
                            <input v-model="searchData.q" type="text" class="form-control" placeholder="Search..." />
                            <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="tabButtons">
                            <button :class="{ active: !teamDisplay }" aria-label="Individual View" @click.prevent="teamDisplay = false">Individual</button>
                            <button :class="{ active: teamDisplay }" aria-label="Team View" @click.prevent="teamDisplay = true">Team</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-if="displayReloadAlert" role="alert" aria-live="assertive" aria-atomic="true" class="alert alert-warning marginBottom20 marginTop20">
            <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true" />
            <div style="font-size: 18px">
                This page will automatically refresh once done.
                <a
                    href="#"
                    @click="
                        displayReloadAlert = false;
                        reset(false);
                    "
                >
                    Manually reload here.
                </a>
            </div>
        </div>
        <!--<div class="alert alert-warning marginBottom20" v-if="manage&&unassigned_students.length!=0">
             <i class="fas fa-exclamation-triangle"></i>&nbsp;
            <div>
               Please make sure all students are assigned to a team
            </div>
        </div>-->
        <div v-if="courseObj.students.length == 0" class="marginTop20 lineHeight1_2">
            No students yet,
            <router-link
                :to="{
                    name: 'courses.manage.students.create',
                    params: { course_id: course.uuid },
                }"
                tag="a"
                class="link"
            >
                add student here
            </router-link>
        </div>
        <div v-else-if="!(!manage && unassigned_students.length == 0)" class="studentList">
            <div class="header textAlignLeft">
                <template v-if="manage">
                    <div class="hidden-xs hidden-sm buttonGroupInline align-items">
                        <!-- <div class="col-xs-4 random"  @click.prevent="shuffleStudents('unassigned')">
							<i class="fa fa-random"></i>&nbsp;Assign to Existing Teams
						</div> -->
                        <button
                            class="btn btn-default mainBtn primaryHover marginRight10"
                            aria-label="Assign to Existing Teams"
                            @click.prevent="shuffleStudents('unassigned')"
                        >
                            <i class="fa fa-random marginRight8" aria-hidden="true" />Assign to Existing Teams
                        </button>
                        <div>unassigned students ({{ unassigned_students.length }})</div>
                    </div>
                    <div class="hidden-md hidden-lg flexSpaceBetween">
                        <div>unassigned students ({{ unassigned_students.length }})</div>
                        <div>
                            <i class="fa fa-caret-up" aria-hidden="true" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="hidden-xs hidden-sm">unassigned students ({{ unassigned_students.length }})</div>
                    <div class="hidden-md hidden-lg flexSpaceBetween">
                        <div>unassigned students ({{ unassigned_students.length }})</div>
                        <div>
                            <i class="fa fa-caret-up" aria-hidden="true" />
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-xs-12" @dragover="allowDropToUnassigned" @drop="dropToUnassigned($event)">
                <template v-if="!studentsDetailedView && !(mobile && manage)">
                    <div class="content flex hidden-xs hidden-sm">
                        <template v-if="unassigned_students.length == 0">
                            All students have been assigned to respective section and teams<br />
                            You may drag students here to remove them from their team
                        </template>
                        <template v-for="(student, idx) in unassigned_students" v-else>
                            <div
                                v-if="!isStudentDeleted(student)"
                                class="unassignedBtns flexSpaceBetween"
                                aria-label="Open Student Info Modal"
                                @click.prevent="openStudentInfoModal(student)"
                            >
                                <button
                                    v-if="manage"
                                    class="btn btn-default courseViewStudentsButton--notDetails-edit with-accommodations-icons col-xs-12 primaryHover flex align-items justifyLeft padding5"
                                    draggable="true"
                                    :aria-label="student.displayName"
                                    @dragstart="
                                        dragStudent($event, student, {
                                            from: 'unassigned',
                                            unassigned_idx: idx,
                                        })
                                    "
                                >
                                    <div class="flex align-items marginRight8">
                                        <i class="fas fa-ellipsis-v" :class="{ marginRight8: student.hasAccommodations }" aria-hidden="true" />
                                        <div
                                            v-if="student.hasAccommodations"
                                            v-tooltip="{
                                                content: 'This student has preset accommodations. You may edit this in Accommodations section.',
                                            }"
                                            class="width18px"
                                        >
                                            <i class="fas fa-universal-access" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div class="flexOnly justifyCenter align-items">
                                        <i v-if="student.isSuspended" class="fa fa-circle suspendCircle marginRight8" aria-label="supsended" />
                                        {{ student.displayName }}
                                    </div>
                                </button>
                                <button
                                    v-else
                                    class="btn btn-default width100 primaryHover flex align-items justifyLeft with-accommodations-icons courseViewStudentsButton--notDetails-view"
                                    @click.prevent="openStudentInfoModal(student)"
                                >
                                    <div class="width18px marginRight8">
                                        <i
                                            v-if="student.hasAccommodations"
                                            v-tooltip="{
                                                content: 'This student has preset accommodations. You may edit this in Accommodations section.',
                                            }"
                                            class="fas fa-universal-access"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div class="flexOnly justifyCenter align-items">
                                        <i v-if="student.isSuspended" class="fa fa-circle suspendCircle marginRight8" aria-label="supsended" />
                                        {{ student.displayName }}
                                    </div>
                                </button>
                            </div>
                        </template>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <template v-if="unassigned_students.length == 0">
                            All students have been assigned to respective section and teams<br />
                            You may drag students here to remove them from their team
                        </template>
                        <template v-for="(student, idx) in unassigned_students" v-else>
                            <div v-if="!isStudentDeleted(student)" class="col-xs-12 flexSpaceBetween" @click.prevent="openStudentInfoModal(student)">
                                <template v-if="manage">
                                    <button class="btn flexSpaceBetween" draggable="true" :aria-label="student.displayName">
                                        <div>
                                            <template v-if="student.isSuspended">
                                                <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />
                                            </template>
                                            {{ student.displayName }}
                                        </div>
                                        <div>
                                            <i class="fa fa-chevron-right" aria-hidden="true" />
                                        </div>
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn flexSpaceBetween" :aria-label="student.displayName">
                                        <div>
                                            <template v-if="student.isSuspended">
                                                <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />
                                            </template>
                                            {{ student.displayName }}
                                        </div>
                                        <div>
                                            <i class="fa fa-chevron-right" aria-hidden="true" />
                                        </div>
                                    </button>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <template v-if="unassigned_students.length == 0">
                        <div class="content">
                            All students have been assigned to respective section and teams<br />
                            You may drag students here to remove them from their team
                        </div>
                    </template>
                    <template v-else>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr class="table-header-row">
                                        <th class="width160px maxWidth160px">NAME</th>
                                        <th class="hidden-xs hidden-sm width200px maxWidth200px">EMAIL</th>
                                        <th class="hidden-xs hidden-sm width200px maxWidth200px">STUDENT ID</th>
                                        <th class="width113px">SECTION</th>
                                        <th class="width113px">TEAM</th>
                                        <th class="hidden-xs hidden-sm width200px maxWidth200px">ACTIVATED ON</th>
                                        <th v-if="hasPaidHeader" class="hidden-xs hidden-sm">PAID?</th>
                                        <th class="width178px" />
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(student, idx) in unassigned_students">
                                        <tr v-if="!isStudentDeleted(student)">
                                            <td class="width160px maxWidth160px">
                                                <div class="flexSpaceBetween">
                                                    <div class="flexOnly">
                                                        <div class="width10px marginRight8">
                                                            <i v-if="student.isSuspended" class="fa fa-circle suspendCircle" aria-hidden="true" />
                                                        </div>
                                                        <div class="col-xs-10 break-word">
                                                            {{ student.displayName }}
                                                        </div>
                                                    </div>

                                                    <div class="width18px marginLeft8">
                                                        <div
                                                            v-if="student.hasAccommodations"
                                                            v-tooltip="{
                                                                content: 'This student has preset accommodations. You may edit this in Accommodations section.',
                                                            }"
                                                        >
                                                            <i class="fas fa-universal-access color-purple" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="hidden-xs hidden-sm break-word width200px maxWidth200px">
                                                {{ student.email ? student.email : '-' }}
                                            </td>
                                            <td class="hidden-xs hidden-sm break-word width200px maxWidth200px">
                                                {{ student.studentID }}
                                            </td>
                                            <td>
                                                <template v-if="manage">
                                                    <div class="form-group displayContent">
                                                        <span>
                                                            <div class="form-dropdown digit marginLeft0">
                                                                <select
                                                                    v-model="student._tempSectionUuid"
                                                                    class="form-control"
                                                                    :aria-label="student._tempSectionUuid"
                                                                >
                                                                    <option value="">-</option>
                                                                    <template v-for="(a, a_idx) in sections">
                                                                        <option :value="a.uuid ? a.uuid : a._temp_id">
                                                                            {{ a_idx + 1 }}
                                                                        </option>
                                                                    </template>
                                                                </select>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </template>
                                                <template v-else> - </template>
                                            </td>
                                            <td>
                                                <template v-if="manage">
                                                    <div class="form-group displayContent">
                                                        <span>
                                                            <div class="form-dropdown digit marginLeft0">
                                                                <select
                                                                    v-model="student._tempTeamUuid"
                                                                    class="form-control"
                                                                    :aria-label="student._tempTeamUuid"
                                                                    @change="
                                                                        dropSelect(student._tempSectionUuid, student._tempTeamUuid, {
                                                                            from: 'unassigned',
                                                                            unassigned_idx: idx,
                                                                        })
                                                                    "
                                                                >
                                                                    <template v-if="student._tempSectionUuid != null">
                                                                        <option value="">-</option>
                                                                        <template v-for="(a, a_idx) in sections">
                                                                            <template
                                                                                v-if="
                                                                                    a.uuid == student._tempSectionUuid || a._temp_id == student._tempSectionUuid
                                                                                "
                                                                            >
                                                                                <template v-for="(b, b_idx) in a.teams">
                                                                                    <option :value="b.uuid ? b.uuid : b._temp_id">
                                                                                        {{ b_idx + 1 }}
                                                                                    </option>
                                                                                </template>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </select>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </template>
                                                <template v-else> - </template>
                                            </td>
                                            <td class="hidden-xs hidden-sm width200px maxWidth200px">
                                                <template v-if="student.dateActivated">
                                                    <i class="fa fa-circle dateCircle marginRight8" aria-hidden="true" />
                                                    {{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else>
                                                    <i v-if="student.isSuspended" class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />-
                                                </template>
                                            </td>
                                            <td v-if="hasPaidHeader" class="hidden-xs hidden-sm">
                                                <span v-if="student.status == 'Active (Paid)'" class="darkGreen">Paid</span>
                                                <span v-else class="important">Not Paid</span>
                                            </td>
                                            <td>
                                                <div class="hidden-xs hidden-sm buttonGroupInline">
                                                    <button
                                                        class="btn btn-primary subBtn"
                                                        arial-label="View Student Info"
                                                        @click.prevent="openStudentInfoModal(student)"
                                                    >
                                                        View
                                                    </button>
                                                    <button
                                                        v-if="manage"
                                                        class="btn btn-danger subBtn"
                                                        arial-label="Remove Student"
                                                        @click.prevent="
                                                            removeStudentFromCourse(student, 'unassigned_students', {
                                                                unassigned_idx: idx,
                                                            })
                                                        "
                                                    >
                                                        <i class="fa fa-trash" aria-hidden="true" />
                                                    </button>
                                                </div>
                                                <div class="hidden-md hidden-lg buttonGroupInline">
                                                    <button
                                                        class="btn btn-primary subBtn"
                                                        arial-label="View Student Info"
                                                        @click.prevent="openStudentInfoModal(student)"
                                                    >
                                                        <i class="fa fa-play" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="manage"
                                                        class="btn btn-danger subBtn"
                                                        arial-label="Remove Student"
                                                        @click.prevent="
                                                            removeStudentFromCourse(student, 'unassigned_students', {
                                                                unassigned_idx: idx,
                                                            })
                                                        "
                                                    >
                                                        <i class="fa fa-trash" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <template v-for="(section, section_idx) in sections" v-if="!isSectionDeleted(section)">
            <div class="studentList" :class="{ marginTop20: courseObj.students.length == 0 }">
                <div class="header">
                    <div class="hidden-xs hidden-sm flex align-items">
                        <!-- <div class="col-xs-4 random" v-if="manage" @click.prevent="shuffleStudents('section',section_idx)">
							<i class="fa fa-random"></i>&nbsp;Shuffle This Section
						</div> -->
                        <div v-if="manage" class="col-xs-4 textAlignLeft">
                            <button
                                class="btn btn-default mainBtn primaryHover"
                                aria-label="Shuffle This Section"
                                @click.prevent="shuffleStudents('section', section_idx)"
                            >
                                <i class="fa fa-random marginRight8" aria-hidden="true" />Shuffle This Section
                            </button>
                        </div>
                        <div class="col-md-4" :class="{ 'col-md-offset-4': !manage }">
                            <template v-if="!section._showEditName">
                                Section {{ section.name }} ({{ countStudentsInSection(section) }} student{{ countStudentsInSection(section) > 1 ? 's' : '' }})
                                <a
                                    v-if="manage && auth.user().account.version == 'full'"
                                    @click.prevent="
                                        section._showEditName = true;
                                        sections.splice();
                                    "
                                >
                                    <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                </a>
                            </template>
                            <template v-else>
                                <input
                                    class="form-control"
                                    autofocus
                                    :value="sections[section_idx].name"
                                    @blur="updateName($event, sections[section_idx])"
                                    @keypress="$event.which == 13 ? updateName($event, sections[section_idx]) : ''"
                                />
                            </template>
                        </div>
                        <div v-if="manage && auth.user().account.version == 'full'" class="col-md-4 alignRight capitalize">
                            <button
                                class="btn btn-danger subBtn"
                                :aria-label="'Remove Section ' + (section_idx + 1)"
                                @click.prevent="removeSection(section_idx)"
                            >
                                <i class="fa fa-trash" aria-hidden="true" />
                            </button>
                            <!--<template v-if="section._showDetails">
								<div @click.prevent="section._showDetails = false;sections.splice()">
									Less&nbsp;<i class="fa fa-caret-up"></i>
								</div>
							</template>
							<template v-else>
								<div @click.prevent="section._showDetails = true;sections.splice()">
									More&nbsp;<i class="fa fa-caret-down"></i>
								</div>
							</template>-->
                        </div>
                    </div>
                    <div class="hidden-md hidden-lg flexSpaceBetween">
                        <div>
                            <template v-if="!section._showEditName">
                                Section {{ section.name }} ({{ countStudentsInSection(section) }} student{{ countStudentsInSection(section) > 1 ? 's' : '' }})
                                <a
                                    v-if="manage && auth.user().account.version == 'full'"
                                    @click.prevent="
                                        section._showEditName = true;
                                        sections.splice();
                                    "
                                >
                                    <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                </a>
                            </template>
                            <template v-else>
                                <input
                                    class="form-control"
                                    autofocus
                                    :value="sections[section_idx].name"
                                    @blur="updateName($event, sections[section_idx])"
                                    @keypress="$event.which == 13 ? updateName($event, sections[section_idx]) : ''"
                                />
                            </template>
                        </div>
                        <div v-if="manage && auth.user().account.version == 'full'">
                            <button
                                class="btn btn-danger subBtn"
                                :aria-label="'Remove Section ' + (section_idx + 1)"
                                @click.prevent="removeSection(section_idx)"
                            >
                                <i class="fa fa-trash" aria-hidden="true" />
                            </button>
                        </div>
                        <!-- <div>
							<div v-if="section._showDetails" @click.prevent="section._showDetails = false;sections.splice()">
								<i class="fa fa-caret-up"></i>
							</div>
							<div v-else @click.prevent="section._showDetails = true;sections.splice()">
								<i class="fa fa-caret-down"></i>
							</div>
						</div> -->
                    </div>
                </div>
                <div v-show="section._showDetails" class="col-xs-12">
                    <template v-if="!studentsDetailedView && !(mobile && manage)">
                        <div class="content col-xs-12 flex hidden-xs hidden-sm" @dragover="allowDropToSection" @drop="dropToSection($event, section_idx)">
                            <template v-if="section.teams.length == 0 && !manage"> NO TEAM </template>
                            <template v-for="(team, team_idx) in section.teams" v-else>
                                <div v-if="!isTeamDeleted(team)" class="sectionDiv">
                                    <div class="team" @dragover="allowDropToTeam" @drop="dropToTeam($event, section_idx, team_idx)">
                                        <div
                                            v-if="teamDisplay || manage"
                                            class="header"
                                            :draggable="manage"
                                            @dragstart="
                                                dragTeam($event, team, {
                                                    section_idx: section_idx,
                                                    team_idx: team_idx,
                                                })
                                            "
                                        >
                                            <template v-if="!team._showEditName">
                                                <div v-if="manage" class="flexSpaceBetween align-items">
                                                    <div class="col-xs-1 dots">
                                                        <i class="fas fa-ellipsis-v" aria-hidden="true" />
                                                    </div>
                                                    <div>
                                                        {{ team.name }}
                                                        <a
                                                            @click.prevent="
                                                                team._showEditName = true;
                                                                sections.splice();
                                                            "
                                                        >
                                                            <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                                        </a>
                                                    </div>
                                                    <div class="width36px">
                                                        <button
                                                            class="btn btn-default subBtn"
                                                            :aria-label="'Remove ' + team.name + ' in Section ' + (section_idx + 1)"
                                                            @click="removeTeam(section_idx, team_idx)"
                                                        >
                                                            <i class="fa fa-trash" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <div>
                                                        {{ team.name }}
                                                    </div>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <input
                                                    class="form-control"
                                                    autofocus
                                                    :value="sections[section_idx].teams[team_idx].name"
                                                    @blur="updateName($event, sections[section_idx].teams[team_idx])"
                                                    @keypress="$event.which == 13 ? updateName($event, sections[section_idx].teams[team_idx]) : ''"
                                                />
                                            </template>
                                        </div>

                                        <template v-if="team.students.length > 0">
                                            <div class="content">
                                                <template v-for="(student, student_idx) in team.students">
                                                    <button
                                                        v-if="manage"
                                                        class="btn btn-default courseViewStudentsButton--notDetails-edit with-accommodations-icons col-xs-12 primaryHover flex align-items justifyLeft padding5"
                                                        draggable="true"
                                                        :aria-label="'View ' + student.displayName + ' Info'"
                                                        @dragstart="
                                                            dragStudent($event, student, {
                                                                from: 'team',
                                                                section_idx: section_idx,
                                                                team_idx: team_idx,
                                                                student_idx: student_idx,
                                                            })
                                                        "
                                                        @click.prevent="openStudentInfoModal(student)"
                                                    >
                                                        <div class="flex align-items marginRight8">
                                                            <i
                                                                class="fas fa-ellipsis-v"
                                                                :class="{ marginRight8: student.hasAccommodations }"
                                                                aria-hidden="true"
                                                            />
                                                            <div
                                                                v-if="student.hasAccommodations"
                                                                v-tooltip="{
                                                                    content:
                                                                        'This student has preset accommodations. You may edit this in Accommodations section.',
                                                                }"
                                                                class="width18px"
                                                            >
                                                                <i class="fas fa-universal-access" aria-hidden="true" />
                                                            </div>
                                                        </div>
                                                        <div class="flexOnly justifyCenter align-items">
                                                            <i
                                                                v-if="student.isSuspended"
                                                                class="fa fa-circle suspendCircle marginRight8"
                                                                aria-label="supsended"
                                                            />
                                                            {{ student.displayName }}
                                                        </div>
                                                    </button>
                                                    <button
                                                        v-else
                                                        class="btn btn-default width100 primaryHover flex align-items justifyLeft padding5 with-accommodations-icons courseViewStudentsButton--notDetails-view"
                                                        @click.prevent="openStudentInfoModal(student)"
                                                    >
                                                        <div class="width18px marginRight8">
                                                            <i
                                                                v-if="student.hasAccommodations"
                                                                v-tooltip="{
                                                                    content:
                                                                        'This student has preset accommodations. You may edit this in Accommodations section.',
                                                                }"
                                                                class="fas fa-universal-access"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                        <div class="flexOnly justifyCenter align-items">
                                                            <i
                                                                v-if="student.isSuspended"
                                                                class="fa fa-circle suspendCircle marginRight8"
                                                                aria-label="supsended"
                                                            />
                                                            {{ student.displayName }}
                                                        </div>
                                                    </button>
                                                </template>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-xs-12 content flex justifyCenter">
                                                <div v-if="manage" class="col-xs-12 col-md-10 greyText textAlignCenter">
                                                    Drag and drop students here to add into this team
                                                </div>
                                                <div v-else class="col-xs-12 col-md-10 greyText textAlignCenter">NO STUDENTS</div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <div v-if="manage" class="sectionDiv">
                                <button class="btn btn-default mainBtn col-xs-12 primaryHover" aria-label="Add New Team" @click.prevent="addTeam(section_idx)">
                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />
                                    Add New Team
                                </button>
                            </div>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <template v-if="section.teams.length == 0 && !manage">
                                <div class="content">NO TEAM</div>
                            </template>
                            <template v-for="(team, team_idx) in section.teams">
                                <div v-if="!isTeamDeleted(team)" class="col-xs-12">
                                    <div class="team" @dragover="allowDropToTeam" @drop="dropToTeam($event, section_idx, team_idx)">
                                        <div v-if="teamDisplay || manage" class="header">
                                            <template v-if="!team._showEditName">
                                                {{ team.name }}
                                                <a
                                                    v-if="manage"
                                                    @click.prevent="
                                                        team._showEditName = true;
                                                        sections.splice();
                                                    "
                                                >
                                                    <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                                </a>
                                            </template>
                                            <template v-else>
                                                <input
                                                    class="form-control"
                                                    autofocus
                                                    :value="sections[section_idx].teams[team_idx].name"
                                                    @blur="updateName($event, sections[section_idx].teams[team_idx])"
                                                    @keypress="$event.which == 13 ? updateName($event, sections[section_idx].teams[team_idx]) : ''"
                                                />
                                            </template>
                                        </div>
                                        <div class="content">
                                            <template v-if="team.students.length == 0">
                                                <button class="btn flexSpaceBetween">NO STUDENTS</button>
                                            </template>
                                            <template v-for="(student, student_idx) in team.students" v-else>
                                                <div
                                                    class="col-xs-12 flexSpaceBetween"
                                                    :aria-label="'View ' + student.name + ' Info'"
                                                    @click.prevent="openStudentInfoModal(student)"
                                                >
                                                    <template v-if="manage">
                                                        <button class="btn flexSpaceBetween" draggable="true">
                                                            <div>
                                                                <template v-if="student.isSuspended">
                                                                    <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />
                                                                </template>
                                                                {{ student.name }}
                                                            </div>
                                                            <div>
                                                                <i class="fa fa-chevron-right" aria-hidden="true" />
                                                            </div>
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn flexSpaceBetween">
                                                            <div>
                                                                <template v-if="student.isSuspended">
                                                                    <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" /> </template
                                                                >{{ student.name }}
                                                            </div>
                                                            <div>
                                                                <i class="fa fa-chevron-right" aria-hidden="true" />
                                                            </div>
                                                        </button>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="teamDisplay || manage">
                            <template v-if="section.teams.length == 0 && !manage">
                                <div class="content">NO TEAM</div>
                            </template>
                            <template v-for="(team, team_idx) in section.teams" v-else>
                                <div
                                    v-if="!isTeamDeleted(team)"
                                    class="table-responsive"
                                    @dragover="allowDropToTeam"
                                    @drop="dropToTeam($event, section_idx, team_idx)"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr class="teamNameHeader textAlignCenter">
                                                <th v-if="teamDisplay || manage" colspan="12">
                                                    <div class="hidden-xs hidden-sm flex textAlignCenter fontSize14">
                                                        <div class="col-md-4" />
                                                        <template v-if="!team._showEditName">
                                                            <div class="col-md-4">
                                                                {{ team.name }}
                                                                <a
                                                                    v-if="manage"
                                                                    @click.prevent="
                                                                        team._showEditName = true;
                                                                        sections.splice();
                                                                    "
                                                                >
                                                                    <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                                                </a>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="col-md-4">
                                                                <input
                                                                    class="form-control"
                                                                    autofocus
                                                                    :value="sections[section_idx].teams[team_idx].name"
                                                                    @blur="updateName($event, sections[section_idx].teams[team_idx])"
                                                                    @keypress="
                                                                        $event.which == 13 ? updateName($event, sections[section_idx].teams[team_idx]) : ''
                                                                    "
                                                                />
                                                            </div>
                                                        </template>
                                                        <!-- <div class="col-md-4 alignRight capitalize">
															<template v-if="team._showDetails">
																<div @click.prevent="team._showDetails = false;sections.splice()">
																	Less&nbsp;<i class="fa fa-caret-up"></i>
																</div>
															</template>
															<template v-else>
																<div @click.prevent="team._showDetails = true;sections.splice()">
																	More&nbsp;<i class="fa fa-caret-down"></i>
																</div>
															</template>
														</div> -->
                                                    </div>
                                                    <div class="hidden-md hidden-lg flexSpaceBetween textAlignCenter fontSize14">
                                                        <template v-if="!team._showEditName">
                                                            <div>
                                                                {{ team.name }}
                                                                <a
                                                                    v-if="manage"
                                                                    @click.prevent="
                                                                        team._showEditName = true;
                                                                        sections.splice();
                                                                    "
                                                                >
                                                                    <i class="fa fa-pencil-square-o marginLeft8" aria-hidden="true" />
                                                                </a>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div>
                                                                <input
                                                                    class="form-control"
                                                                    autofocus
                                                                    :value="sections[section_idx].teams[team_idx].name"
                                                                    @blur="updateName($event, sections[section_idx].teams[team_idx])"
                                                                    @keypress="
                                                                        $event.which == 13 ? updateName($event, sections[section_idx].teams[team_idx]) : ''
                                                                    "
                                                                />
                                                            </div>
                                                        </template>
                                                        <!-- <div>
															<template v-if="team._showDetails">
																<i class="fa fa-caret-up" @click.prevent="team._showDetails = false;sections.splice()"></i>
															</template>
															<template v-else>
																<i class="fa fa-caret-down" @click.prevent="team._showDetails = true;sections.splice()"></i>
															</template>
														</div> -->
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr v-show="team._showDetails" v-if="team.students.length > 0" class="table-header-row">
                                                <th v-if="manage" />
                                                <th class="width160px maxWidth160px">NAME</th>
                                                <th class="hidden-xs hidden-sm width200px maxWidth200px">EMAIL</th>
                                                <th class="hidden-xs hidden-sm width200px maxWidth200px">STUDENT ID</th>
                                                <th class="width113px">SECTION</th>
                                                <th class="width113px">TEAM</th>
                                                <th class="hidden-xs hidden-sm width200px">ACTIVATED ON</th>
                                                <th v-if="hasPaidHeader" class="hidden-xs hidden-sm">PAID?</th>
                                                <th class="width178px" />
                                            </tr>
                                        </thead>
                                        <tbody v-show="team._showDetails">
                                            <template v-if="team.students.length == 0">
                                                <div
                                                    class="content"
                                                    :class="{
                                                        paddingBottom0: manage,
                                                    }"
                                                >
                                                    NO STUDENTS
                                                </div>
                                            </template>
                                            <template v-else>
                                                <template v-for="(student, student_idx) in team.students">
                                                    <tr>
                                                        <td v-if="manage">
                                                            <div class="checkboxField">
                                                                <input v-model="disenrolled_students" type="checkbox" :value="student.userUuid" />
                                                            </div>
                                                        </td>
                                                        <td class="width160px maxWidth160px">
                                                            <div class="flexSpaceBetween">
                                                                <div class="flexOnly">
                                                                    <div class="width10px marginRight8">
                                                                        <i v-if="student.isSuspended" class="fa fa-circle suspendCircle" aria-hidden="true" />
                                                                    </div>
                                                                    <div class="col-xs-10 break-word">
                                                                        {{ student.displayName }}
                                                                    </div>
                                                                </div>

                                                                <div class="width18px marginLeft8">
                                                                    <div
                                                                        v-if="student.hasAccommodations"
                                                                        v-tooltip="{
                                                                            content:
                                                                                'This student has preset accommodations. You may edit this in Accommodations section.',
                                                                        }"
                                                                    >
                                                                        <i class="fas fa-universal-access color-purple" aria-hidden="true" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="hidden-xs hidden-sm break-word width200px maxWidth200px">
                                                            {{ student.email ? student.email : '-' }}
                                                        </td>
                                                        <td class="hidden-xs hidden-sm break-word width200px maxWidth200px">
                                                            {{ student.studentID }}
                                                        </td>
                                                        <td>
                                                            <template v-if="manage">
                                                                <div class="form-group displayContent">
                                                                    <span>
                                                                        <div class="form-dropdown digit marginLeft0">
                                                                            <select
                                                                                v-model="student._tempSectionUuid"
                                                                                class="form-control"
                                                                                :aria-label="student._tempSectionUuid"
                                                                                @change="sections.splice()"
                                                                            >
                                                                                <option value="">-</option>
                                                                                <template v-for="(a, a_idx) in sections">
                                                                                    <option :value="a.uuid ? a.uuid : a._temp_id">
                                                                                        {{ a_idx + 1 }}
                                                                                    </option>
                                                                                </template>
                                                                            </select>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                {{ section_idx + 1 }}
                                                            </template>
                                                        </td>
                                                        <td>
                                                            <template v-if="manage">
                                                                <div class="form-group displayContent">
                                                                    <span>
                                                                        <div class="form-dropdown digit marginLeft0">
                                                                            <select
                                                                                v-model="student._tempTeamUuid"
                                                                                class="form-control"
                                                                                :aria-label="student._tempTeamUuid"
                                                                                @change="
                                                                                    dropSelect(student._tempSectionUuid, student._tempTeamUuid, {
                                                                                        from: 'team',
                                                                                        section_idx: section_idx,
                                                                                        team_idx: team_idx,
                                                                                        student_idx: student_idx,
                                                                                    })
                                                                                "
                                                                            >
                                                                                <template v-if="student._tempSectionUuid != null">
                                                                                    <option value="">-</option>
                                                                                    <template v-for="(a, a_idx) in sections">
                                                                                        <template
                                                                                            v-if="
                                                                                                a.uuid == student._tempSectionUuid ||
                                                                                                a._temp_id == student._tempSectionUuid
                                                                                            "
                                                                                        >
                                                                                            <template v-for="(b, b_idx) in a.teams">
                                                                                                <option :value="b.uuid ? b.uuid : b._temp_id">
                                                                                                    {{ b_idx + 1 }}
                                                                                                </option>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                            </select>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                {{ team_idx + 1 }}
                                                            </template>
                                                        </td>
                                                        <td class="hidden-xs hidden-sm width200px maxWidth200px">
                                                            <div class="flexOnly">
                                                                <template v-if="student.dateActivated">
                                                                    <i class="fa fa-circle dateCircle marginTop5 marginRight8" />{{
                                                                        convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date
                                                                    }}
                                                                    ({{
                                                                        convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone
                                                                    }})
                                                                </template>
                                                                <template v-else>
                                                                    <i v-if="student.isSuspended" class="fa fa-circle suspendCircle marginTop5 marginRight8" />-
                                                                </template>
                                                            </div>
                                                        </td>
                                                        <td v-if="hasPaidHeader" class="hidden-xs hidden-sm">
                                                            <span v-if="student.status == 'Active (Paid)'" class="darkGreen">Paid</span>
                                                            <span v-else class="important">Not Paid</span>
                                                        </td>
                                                        <td>
                                                            <div class="hidden-xs hidden-sm buttonGroupInline">
                                                                <button
                                                                    class="btn btn-primary subBtn"
                                                                    aria-label="View Student"
                                                                    @click.prevent="openStudentInfoModal(student)"
                                                                >
                                                                    View
                                                                </button>
                                                                <router-link
                                                                    v-slot="{ navigate }"
                                                                    :to="{
                                                                        name: 'courses.manage.students.edit',
                                                                        params: {
                                                                            course_id: course.uuid,
                                                                            id: student.userUuid,
                                                                        },
                                                                    }"
                                                                    custom
                                                                >
                                                                    <button class="btn btn-default" @click="navigate">
                                                                        <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                                                    </button>
                                                                </router-link>
                                                                <button
                                                                    v-if="manage"
                                                                    class="btn btn-danger subBtn"
                                                                    aria-label="Remove Student"
                                                                    @click.prevent="
                                                                        dropSelect(null, null, {
                                                                            from: 'team',
                                                                            section_idx: section_idx,
                                                                            team_idx: team_idx,
                                                                            student_idx: student_idx,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                            <div class="hidden-md hidden-lg buttonGroupInline">
                                                                <button
                                                                    class="btn btn-primary subBtn"
                                                                    aria-label="View Student"
                                                                    @click.prevent="openStudentInfoModal(student)"
                                                                >
                                                                    <i class="fa fa-play" aria-hidden="true" />
                                                                </button>
                                                                <router-link
                                                                    v-slot="{ navigate }"
                                                                    :to="{
                                                                        name: 'courses.manage.students.edit',
                                                                        params: {
                                                                            course_id: course.uuid,
                                                                            id: student.userUuid,
                                                                        },
                                                                    }"
                                                                    custom
                                                                >
                                                                    <button class="btn btn-default" @click="navigate">
                                                                        <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                                                    </button>
                                                                </router-link>
                                                                <button
                                                                    v-if="manage"
                                                                    class="btn btn-danger subBtn"
                                                                    aria-label="Remove Student"
                                                                    @click.prevent="
                                                                        dropSelect(null, null, {
                                                                            from: 'team',
                                                                            section_idx: section_idx,
                                                                            team_idx: team_idx,
                                                                            student_idx: student_idx,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="section.teams.length == 0">
                                <div class="content">NO STUDENTS</div>
                            </template>
                            <div v-else class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-header-row">
                                            <th>NAME</th>
                                            <th class="hidden-xs hidden-sm">EMAIL</th>
                                            <th class="hidden-xs hidden-sm">STUDENT ID</th>
                                            <th>SECTION</th>
                                            <th>TEAM</th>
                                            <th class="hidden-xs hidden-sm">ACTIVATED ON</th>
                                            <th v-if="hasPaidHeader" class="hidden-xs hidden-sm">PAID?</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(team, team_idx) in section.teams">
                                            <template v-if="team.students.length > 0">
                                                <template v-for="(student, student_idx) in team.students">
                                                    <tr v-if="!searchData.isSearching || (searchData.isSearching && student._searchDisplay)">
                                                        <td class="break-word" style="width: 133px">
                                                            <template v-if="student.isSuspended">
                                                                <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />
                                                            </template>
                                                            {{ student.displayName }}
                                                        </td>
                                                        <td class="hidden-xs hidden-sm" style="width: 197px">
                                                            {{ student.email ? student.email : '-' }}
                                                        </td>
                                                        <td class="hidden-xs hidden-sm">
                                                            {{ student.studentID }}
                                                        </td>
                                                        <td>
                                                            <template v-if="manage">
                                                                <div class="form-group displayContent">
                                                                    <span>
                                                                        <div class="form-dropdown digit marginLeft0">
                                                                            <select
                                                                                v-model="student._tempSectionUuid"
                                                                                class="form-control"
                                                                                :aria-label="student._tempSectionUuid"
                                                                                @change="sections.splice()"
                                                                            >
                                                                                <option value="">-</option>
                                                                                <template v-for="(a, a_idx) in sections">
                                                                                    <option :value="a.uuid ? a.uuid : a._temp_id">
                                                                                        {{ a_idx + 1 }}
                                                                                    </option>
                                                                                </template>
                                                                            </select>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                {{ section_idx + 1 }}
                                                            </template>
                                                        </td>
                                                        <td>
                                                            <template v-if="manage">
                                                                <div class="form-group displayContent">
                                                                    <span>
                                                                        <div class="form-dropdown digit marginLeft0">
                                                                            <select
                                                                                v-model="student._tempTeamUuid"
                                                                                class="form-control"
                                                                                :aria-label="student._tempTeamUuid"
                                                                                @change="
                                                                                    dropSelect(student._tempSectionUuid, student._tempTeamUuid, {
                                                                                        from: 'team',
                                                                                        section_idx: section_idx,
                                                                                        team_idx: team_idx,
                                                                                        student_idx: student_idx,
                                                                                    })
                                                                                "
                                                                            >
                                                                                <template v-if="student._tempSectionUuid != null">
                                                                                    <option value="">-</option>
                                                                                    <template v-for="(a, a_idx) in sections">
                                                                                        <template
                                                                                            v-if="
                                                                                                a.uuid == student._tempSectionUuid ||
                                                                                                a._temp_id == student._tempSectionUuid
                                                                                            "
                                                                                        >
                                                                                            <template v-for="(b, b_idx) in a.teams">
                                                                                                <option :value="b.uuid ? b.uuid : b._temp_id">
                                                                                                    {{ b_idx + 1 }}
                                                                                                </option>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                            </select>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                {{ team_idx + 1 }}
                                                            </template>
                                                        </td>
                                                        <td class="hidden-xs hidden-sm">
                                                            <template v-if="student.dateActivated">
                                                                <i class="fa fa-circle dateCircle marginRight8" aria-hidden="true" />
                                                                {{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                                ({{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                            </template>
                                                            <template v-else>
                                                                <i
                                                                    v-if="student.isSuspended"
                                                                    class="fa fa-circle suspendCircle marginRight8"
                                                                    aria-hidden="true"
                                                                />-
                                                            </template>
                                                        </td>
                                                        <td v-if="hasPaidHeader" class="hidden-xs hidden-sm">
                                                            <span v-if="student.status == 'Active (Paid)'" class="darkGreen">Paid</span>
                                                            <span v-else class="important">Not Paid</span>
                                                        </td>
                                                        <td>
                                                            <div class="hidden-xs hidden-sm buttonGroupInline">
                                                                <button
                                                                    class="btn btn-primary subBtn"
                                                                    aria-label="View Student"
                                                                    @click.prevent="openStudentInfoModal(student)"
                                                                >
                                                                    View
                                                                </button>
                                                                <button
                                                                    v-if="manage"
                                                                    class="btn btn-danger subBtn"
                                                                    aria-label="Remove Student"
                                                                    @click.prevent="
                                                                        dropSelect(null, null, {
                                                                            from: 'team',
                                                                            section_idx: section_idx,
                                                                            team_idx: team_idx,
                                                                            student_idx: student_idx,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                            <div class="hidden-md hidden-lg buttonGroupInline">
                                                                <button
                                                                    class="btn btn-primary subBtn"
                                                                    aria-label="View Student"
                                                                    @click.prevent="openStudentInfoModal(student)"
                                                                >
                                                                    <i class="fa fa-play" aria-hidden="true" />
                                                                </button>
                                                                <button
                                                                    v-if="manage"
                                                                    class="btn btn-danger subBtn"
                                                                    aria-label="Remove Student"
                                                                    @click.prevent="
                                                                        dropSelect(null, null, {
                                                                            from: 'team',
                                                                            section_idx: section_idx,
                                                                            team_idx: team_idx,
                                                                            student_idx: student_idx,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                        <tr v-if="searchData.isSearching && searchData.matched == 0">
                                            <td colspan="10" style="text-align: center">NO SEARCH RESULTS</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <div v-if="manage" class="content">
                            <div class="tableSectionDiv">
                                <button class="btn btn-default mainBtn col-xs-12 primaryHover" aria-label="Add New Team" @click.prevent="addTeam(section_idx)">
                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />
                                    Add New Team
                                </button>
                            </div>
                        </div>
                    </template>
                    <!-- <template v-else>
						<template v-for="(team,team_idx) in section.teams">
							<div v-if="!isTeamDeleted(team)">
								<div class="team">
									<div class="header">
										<div class="hidden-xs hidden-sm flex">
										
											<div class="col-md-4 col-md-offset-4">
												{{team.name}}
											</div>
											<div class="col-md-4 alignRight capitalize">
												<template v-if="team._showDetails">
													Less&nbsp;<i class="fa fa-caret-up" @click.prevent="team._showDetails = false;sections.splice()"></i>
												</template>
												<template v-else>
													More&nbsp;<i class="fa fa-caret-down" @click.prevent="team._showDetails = true;sections.splice()"></i>
												</template>
											</div>
										</div>
										<div class="hidden-md hidden-lg flexSpaceBetween">
											<div>
												{{team.name}}
											</div>
											<div>
												<i class="fa fa-caret-up" v-if="team._showDetails" @click.prevent="team._showDetails = false;sections.splice()"></i>
												<i class="fa fa-caret-down" v-else @click.prevent="team._showDetails = true;sections.splice()"></i>
											</div>
										</div>
									</div>
									<template v-if="team._showDetails">
										<template v-if="team.students.length>0">
											<div class="content">
												<template v-for="(student,student_idx) in team.students">
													<div class="col-md-4 col-xs-12 flex justifyCenter align-items btn-default mainBtn" @click.prevent="openStudentInfoModal(student)" style="cursor:pointer">
														{{student.name}}
													</div>	
												</template>
											</div>
										</template>
										<template v-else>
											<div class="content flex">
												<div class="col-xs-12 col-md-12 noMember" style="text-align:center">
													NO STUDENTS ON THIS TEAM
												</div>
											</div>
										</template>
									</template>		
								</div>
							</div>
						</template> 
					</template> -->
                </div>
            </div>
        </template>
        <div v-if="manage && (auth.user().account.version == 'full' || sections.length == 0)" class="col-xs-12 marginTop30">
            <button class="col-xs-12 btn btn-default mainBtn" aria-label="Add New Section" @click="addSection()">
                <i class="fa fa-plus marginRight8" aria-hidden="true" />Add New Section
            </button>
        </div>

        <div id="studentInfoModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="studentInfoModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="studentInfoModal-title" class="modal-title">
                            {{ student.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="create col-xs-12">
                            <div class="col-xs-12 flex">
                                <!-- <div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">EMAIL</label>
			                        	<p class="form-control-static">{{student.email?student.email:student.identity}}</p>
			                    	</div>
					        	</div>
					        	<div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">STUDENT ID</label>
			                        	<p class="form-control-static">{{student.studentID}}</p>
			                    	</div>
					        	</div> -->
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">FULL NAME</label>
                                        <p class="form-control-static">
                                            {{ student.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">EMAIL</label>
                                        <p class="form-control-static">
                                            {{ student.email ? student.email : '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <!-- <div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">SECTION</label>
			                        	<p class="form-control-static" v-if="student.identity">
			                        	{{getStudentSectionIdx(student)!=-1?sections[getStudentSectionIdx(student)].name:'N/A'}}
			                        	</p>
			                    	</div>
					        	</div>
					        	<div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">TEAM</label>
			                        	<p class="form-control-static" v-if="student.identity">
			                        		{{getStudentTeamIdx(student)!=-1?sections[getStudentSectionIdx(student)].teams[getStudentTeamIdx(student)].name:'N/A'}}
			                        	</p>	
			                    	</div>
					        	</div> -->
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase"> STATUS </label>
                                        <p class="form-control-static flex align-items">
                                            <!--<template v-if="student.isSuspended">
							                    <i class="fa fa-circle suspendCircle"></i>&nbsp;Suspended
						                 	</template>
						                  	<template v-else>
						                      <i class="fa fa-circle activeCircle"></i>&nbsp;Active
						                 	 </template>
											-->
                                            <i
                                                class="fa fa-circle marginRight8"
                                                :class="{
                                                    suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(student.status),
                                                    readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(student.status),
                                                    activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(student.status),
                                                }"
                                                aria-hidden="true"
                                            />
                                            {{ student.status }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACTIVATED ON</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateActivated">
                                                {{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <!-- <div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">ACCOUNT CREATED</label>
			                        	<p class="form-control-static"><template v-if="student.dateCreated"> {{convertToReadableDate(student.dateCreated,'DD MMM YYYY, hh:mm A').date}}</template> <template v-else>N/A</template></p>
			                    	</div>
					        	</div>
					        	<div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">ACCOUNT ACTIVATED</label>
			                        	<p class="form-control-static"><template v-if="student.dateActivated">{{convertToReadableDate(student.dateActivated,'DD MMM YYYY, hh:mm A').date}}</template> <template v-else>N/A</template></p>
			                    	</div>
					        	</div> -->
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">CREATED BY</label>
                                        <p class="form-control-static">
                                            {{ student.createBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">CREATED ON</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateCreated">
                                                {{ convertToReadableDate(student.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateCreated, 'd MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <!-- <div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">PLAN</label>
			                        	<p class="form-control-static">Institute (1 Year)</p>
			                    	</div>
					        	</div>
					        	<div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label">ACCOUNT LAST MODIFIED</label>
			                        	<p class="form-control-static"><template v-if="student.lastModified"> {{convertToReadableDate(student.lastModified,'DD MMM YYYY, hh:mm A').date}}</template> <template v-else>N/A</template></p>
			                    	</div>
					        	</div> -->
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACCOUNT LAST LOGIN</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateLastLogin">
                                                {{ convertToReadableDate(student.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateLastLogin, 'd MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">ACCOUNT LAST MODIFIED</label>
                                        <p class="form-control-static">
                                            <template v-if="student.lastModified">
                                                {{ convertToReadableDate(student.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.lastModified, 'd MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">SECTION</label>
                                        <p v-if="student.identity" class="form-control-static">
                                            {{ getStudentSectionIdx(student) != -1 ? sections[getStudentSectionIdx(student)].name : '-' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">TEAM</label>
                                        <p v-if="student.identity" class="form-control-static">
                                            {{
                                                getStudentTeamIdx(student) != -1
                                                    ? sections[getStudentSectionIdx(student)].teams[getStudentTeamIdx(student)].name
                                                    : '-'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPayPlan(student)" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6" style="padding-left: 0px">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">PAYMENT STATUS</label>
                                        <p class="form-control-static">
                                            <template v-if="!student.paidDate">
                                                <span style="color: #dc3545"> Not yet Paid </span>
                                                <router-link
                                                    v-if="auth.user().roleId != 1"
                                                    class="link"
                                                    :to="{
                                                        name: 'payments.gift',
                                                        params: {
                                                            id: student.userUuid,
                                                        },
                                                    }"
                                                >
                                                    Gift Subscription
                                                </router-link>
                                            </template>
                                            <template v-else>
                                                <span style="color: #28a745">
                                                    Paid on
                                                    {{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </span>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                                <!--<div class="col-xs-12 col-md-6">
					        		<div class="form-group">
			                        	<label class="control-label text-uppercase">ACCOUNT EXPIRY</label>
			                        	<p class="form-control-static">
			                        		<template v-if="student.expiryDate"> 
			                        			{{convertToReadableDate(student.expiryDate,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(student.expiryDate,'DD MMM YYYY, hh:mm a').current_timezone}})
			                        		</template> 
			                        		<template v-else>
			                        			-
			                        		</template>
			                        	</p>
			                    	</div>
					        	</div>-->
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> REMARKS </label>

                                        <p class="form-control-static">
                                            <template v-if="student.remarks">
                                                {{ student.remarks }}
                                            </template>
                                            <template> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group blueLink">
                                        <label class="control-label"> ACCOMMODATIONS </label>

                                        <p class="form-control-static">
                                            <template v-if="student.accommodationsTimeMultiple == '1.25x'"> Has 25% extra time </template>
                                            <template v-else-if="student.accommodationsTimeMultiple == '1.5x'"> Has 50% extra time </template>
                                            <template v-else-if="student.accommodationsTimeMultiple == '1.75x'"> Has 75% extra time </template>
                                            <template v-else-if="student.accommodationsTimeMultiple == '2x'"> Has 100% extra time </template>
                                            <template v-else>
                                                <a data-dismiss="modal" href="#" @click="toggleAccommodations"> Add </a>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex hidden-xs hidden-sm">
                                <div
                                    v-tooltip="{
                                        content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="col-xs-12 col-md-6"
                                >
                                    <router-link
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: course.uuid,
                                                id: student.userUuid,
                                            },
                                        }"
                                        custom
                                    >
                                        <button
                                            class="col-xs-12 btn btn-default"
                                            :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                            @click="navigate"
                                        >
                                            <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                        </button>
                                    </router-link>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        v-if="!manage"
                                        v-tooltip="{
                                            content:
                                                'To disenroll a student from a course, click the ‘Organise’ button first, then select the student you want to disenroll',
                                        }"
                                        class="col-xs-12 btn mainBtn disabled"
                                        aria-label="Disenroll Student from Course"
                                    >
                                        Disenroll Student from Course
                                    </button>
                                    <button
                                        v-else
                                        class="col-xs-12 btn btn-danger mainBtn"
                                        aria-label="Disenroll Student from Course"
                                        @click.prevent="removeStudentFromCourse(student, 'modal')"
                                    >
                                        Disenroll Student from Course
                                    </button>
                                    <div
                                        v-if="student.email"
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="marginTop20"
                                    >
                                        <button
                                            class="col-xs-12 btn btn-default mainBtn"
                                            data-dismiss="modal"
                                            :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                            :aria-label="hasWritePrivilege(course) == -1 ? 'Reset Password Button Disabled' : 'Reset Password'"
                                            @click.prevent="openResetPasswordModal(student)"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                    <!--<div class="viewDropdown marginTop20">
										<div class="col-xs-12 dropdown">
											<button class="btn drop-down" type="button" id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span class="marginRight10">
													Reset Password
												</span>
												<span>
													<i class="fa fa-caret-down"></i>
												</span>
											</button>
											<div class="col-xs-12 dropdown-menu dropdown-menu-right marginBottom30" aria-labelledby="viewDropdownMenuButton">
												<button class="btn btn-primary mainBtn">
													<i class="fa fa-paper-plane"></i>&nbsp;Send Reset Password Link
												</button>
												<button class="btn btn-default mainBtn">
													Reset Temporary Password to 7418
												</button>
											</div>
										</div>
									</div> -->
                                    <!-- <div class="viewDropdown dropdown col-xs-12 marginTop20">
										<button class="btn drop-down flexSpaceBetween" type="button" id="d-published-DropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="marginRight10">
												More Actions
											</span>
											<span>
												<i class="fa fa-caret-down"></i>
											</span>
										</button>
										<div class="col-xs-12 dropdown-menu dropdown-menu-right" aria-labelledby="viewDropdownMenuButton">
											<button class="btn btn-primary mainBtn">
												<i class="fa fa-paper-plane"></i>&nbsp;Send Reset Password Link
											</button>
											<button class="btn btn-default mainBtn">
												Reset Temporary Password to 7418
											</button>
										</div>
									</div> -->
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <div
                                    v-tooltip="{
                                        content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                    }"
                                >
                                    <router-link
                                        :to="{
                                            name: 'courses.manage.students.edit',
                                            params: {
                                                course_id: course.uuid,
                                                id: student.userUuid,
                                            },
                                        }"
                                        class="col-xs-12 btn btn-default mainBtn"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                    >
                                        <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                    </router-link>
                                </div>

                                <button
                                    class="col-xs-12 col-md-6 btn btn-danger mainBtn"
                                    :disabled="!manage ? true : undefined"
                                    aria-label="Disenroll Student from Course"
                                    @click.prevent="removeStudentFromCourse(student, 'modal')"
                                >
                                    Disenroll Student from Course
                                </button>

                                <div
                                    v-if="student.email"
                                    v-tooltip="{
                                        content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="marginTop20"
                                >
                                    <button
                                        class="col-xs-12 col-md-6 btn btn-default mainBtn"
                                        data-dismiss="modal"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                        :aria-label="hasWritePrivilege(course) == -1 ? 'Reset Password Button Disabled' : 'Reset Password'"
                                        @click.prevent="openResetPasswordModal(student)"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                                <!--<div class="viewDropdown marginTop20">
									<div class="col-xs-12 dropdown">
										<button class="btn drop-down" type="button" id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="marginRight10">
												Reset Password
											</span>
											<span>
												<i class="fa fa-caret-down"></i>
											</span>
										</button>
										<div class="dropdown-menu dropdown-menu-right marginBottom30" aria-labelledby="viewDropdownMenuButton">
											<button class="btn btn-primary mainBtn">
												<i class="fa fa-paper-plane"></i>&nbsp;Send Reset Password Link
											</button>
											<button class="btn btn-default mainBtn">
												Reset Temporary Password to 7418
											</button>
										</div>
									</div>
								</div>-->
                                <!-- <div class="dropdown">
						        	<div class="viewDropdown col-xs-12 marginTop20">
										<button class="btn drop-down flexSpaceBetween" type="button" id="d-published-DropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="marginRight10">
												More Actions
											</span>
											<span>
												<i class="fa fa-caret-down"></i>
											</span>
										</button>
										<div class="dropdown-menu dropdown-menu-right" aria-labelledby="viewDropdownMenuButton">
											<button class="btn btn-primary mainBtn">
												<i class="fa fa-paper-plane"></i>&nbsp;Send Reset Password Link
											</button>
											<button class="btn btn-default mainBtn">
												Reset Temporary Password to 7418
											</button>
										</div>
									</div>
								</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div id="disenrollStudentsModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="disenrollStudentsModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="disenrollStudentsModal-title" class="modal-title">Disenroll Confirmation</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>
                                    Are you sure you would like to disenroll
                                    {{ disenrolled_students.length }} student/s from this course?
                                </div>
                            </div>
                        </div>
                        <div class="row marginTop10">
                            <div class="flex">
                                <button
                                    id="modal-logout-btn"
                                    class="btn btn-danger mainBtn"
                                    style="width: 100%; padding-right: 10px"
                                    data-dismiss="modal"
                                    aria-label="Confirm Disenroll"
                                    @click.prevent="disenrollStudents"
                                >
                                    Confirm Disenroll
                                </button>
                                <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="cancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="updateSortingModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="updateSortingModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 id="updateSortingModal-title" class="modal-title">New sorting update</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <div>You have <b>published (but not started)</b> the following activities:</div>
                        <div v-if="notStartedActivities.length < 6">
                            <template v-for="(activity, idx) in notStartedActivities" :key="idx + '-activity'">
                                <p>
                                    {{ idx + 1 }}. ({{ activity.type }}) Section{{ activity.sectionName }}
                                    {{ activity.activityName }}
                                </p>
                            </template>
                        </div>
                        <div v-else>
                            <table class="table">
                                <thead>
                                    <th>No</th>
                                    <th>Activity Type</th>
                                    <th>Section</th>
                                    <th>Activity Name</th>
                                </thead>
                                <tbody>
                                    <template v-for="(activity, idx) in notStartedActivities">
                                        <tr>
                                            <td>{{ idx + 1 }}</td>
                                            <td>{{ activity.type }}</td>
                                            <td>{{ activity.sectionName }}</td>
                                            <td>{{ activity.activityName }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="marginTop20">Would you like to apply your section/teams structure changes to these activities as well?</div>
                        <div class="flex marginTop30">
                            <button class="btn btn-default mainBtn" aria-label="Cancel" data-dismiss="modal">No</button>
                            <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Yes, update" @click="resnapshotSection">
                                Yes, update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="errorStudentsModal"
            class="modal danger-modal errorStudentsModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="errorStudentsModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="errorStudentsModal-title" class="modal-title">ERROR</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">Cannot Delete Section</h2>
                        <br />
                        <p>Existing activities are associated with this section.</p>
                        <p>You need to delete the activities first.</p>
                        <br />
                        <p>Go to activities listing screen?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-primary" @click="backToActivity()">Go to Activities</button>
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password />
    </div>

    <!--<div class="col-xs-12" style="margin-bottom:15px;">
			<button class="btn btn-default btn-action col-xs-12 col-md-12" style="color:#fff;font-weight:normal;background:#47a647;margin:0;margin-bottom:15px;width:100%" @click.prevent="emit('done')">
				Next Step
			</button>
		</div>-->
    <!--<kr-panel :options="panel_options">
			<template slot="title">
				<div class="x_title">
					Manage Students
				</div>	
			</template>
			<template slot="content">
				
			</template>
			<template slot="footer">
			    <div class="col-xs-12">
			        <button class="btn addQnsBtn" style="margin:0;" type="submit" @click="addSection()"><i class="fa fa-plus-circle" aria-hidden="true"></i> New Section </button>

			        <button class="btn pull-right btn-success" style="margin:0;" type="submit" @click="update()">Save Changes</button>
			    </div>  
	    	</template>
		</kr-panel>	
		<div class="col-xs-12" style="margin-bottom:15px;" v-if="component_type=='form'">
			<button class="btn btn-default btn-action col-xs-12 col-md-12" style="color:#fff;font-weight:normal;background:#47a647;margin:0;margin-bottom:15px;width:100%" @click.prevent="emit('done')">
				Next Step
			</button>
		</div>
	</div>-->
</template>

<script>
import KrForm from '../../../../../components/forms/form';
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['courseObj', 'component_type', 'panel_options', 'manage'],
    //component type form/page/iframe
    data() {
        return {
            account: {},
            auth: new KrAuth(),
            course: null,
            sections: [],
            unassigned_students: [],
            studentsDetailedView: false,
            student: {}, //for display
            mobile: false,
            teamDisplay: true,
            searchData: { q: '', isSearching: false, matched: 0 },
            hasNewCourseStudents: false,
            readOnlyLabel: 'You have read-only access',
            displayReloadAlert: this.$route.query.from == 'students.create',
            disenrolled_students: [],
            notStartedActivities: [],
        };
    },
    computed: {
        hasPaidHeader() {
            if (_.get(this.auth.user(), 'account.paymentMethod') == 'Student-Paid') {
                return true;
            }
            return false;
        },
    },
    watch: {
        studentsDetailedView: {
            handler: function () {
                this.$emit('studentsDetailedView', this.studentsDetailedView);
            },
            deep: true,
        },
        unassigned_students: {
            handler: function () {
                this.$emit('unassigned_students_length', this.unassigned_students.length);
            },
            deep: true,
        },
        disenrolled_students: {
            handler: function () {
                this.$emit('disenrolled_students_length', this.disenrolled_students.length);
            },
            deep: true,
        },
        'searchData.q': {
            handler: function () {
                this.search();
            },
        },
        teamDisplay: {
            handler: function () {
                if (this.teamDisplay) {
                    this.searchData.q = '';
                    this.search();
                }
            },
        },
        studentsDetailedView: {
            handler: function () {
                if (!this.studentsDetailedView) {
                    this.searchData.q = '';
                    this.search();
                }
            },
        },
        manage: {
            handler: function () {
                this.searchData.q = '';
                this.search();
            },
        },
    },
    created() {
        var that = this;
        if ($(window).width() < 992) {
            that.mobile = true;
        } else {
            that.mobile = false;
        }
        $(window).resize(function () {
            if ($(window).width() < 992) {
                that.mobile = true;
            } else {
                that.mobile = false;
            }
        });
        if (this.courseObj) {
            this.course = this.courseObj;
            that.fetch().then(function (response) {
                that.sections = response.data.sections;
                that.unassigned_students = response.data.unassigned;
                that.account = response.data.account;
                that.init();
                if (that.courseObj) {
                    that.courseObj.students.length = response.data.length;
                    that.courseObj.students.sections = response.data.sections;
                }
                that.component_done_loading = true;
            });
        }
    },
    mounted() {
        var that = this;
        that.processEchoListeners();
        Events.listen('disenroll_manage_students', function () {
            that.confirmDisenrollStudentsModal();
        });
        Events.listen('save_manage_students', function () {
            that.update();
        });
        Events.listen('reset_manage_students_form', function () {
            that.reset(false);
        });
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (!that.manage) {
                    that.displayReloadAlert = false;
                    that.fetch().then(function (response) {
                        that.sections = response.data.sections;
                        that.unassigned_students = response.data.unassigned;
                        that.init();
                        if (that.courseObj) {
                            that.courseObj.students.length = response.data.length;
                        }
                    });
                } else {
                    that.hasNewCourseStudents = true;
                }
            };
            let c1 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('CourseUserPlacementsUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'CourseUserPlacementsUpdated',
                handler: h1,
            });
        },
        resnapshotSection() {
            var that = this;
            let testUuids = [];
            for (var i = 0; i < this.notStartedActivities.length; i++) {
                testUuids.push(this.notStartedActivities[i].uuid);
            }
            axios
                .post(`/courses/${this.course.uuid}/students/teams-resnapshot`, {
                    testUuids: testUuids,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Student Sorting for other activities successfully updated',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change student sortings for other activities at this time, please try again later',
                    });
                });
        },
        confirmDisenrollStudentsModal() {
            $('#disenrollStudentsModal').modal('show');
        },
        disenrollStudents() {
            var that = this;
            axios
                .post(`/students/mass-disenroll`, {
                    courseUuid: this.course.uuid,
                    userUuids: this.disenrolled_students,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Student/s successfully disenrolled',
                    });
                    that.disenrolled_students = [];
                    that.reset(false);
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to disenroll student/s at this time, please try again later',
                    });
                });
        },
        isPayPlan(student) {
            //return true;
            if (this.account.paymentMethod == 'Student-Paid' && !student.isGeneric && student.email != null) {
                return true;
            }
            return false;
        },
        countStudentsInSection(section) {
            let count = 0;
            for (var i = 0; i < section.teams.length; i++) {
                count += section.teams[i].students.length;
            }
            return count;
        },
        search() {
            if (this.searchData.q != '') {
                this.searchData.isSearching = true;
                this.teamDisplay = false;
                this.studentsDetailedView = true;
                this.searchData.matched = 0;
                for (var i = 0; i < this.sections.length; i++) {
                    for (var j = 0; j < this.sections[i].teams.length; j++) {
                        let team = this.sections[i].teams[j];
                        for (var k = 0; k < team.students.length; k++) {
                            let student = team.students[k];
                            let haystack = student.name + student.studentID + student.email + student.identity;
                            let doesContain = haystack.indexOf(this.searchData.q);
                            if (doesContain != -1) {
                                student._searchDisplay = true;
                                //Vue.set(student, '_searchDisplay', true);
                                this.searchData.matched++;
                            } else {
                                student._searchDisplay = false;
                                //Vue.set(student, '_searchDisplay', false);
                            }
                        }
                    }
                }
            } else {
                this.searchData.isSearching = false;
                /*for(var i = 0;i<this.sections.length;i++){
						for(var j = 0;j<this.sections[i].teams.length;j++){
							let team = this.sections[i].teams[j];
							for(var k =0; k <team.students.length;k++){
								let student = team.students[k];
								//Vue.set(student,'_searchDisplay',true);
							}
						}
					}*/
            }
        },
        openResetPasswordModal(user) {
            user.uuid = user.userUuid;
            Events.fire('open-reset-password-modal', {
                user: user,
                organisation: this.auth.user().account.organisationName,
            });
        },
        shuffleStudents(from = 'all', idx = null) {
            let students = [];
            let sections_count = this.sections.length;
            let teams_count = 0;
            let students_per_team = 0;
            let section_start = 0;
            let section_end = 0;
            let mean_students_per_team = 0;
            if (from == 'all' || from == 'unassigned') {
                students = _.cloneDeep(this.unassigned_students);
                this.unassigned_students = [];
            }
            if (from == 'section') {
                section_start = idx;
                section_end = idx + 1;
            } else {
                section_end = this.sections.length;
            }
            for (var i = section_start; i < section_end; i++) {
                teams_count += this.sections[i].teams.length;
                for (var j = 0; j < this.sections[i].teams.length; j++) {
                    let team = this.sections[i].teams[j];
                    mean_students_per_team += team.students.length;
                    for (var k = 0; k < team.students.length; k++) {
                        if (from != 'unassigned') {
                            students.push(_.cloneDeep(team.students[k]));
                        }
                    }
                    if (from != 'unassigned') {
                        team.students = [];
                    }
                }
            }
            if (students.length == 0) {
                return;
            }
            for (var i = 0; i < students.length; i++) {
                if (students[i].teamUuid != null) {
                    students[i]._new = {
                        teamUuid: null,
                        sectionUuid: null,
                    };
                    delete students[i]._tempSectionUuid;
                    delete students[i]._tempTeamUuid;
                }
            }
            if (teams_count == 0) {
                return;
            }
            //shuffle students
            students = _.shuffle(students);
            students_per_team = students.length / teams_count < 1 ? 1 : Math.floor(students.length / teams_count);

            if (from == 'unassigned') {
                students_per_team = Math.floor(mean_students_per_team / teams_count);
                //alert(students_per_team);
                //return;
            }
            let count = students.length - 1;
            //is pass done
            let reset = false;
            let offset = 0;
            for (var i = section_start; i < section_end && count != -1; i++) {
                if (!reset) {
                    teams_count += this.sections[i].teams.length;
                    //console.log('not-reseted',i);
                } else {
                    //console.log('resetted',i);
                }
                for (var j = 0; j < this.sections[i].teams.length && count != -1; j++) {
                    offset = !reset && from == 'unassigned' ? this.sections[i].teams[j].students.length : reset ? 0 : 0;
                    for (var k = 0; k < students_per_team - offset && count != -1; k++) {
                        let student = _.cloneDeep(students[count]);
                        students.splice(count--, 1);
                        //console.log(count);
                        student._new = {
                            teamUuid: this.sections[i].teams[j].uuid ? this.sections[i].teams[j].uuid : this.sections[i].teams[j]._temp_id,
                            sectionUuid: this.sections[i].uuid ? this.sections[i].uuid : this.sections[i]._temp_id,
                        };
                        student._tempSectionUuid = student._new.sectionUuid;
                        student._tempTeamUuid = student._new.teamUuid;
                        this.sections[i].teams[j].students.push(student);
                    }
                }
                if (this.sections.length - 1 == i && count != -1) {
                    i = section_start - 1;
                    students_per_team = 1;
                    reset = true;
                    //console.log(reset);
                }
            }
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Students have been shuffled.',
            });
        },
        fetch() {
            return axios.get('/courses/' + this.course.uuid + '/students?display=group');
        },
        reset(notify = true) {
            var that = this;
            this.fetch().then(function (response) {
                that.sections = response.data.sections;
                that.unassigned_students = response.data.unassigned;
                that.account = response.data.account;
                that.init();
                if (that.courseObj) {
                    that.courseObj.students.length = response.data.length;
                }
                if (notify) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teams/students placements have been successfully reset',
                    });
                }
            });
        },
        dropSelect(section_uuid = false, team_uuid = false, options = {}) {
            var section_idx = _.findIndex(this.sections, function (o) {
                return (o.uuid == section_uuid || o._temp_id == section_uuid) && section_uuid;
            });
            var team_idx = -1;
            if (section_idx != -1) {
                team_idx = _.findIndex(this.sections[section_idx].teams, function (o) {
                    return (o.uuid == team_uuid || o._temp_id == team_uuid) && team_uuid;
                });
            }
            if (section_idx != -1 && team_idx != -1) {
                this.dropToTeam(false, section_idx, team_idx, options);
            } else {
                this.dropToUnassigned(false, options);
            }
        },
        getStudentSectionIdx(student) {
            let uuid = _.get(student, '_new.sectionUuid', student.sectionUuid);

            if (uuid) {
                return _.findIndex(this.sections, function (o) {
                    return o.uuid == uuid || o._temp_id == uuid;
                });
            }
            return -1;
        },
        getStudentTeamIdx(student) {
            let section_idx = this.getStudentSectionIdx(student);
            if (section_idx == -1) {
                return -1;
            }
            let uuid = _.get(student, '_new.teamUuid', student.teamUuid);
            if (!uuid) {
                return -1;
            }
            return _.findIndex(this.sections[section_idx].teams, function (o) {
                return o.uuid == uuid || o._temp_id == uuid;
            });
        },
        getSelected(id) {
            return $('#' + id).val();
        },
        emit(status, data = {}) {
            this.$emit(status, data);
        },
        isSectionDeleted(section) {
            return _.get(section, '_trashed', false);
        },
        isTeamDeleted(team) {
            return _.get(team, '_trashed', false);
        },
        isStudentDeleted(student) {
            return _.get(student, '_trashed', false);
        },
        removeSection(section_idx) {
            //copy
            let section = this.sections[section_idx];
            //if there are teams on section, orphan them and their kids.
            //loop backwards otherwise run condition on splice inside removeTeam
            for (var i = section.teams.length - 1; i >= 0; i--) {
                this.removeTeam(section_idx, i);
            }
            section.teams = [];
            //if team is not yet set on server, just delete
            if (section.uuid == null) {
                this.sections.splice(section_idx, 1);
            } else {
                section._trashed = true;
            }
        },
        removeTeam(section_idx, team_idx, options = { unassign_students: true }) {
            //copy
            let team = this.sections[section_idx].teams[team_idx];
            //if there are students on team, orphan them
            for (var i = 0; i < team.students.length; i++) {
                let student = team.students[i];
                student._new = {
                    teamUuid: null,
                    sectionUuid: null,
                    deletedTeam: true,
                };
                if (options.unassign_students) {
                    this.unassigned_students.push(student);
                }
            }
            //if team is not yet set on server, just delete
            if (team.uuid == null) {
                //delete team from sections
                this.sections[section_idx].teams.splice(team_idx, 1);
            } else {
                team.students = [];
                team._trashed = true;
            }
        },
        removeStudentFromCourse(student, from = 'unassigned_students', options = {}) {
            let teamUuid = false;
            let sectionUuid = false;

            //check where student is from
            if (student._new) {
                teamUuid = student._new.teamUuid;
                sectionUuid = student._new.sectionUuid;
            } else {
                teamUuid = student._tempTeamUuid;
                sectionUuid = student._tempSectionUuid;
            }
            if (!teamUuid && !sectionUuid) {
                options.unassigned_idx = _.findIndex(this.unassigned_students, function (o) {
                    return o.userUuid == student.userUuid;
                });
            }
            if (teamUuid && sectionUuid) {
                let section_idx = _.findIndex(this.sections, function (o) {
                    return o.uuid == sectionUuid || o._temp_id == sectionUuid;
                });
                let team_idx = -1;
                if (section_idx != -1) {
                    team_idx = _.findIndex(this.sections[section_idx].teams, function (o) {
                        return o.uuid == teamUuid || o._temp_id == teamUuid;
                    });
                }
                if (team_idx != -1) {
                    let student_idx = _.findIndex(this.sections[section_idx].teams[team_idx].students, function (o) {
                        return o.userUuid == student.userUuid;
                    });
                    if (student_idx != -1) {
                        this.dropSelect(null, null, {
                            from: 'team',
                            section_idx: section_idx,
                            team_idx: team_idx,
                            student_idx: student_idx,
                        });
                        this.unassigned_students[this.unassigned_students.length - 1]._trashed = true;
                        this.unassigned_students.splice();
                    }
                }
            }
            if (!teamUuid && !sectionUuid) {
                let idx = _.get(options, 'unassigned_idx', false);
                if (idx !== false) {
                    this.unassigned_students[idx]._trashed = true;
                    this.unassigned_students.splice();
                }
            }
            $('#studentInfoModal').modal('hide');
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Student has been removed from course',
            });
        },
        updateName($event, object) {
            object.name = $event.target.value;
            object._showEditName = false;
            object._new = true;
            this.sections.splice();
        },
        /*First Step  = Creation of new Sections/Teams
			  Second Step = Updating of [Sections/Teams](names)/Student Placements
			  Third Step = Remove Redundancies/Unused Sections/Teams
			*/
        update() {
            var that = this;
            var isForResnapshot = false;
            //delete
            var thirdStep = function () {
                let concurrentUrls = [];
                let sectionData = [];
                let teamData = [];
                var sectionDelete = function (data) {
                    return axios.post('/courses/' + that.course.uuid + '/sections/bulk/delete', { data: data });
                };
                var teamDelete = function (data) {
                    //console.log({data:data});
                    return axios.post('/courses/' + that.course.uuid + '/sections/bulk/delete/teams', { data: data, courseUuid: that.course.uuid });
                };

                for (var i = 0; i < that.sections.length; i++) {
                    let s = that.sections[i];
                    if (s.uuid && s._trashed) {
                        sectionData.push(s.uuid);
                    }
                    for (var j = 0; j < s.teams.length; j++) {
                        let t = s.teams[j];
                        if (t.uuid && t._trashed) {
                            teamData.push(t.uuid);
                        }
                    }
                }
                if (sectionData.length != 0) {
                    concurrentUrls.push(sectionDelete(sectionData));
                }
                if (teamData.length != 0) {
                    concurrentUrls.push(teamDelete(teamData));
                }
                if (concurrentUrls.length != 0) {
                    axios
                        .all(concurrentUrls)
                        .then(
                            axios.spread((...res) => {
                                res.map((d) => d.data);
                                /*for(var i = 0; i<that.teams;i++){
								let result = res[i];
								let params = result.config.params;
								if(params.from=='sections'){
									that.sections.splice(params.idx,1);
								}
								if(params.from=='teams'){
									that.teams.splice(params.idx,1);
								}
							}*/
                                for (var i = that.sections.length - 1; i >= 0; i--) {
                                    let s = that.sections[i];
                                    if (s.uuid && s._trashed) {
                                        that.sections.splice(i, 1);
                                    }
                                    for (var j = s.teams.length - 1; j >= 0; j--) {
                                        let t = s.teams[j];
                                        if (t.uuid && t._trashed) {
                                            s.teams.splice(j, 1);
                                        }
                                    }
                                }
                                that.$emit('changed', {
                                    sections: that.sections,
                                });
                                if (that.hasNewCourseStudents) {
                                    that.hasNewCourseStudents = false;
                                    that.fetch().then(function (response) {
                                        that.sections = response.data.sections;
                                        that.unassigned_students = response.data.unassigned;
                                        that.account = response.data.account;
                                        that.init();
                                        if (that.courseObj) {
                                            that.courseObj.students.length = response.data.length;
                                        }
                                        that.component_done_loading = true;
                                    });
                                } else {
                                    that.init();
                                }
                                that.$notify({
                                    group: 'form',
                                    type: 'success',
                                    title: 'Success',
                                    text: 'Teams/students have been updated',
                                });
                                if (isForResnapshot) {
                                    axios.get(`/courses/${that.course.uuid}/students/tests/not-started`).then(function (response) {
                                        that.notStartedActivities = response.data;
                                        if (that.notStartedActivities.length > 0) {
                                            $('#updateSortingModal').modal('show');
                                        }
                                    });
                                }
                            })
                        )
                        .catch(function (errors) {
                            $('.errorStudentsModal').modal('show');
                            // that.$notify({
                            //     group: 'form',
                            //     type: 'error',
                            //     title: 'Error',
                            //     text: _.get(
                            //         errors,
                            //         'response.data.message',
                            //         'Something went wrong, please try again later'
                            //     ),
                            // });
                            that.reset(false);
                        });
                } else {
                    that.$emit('changed', { sections: that.sections });
                    if (that.hasNewCourseStudents) {
                        that.hasNewCourseStudents = false;
                        that.fetch().then(function (response) {
                            that.sections = response.data.sections;
                            that.unassigned_students = response.data.unassigned;
                            that.account = response.data.account;
                            that.init();
                            if (that.courseObj) {
                                that.courseObj.students.length = response.data.length;
                            }
                            that.component_done_loading = true;
                        });
                    } else {
                        that.init();
                    }
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teams/students have been updated',
                    });
                    if (isForResnapshot) {
                        axios.get(`/courses/${that.course.uuid}/students/tests/not-started`).then(function (response) {
                            that.notStartedActivities = response.data;
                            if (that.notStartedActivities.length > 0) {
                                $('#updateSortingModal').modal('show');
                            }
                        });
                    }
                }
            }.bind(that);
            //update
            var secondStep = function () {
                var sectionUpdate = function (data) {
                    return axios.put('/courses/' + that.course.uuid + '/sections/bulk/update', { data: data });
                };
                var teamUpdate = function (data) {
                    return axios.put('/courses/' + that.course.uuid + '/sections/bulk/update/teams', { data: data });
                };
                var studentTeamUpdates = function (data) {
                    return axios.post('/students/bulk-transfer', {
                        data: data,
                        courseUuid: that.course.uuid,
                    });
                };

                let sectionData = [];
                let teamData = [];
                let studentData = [];
                for (var i = 0; i < that.sections.length; i++) {
                    let s = that.sections[i];
                    if (s.uuid && s._new && !s._trashed) {
                        sectionData.push({ uuid: s.uuid, name: s.name });
                        delete s._new;
                    }
                    for (var j = 0; j < s.teams.length; j++) {
                        let t = s.teams[j];
                        if (t.uuid && t._new && !t._trashed) {
                            teamData.push({ uuid: t.uuid, name: t.name });
                            delete t._new;
                        }
                        for (var k = 0; k < t.students.length; k++) {
                            let u = t.students[k];
                            if (u._new && !u._trashed) {
                                studentData.push({
                                    disenroll: false,
                                    placementId: u.placementId,
                                    sectionUuid: s.uuid,
                                    teamUuid: t.uuid,
                                });
                                delete u._new;
                            }
                        }
                    }
                }
                for (var i = 0; i < that.unassigned_students.length; i++) {
                    let u = that.unassigned_students[i];
                    if (u._new && !u._trashed) {
                        if (!u._new.deletedTeam) {
                            studentData.push({
                                disenroll: false,
                                placementId: u.placementId,
                                sectionUuid: null,
                                teamUuid: null,
                            });
                        }
                        delete u._new;
                    }
                    if (u._trashed) {
                        studentData.push({
                            disenroll: true,
                            placementId: u.placementId,
                            sectionUuid: null,
                            teamUuid: null,
                        });
                    }
                }
                //console.log(studentData);
                let concurrentUrls = [];
                if (sectionData.length != 0) {
                    isForResnapshot = true;
                    concurrentUrls.push(sectionUpdate(sectionData));
                }
                if (teamData.length != 0) {
                    isForResnapshot = true;
                    concurrentUrls.push(teamUpdate(teamData));
                }
                if (studentData.length != 0) {
                    isForResnapshot = true;
                    concurrentUrls.push(studentTeamUpdates(studentData));
                }
                if (concurrentUrls.length != 0) {
                    axios.all(concurrentUrls).then(
                        axios.spread((...res) => {
                            res.map((d) => d.data);

                            thirdStep();
                        })
                    );
                } else {
                    thirdStep();
                }
            }.bind(that);
            //nextStep();

            //creation
            var firstStep = function () {
                var sectionCreationStep = function () {
                    let concurrentUrls = [];

                    var section_create = function (data, extra_params = []) {
                        return axios.post('courses/' + that.course.uuid + '/sections', data, { params: extra_params });
                    };
                    /*For Sections Creation*/
                    for (var i = 0; i < that.sections.length; i++) {
                        let section = that.sections[i];
                        if (section._new && !section.uuid && !section._trashed) {
                            concurrentUrls.push(section_create({ name: section.name }, { for: 'sections', idx: i }));
                        }
                    }
                    /*For Sections Creation*/
                    if (concurrentUrls.length != 0) {
                        axios.all(concurrentUrls).then(
                            axios.spread((...res) => {
                                res.map((d) => d.data);
                                for (var i = 0; i < res.length; i++) {
                                    let result = res[i];
                                    let params = result.config.params;
                                    if (params.for == 'sections') {
                                        let section = that.sections[params.idx];
                                        let teams = section.teams;
                                        section.uuid = result.data.data.uuid;
                                        delete section._new;
                                        delete section._temp_id;
                                        section.teams = teams;
                                    }
                                }
                                //go to team creation step after pushing all sections
                                teamCreationStep();
                            })
                        );
                    } else {
                        teamCreationStep();
                    }
                };
                var teamCreationStep = function () {
                    var team_create = function (data, extra_params = []) {
                        return axios.post('/courses/' + that.course.uuid + '/sections/' + that.sections[extra_params.section_idx].uuid + '/teams', data, {
                            params: extra_params,
                        });
                    };
                    let concurrentUrls = [];
                    for (var i = 0; i < that.sections.length; i++) {
                        for (var j = 0; j < that.sections[i].teams.length; j++) {
                            let team = that.sections[i].teams[j];
                            if (team._new && !team.uuid && !team._trashed) {
                                concurrentUrls.push(
                                    team_create(
                                        { name: team.name },
                                        {
                                            for: 'teams',
                                            section_idx: i,
                                            team_idx: j,
                                        }
                                    )
                                );
                            }
                        }
                    }
                    //console.log(concurrentUrls);
                    if (concurrentUrls.length != 0) {
                        axios.all(concurrentUrls).then(
                            axios.spread((...res) => {
                                res.map((d) => d.data);
                                for (var i = 0; i < res.length; i++) {
                                    let result = res[i];
                                    let params = result.config.params;
                                    if (params.for == 'teams') {
                                        let team = that.sections[params.section_idx].teams[params.team_idx];
                                        let students = team.students;
                                        team.uuid = result.data.data.uuid;
                                        delete team._new;
                                        delete team._temp_id;
                                        team.students = students;
                                        for (var j = 0; j < students.length; j++) {
                                            let student = students[j];
                                            student._new['teamUuid'] = team.uuid;
                                            student._new['sectionUuid'] = that.sections[params.section_idx].uuid;
                                        }
                                    }
                                }
                                secondStep();
                            })
                        );
                    } else {
                        secondStep();
                    }
                }.bind(that);
                sectionCreationStep();
            }.bind(that);

            firstStep();
        },
        dropToUnassigned(event, datum = {}) {
            var data = {};
            if (event) {
                data = JSON.parse(event.dataTransfer.getData('text'));
                if (event.preventDefault) {
                    event.preventDefault();
                }
                if (event.stopPropagation) {
                    event.stopPropagation();
                }
            } else {
                data.options = datum;
            }
            if (data.options.from == 'team') {
                let student = this.sections[data.options.section_idx].teams[data.options.team_idx].students[data.options.student_idx];
                this.sections[data.options.section_idx].teams[data.options.team_idx].students.splice(data.options.student_idx, 1); //remove from teams[]

                if (student._new) {
                    student._new['teamUuid'] = null;
                    student._new['sectionUuid'] = null;
                    //already updated
                } else {
                    //new change
                    student._new = {
                        teamUuid: null,
                        sectionUuid: null,
                    };
                }
                //magic reupdate
                delete student._tempSectionUuid;
                delete student._tempTeamUuid;
                this.unassigned_students.push(student);
            }
        },

        dropToSection(event, section_idx) {
            var data = {};
            if (event) {
                data = JSON.parse(event.dataTransfer.getData('text'));
                if (event.preventDefault) {
                    event.preventDefault();
                }
                if (event.stopPropagation) {
                    event.stopPropagation();
                }
            } else {
                return false;
            }
            if (data.team) {
                //team to section
                var old_section_idx = data.options.section_idx;
                if (section_idx == old_section_idx) {
                    return false;
                }
                var old_team_idx = data.options.team_idx;
                this.removeTeam(old_section_idx, old_team_idx, {
                    unassign_students: false,
                });
                if (_.get(this.sections, old_section_idx + '.teams.' + old_team_idx)) {
                    this.sections[old_section_idx].teams[old_team_idx].students = [];
                }
                let team_idx = this.addTeam(section_idx);
                this.sections[section_idx].teams[team_idx].name = data.team.name;
                this.sections[section_idx].teams[team_idx].students = data.team.students;

                for (var i = 0; i < this.sections[section_idx].teams[team_idx].students.length; i++) {
                    let student = this.sections[section_idx].teams[team_idx].students[i];
                    if (student._new) {
                        //student._new['placements+'] = [this.activity.courses_ref[0]+':'+this.sections[section_idx].id+':'+this.sections[section_idx].teams[team_idx].id];
                        (student._new['teamUuid'] = this.sections[section_idx].teams[team_idx].uuid
                            ? this.sections[section_idx].teams[team_idx].uuid
                            : this.sections[section_idx].teams[team_idx]._temp_id),
                            (student._new['sectionUuid'] = this.sections[section_idx].uuid
                                ? this.sections[section_idx].uuid
                                : this.sections[section_idx]._temp_id);
                        //already updated
                    } else {
                        //new change
                        student._new = {
                            teamUuid: this.sections[section_idx].teams[team_idx].uuid
                                ? this.sections[section_idx].teams[team_idx].uuid
                                : this.sections[section_idx].teams[team_idx]._temp_id,
                            sectionUuid: this.sections[section_idx].uuid ? this.sections[section_idx].uuid : this.sections[section_idx]._temp_id,
                        };
                    }
                    //console.log(this.sections[section_idx].teams[team_idx].students[i]);
                }
            }
            //console.log(data);
        },
        dropToTeam(event, section_idx, team_idx, datum = {}) {
            var data = {};
            if (event) {
                data = JSON.parse(event.dataTransfer.getData('text'));
                if (event.preventDefault) {
                    event.preventDefault();
                }
                if (event.stopPropagation) {
                    event.stopPropagation();
                }
            } else {
                data.options = datum;
            }
            //console.log('old_team_idx:'+data.options.team_idx+'new_team_idx:'+team_idx+'old_section_idx:'+data.options.section_idx+'new_section_idx'+section_idx);
            if (data.options.from == 'team' && (team_idx != data.options.team_idx || section_idx != data.options.section_idx)) {
                this.sections[section_idx].teams[team_idx].students.push(
                    this.sections[data.options.section_idx].teams[data.options.team_idx].students[data.options.student_idx]
                );
                //check if this has been updated before
                let student = this.sections[section_idx].teams[team_idx].students[this.sections[section_idx].teams[team_idx].students.length - 1];

                this.sections[data.options.section_idx].teams[data.options.team_idx].students.splice(data.options.student_idx, 1);
                if (student._new) {
                    //student._new['placements+'] = [this.activity.courses_ref[0]+':'+this.sections[section_idx].id+':'+this.sections[section_idx].teams[team_idx].id];
                    student._new['teamUuid'] = this.sections[section_idx].teams[team_idx].uuid
                        ? this.sections[section_idx].teams[team_idx].uuid
                        : this.sections[section_idx].teams[team_idx]._temp_id;
                    student._new['sectionUuid'] = this.sections[section_idx].uuid ? this.sections[section_idx].uuid : this.sections[section_idx]._temp_id;
                    //already updated
                } else {
                    //new change
                    student._new = {
                        teamUuid: this.sections[section_idx].teams[team_idx].uuid
                            ? this.sections[section_idx].teams[team_idx].uuid
                            : this.sections[section_idx].teams[team_idx]._temp_id,
                        sectionUuid: this.sections[section_idx].uuid ? this.sections[section_idx].uuid : this.sections[section_idx]._temp_id,
                    };
                }
            }
            if (data.options.from == 'unassigned') {
                //reassign
                let student = this.unassigned_students[data.options.unassigned_idx];
                if (student._new) {
                    //student._new['placements+'] = [this.activity.courses_ref[0]+':'+this.sections[section_idx].id+':'+this.sections[section_idx].teams[team_idx].id];
                    student._new['teamUuid'] = this.sections[section_idx].teams[team_idx].uuid;
                    student._new['sectionUuid'] = this.sections[section_idx].uuid;
                    //already updated
                } else {
                    //new change
                    student._new = {
                        teamUuid: this.sections[section_idx].teams[team_idx].uuid,
                        sectionUuid: this.sections[section_idx].uuid,
                    };
                }
                this.sections[section_idx].teams[team_idx].students.push(student);
                //magic reupdate
                this.unassigned_students.splice(data.options.unassigned_idx, 1);
            }
        },
        allowDropToUnassigned(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
        },
        allowDropToTeam(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
        },
        dragStudent(event, model, options = {}) {
            event.dataTransfer.setData('text', JSON.stringify({ student: model, options: options }));
            event.dataTransfer.dropEffect = 'move';
        },
        allowDropToSection(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
        },
        dragTeam(event, model, options = {}) {
            event.dataTransfer.setData('text', JSON.stringify({ team: model, options: options }));
            event.dataTransfer.dropEffect = 'move';
        },
        addTeam(section_idx) {
            let section = this.sections[section_idx];
            var length = section.teams.length;
            length = section.teams.push({
                _new: true,
                name: 'Team ' + (length + 1),
                students: [],
            });
            let team = section.teams[length - 1];
            team._showDetails = true;
            team._temp_id = Math.random().toString(36).slice(2);
            return length - 1;
        },
        addSection(teams = []) {
            var length = this.sections.length;
            length = this.sections.push({
                _new: true,
                name: length + 1,
                teams: teams,
            });
            let section = this.sections[length - 1];
            section._showDetails = true;
            section._temp_id = Math.random().toString(36).slice(2);
        },
        init() {
            var that = this;
            for (var i = 0; i < this.sections.length; i++) {
                let section = this.sections[i];
                section._showEditName = false;
                section._showDetails = true;
                this.natSort(section.teams, 'name');
                //section.teams = _.orderBy(section.teams, ['name'],['asc']);
                var teams = section.teams;
                for (var j = 0; j < teams.length; j++) {
                    let team = teams[j];
                    team._showEditName = false;
                    team._showDetails = true;
                    let students = team.students;
                    for (var k = 0; k < students.length; k++) {
                        //for dropdown change of section and teams
                        let student = students[k];
                        student._tempSectionUuid = student.sectionUuid;
                        student._tempTeamUuid = student.teamUuid;
                    }
                }
                this.sections[i].teams = teams;
                //Vue.set(this.sections[i], 'teams', teams);
            }
            this.search();
            //that.$emit('changed',{sections:that.sections});
        },
        openStudentInfoModal(student) {
            this.student = student;
            $('#studentInfoModal').modal('show');
        },
        toggleAccommodations() {
            this.$emit('updateManageAccommodations');
        },
        backToActivity() {
            this.$emit('getBackToModuleTab');
            $('.errorStudentsModal').modal('hide');
            // this.$router.push({
            //     // name: 'courses.index',
            //     name: 'courses.view',
            //     params: { id: this.course.uuid },
            //     query: {
            //         tab: 'module',
            //     },
            // });
        },
    },
};
</script>
<style scoped lang="scss">
.studentList {
    margin-bottom: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 1;
}

.studentList:nth-last-of-type(3) {
    margin-bottom: 0;
}

.studentList:nth-child(2) {
    margin-top: 30px;
}

.unassignedBtns {
    margin-top: 10px;
}

.studentList .header {
    padding: 15px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
}

.studentList .header.textAlignLeft {
    text-align: left;
}

.studentList .content {
    padding: 20px 25px;
}

.studentList .content.paddingBottom0 {
    padding-bottom: 0;
}

.studentList .header .random {
    text-align: left;
}

/* New Team Button */

.sectionDiv {
    margin-top: 30px;
}

.team {
    height: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.studentList .hidden-md.hidden-lg .team {
    border: 0;
}

.team .header {
    padding-left: 10px;
    background-color: #fbfbfb;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    line-height: 1;
    background-image: none;
}

.studentList .hidden-md.hidden-lg .team .header {
    border-bottom: 0;
}

.team .content {
    padding: 10px;
}

.studentList .sectionDiv .btn,
.studentList .sectionDiv .content div {
    margin-left: 0;
    margin-top: 10px;
}

.studentList .sectionDiv .btn:first-child,
.studentList .sectionDiv .content div:first-child,
.studentList .sectionDiv .btn div {
    margin-top: 0;
}

.studentList .sectionDiv .btn .dots {
    color: #717171;
    line-height: 0.5;
}

.studentList .sectionDiv .primaryHover:hover .dots {
    color: #fff;
}

.studentList .table {
    margin-bottom: 0;
}

.studentList table {
    border: none;
    border-radius: 0;
    border-top: 1px solid #d8d8d8;
}

.studentList thead tr:first-child,
.studentList thead tr:only-child {
    background-color: #fbfbfb;
    text-align: center;
    text-transform: uppercase;
    background-image: none;
}

.studentList {
    thead {
        tr {
            &:only-child {
                th {
                    font-size: 1em;
                    padding: 15px 20px;
                    &.paddingTop6 {
                        padding-top: 6px;
                    }
                    &.paddingBottom6 {
                        padding-bottom: 6px;
                    }
                    &.fontSize16 {
                        font-size: 0.88rem;
                    }
                }
            }

            &.table-header-row {
                th {
                    font-size: 0.88rem;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }
        }
    }

    .table {
        tbody {
            tr {
                td {
                    padding: 10px;
                }
            }
        }
    }
}

.studentList thead tr:last-child {
    background-color: #f6f8fa;
}

.studentList .hidden-md.hidden-lg div .btn {
    padding: 0 15px 15px;
}

.studentList .hidden-md.hidden-lg div:first-child .btn {
    padding-top: 15px;
}

.studentList .hidden-md.hidden-lg .content div .btn {
    padding: 0 0 15px;
}

.studentList .hidden-md.hidden-lg .content div:last-child .btn {
    padding: 0;
}

.studentList .hidden-md.hidden-lg div .btn .fa-chevron-right {
    color: #c5d0de;
}

@media (min-width: 992px) {
    .sectionDiv:nth-child(-n + 4),
    .unassignedBtns:nth-child(-n + 4) {
        margin-top: 0;
    }

    .studentList .sectionDiv,
    .studentList .unassignedBtns,
    .studentList .tableSectionDiv {
        width: 23.5%;
        margin-right: 2%;
    }

    .studentList .sectionDiv:nth-child(4n),
    .studentList .unassignedBtns:nth-child(4n) {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .studentList .sectionDiv,
    .studentList .unassignedBtns {
        width: 100%;
    }

    .sectionDiv:first-child {
        margin-top: 0;
    }
}

@media screen and (max-width: 767px) {
    .studentList .table-responsive {
        margin-bottom: 0;
    }
}

.manageStudents {
    .tabButtons {
        display: flex;

        button {
            border: 1px solid #d8d8d8;
            background-color: #f6f8fa;
            color: #717171;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;

            &.active {
                border: 1px solid #0071be;
                background-color: #ffffff;
                color: #222222;
            }

            &:first-child {
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .manageStudents {
        .tabButtons {
            button {
                width: 125px;
            }
        }
    }
}

@media (max-width: 991px) {
    .manageStudents {
        .tabButtons {
            margin-top: 20px;
        }
    }

    .manageStudents {
        .tabButtons {
            button {
                width: 50%;
            }
        }
    }
}

.break-word {
    word-wrap: break-word;
}

.studentList thead tr.teamNameHeader > th {
    background: #fbfbfb;
    border-bottom: 1px solid #ddd;
}
.width310px {
    width: 310px;
}

.width178px {
    width: 178px;
}

#updateSortingModal table {
    height: 250px;
    overflow: auto;
    display: block;
}

#updateSortingModal thead,
#updateSortingModal tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#updateSortingModal thead {
    position: sticky;
    top: 0;
    border-radius: 3px;
    background-color: #f6f8fa;
    -webkit-box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);
    box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);
    border-bottom: solid 1px #d8d8d8;
    text-transform: uppercase;
    background-image: none;
}

#updateSortingModal thead th {
    text-align: center;
}

#updateSortingModal .table > thead > tr > th,
#updateSortingModal .table > thead > tr > td,
#updateSortingModal .table > tbody > tr > th,
#updateSortingModal .table > tbody > tr > td,
#updateSortingModal .table > tfoot > tr > th,
#updateSortingModal .table > tfoot > tr > td {
    padding: 15px 20px;
    line-height: 1.2;
}

#updateSortingModal table th:last-child {
    border-right: solid 1px #d8d8d8;
}

#updateSortingModal table {
    margin-top: 10px;
}

#updateSortingModal thead th:first-child,
#updateSortingModal tbody td:first-child {
    width: 60px;
}

#updateSortingModal .table > tbody > tr:first-child > th,
#updateSortingModal .table > tbody > tr:first-child > td {
    border-top: 0;
}

.studentList {
    .col-xs-11 {
        min-height: 15px;
        line-height: 1;
    }
}

.courseViewStudentsButton--notDetails {
    &-edit {
        & > div {
            &:first-child {
                width: 31px;
            }

            &:last-child {
                width: calc(100% - 78px);
            }
        }
    }

    &-view {
        & > div {
            &:last-child {
                width: calc(100% - 52px);
            }
        }
    }
}
</style>
