<template>
    <div
        ref="modal"
        class="modal default-modal shareTemplateUrlModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="shareTemplateUrlModal-title"
    >
        <div class="modal-dialog">
            <div v-if="loaded" class="preview modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 v-if="template" class="modal-title">Share {{ template.name }}</h2>
                </div>
                <div class="modal-body">
                    <div class="marginBottom20 flexOnly">
                        <label class="form-switch marginRight20">
                            <input id="allowStudentsToViewTheirAnswers" v-model="template.public" type="checkbox" @change="template.public?submit():hide()"/>
                            <span class="slider round" />
                        </label>

                        <div>
                            <label for="allowStudentsToViewTheirAnswers" class="fs-18px"> Share this template </label>
                            <p class="fs-18px">Anyone with this link can import the template (InteDashboard Users only)</p>
                        </div>
                    </div>

                    <div v-if="template.public" class="flexOnly">
                        <div class="width100">
                            <input class="form-control borderTopRightRadius0 borderBottomRightRadius0" type="text" :value="template.publicUrl" readonly @keydown="handleKeyDown"></input>
                        </div>
                        <button
                            v-clipboard:copy="template.publicUrl"
                            v-clipboard:success="onCopyClipboard"
                            v-clipboard:error="onErrorClipboard"
                            class="btn btn-primary marginLeft0 borderTopLeftRadius0 borderBottomLeftRadius0"
                        >
                            <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';
const modal = ref(null);
const template = ref(null);

const loaded = ref(false);

function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function share(data = {}) {
    template.value = data;
    loaded.value = true;
    openModal();
}

function onCopyClipboard(e) {
    notify({
        group: 'form',
        type: 'success',
        title: 'Success',
        text: 'Copied to clipboard',
    });
}
function onErrorClipboard(e) {
    notify({
        group: 'form',
        type: 'error',
        title: 'Error',
        text: 'Error copying to clipboard',
    });
}
function submit() {
    axios
        .post(`templates/${template.value.uuid}/share`)
        .then(function (response) {
            let data = response.data.data;
            template.value.publicUrl = data.publicUrl;
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong with generating sharable link. Please try again later',
            });
        });
}
function hide() {
    axios
        .post(`templates/${template.value.uuid}/hide`)
        .then(function (response) {
            template.value.publicUrl = '';
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong with generating sharable link. Please try again later',
            });
        });
}

function handleKeyDown(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        onCopyClipboard();
    }
}
defineExpose({ share });
</script>
