<template>
    <div v-if="component_done_loading" class="dark_topBar flex align-items">
        <a href="#" class="d-block dark_logo">
            <img src="/img/icons/_newicons_intedashboardlogo.png" width="25px" alt="InteDashboard Logo" />
        </a>
        <div class="dark_topBarContent flexSpaceBetween align-items hidden-xs hidden-sm">
            <div>
                <button v-if="testObj.type == 'application'" class="btn btn-default" @click="openClosePresentationModal">
                    <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                </button>
                <router-link
                    v-else
                    v-slot="{ navigate }"
                    :to="{
                        name: 'activities.tests.dashboard',
                        params: { id: testObj.uuid },
                    }"
                    custom
                >
                    <button class="btn btn-default" @click="navigate">
                        <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                    </button>
                </router-link>
            </div>

            <div class="dark_title">{{ testObj.name }} Presentation Screen</div>

            <test-status
                v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                :test="testObj.status"
                role="topBarStatus"
            />

            <!-- <template v-if="testObj.status == 'not started'">
        <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger">
          Not Started
        </div>
      </template>
      <template v-else-if="testObj.status == 'paused'">
        <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning">
          Paused
        </div>
      </template>
      <template v-else-if="testObj.status == 'ongoing'">
        <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning">
          Ongoing
        </div>
      </template>
      <template v-else-if="['ended', 'completed'].includes(testObj.status)">
        <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger">
          Ended
        </div>
      </template> -->
        </div>
        <div class="dark_topBarContent hidden-md hidden-lg">
            <div class="flexSpaceBetween">
                <div>
                    <button v-if="testObj.type == 'application'" class="btn btn-default" @click="openClosePresentationModal">
                        <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                    </button>
                    <router-link
                        v-else
                        v-slot="{ navigate }"
                        :to="{
                            name: 'activities.tests.dashboard',
                            params: { id: testObj.uuid },
                        }"
                        custom
                    >
                        <button class="btn btn-default" @click="navigate">
                            <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                        </button>
                    </router-link>
                </div>
                <test-status
                    v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                    :test="testObj.status"
                    role="topBarStatus"
                />
                <!-- <template v-if="testObj.status == 'not started'">
          <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger">
            Not Started
          </div>
        </template>
        <template v-else-if="testObj.status == 'paused'">
          <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning">
            Paused
          </div>
        </template>
        <template v-else-if="testObj.status == 'ongoing'">
          <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-warning">
            Ongoing
          </div>
        </template>
        <template v-else-if="['ended', 'completed'].includes(testObj.status)">
          <div class="tableStatusTag whiteSpaceNowrap tableStatusTag-danger">
            Ended
          </div>
        </template> -->
            </div>

            <div class="dark_title marginTop10">{{ testObj.name }} Presentation Screen</div>
        </div>
        <div id="closePresentationModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="closePresentationModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="closePresentationModal-title" class="modal-title">Close Presentation Mode</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to close presentation mode?</div>
                                <div>Any e-Gallery walks will be stopped.</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-delete-activity-btn"
                                        class="btn btn-danger mainBtn"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Close"
                                        @click.prevent="closePresentationMode()"
                                    >
                                        Yes, Close
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-delete-activity-dismiss-btn"
                                        class="btn btn-default mainBtn"
                                        style="width: 100%"
                                        data-dismiss="modal"
                                        aria-label="No, Cancel"
                                    >
                                        No, cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
        };
    },
    created() {
        const toUpperCase = this.testObj.type ? this.testObj.type.toUpperCase() : '';
        document.title = `${this.testObj.name} Presentation Screen | ${toUpperCase} | InteDashboard | TBL Makes Teams Work`;
    },
    mounted() {
        this.component_done_loading = true;
    },
    methods: {
        closePresentationMode() {
            this.$router.push({
                name: 'activities.tests.dashboard',
                params: { id: this.testObj.uuid },
            });
        },
        openClosePresentationModal() {
            $('#closePresentationModal').modal('show');
        },
    },
};
</script>

<style scoped>
.dark_topBarContent {
    padding: 10px 25px;
    background-color: #495060;
    flex: 1;
}

.dark_topBar {
    background-color: #273142;
    position: fixed;
    z-index: 200;
    width: 100%;
}

.dark_title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
}

@media (min-width: 992px) {
    .dark_logo {
        padding: 10px 35px;
    }
}

@media (max-width: 991px) {
    .dark_logo {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .dark_topBarContent {
        padding: 15px;
    }
}
#closePresentationModal .modal-body .row:last-child {
    margin-top: 30px;
}
#closePresentationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#closePresentationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
