<template>
    <div class="tableStatusTag whiteSpaceNowrap" :class="tag">
        <p>
            <template v-if="label == 'time-limit'">
                <template v-if="hasTeacherStartedEarly || isStartedEarly">
                    <i class="fa fa-clock marginRight5" aria-hidden="true" />
                </template>
                <template v-if="time.days != 0"> {{ parseInt(time.days) }} Day(s) </template>
                <template v-else>
                    {{ time.hours.charAt(0) }}{{ time.hours.charAt(1) }} : {{ time.minutes.charAt(0) }}{{ time.minutes.charAt(1) }} : {{ time.seconds.charAt(0)
                    }}{{ time.seconds.charAt(1) }}
                </template>
            </template>
            <template v-else-if="label == 'starts-in'"> Starts in {{ time.diff }} </template>
            <template v-else-if="label == 'due-in'">
                <template v-if="test.type == 'prework'"> Open for </template>
                <template v-else> Due in </template>
                {{ time.diff }}
            </template>
            <template v-else>
                {{ label }}
            </template>
        </p>
    </div>
</template>
<script>
export default {
    props: ['test', 'role', 'testStatus'],
    data() {
        return {
            timer: '',
            time: {
                days: 0,
                hours: '00',
                minutes: '00',
                seconds: '00',
                diff: '',
            },
            label: '',
            tag: '',
        };
    },
    computed: {
        isTimerExtended() {
            if (!this.test) {
                return false;
            } else {
                if (_.get(this.test, 'testStudent.expectedEndingTime', false)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isStartedEarly() {
            if (!this.test) {
                return false;
            } else {
                if (this.isTimerExtended && _.get(this.test, 'testStudent.canEarlyStart', false)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        hasTeacherStartedEarly() {
            if (!this.test) {
                return false;
            } else {
                if (_.get(this.test, 'startedEarly', false) && _.get(this.test, 'expectedEndingTime', false)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    watch: {
        test: {
            handler: function () {
                if (this.role == 'topBarStatus') {
                    this.processStudentTopBarStatus();
                } else if (this.role == 'testView') {
                    this.processStudentTestStatus();
                } else if (this.role != 'student') {
                    this.processTeacherStatusDisplay();
                } else {
                    this.processStudentStatusDisplay();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.role == 'topBarStatus') {
            this.processStudentTopBarStatus();
        } else if (this.role == 'testView') {
            this.processStudentTestStatus();
        } else if (this.role != 'student') {
            this.processTeacherStatusDisplay();
        } else {
            this.processStudentStatusDisplay();
        }
    },
    beforeUnmount() {
        this.stopTimer();
    },
    methods: {
        isExtendedTimerEnded() {
            if (this.isTimerExtended) {
                var endTime = this.convertToReadableDate(_.get(this.test, 'testStudent.expectedEndingTime')).dateObj;
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                return !(distance > 0);
            } else {
                return false;
            }
        },
        processTeacherStatusDisplay() {
            this.stopTimer();
            if (!this.test) {
                this.label = 'Draft';
            }
            if (_.get(this.test, 'settings.type') == 'synchronous') {
                if (_.get(this.test, 'status') == 'not started') {
                    if (this.test.startedEarly && this.test.expectedEndingTime != null) {
                        // this.label = 'Early Start';
                        this.label = 'time-limit';
                        this.initTimer();
                    } else {
                        this.label = 'Not Started';
                    }
                } else if (_.get(this.test, 'status') == 'ongoing') {
                    if (!_.get(this.test, 'settings.hasTimeLimit')) {
                        this.label = 'Opened';
                    } else {
                        //hasTimeLimit
                        this.label = 'time-limit';
                        this.initTimer();
                    }
                } else if (_.get(this.test, 'status') == 'paused') {
                    this.label = 'Paused';
                } else if (['ended', 'completed'].includes(_.get(this.test, 'status'))) {
                    this.label = 'Closed';
                }
            } else if (_.get(this.test, 'settings.type') == 'asynchronous') {
                if (_.get(this.test, 'status') == 'scheduled') {
                    this.label = 'starts-in';
                    this.initTimer();
                } else if (_.get(this.test, 'status') == 'ongoing') {
                    this.label = 'Opened';
                } else if (['ended', 'completed'].includes(_.get(this.test, 'status'))) {
                    this.label = 'Closed';
                }
            }
            this.tag = this.label;
            if (this.label == 'Not Started' || this.label == 'Draft' || this.label == 'Closed') {
                this.tag = 'tableStatusTag-dark';
            } else if (this.label == 'Opened' || this.label == 'Early Start') {
                this.tag = 'tableStatusTag-primary';
            } else if (this.label == 'Paused') {
                this.tag = 'tableStatusTag-warning';
            }
        },
        processStudentStatusDisplay() {
            this.stopTimer();
            /*if( _.get(this.test, "testStudent.myProgression.userTestStatus") ==
          "ongoing" && ["ended", "completed"].includes(_.get(this.test, "status")))
      {
        this.test.testStudent.myProgression.userTestStatus = 'Incomplete'
        //missed case
      }*/
            if (_.get(this.test, 'settings.type') == 'synchronous') {
                if (this.isStartedEarly) {
                    this.label = 'time-limit';
                    this.initTimer();
                } else if (_.get(this.test, 'testStudent.myProgression.userTestStatus') == 'finished') {
                    this.label = 'Finished';
                } else if (_.get(this.test, 'status') == 'not started') {
                    this.label = 'Not Started';
                } else if (_.get(this.test, 'testStudent.myProgression.userTestStatus') == 'incomplete') {
                    if (this.isTimerExtended) {
                        this.label = 'Missed';
                    } else {
                        this.label = 'Incomplete';
                    }
                } else if (
                    _.get(this.test, 'status') == 'ongoing' ||
                    (_.get(this.test, 'status') != 'paused' &&
                        this.isTimerExtended &&
                        !this.isExtendedTimerEnded() &&
                        ['ongoing', null].includes(_.get(this.test, 'testStudent.myProgression.userTestStatus')))
                ) {
                    if (!_.get(this.test, 'settings.hasTimeLimit')) {
                        this.label = 'Opened';
                    } else {
                        if (
                            _.get(this.test, 'type') == 'trat' ||
                            (_.get(this.test, 'type') == 'application' && _.get(this.test, 'others.applicationType') == 'team')
                        ) {
                            if (
                                _.get(this.test, 'testStudent.myProgression.teamStatus') == 'submitted' &&
                                _.get(this.test, 'testStudent.myProgression.userTestStatus') == null
                            ) {
                                this.label = 'Missed';
                            } else {
                                //hasTimeLimit
                                this.label = 'time-limit';
                                this.initTimer();
                            }
                        } else {
                            //hasTimeLimit
                            this.label = 'time-limit';
                            this.initTimer();
                        }
                    }
                } else if (_.get(this.test, 'status') == 'paused') {
                    this.label = 'Paused';
                } else if (['ended', 'completed'].includes(_.get(this.test, 'status'))) {
                    if (this.isTimerExtended) {
                        this.label = 'time-limit';
                        this.initTimer();
                    } else {
                        this.label = 'Missed';
                    }
                }
            } else if (_.get(this.test, 'settings.type') == 'asynchronous') {
                if (_.get(this.test, 'status') == 'scheduled') {
                    this.label = 'starts-in';
                    this.initTimer();
                } else if (_.get(this.test, 'testStudent.myProgression.userTestStatus') == 'finished') {
                    this.label = 'Finished';
                } else if (_.get(this.test, 'testStudent.myProgression.userTestStatus') == 'incomplete') {
                    this.label = 'Incomplete';
                } else if (_.get(this.test, 'status') == 'ongoing') {
                    if (
                        _.get(this.test, 'testStudent.myProgression.userTestStatus') == 'started' ||
                        _.get(this.test, 'testStudent.myProgression.userTestStatus') == 'ongoing'
                    ) {
                        if (_.get(this.test, 'settings.hasTimeLimit')) {
                            this.label = 'time-limit';
                            this.initTimer();
                        } else {
                            this.label = 'due-in';
                            this.initTimer();
                        }
                    } else {
                        this.label = 'due-in';
                        this.initTimer();
                    }
                } else if (['ended', 'completed'].includes(_.get(this.test, 'status'))) {
                    if (this.test.type == 'prework') {
                        this.label = 'Closed';
                    } else {
                        this.label = 'Missed';
                    }
                }
            }

            this.tag = this.label;
            if (this.label == 'Not Started' || this.label == 'Draft' || this.label == 'Closed') {
                this.tag = 'tableStatusTag-dark';
            } else if (this.label == 'Opened') {
                this.tag = 'tableStatusTag-primary';
            } else if (this.label == 'Paused') {
                this.tag = 'tableStatusTag-warning';
            } else if (this.label == 'Finished') {
                this.tag = 'tableStatusTag-success';
            } else if (this.label == 'Missed' || this.label == 'Incomplete') {
                this.tag = 'tableStatusTag-danger';
            }
        },
        processStudentTestStatus() {
            if (this.testStatus == -1) {
                this.label = 'Wrong Answer';
                this.tag = 'danger';
            } else if (this.testStatus == 0) {
                this.label = 'Not Answered';
                this.tag = 'danger';
            } else if (this.testStatus == 1) {
                this.label = 'Answered';
                this.tag = 'default';
            }
        },
        processStudentTopBarStatus() {
            if (this.test == 'not started') {
                this.label = 'Not Started';
                this.tag = 'tableStatusTag-danger';
            } else if (this.test == 'paused') {
                this.label = 'Paused';
                this.tag = 'tableStatusTag-warning';
            } else if (this.test == 'ongoing') {
                this.label = 'Ongoing';
                this.tag = 'tableStatusTag-warning';
            } else if (['ended', 'completed'].includes(this.test)) {
                this.label = 'Ended';
                this.tag = 'tableStatusTag-danger';
            }
        },
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        async initTimer() {
            var that = this;

            let a = _.get(
                this.test,
                'myProgression.startTime',
                _.get(this.test, 'settings.startTime', _.get(this.test, 'startTime', _.get(this.test, 'settings.startDate')))
            );

            let b = _.get(
                this.test,
                'myProgression.endTime',
                _.get(this.test, 'settings.endTime', _.get(this.test, 'endTime', _.get(this.test, 'settings.endDate')))
            );

            let expectedEndingTime = _.get(this.test, 'testStudent.expectedEndingTime');

            /*For early test start teacher*/
            if (this.hasTeacherStartedEarly) {
                expectedEndingTime = _.get(this.test, 'expectedEndingTime');
            }

            if (!(a && b) && !expectedEndingTime) {
                return;
            }
            /*For early test start*/
            if (!b) {
                b = expectedEndingTime;
            }
            /*For early test start*/
            if (!a) {
                a = this.test.earlyStartTestStarted;
            }
            //sync or async uses same time.
            var startTime = this.convertToReadableDate(a).dateObj;
            var endTime = this.convertToReadableDate(b).dateObj;
            if (this.isTimerExtended || this.isStartedEarly) {
                endTime = this.convertToReadableDate(_.get(this.test, 'testStudent.expectedEndingTime')).dateObj;
            }

            if (this.hasTeacherStartedEarly) {
                endTime = this.convertToReadableDate(_.get(this.test, 'expectedEndingTime')).dateObj;
            }

            this.stopTimer();
            this.timer = setInterval(function () {
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                if (!(_.get(that.test, 'status') == 'not started' && that.hasTeacherStartedEarly)) {
                    var startTimeDuration = moment.duration(startTime.diff(now));
                    var daysFromNow = Math.floor(distance.asDays());
                    var daysFromStart = Math.floor(startTimeDuration.asDays());
                }

                if (distance > 0) {
                    if (!(_.get(that.test, 'status') == 'not started' && that.hasTeacherStartedEarly)) {
                        that.time.days = daysFromNow;
                    }
                    that.time.hours = ('0' + distance.hours()).slice(-2);
                    that.time.minutes = ('0' + distance.minutes()).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    if (that.isTimerExtended && _.get(this.test, 'testStudent.myProgression.userTestStatus') == null) {
                        that.label = 'Missed';
                    }

                    that.time.days = 0;
                    that.time.hours = '00';
                    that.time.minutes = '00';
                    that.time.seconds = '00';
                    that.stopTimer();
                }

                //totalDistance.subtract(1,'seconds');

                if (that.test.settings.type == 'synchronous') {
                    const percentile = (100.0 * now.diff(startTime)) / endTime.diff(startTime);

                    if (percentile >= 80.0) {
                        that.tag = 'tableStatusTag-danger';
                    } else if (percentile >= 50.0) {
                        that.tag = 'tableStatusTag-warning';
                    } else if (percentile >= 0) {
                        that.tag = 'tableStatusTag-primary';
                    }

                    if (_.get(that.test, 'status') == 'not started' && that.hasTeacherStartedEarly) {
                        that.tag = 'tableStatusTag-primary';
                    }
                } else {
                    if (that.label == 'starts-in') {
                        if (daysFromStart != 0) {
                            that.time.diff = daysFromStart + ' Day(s) ';
                        } else {
                            that.time.diff =
                                ('0' + startTimeDuration.hours()).slice(-2) +
                                ' : ' +
                                ('0' + startTimeDuration.minutes()).slice(-2) +
                                ' : ' +
                                ('0' + startTimeDuration.seconds()).slice(-2);
                        }
                        if (startTimeDuration.hours() == 0 && daysFromStart == 0) {
                            that.tag = 'tableStatusTag-danger';
                        } else if (startTimeDuration.hours() != 0 && daysFromStart == 0) {
                            that.tag = 'tableStatusTag-warning';
                        } else if (startTimeDuration.hours() != 0 && daysFromStart != 0) {
                            that.tag = 'tableStatusTag-primary';
                        }
                    } else if (that.label == 'due-in') {
                        if (daysFromNow != 0) {
                            that.time.diff = daysFromNow + ' Day(s) ';
                            //that.time.diff = ("0" + distance.days()).slice(-2) + " Day(s) ";
                        } else {
                            that.time.diff =
                                ('0' + distance.hours()).slice(-2) +
                                ' : ' +
                                ('0' + distance.minutes()).slice(-2) +
                                ' : ' +
                                ('0' + distance.seconds()).slice(-2);
                        }

                        const percentile = (100.0 * now.diff(startTime)) / endTime.diff(startTime);

                        if (percentile >= 80.0) {
                            that.tag = 'tableStatusTag-danger';
                        } else if (percentile >= 50.0) {
                            that.tag = 'tableStatusTag-warning';
                        } else if (percentile >= 0) {
                            that.tag = 'tableStatusTag-primary';
                        }
                    } else {
                        const percentile = (100.0 * now.diff(startTime)) / endTime.diff(startTime);

                        if (percentile >= 80.0) {
                            that.tag = 'tableStatusTag-danger';
                        } else if (percentile >= 50.0) {
                            that.tag = 'tableStatusTag-warning';
                        } else if (percentile >= 0) {
                            that.tag = 'tableStatusTag-primary';
                        }
                    }
                }
            }, 1000);
        },
    },
};
</script>
<style lang="scss">
.tableStatusTag {
    text-align: center;
    padding: 6px 8px 4px 8px;
    border-radius: 44px;
    background-color: #fff;
    font-size: 0.88rem;
    line-height: 1;

    p {
        font-size: 0.88rem;
        display: inline-block;
    }

    &.tableStatusTag-dark,
    &.Starts {
        background: #efefef;
        border: 1px solid #717171;
        color: #3c3c43;
    }

    &.tableStatusTag-primary {
        background: #93c4f9;
        border: 1px solid #0071be;
        color: #3c3c43;
    }

    &.tableStatusTag-warning {
        background: #e3c1a0;
        border: 1px solid #ac631b;
        color: #3c3c43;
    }

    &.tableStatusTag-success {
        background: #a5c9b7;
        border: 1px solid #3e7c5b;
        color: #3c3c43;
    }

    &.tableStatusTag-danger {
        background: #e6aeae;
        border: 1px solid #ca3333;
        color: #3c3c43;
    }

    &.danger {
        border: 1px solid #ca3333;
        background: #ca3333;
        color: #fff;
    }

    &.default {
        border: 1px solid #efefef;
        background: #efefef;
        color: #717171;
    }

    &.answered {
        border: 1px solid #3e7c5b;
        background: #3e7c5b;
        color: #ffffff;
    }
}

.testSidebar-status {
    font-size: 0.667rem;
    line-height: 1;
    padding: 6px 12px;

    p {
        font-size: 0.667rem;
        line-height: 1;
    }

    &.danger {
        padding: 6px 8px;
    }
}
</style>
